<template>
  <div>
    <drive :idarchivo="idarchivo" @idarchivo="idarchivo=$event" ></drive>
    {{idarchivo}}
  </div>
</template>
<script>
import drive from "@/components/drive";
import moment from 'moment';
moment.locale('es');
  export default {
    components : {drive},
    data: () => ({

      idarchivo:''
    }),
    mounted() {
      let tz = moment.tz.guess();
      console.log(tz)
    },
    methods:{

    },
    watch:{
      idarchivo: function (val) {
        //this.fullName = val + ' ' + this.lastName
        alert(val)
      }
    }
  }
</script>
<style scoped>

</style>
<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog v-if="dialogObjetivo" :value="true" @input="dialogObjetivo = false" persistent max-width="790">
          <v-card elevation="2" class="mx-auto">
            <v-card-title>
              <v-icon large class="mr-2" color="green">mdi-message-text-outline</v-icon><h3>Agregar Oferta Académica - Currícula Curso</h3>
              <v-spacer></v-spacer>

            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12">
                  <template>
                    <v-select
                        label='Oferta Académica:'
                        v-model='idOferta'
                        :items='ofertas'
                        item-value='id'
                        item-text='nombre_oferta_academica'
                        outlined
                        return-object
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre_oferta_academica }}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre_oferta_academica }}
                      </template>
                    </v-select>
                  </template>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <template>
                    <v-select
                        label='Curricula:'
                        v-model='idCurricula'
                        :items='curriculas'
                        item-value='id'
                        item-text='nombre_curricula'
                        outlined
                        return-object
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre_curricula }}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre_curricula }}
                      </template>
                    </v-select>
                  </template>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-btn @click="guardar" small>
                    {{txtboton}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="closeImportar()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-card elevation="2" class="mx-auto">
      <v-card-title>
        <v-icon large class="mr-2" color="green">mdi-message-text-outline</v-icon><h3>Grupo - Oferta Académica - Currícula</h3>
        <v-spacer></v-spacer>

      </v-card-title>
      <v-card elevation="2">
        <v-card-title>
          <v-row
              align="center"
              justify="space-around"

          >
            <v-btn
                color="green"
                small
                @click="showUnidad()"
                title="Crear Unidad"
            >
              <v-icon  small color="white" >mdi-message-text-outline</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card elevation="2">
        <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table :headers="headers" :items="concentrado" class="elevation-1">
          <template v-slot:item.eliminar="{ item }">
            <v-btn
                fab elevation="2"
                color="red"
                small
                x-small
                title="Eliminar Oferta Académica"
            >
              <v-icon  small @click="deleteOferta(item)" title="Eliminar Oferta Académica - Currícula" color="white">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data: vm => ({
    opacity: 0.46,
    search:'',
    zIndex: 5,
    nombre:'',
    idTipoReactivo:'',
    escuela_id:1,
    refrescar_tabla:0,
    abreviatura:'',
    tiporeactivo:[],
    banco:[],
    item:[],
    concentrado:[],
    historial_importaciones:[],
    snack: false,
    snackColor: '',
    snackText: '',
    respuestasTotal:0,
    max25chars: v => v.length <= 25 || 'Input too long!',
    pagination: {},
    headers: [
      {text: 'Id',align: 'start',sortable: true,value: 'id'},
      { text: 'Oferta Academica', value: 'oferta_academica.nombre_oferta_academica',align: 'start'},
      { text: 'Currícula', value: 'curricula_curso.nombre_curricula' , align: 'center'},
      { text: 'Eliminar', value: 'eliminar',align:'center'},
    ],
    overlay: false,
    absolute:true,
    isModalVisible: false,
    dialog: false,
    dialogObjetivo: false,
    dialogAyuda:false,
    tipoaccion:'',
    buscar_contador:0,
    checkstatus : null,
    nombre_unidad:'',
    clave_interna:'',
    txtboton:'',
    estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
    idOfertaAcademica:0,
    modolectura:false,
    //variables consideradas de la tabla para cada campo solicitado
    nombre_oferta_academica:'',
    descripcion:'',
    estatus:'',
    imagen:'',
    publicado:0,
    switchpublicado:1,
    date_inicio: new Date().toISOString().substr(0, 10),
    fecha_inicio: vm.formatDate(new Date().toISOString().substr(0, 10)),
    date_finalizacion: new Date().toISOString().substr(0, 10),
    fecha_finalizacion: vm.formatDate(new Date().toISOString().substr(0, 10)),

    date_inicio_publicacion: new Date().toISOString().substr(0, 10),
    fecha_inicio_publicacion: vm.formatDate(new Date().toISOString().substr(0, 10)),

    date_fin_publicacion: new Date().toISOString().substr(0, 10),
    fecha_fin_publicacion: vm.formatDate(new Date().toISOString().substr(0, 10)),
    copia_date:new Date().toISOString().substr(0, 10),
    copia_fecha:vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    idOferta: '',                ofertas: [],                 oferta_id: '',
    idCurricula: '',             curriculas: [],              curricula_id: '',
  }),
  created () {
    this.init();
  },
  computed: {
    /*   computedDateFormatted:function  () {
         return this.formatDate(this.date_inicio)
       },
       computedfecha_finalizacion:function  () {
         return this.formatDate(this.date_finalizacion)
       },*/
  },
  watch: {
    date_inicio (val) {
      this.fecha_inicio = this.formatDate(this.date_inicio)
    },
    date_finalizacion (val) {
      this.fecha_finalizacion = this.formatDate(this.date_finalizacion)
    },
    date_inicio_publicacion (val) {
      this.fecha_inicio_publicacion = this.formatDate(this.date_inicio_publicacion)
    },
    date_fin_publicacion (val) {
      this.fecha_fin_publicacion = this.formatDate(this.date_fin_publicacion)
    },

  },
  methods: {
    getDateTime(date) {
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      //return `${month}/${day}/${year}`
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      //const [month, day, year] = date.split('/')
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    init () {
      this.getOfertas()       //obtiene los reactivos filtrados por el usuario actual.
      this.getCurriculas()
      this.getOfertasCurriculas()
    },
    closeModal() {
      this.dialog = false
    },
    closeAyuda(){
      this.dialogAyuda =  false
    },
    closeImportar(){
      this.nombre_unidad    =  '';
      this.clave_interna      =  '';
      this.descripcion        =  '';
      this.txtboton           =  '';
      this.dialogObjetivo     =  false
      this.modolectura        =  false;
    },
    consultar(id){
      this.idTipoReactivo = id;
      if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
        this.showModal();
      }
      else if (this.idTipoReactivo==6)
        this.showModal();
    },
    consultarNivelEducativo(){
      let url = '';
      url = `/api/grados?idnivel=${this.idNivel.id}`
      this.$http.get(url)
          .then((response) => {
            this.grados = response.data;
            if(this.tipoaccion==2 ) {
              this.idGrado = this.grados.find(item => item.id == this.grado_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    consultarArea(){
      let url = '';
      url = `/api/asignaturas?idArea=${this.idArea.id}`
      this.$http.get(url)
          .then((response) => {
            this.asignaturas = response.data;
            if(this.tipoaccion==2 ){
              this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },

    deleteOferta (item){
      Swal.fire({
        title: '¿Desea eliminar el Grupo- Oferta Académica - Currícula, con ID: '+ item.id + '?',
        showDenyButton: true,
        confirmButtonText: `Eliminar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          let url = `/api/aprende/curricula-curso-oferta/${item.id}`
          this.$http
              .delete(url)
              .then((response) => {
                this.getOfertasCurriculas()
                Swal.fire('Oferta Académica - Currícula eliminada satisfactoriamente.', '', 'success')
              })
              .catch(function (error){
                console.log(error)
              })

        } else if (result.isDenied) {
          //Swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    editReactivo(item){
      this.item                 =   item;
      this.dialogObjetivo       =   true;
      this.idTipoReactivo       =   item.tiporeactivo.tiporeactivo_concentrado.id;
      this.tipoaccion           =   2;
      this.estatusAccionTabla   =   2;
    },
    editOferta(item) {
      this.idOfertaAcademica          =   item.id;
      this.nombre_oferta_academica    =   item.nombre_oferta_academica;
      this.descripcion                =   item.descripcion;
      this.imagen                     =   item.imagen;
      this.publicado                  =   item.publicado;
      if (this.publicado)
        this.switchpublicado=true
      else
        this.switchpublicado=false;

      this.date_inicio                =   item.fecha_inicio;
      this.fecha_inicio               =   this.formatDate(item.fecha_inicio);

      this.date_finalizacion          =   item.fecha_finalizacion;
      this.fecha_finalizacion         =   this.formatDate(item.fecha_finalizacion);

      this.date_inicio_publicacion   =   item.fecha_inicio_publicacion;
      this.fecha_inicio_publicacion   =   this.formatDate(item.fecha_inicio_publicacion);

      this.date_fin_publicacion      =   item.fecha_fin_publicacion;
      this.fecha_fin_publicacion      =   this.formatDate(item.fecha_fin_publicacion);

      this.txtboton                   =   "Actualizar"
      this.modolectura                =   true;
      this.estatusAccionTabla         =   2;
      this.dialogObjetivo             =   true;
    },
    getOfertas(){
      let url = `/api/aprende/ofertaAcademica`
      this.$http.get(url)
          .then((response) => {
            //console.log(response.data)
            this.ofertas = response.data;
          })
          .catch(function (error){
            console.log(error)
          })
    },
    getCurriculas(){
      let url = `/api/aprende/curriculas`
      this.$http.get(url)
          .then((response) => {
            // console.log("curriculas:  ")
            // console.log(response.data)
            this.curriculas = response.data;
          })
          .catch(function (error){
            console.log(error)
          })
    },
    getOfertasCurriculas(){
      let url = `/api/aprende/curricula-curso-oferta`
      this.$http.get(url)
          .then((response) => {
            console.log("ofertas-curriculas:  ")
            console.log(response.data)
            this.concentrado = response.data;
          })
          .catch(function (error){
            console.log(error)
          })
    },
    guardar(){
      if(this.estatusAccionTabla==1){
        this.$http.post('/api/aprende/curricula-curso-oferta', {
          'CURRICULA_CURSO_ID':        this.idCurricula.id,
          'OFERTA_ACADEMICA_ID':       this.idOferta.id,
        })
            .then((response) => {
              if(response.data){
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Registro guardado exitosamente.',
                  showConfirmButton: false,
                  timer: 2500
                })
                this.getOfertasCurriculas()
                //this.getOfertas()  llenar el arreglo
                // this.concentrado.unshift(response.data) //inserta el elemento al principio del Arreglo
                // this.setLimpiarVariables();
              }
              else{
                Swal.fire({
                  position: 'top-end',
                  icon: 'warning',
                  title: 'Registro existente, favor de verificar.',
                  showConfirmButton: false,
                  timer: 2500
                })
              }
            })
            .catch(function (error) {
              console.log(error)
            })
      }
      else if(this.estatusAccionTabla==2){
        if(this.switchpublicado==true)
          this.publicado = 1
        else
          this.publicado = 0
        this.$http.post('/api/aprende/ofertaAcademica/'+ this.idOfertaAcademica, {
          _method:    'PUT',
          'nombre_oferta_academica':        this.nombre_oferta_academica,
          'descripcion':                    this.descripcion,
          'imagen':                         this.imagen,
          'publicado':                      this.publicado,
          'fecha_inicio':                   this.getDateTime(this.fecha_inicio),
          'fecha_finalizacion':             this.getDateTime(this.fecha_finalizacion),
          'fecha_inicio_publicacion':       this.getDateTime(this.fecha_inicio_publicacion),
          'fecha_fin_publicacion'   :       this.getDateTime(this.fecha_fin_publicacion),
        })
            .then((response) => {
              if(response.data){
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Oferta Académica actualizada exitosamente.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.getOfertas()
              }
              else{
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Unidad no actualizada. Verificar.',
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            })
            .catch(function (error) {
              console.log(error)
            })
      }

    },
    setLimpiarVariables(){
      this.nombre_oferta_academica = '';
      this.descripcion  = '';
      this.publicado  =0;
      this.imagen     ='',
          this.switchpublicado  = 1;
      this.date_inicio = this.copia_date
      this.fecha_inicio= this.copia_fecha
      this.date_finalizacion= this.copia_date
      this.fecha_finalizacion= this.copia_fecha

      this.date_inicio_publicacion= this.copia_date
      this.fecha_inicio_publicacion= this.copia_fecha
      this.date_fin_publicacion= this.copia_date
      this.fecha_fin_publicacion= this.copia_fecha
    },
    showImportar(){
      this.dialogObjetivo = true;
    },
    showObjetivos(item){
      this.$router.push({ path: '/dashboardUnidadesObjetivos', query: { idUnidad: btoa(item.id),nombre_unidad:btoa(item.nombre_unidad),descripcion:btoa(item.descripcion)} })
    },
    showRequisitosUnidades(item){
      this.$router.push({ path: '/dashboardRequisitosUnidades', query: { idUnidad: btoa(item.id),nombre_unidad:btoa(item.nombre_unidad),descripcion:btoa(item.descripcion)} })
    },
    showRequisitosCursos(item){
      this.$router.push({ path: '/dashboardRequisitosCursos', query: { idUnidad: btoa(item.id),nombre_unidad:btoa(item.nombre_unidad),descripcion:btoa(item.descripcion)} })
    },

    showModal() {
      this.dialog = true;
    },
    showUnidad() {
      this.dialogObjetivo     = true;
      this.txtboton           = "Guardar";
      this.estatusAccionTabla =   1;
    },
    totalrespuestas(total){
      this.respuestasTotal =  total;
    },
  },
  mounted(){
  }
}
</script>

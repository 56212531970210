<template>
  <v-container fluid>
    <v-row>
        <v-col :cols="12">         

                     <v-card elevation="4">
                        <v-card-title>
                            <v-icon large class="mr-2" color="purple">
                                mdi mdi-account-multiple
                            </v-icon>
                            <h3>Alta de alumnos</h3>

                        </v-card-title>
                        <v-card-text>

                          <v-row>
                            <v-card-text>
                              <v-alert
                                  dense
                                  type="info"
                              >
                               Se debe de utilizar un documento <strong>google spreadsheet</strong> desde su Google Drive, hacerlo público y pegar el link del vínculo en este formulario. Tomar de referencia la plantilla
                                <a target="_blank" class="white--text" href="https://docs.google.com/spreadsheets/d/1xY53leNrYrQ7xteNMJTqxYebRFGSznWZzx6hCmpIHb8">aquí</a>
                              </v-alert>
                              <div>

                                <div class="text-right mb-4">
                                  <v-btn color="primary" @click="dialogAyuda = !dialogAyuda" small><v-icon small>mdi-help</v-icon></v-btn>
                                </div>
                                <v-alert :value="dialogAyuda" color="primary" dark border="top" icon="mdi-home" transition="scale-transition">
                                  <p>Para importar alumnos debe seguir el siguiente procedimiento:</p>
                                  <p>1. Una vez creado el archivo google spreadsheet, no debe cambiarle la extension</p>
                                  <p>2. Llene así corresponda las columnas Nombres, Apellido1, Apellido2, Correo y CURSO.</p>
                                  <p>3. Una vez que haya concluido la captura de los datos correspondientes, desde su cuenta google drive se debe generar la ruta para compartirlo.</p>
                                </v-alert>
                              </div>

                              <template>
                                <v-img width="800px" height="auto" src="https://i.imgur.com/R5O9AU4.png"></v-img>
                                <br>
                                  <v-card-title>
                           <v-icon large class="mr-2" color="purple" style="left:20px">
                                mdi mdi-arrow-down-thick
                            </v-icon>
                             <v-icon large class="mr-2" color="purple" style="left:5px; right:20px">
                                mdi mdi-arrow-down-thick
                            </v-icon>
                            <h3>Colocar aquí la liga del spreadsheet</h3>
                            <v-icon large class="mr-2" color="purple" style="left:20px">
                                mdi mdi-arrow-down-thick
                            </v-icon>
                             <v-icon large class="mr-2" color="purple" style="left:5px">
                                mdi mdi-arrow-down-thick
                            </v-icon>
                        </v-card-title>
                                <br>
                                <v-text-field ref="nombreURL" required :rules="rulesss" v-model="archivo" label="Ruta del archivo"></v-text-field>
                                <v-progress-circular
                                    :size="50"
                                    v-if="loadingDocument"
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                              </template>



                            </v-card-text>
  
                          </v-row>


                          <v-btn v-if="!loadingDocument"
                                 color="primary"
                                 dark
                                 @click="upload"
                                 class="primary my-2 ml-auto "
                                >Subir Archivo
                          </v-btn>
                        </v-card-text>
                    </v-card>
                    
        </v-col>

   </v-row>
  

  </v-container>

</template>

<script>

export default {
    data() {
        return {
           rulesss: [
                v => !!v || 'Dato requerido',
                v => this.isURL(v) || v.length >=44 || 'No es una URL',
            ],
          archivo:[],
          dialogAyuda:false,
        //  zonaHoraria:Intl.DateTimeFormat().resolvedOptions().timeZone,
         // offset : new Date().getTimezoneOffset(),
          loadingDocument : false,
          dialogImportar : false,
        }
    },
     computed: {

    },

    watch: {

    },

  methods: {
    isURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
    save(){
        console.log("OK")
    },
    setVariables(){
          this.archivo          = '';
    },
    showImportar() {
      this.dialogImportar = true;
    },
    upload(){
      //this.loadingDocument = true;
      
        let file  = (this.archivo)   ? this.archivo : 0;

        if(this.archivo==0 ) { 
             Swal.fire(
                        '¡Uuppss!',
                        'El campo URL es necesario',
                        'error'
                        )
              this.$refs.nombreURL.focus();
            return 
            }

          this.$http.post('/api/cuentas-alumnos', {
                'archivo': this.archivo
          })
          .then( (response) => {
             //console.log(response.data)
            alert('La importacion esta en proceso...')

            
             Swal.fire(
                        'Excelente!!',
                        'La importacion esta en proceso...',
                        'success'
                        )


                this.setVariables();
        //         this.loadingDocument = false;
          })
          .catch(function (error) {
            console.log(error)

          })


    },
    mounted () {
     

    },

  }

}
</script>

<style scoped>

</style>
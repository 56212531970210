<template>
  <div>
    <div v-for="(pregunta, indexpregunta) in preguntas">
      <v-card
          class="elevation-3 mx-auto px-5 py-5 mb-5"
      >

        <div v-if="(pregunta.reactivo.tiporeactivo_id==6)">
          <v-row>
            <v-col class="text-left" lg="8" md="8" sm="8">
              <label><h3>Complete las preguntas en blanco:</h3></label>
              <img
                  v-if="pregunta.reactivo.urlimagen_pregunta"
                  :src="pregunta.reactivo.urlimagen_pregunta"
                  alt="Ver imagen completa"
                  style="cursor: pointer"
                  title="Ver imagen completa"
                  @click="visualizarImagen(pregunta.reactivo.urlimagen_pregunta)"
              />
            </v-col>
            <v-col class="text-right" lg="4" md="4" sm="4">
              <h3>{{ pregunta.valor_pregunta_puntos }}ptos</h3>
            </v-col>
          </v-row>
          <autocompletar
              :idExamen="parseInt(examen_id)"
              :onlyeread="1"
              :pregunta="pregunta.reactivo"
              :student_stauts_exam="student_stauts_exam"></autocompletar>
        </div>

        <div v-else>
          <v-row>
            <v-col class="text-left" lg="8" md="8" sm="8">
              <h3 class="">{{ pregunta.reactivo.descripcion }}</h3>
              <img
                  v-if="pregunta.reactivo.urlimagen_pregunta"
                  :src="pregunta.reactivo.urlimagen_pregunta"
                  alt="Ver imagen completa"
                  style="cursor: pointer"
                  title="Ver imagen completa"
                  @click="visualizarImagen(pregunta.reactivo.urlimagen_pregunta)"
              />
            </v-col>
            <v-col class="text-right" lg="4" md="4" sm="4">
              <h3 class="font-weight-bold">{{ pregunta.valor_pregunta_puntos }}ptos</h3>
            </v-col>
          </v-row>
          <label><h5>{{ pregunta.reactivo.instrucciones }}</h5></label>

          <iframe v-if="( pregunta.reactivo.enlace_externo_youtube )"
                  :src="'https://www.youtube.com/embed/' + pregunta.reactivo.enlace_externo_youtube"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  frameborder="0"
                  title="YouTube video player"></iframe>

          <iframe v-if="( pregunta.reactivo.youtube_video_id )"
                  :src="'https://www.youtube.com/embed/' + pregunta.reactivo.youtube_video_id"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  frameborder="0"
                  title="YouTube video player"></iframe>
          <div v-if="pregunta.reactivo.tiporeactivo_id == 2">
            <v-checkbox
                v-for="descripcion in pregunta.reactivo.respuestas"
                :input-value="( descripcion.reactivo_contestado && (descripcion.id == descripcion.reactivo_contestado.respuesta_id) ) ? true : false"
                @change="saveResponses(pregunta.reactivo.id, descripcion.id, pregunta.reactivo.tiporeactivo_id, $event)"
            >
              <template v-slot:label>
                {{ descripcion.respuesta }}
                <img
                    v-if="descripcion.imagen_respuesta"
                    :src="descripcion.imagen_respuesta"
                    alt="Ver imagen completa"
                    style="margin-left: 10px"
                    title="Ver imagen completa"
                    @click="visualizarImagen(descripcion.imagen_respuesta)"
                />
              </template>
            </v-checkbox>
          </div>
          <div v-else="(pregunta.reactivo.tiporeactivo_id == 1) || (pregunta.reactivo.tiporeactivo_id == 3)">
            <v-radio-group
                :value="( pregunta.reactivo.reactivo_contestado ) ? pregunta.reactivo.reactivo_contestado.respuesta_id : 0">
              <v-radio
                  v-for="(itemradio, index) in pregunta.reactivo.respuestas"
                  :id="'respuesta-' + itemradio.id"
                  :value="itemradio.id"

                  @change="saveResponses(pregunta.reactivo.id, itemradio.id, pregunta.reactivo.tiporeactivo_id)"
              >
                <template v-slot:label>
                  {{ itemradio.respuesta }}
                  <img
                      v-if="itemradio.imagen_respuesta"
                      :src="itemradio.imagen_respuesta"
                      alt="Ver imagen completa"
                      style="margin-left: 10px"
                      title="Ver imagen completa"
                      @click="visualizarImagen(itemradio.imagen_respuesta)"
                  />
                </template>

              </v-radio>
            </v-radio-group>

          </div>

        </div>
        <uploadpdf
            v-if="pregunta.reactivo.permitir_justificante"
            :examen_aplicado_alumno_id="pregunta.examen_aplicado_alumno_id"
            :examen_id="parseInt(examen_id)"
            :indexpregunta="indexpregunta"
            :url_justificante="(pregunta.url_justificante)?parseInt(pregunta.url_justificante):0"
            @justificante_data="justificante_data = $event"></uploadpdf>
      </v-card>

    </div>
  </div>
</template>
<script>
import autocompletar from '@/views/Evaluacion/AutoCompletar'
import uploadpdf from "@/components/uploadpdf";
export default {
  name: 'preguntasexamen',
  props: ['preguntas', 'examen_id'],
  components: {autocompletar, uploadpdf},
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    saveResponses(reactivo_id, id_respuesta, tiporeactivo_id, ev = null) {
      this.$http.post('/api/evaluacionalumno', {
        reactivo_id: reactivo_id,
        id_respuesta: id_respuesta,
        tiporeactivo_id: tiporeactivo_id,
        examen_id: this.examen_id,
        checked: (ev) ? 1 : 0
      }).then(response => {

      });
    },
    visualizarImagen(imagen) {
      this.src_imagen_visualizar = imagen
      // this.dialogVisualizarImagen = true
      window.open(this.src_imagen_visualizar, "_blank")
    },
  }
}
</script>

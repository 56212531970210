<template>
  <div>
    <h1>Cuenta de acceso</h1>
    <v-alert
        dense
        type="info"
        class="pa-5"
    >Favor de completar los siguientes datos para ingresar a su cuenta:</v-alert>
    <v-card class="pa-5 elevation-1">
      <v-card-text class="pa-0 ma-0">


        <v-row>
<!--          <v-col cols="2" lg="2" md="12">
            <div align="center">
              <v-img
                  width="200"
                  height="200"
                  lazy-src="https://i.imgur.com/TQR1sff.png"
                  src="https://i.imgur.com/RHDXuAA.jpg"
              ></v-img>
&lt;!&ndash;              <v-file-input
                  @change="getImage()"
                  v-model="usuario.foto_perfil"
                  label="Seleccionar una imagen para cambiarla"
              ></v-file-input>&ndash;&gt;
              <div>
                <small>Usted no tiene permisos para subir una foto, puede dejar este campo vacío.</small>
              </div>
            </div>
          </v-col>-->
          <v-col cols="12" lg="12" md="12">
            <v-text-field v-model="usuario.nombres" label="Nombre(s)"></v-text-field>

            <v-text-field v-model="usuario.apellido_p" label="Primer apellido"></v-text-field>

            <v-text-field v-model="usuario.apellido_m" label="Segundo apellido"></v-text-field>

            <v-text-field v-model="usuario.identificacion" label="CURP o documento de identidad de nacimiento"></v-text-field>

            <v-select item-text="nombre" item-value="id" label="Seleccione su país" :items="itemsPises" v-model="usuario.pais" return-object></v-select>

            <v-btn v-if="(!datos_completados)&&(show_button)" @click="guardarInformacionUsuario" class="primary">Guardar cambios</v-btn>

            <v-alert
                v-if="datos_completados"
                class="mt-5"
                outlined
                type="success"
                text
            >
              Se ha completado con éxito el proceso de su cuenta. Hemos enviado un email a su cuenta principal de correo electrónico con la cual podrá acceder al sistema. <v-btn @click="aceptarCuenta" class="green">Aceptar</v-btn>
            </v-alert>

          </v-col>
        </v-row>
        <small>Todos los campos de este formulario son requeridos y no pueden quedar vacíos, podrá modificarlos en un futuro dentro de su cuenta.</small>
      </v-card-text>
    </v-card>

  </div>
</template>
<script>
export default {
  data:() => ({
    show_button : true,
    datos_completados : false,
    itemsPises : [],
    usuario : {
      pais : [],
      //foto_perfil: [],
      nombres : '',
      apellido_p : '',
      apellido_m : '',
      identificacion : '',
    }
  }),
  mounted() {
    this.getPaises();
  },
  methods : {
    getPaises() {
      this.$http.get("/api/paises")
      .then(response => {
        this.itemsPises = response.data;
      })
      .catch(response => {

      })
    },
    aceptarCuenta() {
      let url = 'logout'
      this.$http.post(url)
      .then(response => {
        window.location.href = 'inicio';
      }).catch(error => {

      });
    },
    guardarInformacionUsuario() {
      this.show_button = false;
      this.$http.post('/api/aprende/users/update-user', this.usuario)
      .then(response => {
        if ( parseInt(response.data) == 1 ) {
          this.datos_completados = true;
          this.show_button = true;
        }
      })
      .catch(error => {
        alert("Por favor complete todos los datos del formulario")
        this.show_button = true;
      })
/*      let formData = new FormData();
      console.log(this.usuario.foto_perfil)
      //formData.append('file', this.usuario.foto_perfil);
      formData.append('nombres', this.usuario.nombres);
      formData.append('apellido_m', this.usuario.apellido_m);
      formData.append('apellido_p', this.usuario.apellido_p);
      formData.append('identificacion', this.usuario.identificacion);

      this.$http.post( '/api/aprende/users/update-user',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(){
        console.log('SUCCESS!!');
      })
      .catch(function(){
        console.log('FAILURE!!');
      });*/
    },
    handleFileUpload(){
      this.usuario.foto_perfil = this.$refs.file.files[0];
    }
  }
}
</script>
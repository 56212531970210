<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog v-if="dialogImportar" :value="true" @input="dialogImportar = false" persistent max-width="790">
          <v-card elevation="2" class="mx-auto">
            <v-card-title>
              <v-icon large class="mr-2" color="green">mdi-folder-download</v-icon><h3>Importar Preguntas</h3>
              <v-spacer></v-spacer>
              <label>Seleccione los datos de las Preguntas a Importar:</label>
            </v-card-title>
            <v-card-text>
              <div>
                <div class="text-right mb-4">
                  <v-btn color="primary" @click="dialogAyuda = !dialogAyuda" small><v-icon small>mdi-help</v-icon></v-btn>
                </div>
                <v-alert :value="dialogAyuda" color="green" dark border="top" icon="mdi-home" transition="scale-transition">
                  <b>Para el llenado de la hoja de cálculo para las importaciones, capturar en la columna correspondiente:</b><br>
                  <b>TÍTULO: </b>En esta sección se redacta la pregunta completa.<br>
                  <b>INSTRUCCIONES:</b> Aquí se redactan las instrucciones específicas para cada pregunta (no es indispensable).
                  <b>TIEMPO EN RESPONDER: </b><b>0.5 </b> equivale a 30 segundos, <b>1 </b>=1 minuto, <b>2 </b>=2 minutos, <b>3 </b>=3 minutos, <b>4 </b>=4 minutos, <b>5 </b>=5 minutos,
                  <b>7</b>=10 minutos,<b>8 </b>=15 minutos,<b>9 </b>=20 minutos,<b>10 </b>=25 minutos,<b>11 </b>= 30 minutos<br>
                  <b>NIVEL DE PREGUNTA: </b><b>1, 2 ó 3</b><br>
                  <b>TIPO DE VISIBILIDAD: </b><b>1 </b>Privado, <b>2 </b>Institucional<br>
                  <b>CRITERIO PARA CALIFICAR: </b><b>1 </b>Todo <b>2 </b>Distribuido<br>
                  <b>TIPO DE PREGUNTA: </b>
                  <b>1 </b>Opción múltiple única respuesta
                  <b>2 </b>Opción múltiple varias respuestas
                  <b>3 </b>Verdadero / Falso
                  <b>4 </b>Rellenar espacios en blanco.<br>
                  <b>TIPOELEMENTO: </b>Colocar <b>P</b> si en la fila está redactando una pregunta y <b>R</b> en caso de estar
                  redactando las características de una respuesta.<br>
                  <b>TITULO DE LA RESPUESTA: </b>Para los tipos de pregunta 1 y 2 se redacta la opción de respuesta.<br>
                  Para las preguntas de tipo 3, se redacta por opción <b>V</b> para verdadero y <b>F</b> para falso.<br>
                  <b>ES RESPUESTA CORRECTA. </b> Para las preguntas de Tipo <b>1</b>, se debe colocar la palabra <b>OK</b>, para la respuesta que sea correcta.<br>
                  Para las preguntas de Tipo <b>2 </b> se debe colocar la palabra <b>OK</b>, para todas las respuestas que sean correctas.<br>
                  Para las preguntas de Tipo <b>3</b> se debe colocar la palabra <b>OK</b> para la respuesta que según corresponda.<br>
                  <b>Para la redacción de Preguntas de Tipo 4</b> en la sección de título colocar las respuestas entre /* */, por ejemplo:<br>
                  Después del Planeta Tierra se encuentra el Planeta <b>/*</b>Marte;MARTE<b>*/</b> . El 15 de septiembre de <b>/*</b>1810<b>*/</b> fue el año de inicio de la independencia
                  en México.<br>
                  <b>NOTA:</b>Por cada /* */ debe colocar un reglón de Respuesta y en la columna TIPOELEMENTO, colocar <b>R</b>
                </v-alert>
              </div>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <template>
                    <v-select
                        label='Nivel Educativo:'
                        v-model='idNivel'
                        :items='niveles'
                        item-value='id'
                        item-text='nombre'
                        outlined
                        id="selectidnivel"
                        return-object
                        @change="consultarNivelEducativo"
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                      </template>
                    </v-select>
                  </template>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <template>
                    <v-select
                        label='Grado:'
                        v-model='idGrado'
                        :items='grados'
                        item-value='id'
                        item-text='nombre'
                        outlined
                        id="selectidGrado"
                        return-object
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                      </template>
                    </v-select>
                  </template>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <template>
                    <v-select
                        label='Area de Conocimiento:'
                        v-model='idArea'
                        :items='areas'
                        item-value='id'
                        item-text='nombre'
                        outlined
                        id="selectidArea"
                        return-object
                        @change="consultarArea"
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                      </template>
                    </v-select>
                  </template>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <template>
                    <v-select
                        label='Asignatura:'
                        v-model='idAsignatura'
                        :items='asignaturas'
                        item-value='id'
                        item-text='nombre'
                        outlined
                        id="selectidAsignatura"
                        return-object
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                      </template>
                    </v-select>
                  </template>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-btn @click="buscarImportaciones" small>
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  Historial de Importaciones
                  <v-data-table
                      :headers="headers_rpt"
                      :items="historial_importaciones"
                  >
                    <template v-slot:item.estatus="{ item }">
                      <div v-if="item.estatus==1"><v-icon small color="orange" title="No Importado">mdi-checkbox-multiple-blank-outline</v-icon></div>
                      <div v-if="item.estatus==2"><v-icon small color="green" title="Importado">mdi-check</v-icon></div>
                    </template>
                    <!--  <template #item.url="{ value }">
                          <a target="_blank" :href="value" >
                              <v-icon small>mdi-pencil</v-icon>
                          </a>
                      </template>-->
                    <template v-slot:item.actions="{ item }">
                      <v-btn small title="Ver concentrado">
                        <a target="_blank" :href="item.url" title="Ver" style="text-decoration: none;">
                          <v-icon small>mdi-table-edit</v-icon>
                        </a>
                      </v-btn>
                      <v-btn
                          @click="importarReactivos(item)"
                          small
                          title="Importar"
                          v-if="item.estatus==1"
                      >
                        <v-icon small color="green">mdi-cloud-download</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="closeImportar()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-if="dialog" :value="true" @input="dialog = false"
            persistent
            max-width="690"
        >
          <v-card>
            <v-card-text>
              <div v-if="idTipoReactivo<4 && tipoaccion==1">
                <reactivounico  @gettotalRespuestasSeleccionadas = "listenerRespuestasSelect($event)" @getTotalRespuestas = "listenerTotalRespuestas($event)" :tiporeactivo="idTipoReactivo" :tipoaccion="tipoaccion"  @getrefrescar_tabla = "listener($event)" ></reactivounico>
              </div>
              <div v-if="idTipoReactivo==6 && tipoaccion==1">
                <reactivorellenar :tiporeactivo="idTipoReactivo" :tipoaccion="tipoaccion"  @getrefrescar_tabla = "listener($event)"></reactivorellenar>
              </div>
              <div v-if="idTipoReactivo<4 && tipoaccion==2 ">
                <reactivounico @gettotalRespuestasSeleccionadas = "listenerRespuestasSelect($event)" @getTotalRespuestas = "listenerTotalRespuestas($event)"    :enlace_externo_youtube_edit="item.enlace_externo_youtube" :show_youtube_info="parseInt(show_youtube_info)" :youtube_video_id="item.youtube_video_id" :tiporeactivo="item.tiporeactivo.tiporeactivo_concentrado.id" :tipoaccion="tipoaccion" :escuela_id_prop="item.escuela.escuela_concentrado.id" :nivel_id="item.nivel.nivel_concentrado.id" :grado_id="item.grado.grado_concentrado.id" :area_id="item.area.area_concentrado.id" :asignatura_id="item.asignatura.asignatura_concentrado.id" :descripcion_prop="item.descripcion" :instrucciones_prop="item.instrucciones" :id="item.id" :tiemporeactivo="item.tiemporeactivo" :nivelreactivo="item.nivelreactivo" :tipovisibilidad="item.tipovisibilidad" @getrefrescar_tabla = "listener($event)" :criterio_id="item.criterio.criterio_id" :urlimagen_pregunta="item.urlimagen_pregunta" :permitir_justificante="item.permitir_justificante"></reactivounico>
              </div>
              <div v-if="idTipoReactivo==6 && tipoaccion==2">
                <reactivorellenar :tiporeactivo="item.tiporeactivo.tiporeactivo_concentrado.id" :tipoaccion="tipoaccion" :escuela_id="item.escuela.escuela_concentrado.id" :nivel_id="item.nivel.nivel_concentrado.id" :grado_id="item.grado.grado_concentrado.id" :area_id="item.area.area_concentrado.id" :asignatura_id="item.asignatura.asignatura_concentrado.id" :descripcion_prop="item.descripcion" :descripcion_copia_prop="item.descripcion_copia" :instrucciones_prop="item.instrucciones" :id="item.id" :tiemporeactivo="item.tiemporeactivo" :nivelreactivo="item.nivelreactivo" :tipovisibilidad="item.tipovisibilidad" @getrefrescar_tabla = "listener($event)" :criterio_id="item.criterio.criterio_id" :urlimagen_pregunta="item.urlimagen_pregunta" :permitir_justificante="item.permitir_justificante"></reactivorellenar>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="closeModal()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-card elevation="2" class="mx-auto">
      <v-card-title>
        <v-icon large class="mr-2" color="green">mdi-equal-box</v-icon><h3>Banco de Preguntas</h3>
        <v-spacer></v-spacer>
        <v-select label='Crear reactivo:' v-model='idTipoReactivo' :items='tiporeactivo' item-value='id' item-text='nombre'
                  outlined

                  @input="setSelected">
          <template slot='selection' slot-scope='{ item }'>
            {{ item.nombre}}
          </template>
          <template slot='item' slot-scope='{ item }'>
            {{ item.nombre}}
          </template>
        </v-select>
      </v-card-title>
      <v-card elevation="2">
        <v-card-title>
          <v-row
              align="center"
              justify="space-around"
              title="Importar Preguntas"
          >
            <v-btn
                color="green"
                small
                @click="showImportar()"
            >
              <v-icon  small color="white" >mdi-folder-download</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card elevation="2">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table :headers="headers" :items="banco" :search="search" class="elevation-1">
          <template v-slot:item.tipovisibilidad="{ item }">
            <v-icon
                small
                class="mr-2"
                color="orange"
                v-if="item.tipovisibilidad==1"
                title="Privado"
            >
              mdi-account
            </v-icon>

            <v-icon
                small
                class="mr-2"
                color="blue"
                v-if="item.tipovisibilidad==2"
                title="Institucional"
            >
              mdi-web
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <v-btn
                  fab elevation="2"
                  color="green"
                  small
                  x-small
              >
                <v-icon small color="white" title="Editar reactivo" @click="editReactivo(item)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  fab elevation="2"
                  color="red"
                  small
                  x-small
                  v-if="item.tipovisibilidad!=2"
              >
                <v-icon  small @click="deleteReactivo(item)" title="Eliminar Reactivo" color="white">mdi-delete</v-icon>
              </v-btn>
            </div>

          </template>
        </v-data-table>

      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import reactivounico from './reactivo_unico.vue'
import reactivorellenar from './reactivorellenar.vue'
export default {
  components: {
    reactivounico, //registro del componente
    reactivorellenar
  },
  data:() => ({
    show_youtube_info: 0,
    search:'',
    nombre:'',
    idTipoReactivo:'',
    escuela_id:1,
    refrescar_tabla:0,
    abreviatura:'',
    tiporeactivo:[],
    banco:[],
    item:[],
    historial_importaciones:[],
    respuestasTotal:0,
    headers: [
      {text: 'Id',align: 'start',sortable: true,value: 'id'},
      // {text: 'Escuela',align: 'start',sortable: false,value: 'escuela.escuela_concentrado.nombre'},
      { text: 'Grado', value: 'grado.grado_concentrado.nombre'},
      //{ text: 'Área', value: 'area.area_concentrado.nombre'},
      { text: 'Asignatura', value: 'asignatura.asignatura_concentrado.nombre' },
      { text: 'Descripcion', value: 'descripcion',align: 'justify' },
      { text: 'Tipo de Pregunta', value: 'tiporeactivo.tiporeactivo_concentrado.nombre' },
      { text: 'Tipo Visibilidad', value: 'tipovisibilidad' },
      { text: 'Dificultad', value: 'nivelreactivo'},

      { text: 'Acciones', value: 'actions', sortable: true },
    ],
    headers_rpt: [
      { text: 'Nombre',value: 'nombre_documento_importado'},
      //{ text: "Ver Concentrado", value: "url" },
      { text: "Estatus", value: "estatus" },
      { text: "Preguntas importadas", value: "reactivos_importados", align:"center" },
      { text: 'Acciones',value:'actions'},
    ],
    overlay: false,
    cargando_historial:false,
    absolute:true,
    isModalVisible: false,
    dialog: false,
    dialogImportar: false,
    dialogAyuda:false,
    tipoaccion:'',
    idNivel:'',
    idGrado:'',
    idArea:'',
    idAsignatura:'',
    niveles:[],
    grados:[],
    areas:[],
    asignaturas:[],
    buscar_contador:0,
    checkstatus : null,
    totalRespuestas : 0,
    totalSeleccionadas : 0

  }),
  created () {
    this.init();
  },
  methods: {
    init () {
      this.getTipoReactivo()    //se ocupa para el listado de crear reactivos
      this.getReactivos()       //obtiene los reactivos filtrados por el usuario actual.
      this.getCatalogos()
    },
    buscarImportaciones(){
      // AEJECUTAR FUNCION QUE CREA EL SHEET Y LO GUARDA EN SU TABLA CORRESPONDIENTE
      this.buscar_contador++
      if(this.buscar_contador==1 && (this.idNivel.id>0) && (this.idGrado.id>0) && (this.idArea.id>0) && (this.idAsignatura.id>0)   ){
        this.checkstatus =  setInterval(() => {
          console.log("actualizando historial...")
          this.buscarImportaciones();
          this.getReactivos();
        }, 10000)
      }
      this.$http.post('/api/importarreactivos', {
        'idNivel':          this.idNivel.id,
        'idGrado':          this.idGrado.id,
        'idArea':           this.idArea.id,
        'idAsignatura':     this.idAsignatura.id
      })
          .then((response) => {
            console.log(response.data)
            this.historial_importaciones = response.data
          })
          .catch(function (error){
            console.log(error)
          })

    },
    importarReactivos(item){
      //FUNCIONAMIENTO PARA EL BOTON IMPORTAR REACTIVOS
      this.$http.post('/api/importarreactivos/get-preguntas', {
        'sheetId': item.sheetId,
      })
          .then((response) => {
            this.buscarImportaciones(); //Actualizar el dataTable de Importaciones
            this.getReactivos();        //Actualizar el Banco de Reactivos
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Importando Preguntas...',
              showConfirmButton: false,
              timer: 1500
            })
          })
          .catch(function (error){
            console.log(error)
          })
    },
    setSelected(value){
      this.idTipoReactivo = value;
      this.dialog = true;
      this.tipoaccion=1;
    },
    showModal() {
      this.dialog = true;
    },
    showImportar() {
      this.dialogImportar = true;
      this.cargando_historial = true;

    },
    closeModal() {
      //alert("respuestas seleccionadas" + this.totalSeleccionadas)
      if(this.totalRespuestas<=0){
        Swal.fire(
            'Atención',
            'Debe capturar una opción de respuesta.',
            'warning'
        )
      }
      else if(this.totalSeleccionadas<=0){
        Swal.fire(
            'Atención',
            'Debe seleccionar una respuesta correcta.',
            'warning'
        )
      }
      else{
        //this.totalSeleccionadas = 0

        this.getReactivos()
        this.dialog = false
      }

    },
    closeAyuda(){
      this.dialogAyuda =  false
    },
    closeImportar(){
      this.dialogImportar =  false
      clearInterval(this.checkstatus);
      this.buscar_contador    =   0;
    },
    consultar(id){
      this.idTipoReactivo = id;
      if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
        //this.isModalVisible = true;
        this.showModal();
        //this.$router.push({ path: '/reactivounico', query: { tiporeactivo: this.idTipoReactivo,tipoaccion:1} })
      }
      else if (this.idTipoReactivo==6)
        this.showModal();
    },
    actualizarActivo(item){
      if(item.alumnocontactado==null){
        item.activo = 0;
      }
      else
        item.activo = 1;
      //console.log('alumnoContactado actualizado: ' + alumnocontactado + 'id:' + id)
      this.overlay = true;
      this.$http.post('/api/sistemaseducativos', {

        'id':               item.id,
        'atributo':         2,
        'alumnocontactado':  item.alumnocontactado,

      })
          .then((response) => {
            // console.log(response.data)
            this.overlay = false;
          })
          .catch(function (error){
            // console.log(error)
            this.overlay = false;
          })

    },
    deleteReactivo (item){


      Swal.fire({
        title: 'Atención',
        html: 'Una vez que confirme, usted deberá actualizar las calificaciones finales de los exámenes donde este reactivo se haya preguntado. <br><a target="_blank" href="/calcular-calificaciones">Ir al módulo de cálculo de calificaciones</a>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {

          //this.overlay = true;
          let url = `/api/reactivos/${item.id}`
          this.$http
              .delete(url)
              .then((response) => {
                this.getReactivos()
              })
              .catch(function (error){
                console.log(error)
                //this.overlay = false;
              }).finally(() => {
                Swal.fire(
                    'Excelente',
                    'El reactivo se eliminó correctamente',
                    'success'
                )
          })


        }
      })

/*      var refs = ''
      refs = confirm('¿Deseas eliminar la respuesta: ' + item.descripcion + '?')
      if (refs) {

      }
      else {
        // cancel
      }*/
    },
    editReactivo(item){
      this.item = item;
      this.dialog = true;
      this.idTipoReactivo = item.tiporeactivo.tiporeactivo_concentrado.id;
      this.tipoaccion=2;
      if ( this.item.youtube_video_id ) {
        this.show_youtube_info = 1
      }
      //console.log("item")
      //console.log(item)
      //this.$router.push({ path: '/reactivounico', query: { tipoaccion:2,escuela_id: item.escuela.escuela_concentrado.id,nivel_id:item.nivel.nivel_concentrado.id,grado_id:item.grado.grado_concentrado.id,area_id:item.area.area_concentrado.id,asignatura_id:item.asignatura.asignatura_concentrado.id,tiporeactivo_id:item.tiporeactivo.tiporeactivo_concentrado.id,descripcion:item.descripcion,instrucciones:item.instrucciones,id:item.id}})
    },
    getReactivos(){
      let url = `/api/reactivos?escuela_id=${this.escuela_id}`
      this.$http.get(url)
          .then((response) => {
            this.banco = response.data;
            //console.log("reactivos")
            //console.log(this.banco)
          })
          .catch(function (error){
            console.log(error)
          })
    },
    getCatalogos(){
      let url = `/api/niveleseducativos/get-NivelEducativoAreaConocimiento`
      this.$http.get(url)
          .then((response) => {
            //console.log(response)
            this.niveles = response.data.nivel_educativo;
            this.areas   = response.data.area_conocimiento;
          })
          .catch(function (error){
            console.log(error)
          })
    },
    listener(indicador) {
      if (indicador)
        this.getReactivos();

    },
    listenerTotalRespuestas(event){
      this.totalRespuestas = event;
    },
    listenerRespuestasSelect(val){
      this.totalSeleccionadas = val;
    },
    totalrespuestas(total){
      this.respuestasTotal =  total;
    },
    consultarNivelEducativo(){
      let url = '';
      url = `/api/grados?idnivel=${this.idNivel.id}`
      this.$http.get(url)
          .then((response) => {
            this.grados = response.data;
            if(this.tipoaccion==2 ) {
              this.idGrado = this.grados.find(item => item.id == this.grado_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    consultarArea(){
      let url = '';
      url = `/api/asignaturas?idArea=${this.idArea.id}`
      this.$http.get(url)
          .then((response) => {
            this.asignaturas = response.data;
            if(this.tipoaccion==2 ){
              this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    getTipoReactivo () {
      let url = '/api/tiporeactivo'
      this.$http.get(url)
          .then((response) => {
            this.tiporeactivo = response.data;
          })
          .catch(function (error){
                console.log(error)
              }

          )
    }
  },

  mounted(){

  }
}
</script>

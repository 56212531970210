<template>
<v-container>
    <v-card
      elevation="2"
     >
     <v-card-title>
         <v-icon large class="mr-2" color="green">mdi-equal-box</v-icon><h3>{{texto}}</h3>
          </v-card-title>
      <v-container class="grey lighten-5">
        <v-row >
          <v-col cols="6" sm="6">
                <template>
                    <v-select
                    label='Nivel Educativo:'
                    v-model='idNivel'
                    :items='niveles'
                    item-value='id'
                    item-text='nombre'
                    return-object
                    @change="consultarNivelEducativo"
                    >
                        <template slot='selection' slot-scope='{ item }'>
                            {{ item.nombre}}
                        </template>
                        <template slot='item' slot-scope='{ item }'>
                            {{ item.nombre}}
                        </template>
                    </v-select>
                </template>      
            </v-col>
            <v-col cols="6" sm="6">
                <template>
                    <v-select
                    label='Grado:'
                    v-model='idGrado'
                    :items='grados'
                    item-value='id'
                    item-text='nombre'
                    return-object
                    >
                    <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template> 
                    </v-select>
                </template>      
            </v-col>
            <v-col cols="6" sm="6">
                <template>
                    <v-select
                    label='Area de Conocimiento:'
                    v-model='idArea'
                    :items='areas'
                    item-value='id'
                    item-text='nombre'
                    @change="consultarArea"
                    return-object
                    >
                    <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template> 
                    </v-select>
                </template>      
            </v-col>
            <v-col cols="6" sm="6">
                <template>
                    <v-select
                    label='Asignatura:'
                    v-model='idAsignatura'
                    :items='asignaturas'
                    item-value='id'
                    item-text='nombre'
                    return-object
                    >
                    <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template> 
                    </v-select>
                </template>      
            </v-col>
      </v-row>
      <v-row >
    <v-col
      cols="12"
      sm="12"
      class="py-2"
    >
        <p><b>Para la redacción del reactivo, ingrese entre /* */ la respuesta y separe varias respuestas con comas, por ejemplo: es un planeta cercano a la Tierra /*Venus*/</b></p>

    </v-col>
      </v-row>
   <v-row >
        <v-col
        cols="12"
        sm="12"
        >
            <v-textarea
            label="Descripcion:"
            v-model="descripcion"
            v-bind:value="descripcion"
            :rows="3"
            dense
            id="txtdescripcion"
            @click="actualizarDescripcionOriginal()"
             ></v-textarea>
        </v-col>
   </v-row>
        <v-row>
          <v-col cols="8" sm="8">
            <div v-if="imagen_seleccionada==1">
              <a :href="url_imagen_seleccionada" target="_blank">
                <v-img
                    :src="url_imagen_seleccionada"
                    width="150px"
                    height="auto"
                ></v-img>
              </a>
            </div>
          </v-col>
          <v-col cols="4" sm="4">
            <drive :idarchivo="idarchivo" @idarchivo="idarchivo=$event"></drive>
          </v-col>
        </v-row>
   <v-row >
        <v-col
                  cols="12"
                  sm="12"
              >
                  <v-textarea
                      label="Instrucciones:"
                      v-model="instrucciones"
                      v-bind:value="instrucciones"
                      :rows="2"
                      dense
                      id="txtinstrucciones"
                  ></v-textarea>
              </v-col>
   </v-row>
          <v-row>
              <v-col cols="12" sm="4" md="4">
                  <template>
                      <v-select
                          label='Tiempo en responder:'
                          v-model='idtiemporeactivo'
                          :items='arraytiemporeactivo'
                          item-value='id'
                          item-text='nombre'
                          outlined
                          id="selecttiemporeactivos"
                          return-object
                      >
                          <template slot='selection' slot-scope='{ item }'>
                              {{ item.nombre}}
                          </template>
                          <template slot='item' slot-scope='{ item }'>
                              {{ item.nombre}}
                          </template>
                      </v-select>
                  </template>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                  <template>
                      <v-select
                          label='Nivel de Pregunta:'
                          v-model='idnivelreactivo'
                          :items='arraynivelreactivo'
                          item-value='id'
                          item-text='nombre'
                          outlined
                          id="selectnivelreactivo"
                          return-object
                      >
                          <template slot='selection' slot-scope='{ item }'>
                              {{ item.nombre}}
                          </template>
                          <template slot='item' slot-scope='{ item }'>
                              {{ item.nombre}}
                          </template>
                      </v-select>
                  </template>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                  <template>
                      <v-select
                          label='Tipo de visibilidad:'
                          v-model='idtipovisibilidad'
                          :items='arraytipovisibilidad'
                          item-value='id'
                          item-text='nombre'
                          outlined
                          id="selecttipovisibilidad"
                          @change="consultartipovisibilidad"
                          return-object
                      >
                          <template slot='selection' slot-scope='{ item }'>
                              {{ item.nombre}}
                          </template>
                          <template slot='item' slot-scope='{ item }'>
                              {{ item.nombre}}
                          </template>
                      </v-select>
                  </template>
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="6" sm="4" md="4">
                  <template>
                      <v-select
                          label='Criterio para calificar pregunta:'
                          v-model='idCriterio'
                          :items='arraycriterios'
                          item-value='id'
                          item-text='nombre_criterio'
                          outlined
                          id="selectcriterio"
                          return-object
                      >
                          <template slot='selection' slot-scope='{ item }'>
                              {{ item.nombre_criterio}}
                          </template>
                          <template slot='item' slot-scope='{ item }'>
                              {{ item.nombre_criterio}}
                          </template>
                      </v-select>
                  </template>
              </v-col>
              <v-col cols="6" sm="4" md="4">
                <template>
                  <v-switch
                      label="Permitir justificante"
                      v-model="permitir_justificante_val"
                  ></v-switch>
                </template>
              </v-col>
          </v-row>
      <v-row >
            <v-col cols="12" sm="12">
                <v-btn
                color="primary"
                dark
                id="btnReactivo"
                class="mb-2"                
                @click="guardarReactivo()"
                >{{textobtn}}</v-btn>
            </v-col>
      </v-row>
          <v-row>
              <v-col cols="12" sm="12">
              <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
              <v-data-table
                  :headers="headers"
                  :items="respuestas"
              >
                  <template v-slot:item.respuesta="{ item }">
                      <v-text-field
                          v-model="item.respuesta"
                          v-bind:value="item.respuesta"
                          dense
                          readonly
                      ></v-text-field>
                  </template>

                  <template v-slot:item.tiporespuesta="{ item }">
                      <v-select
                          v-model="item.tiporespuesta"
                          v-bind:value="item.tiporespuesta"
                          :items='arraytipoderespuesta'
                          item-value='id'
                          item-text='nombre'
                          :disabled="validarSelect(item.respuesta)"
                          @change="guardarTipodeRespuesta(item.tiporespuesta,item.id,item.respuesta)"
                      >
                          <template slot='selection' slot-scope='{ item }'>
                              {{ item.nombre }}
                          </template>
                          <template slot='item' slot-scope='{ item }'>
                              {{ item.nombre }}
                          </template>
                      </v-select>
                  </template>
              </v-data-table>
              </v-col>
          </v-row>
      </v-container>
    </v-card>
 </v-container>
</template>

<script>
  import drive from "@/components/drive";
  export default {
      props:{
          tiporeactivo: {
            type: Number,
            required: true,
          },
          tipoaccion: {
              type: Number,
              required: false,
          },
          escuela_id_prop: {
              type: Number,
              required: false,
          },
          nivel_id: {
              type: Number,
              required: false,
          },
          grado_id: {
              type: Number,
              required: false,
          },
          criterio_id: {
              type: Number,
              required: false,
          },
          area_id: {
              type: Number,
              required: false,
          },
          asignatura_id: {
              type: Number,
              required: false,
          },
          descripcion_prop: {
              type: String,
              required: false,
          },
          descripcion_copia_prop: {
              type: String,
              required: false,
          },
          instrucciones_prop: {
              type: String,
              required: false,
          },
          id: {
              type: Number,
              required: false,
          },
          tiemporeactivo: {
              type: Number,
              required: false,
          },
          nivelreactivo: {
              type: Number,
              required: false,
          },
          tipovisibilidad: {
              type: Number,
              required: false,
          },
          urlimagen_pregunta: {
            type: String,
            required: false,
          },
          permitir_justificante: {
            type: Number,
            required: false,
          }
      },
    components: {drive},
    data:() => ({
        arraycriterios:[],
        arraytipoderespuesta: [],
        arraytiemporeactivo: [],
        arraynivelreactivo:[],
        arraytipovisibilidad:[],
        arreglorespuestas:[],
        activobtn:'',
        absolute: false,
        caracteranterior:'',
        caracterposterior:'',
        caracteresperado:'',
        caractercierre1:'',
        caractercierre2:'',
        caracterapertura1:'',
        caracterapertura2:'',
        descripcion:'',
        descripcion_copia:'',
        dialog: false,
        editedIndex: -1,
        oculto:true,
        escuela_id:0,
        instrucciones:'',
        nombre:'',
        idAsignatura:'',
        idEscuela:'',
        idReactivo:'',
        permitir_justificante_val:0,
        idarchivo: [],
        imagen_seleccionada: 0,
        url_imagen_seleccionada: '',
        url_imagen_seleccionada_copia: '',
        obj_imagen_pregunta: [],

        guardando:false,
        niveles:[],
        banco:[],
        areas:[],
        grados:[],
        respuestas:[],
        texto:'',
        textobtn:'Guardar',
        textbtn:'Agregar eactivo',
        asignaturas:[],
        respaldodescripcion:'',
        respuesta:'',
        snack: false,
        snackColor: '',
        snackText: '',
        headers: [
          {text: 'No.',align: 'start',sortable: false,value: 'contador'},
          {text: 'Respuesta',align: 'start',sortable: false,value: 'respuesta'},
          {text: 'Tipo de Respuesta',align: 'start',sortable: false,value: 'tiporespuesta'},

        ],
        overlay: false,
        posicion:1,
        cadenaoriginal:'',
        palabrarespuesta:'',
        numerorespuestas:0,

        parabierto:0,
        idtiemporeactivo:'',
        idnivelreactivo:'',
        idtipovisibilidad:'',
        idCriterio:'',
        idNivel:'',
        idGrado:'',
        idArea:'',
        singleSelect: true,
        selected: [],
        ContienePuntoyComa:false,
    }),
    computed: {
        /*   formTitle () {
            return this.editedIndex === -1 ? 'Agregar Reactivo' : 'Editar Reactivo'
          }, */
    },
    created () {

    },    
    methods: {
    init () {

    },
    validarSelect(palabra){
        return (palabra.indexOf(';')>=0)?true:false

    },
    actualizarCorrecta(item){
      this.guardando = true;
      if(item.correcta==null){    
        item.correcta=0;
      }
      else
        item.correcta = 1;
        //console.log("tipo dereactivo:"+this.tiporeactivo)
                this.$http.post('/api/respuestas', {           
                'id':               item.id,
                'correcta':         item.correcta,
                'reactivo_id':      this.idReactivo,
                'tiporeactivo_id':  this.tiporeactivo             
                })
                 .then((response) => {
                   this.getRespuestas();
                   this.guardando = false;
                    //this.overlay = false;
                })
                .catch(function (error){
                    //this.overlay = false;
                })
                
    },
    actualizarDescripcionOriginal(){
        //this.descripcion = this.descripcion_copia;
    },
    actualizarRespuesta(item){
                this.guardando = true;
                this.$http.post('/api/respuestas', {            
                  'id':               item.id,
                  'respuesta':        item.respuesta           
                })
                 .then((response) => {
                     Swal.fire({
                         position: 'top-end',
                         icon: 'success',
                         title: 'Respuesta actualizada exitosamente.',
                         showConfirmButton: false,
                         timer: 1500
                     })
                     this.getRespuestas();
                    this.guardando = false;
                    //this.overlay = false;
                })
                .catch(function (error){
                   // console.log(error)
                   // this.overlay = false;
                })
                
    },
    consultarNivelEducativo(){
        let url = `/api/grados?idnivel=${this.idNivel.id}`
        this.$http.get(url)
        .then((response) => {
            this.grados = response.data;
            if(this.tipoaccion==2){
                this.idGrado = this.grados.find(item => item.id == this.grado_id)
            }
        })
        .catch(function (error){
            console.log(error)
        })
    },
    consultarGrado(id){
            this.idGrado = id;
    },
    consultarArea(){
            let url = `/api/asignaturas?idArea=${this.idArea.id}`
            this.$http.get(url)
            .then((response) => {
               this.asignaturas = response.data;
                if(this.tipoaccion==2){
                  this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
                }
            })
            .catch(function (error){
                    console.log(error)
            })
    },
    consultarAsignatura(id){
            this.idAsignatura = id;
    },
    consultartiemporeactivos(id){
            this.tiemporeactivo = id;
    },
    consultarnivelreactivo(id){
            this.nivelreactivo = id;
    },
    consultartipovisibilidad(){
        if(this.idtipovisibilidad.id==2){
            Swal.fire(
                'Atención',
                '<p>Al seleccionar este tipo de visibilidad <b>(Institucional)</b>, ' +
                'la pregunta estará disponible para su uso entre los demás usuarios de su misma escuela.</p>'+
                'Así mismo acepta de que su pregunta ya se encuentra bien redactada, ya que después de <b>guardar</b> '+
                '<b>no podrá ser modificada, ni eliminada.</b>',
                'warning'
            )
        }
    },
    deleteRespuesta (item){
      var refs = ''
      refs = confirm('¿Deseas eliminar la respuesta: ' + item.respuesta + '?')
      if (refs) {
          //this.overlay = true;
          let url = `/api/respuestas/${item.id}`
          this.$http
          .delete(url)
          .then((response) => {
            this.getRespuestas()
          })
          .catch(function (error){
            console.log(error)
            //this.overlay = false;
          })
      }
      else {
        // cancel
      }       
    },
    guardarReactivo(){
        var lon = 0;
        lon = this.descripcion.length;
        var letra = '';
        let megas = 0;
        let actualizar_imagen = 0
        megas = ((this.obj_imagen_pregunta.sizeBytes) / 1024) / 1024
        if(this.permitir_justificante_val==false)
          this.permitir_justificante_val=0
        else
          this.permitir_justificante_val=1
        for (var i=0; i<lon; i++){
            letra = this.descripcion[i];
            if (letra == '/' && this.caracterapertura1==''){     
                this.caracterapertura1 = "/";
                this.caracteresperado = '*';
                this.parabierto = 1;
            }
            else if(this.parabierto == 1 && this.caracteranterior=='/' && letra!='*'){
              this.cadenaoriginal = this.cadenaoriginal + '/' + letra;
              this.parabierto = 0;
            }
       /*      else if(this.parabierto == 2 && this.caracteranterior=='*' && (letra!='*' || letra!='/')){
              this.cadenaoriginal = this.cadenaoriginal + '/*' + letra;

            } */
            else if (letra==this.caracteresperado && this.caracteranterior=='/' && this.caracterapertura1=='/'){
              this.parabierto = 2;
              this.caracterapertura2 = "*";
              this.caractercierre1 = '*';
            }
/*             else if (letra == '[' && this.caracteresperado==']'){
                this.cadenaoriginal = this.cadenaoriginal + '[' + this.palabrarespuesta + ' ';
                this.palabrarespuesta = '';
            } */
            else if (letra == this.caractercierre2 && this.caracteranterior == this.caractercierre1 && this.caractercierre1=='*'){
                    this.caracterapertura1 = '';
                    this.caracterapertura2 = '';

                    //this.arreglorespuestas = this.palabrarespuesta;
                    //this.arreglorespuestas[this.numerorespuestas] = this.palabrarespuesta;
                    this.arreglorespuestas.push({"respuesta":this.palabrarespuesta});
                    this.numerorespuestas = this.numerorespuestas + 1;
                    this.cadenaoriginal = this.cadenaoriginal + ' /*' + 'Respuesta' + this.numerorespuestas + '*/';
                    this.caractercierre1 = '';
                    this.palabrarespuesta = '';
                    this.caractercierre2 = '';
                    this.parabierto = 0;

            }
            else if (letra != this.caractercierre1 && this.caracterapertura1 =='/' && this.caracterapertura2 == '*' && (letra!='*' || letra!='/')){
                this.palabrarespuesta = this.palabrarespuesta + letra;
            }
            else if(letra==this.caractercierre1 && this.caractercierre2 == ''){
              this.parabierto = 3;
              this.caractercierre2 = '/';
              this.caracteresperado='/';

            }
            else{
                this.cadenaoriginal = this.cadenaoriginal + letra;
            }
            this.caracteranterior = letra;
        }

        if (this.parabierto==2){
            this.cadenaoriginal = this.cadenaoriginal + "*" + this.palabrarespuesta;
         } 
         else if (this.caracteranterior=='/' && this.parabierto==1){
           this.cadenaoriginal = this.cadenaoriginal +'/';
         }
         if (this.descripcion == ''){
             Swal.fire(
                 'Atención',
                 'Debe capturar la Pregunta.',
                 'warning'
             )
             txtdescripcion.focus()
         }
         else if (megas > 3) {
           Swal.fire(
               'Atención',
               'El tamaño máximo del archivo no debe exceder los 3 megabytes.',
               'warning'
           )
           txtdescripcion.focus();
         }
        else if (this.instrucciones == ''){
            this.instrucciones = ' ';
         }
         else{

/*             if(this.descripcion.indexOf(";")>=0){
                 this.ContienePuntoyComa=true
             }*/

              if (this.idReactivo==''){ //insert
                     this.$http.post('/api/reactivos', {
                         'escuela_id':           this.idEscuela,
                         'nivel_id':             this.idNivel.id,
                         'grado_id':             this.idGrado.id,
                         'area_id':              this.idArea.id,
                         'asignatura_id':        this.idAsignatura.id,
                         'tiporeactivo_id':      this.tiporeactivo,
                         'descripcion':          this.cadenaoriginal,
                         'descripcion_copia':    this.descripcion,
                         'instrucciones':        this.instrucciones,
                         'respuestas':           this.arreglorespuestas,
                         'tiemporeactivo':       this.idtiemporeactivo.id,
                         'nivelreactivo':        this.idnivelreactivo.id,
                         'tipovisibilidad':      this.idtipovisibilidad.id,
                         'criterio_id':          this.idCriterio.id,
                         'obj_imagen_pregunta':  this.obj_imagen_pregunta,
                         'permitir_justificante':this.permitir_justificante_val
                     })
                         .then((response) => {
                            this.idReactivo = response.data.id;
                            this.textobtn = 'Editar';
                            this.texto='Editar reactivo: Rellenar en blanco';
                            this.descripcion = response.data.descripcion_copia;
                            Swal.fire(
                             '¡Guardado!',
                             'La pregunta ha sido guardada exitosamente.',
                             'success'
                            )
                            //this.descripcion_copia = response.data.descripcion_copia;
                            this.getRespuestas();
                            /*reiniciando variables para analizar nueva descripcion*/
                          })
                          .catch(function (error){
                              console.log(error)
                           })
              }
              else{//UPDATE
                if(this.url_imagen_seleccionada!=this.url_imagen_seleccionada_copia){
                  actualizar_imagen = 1
                }
                else{
                  this.obj_imagen_pregunta = 0;
                }
                if(actualizar_imagen){
                  megas = ((this.obj_imagen_pregunta.sizeBytes) / 1024) / 1024
                }
                  let params={
                      'idReactivo':             this.idReactivo,
                      'escuela_id':             this.idEscuela,
                      'nivel_id':               this.idNivel.id,
                      'grado_id':               this.idGrado.id,
                      'area_id':                this.idArea.id,
                      'asignatura_id':          this.idAsignatura.id,
                      'tiporeactivo_id':        this.tiporeactivo,
                      'descripcion':            this.cadenaoriginal,
                      'descripcion_copia':      this.descripcion,
                      'instrucciones':          this.instrucciones,
                      'respuestas':             this.arreglorespuestas,
                      'tiemporeactivo':         this.idtiemporeactivo.id,
                      'nivelreactivo':          this.idnivelreactivo.id,
                      'tipovisibilidad':        this.idtipovisibilidad.id,
                      'criterio_id':            this.idCriterio.id,
                      'obj_imagen_pregunta':    this.obj_imagen_pregunta,
                      'permitir_justificante':  this.permitir_justificante_val
                  };
                 this.$http.post('/api/reactivos', params)
                 .then((response) => {
                     this.getRespuestas();
                     this.idReactivo = response.data.id;
                     //this.btnReactivo.text = "Actualizar reactivo"
                     this.editedIndex = 1;
                     this.descripcion = response.data.descripcion_copia;
                     Swal.fire(
                         '¡Actualizado!',
                         'La pregunta ha sido actualizada exitosamente.',
                         'success'
                     )
                     // this.descripcion_copia = response.data.descripcion_copia;
                 })
                 .catch(function (error){
                    console.log(error)
                 })
              }
             this.$emit("getrefrescar_tabla",1);
         }
        this.cadenaoriginal = '';
        this.palabrarespuesta ='';
        this.numerorespuestas =0;
        this.arreglorespuestas =[];
        this.caracteranterior ='';
        this.caracterposterior ='';
        this.caracteresperado ='';
        this.caractercierre1 ='';
        this.caractercierre2 ='';
        this.caracterapertura1 ='';
        this.caracterapertura2 ='';
        this.parabierto =0;
    },
    guardarRespuesta(respuesta,id){
      this.guardando=true;
                this.$http.post('/api/respuestas', {
                'respuesta':        respuesta,
                'reactivo_id':      id,
                })
                 .then((response) => {
                   // console.log(response.data)
                    //this.overlay = false;
                    this.respuesta = '';
                    //this.getRespuestas();
                    this.guardando=false;
                })
                .catch(function (error){
                   console.log(error)
                   //this.overlay = false;
                })
    },
    guardarTipodeRespuesta(tipo,id,descripcion=''){
                this.$http.post('/api/respuestas', {
                'tiporespuesta':    tipo,
                'respuesta_id':     id
                })
                 .then((response) => {
                    this.overlay = false;
                })
                .catch(function (error){
                   console.log(error)
                   this.overlay = false;
                })
    },
    getAreas () {
            let url = '/api/areas'
            this.$http.get(url)
            .then((response) => {
               this.areas = response.data;
               if(this.tipoaccion==2){
                 //modo edicion de reactivo
                  this.idArea = this.areas.find(item => item.id == this.area_id)
                  this.consultarArea(this.idArea)
                  //console.log("idnivel despues de ejecutarse funcion"+this.idNivel)
                }
            })
            .catch(function (error){
                    console.log(error)
            })
    },
    getCriterios () {
            let url = '';
            url = '/api/criterios'
            this.$http.get(url)
                .then((response) => {
                    //console.log(response)
                    this.arraycriterios = response.data;
                    if(this.tipoaccion==2){
                        this.idCriterio = this.arraycriterios.find(item => item.id == this.criterio_id)
                        if(this.tiporeactivo==3){
                            this.activobtn='';
                        }
                        else{
                            this.activobtn=1;
                        }
                    }
                })
                .catch(function (error){
                    console.log(error)
                })

    },
    getRespuestas(){
            //console.log("obtener respuesta del idreactivo:" + this.idReactivo)
            let url = `/api/respuestas?reactivo_id=${this.idReactivo}&tipo=${this.tiporeactivo}`
            this.$http.get(url)
            .then((response) => {
                this.respuestas = response.data;
            })
            .catch(function (error){
                    console.log(error)
            })
    },
    getNivelesEducativos () {
            let url = '/api/niveleseducativos'
            this.$http.get(url)
            .then((response) => {

               this.niveles = response.data;
               if(this.tipoaccion==2){
                    this.idNivel = this.niveles.find(item => item.id == this.nivel_id)
                    this.consultarNivelEducativo()
               }
            })
            .catch(function (error){
                    console.log(error)
            })
    },
    gettiemporeactivo () {
            let url = '';
            url = '/api/tiemporeactivo'
            this.$http.get(url)
                .then((response) => {
                    this.arraytiemporeactivo = response.data;
                    if(this.tipoaccion==2){
                        this.idtiemporeactivo = this.arraytiemporeactivo.find(item => item.id == this.tiemporeactivo)
                    }
                })
                .catch(function (error){
                    console.log(error)
                })
    },
    getnivelreactivo(){
            let url = '';
            url = '/api/nivelreactivo'
            this.$http.get(url)
                .then((response) => {
                    this.arraynivelreactivo = response.data;
                    if(this.tipoaccion==2){
                        this.idnivelreactivo = this.arraynivelreactivo.find(item => item.id == this.nivelreactivo)
                        if(this.tiporeactivo==3){
                            this.activobtn='';
                        }
                        else{
                            this.activobtn=1;
                        }
                    }
                    else
                        this.idnivelreactivo = '';
                })
                .catch(function (error){
                    console.log(error)
                })
    },
    gettipovisibilidad(){
            let url = '';
            url = '/api/tipovisibilidad'
            this.$http.get(url)
                .then((response) => {
                    this.arraytipovisibilidad = response.data;
                    //console.log(this.arraytipovisibilidad)
                    if(this.tipoaccion==2){
                        this.idtipovisibilidad = this.arraytipovisibilidad.find(item => item.id == this.tipovisibilidad)
                        if(this.tiporeactivo==3){
                            this.activobtn='';
                        }
                        else{
                            this.activobtn=1;
                        }
                    }
                })
                /*                .then(()=>{
                                    this.getAreas()
                                })*/
                .catch(function (error){
                    console.log(error)
                })
    },
    getTiporespuesta () {
            let url = '/api/tiporespuesta'
            this.$http.get(url)
                .then((response) => {
                    this.arraytipoderespuesta = response.data;
                })
                .catch(function (error){
                        console.log(error)
                })
    },
    close() {
        this.dialog = false
    },
    recuperarDescripcion(){
        if(this.respaldodescripcion!= '')
            this.descripcion = this.respaldodescripcion;
    }

    },
    watch: {
      idarchivo: function (val) {
        this.imagen_seleccionada = 1;
        this.url_imagen_seleccionada = "https://drive.google.com/uc?id=" + val.id
        this.obj_imagen_pregunta = val
      }
    },
    mounted(){
        this.getNivelesEducativos()
        this.getAreas()
        this.gettiemporeactivo()
        this.getnivelreactivo()
        this.gettipovisibilidad()
        this.getTiporespuesta()
        this.getCriterios()
        this.tiporeactivo = 6;
        this.idEscuela = 1;
        if(this.permitir_justificante==0 || this.permitir_justificante==null)
          this.permitir_justificante_val = 0;
        else
          this.permitir_justificante_val = 1;
        if(this.tipoaccion==1){
            this.texto='Nuevo reactivo: Rellenar en blanco';
            this.idEscuela = 1;
        }
        else if(this.tipoaccion==2) {
            this.texto='Editar reactivo: Rellenar en blanco';
            this.idReactivo    = this.id;
            //this.idEscuela     = this.escuela_id_prop; TEMPORAL
            if(this.urlimagen_pregunta!=null){
              if (this.urlimagen_pregunta.length>0){
                this.imagen_seleccionada = 1
                this.url_imagen_seleccionada        = this.urlimagen_pregunta
                this.url_imagen_seleccionada_copia  = this.urlimagen_pregunta
                //console.log("longitud de url imagen" + this.urlimagen_pregunta.length)
              }
            }
            this.idEscuela = 1;
            this.descripcion   = this.descripcion_copia_prop;
            this.instrucciones = this.instrucciones_prop;
            this.getRespuestas();
        }


    } 
  }
</script>
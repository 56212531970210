var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-equal-box")]),_c('h3',[_vm._v(_vm._s(_vm.texto))])],1),_c('v-container',{staticClass:"grey lighten-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[[_c('v-select',{attrs:{"label":"Nivel Educativo:","items":_vm.niveles,"item-value":"id","item-text":"nombre","return-object":""},on:{"change":_vm.consultarNivelEducativo},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idNivel),callback:function ($$v) {_vm.idNivel=$$v},expression:"idNivel"}})]],2),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[[_c('v-select',{attrs:{"label":"Grado:","items":_vm.grados,"item-value":"id","item-text":"nombre","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idGrado),callback:function ($$v) {_vm.idGrado=$$v},expression:"idGrado"}})]],2),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[[_c('v-select',{attrs:{"label":"Area de Conocimiento:","items":_vm.areas,"item-value":"id","item-text":"nombre","return-object":""},on:{"change":_vm.consultarArea},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idArea),callback:function ($$v) {_vm.idArea=$$v},expression:"idArea"}})]],2),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[[_c('v-select',{attrs:{"label":"Asignatura:","items":_vm.asignaturas,"item-value":"id","item-text":"nombre","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idAsignatura),callback:function ($$v) {_vm.idAsignatura=$$v},expression:"idAsignatura"}})]],2)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"12"}},[_c('p',[_c('b',[_vm._v("Para la redacción del reactivo, ingrese entre /* */ la respuesta y separe varias respuestas con comas, por ejemplo: es un planeta cercano a la Tierra /*Venus*/")])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"label":"Descripcion:","value":_vm.descripcion,"rows":3,"dense":"","id":"txtdescripcion"},on:{"click":function($event){return _vm.actualizarDescripcionOriginal()}},model:{value:(_vm.descripcion),callback:function ($$v) {_vm.descripcion=$$v},expression:"descripcion"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8","sm":"8"}},[(_vm.imagen_seleccionada==1)?_c('div',[_c('a',{attrs:{"href":_vm.url_imagen_seleccionada,"target":"_blank"}},[_c('v-img',{attrs:{"src":_vm.url_imagen_seleccionada,"width":"150px","height":"auto"}})],1)]):_vm._e()]),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('drive',{attrs:{"idarchivo":_vm.idarchivo},on:{"idarchivo":function($event){_vm.idarchivo=$event}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"label":"Instrucciones:","value":_vm.instrucciones,"rows":2,"dense":"","id":"txtinstrucciones"},model:{value:(_vm.instrucciones),callback:function ($$v) {_vm.instrucciones=$$v},expression:"instrucciones"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[[_c('v-select',{attrs:{"label":"Tiempo en responder:","items":_vm.arraytiemporeactivo,"item-value":"id","item-text":"nombre","outlined":"","id":"selecttiemporeactivos","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idtiemporeactivo),callback:function ($$v) {_vm.idtiemporeactivo=$$v},expression:"idtiemporeactivo"}})]],2),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[[_c('v-select',{attrs:{"label":"Nivel de Pregunta:","items":_vm.arraynivelreactivo,"item-value":"id","item-text":"nombre","outlined":"","id":"selectnivelreactivo","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idnivelreactivo),callback:function ($$v) {_vm.idnivelreactivo=$$v},expression:"idnivelreactivo"}})]],2),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[[_c('v-select',{attrs:{"label":"Tipo de visibilidad:","items":_vm.arraytipovisibilidad,"item-value":"id","item-text":"nombre","outlined":"","id":"selecttipovisibilidad","return-object":""},on:{"change":_vm.consultartipovisibilidad},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idtipovisibilidad),callback:function ($$v) {_vm.idtipovisibilidad=$$v},expression:"idtipovisibilidad"}})]],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4"}},[[_c('v-select',{attrs:{"label":"Criterio para calificar pregunta:","items":_vm.arraycriterios,"item-value":"id","item-text":"nombre_criterio","outlined":"","id":"selectcriterio","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_criterio)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_criterio)+" ")]}}]),model:{value:(_vm.idCriterio),callback:function ($$v) {_vm.idCriterio=$$v},expression:"idCriterio"}})]],2),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4"}},[[_c('v-switch',{attrs:{"label":"Permitir justificante"},model:{value:(_vm.permitir_justificante_val),callback:function ($$v) {_vm.permitir_justificante_val=$$v},expression:"permitir_justificante_val"}})]],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","id":"btnReactivo"},on:{"click":function($event){return _vm.guardarReactivo()}}},[_vm._v(_vm._s(_vm.textobtn))])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.respuestas},scopedSlots:_vm._u([{key:"item.respuesta",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.respuesta,"dense":"","readonly":""},model:{value:(item.respuesta),callback:function ($$v) {_vm.$set(item, "respuesta", $$v)},expression:"item.respuesta"}})]}},{key:"item.tiporespuesta",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"value":item.tiporespuesta,"items":_vm.arraytipoderespuesta,"item-value":"id","item-text":"nombre","disabled":_vm.validarSelect(item.respuesta)},on:{"change":function($event){return _vm.guardarTipodeRespuesta(item.tiporespuesta,item.id,item.respuesta)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}],null,true),model:{value:(item.tiporespuesta),callback:function ($$v) {_vm.$set(item, "tiporespuesta", $$v)},expression:"item.tiporespuesta"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
 <template>
 <form>
<!--    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="email"

            label="Correo electrónico"
            required
          ></v-text-field>          
          <v-text-field
          label="Password"
            v-model="password"
            counter
          ></v-text-field>
    <v-btn class="mr-4" @click="loginGoogle">submit</v-btn>
    <v-btn @click="clear">clear</v-btn>          
        </v-col>        
      </v-row>
    </v-container> --> 
  </form> 
</template>       

<script>
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = 'http://localhost:8000'
  export default {
    //name: 'Home',
    data: () => ({
      email: '',
      password: '',

    }), 
    mounted() {
       this.init()
    },       
    methods:{
    init () {
      /* Aqui lo puedo redireccionar a una pantalla de Bienvenida o un Dashboard */
     this.$router.push({ name: 'ReporteDocente'})
    }, 
      clear () {
          this.password = ''
          this.email = ''

      }, 
      loginGoogle(){
      axios.get('/sanctum/csrf-cookie').then(() => {
            window.location = "http://localhost:8000/redirect"
        })
        .then(response =>{
         // console.log(response);
         // console.log('Bienvenido')
         this.$router.push({ name: 'ReporteDocente'})
        }).catch(function (error){
          console.log(error)
        }
          
        )
      },     
      login(){
        axios.get('/sanctum/csrf-cookie').then(response => {
          axios.post('/login',{
            email: 'vgjb@hotmail.com',
            password: '12345678',
          })
        })
        .then(response =>{
          console.log(response);
         //this.$router.push({ name: 'Dashboard'})
        }).catch(function (error){
          console.log(error)
        }
          
        )
      },
    
    }
  }
</script>
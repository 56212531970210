<template>
  <div>
    <v-alert
        text
        dismissible
        color="info"
    >
      <div>Usted se encuentra <strong>editando un curso</strong></div>
    </v-alert>
    <v-card class="pa-3">
    <v-breadcrumbs
        class="pa-3 ma-0"
        :items="itemsNav"
        divider="/">
    </v-breadcrumbs>
    <v-divider></v-divider>

    <div class="pa-5">
      <v-text-field
          v-model="editar_curso.nombre_curso"
          label="Nombre del curso"
      ></v-text-field>
      <v-text-field
          v-model="editar_curso.clave_interna"
          label="Clave interna del curso"
      ></v-text-field>
      <v-select
          v-model="editar_curso.unidades"
          multiple
          :items="itemsUnidades"
          item-text="nombre_unidad"
          item-value="id"
          label="Seleccionar unidades"
      >
        <template slot="selection" slot-scope="data">
          <!-- HTML that describe how select should render selected items -->
          {{ data.item.nombre_unidad }} - <strong>{{ data.item.clave_interna }}</strong>
        </template>
        <template slot="item" slot-scope="data">
          <!-- HTML that describe how select should render items when the select is open -->
          {{ data.item.nombre_unidad }} - <strong>{{ data.item.clave_interna }}</strong>
        </template>
      </v-select>
      <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="guardarCambios()"
      >Guardar cambios</v-btn>
      <v-divider class="mt-5 mb-5"></v-divider>
      <h2>Estructura actual del curso</h2>
      <div v-for="(item, i) in estructura_curso">
        <p class="mt-5"><strong>{{ (i+1) + '.- ' + item.nombre_curso + ' ('+(item.clave_interna)+')' }}</strong></p>
        <div v-for="unidad in item.unidad.split('~')">
          <p class="ml-10">{{ unidad.split('|')[0] + ' (' + unidad.split('|')[1] + ')' }}</p>
        </div>
      </div>
    </div>

  </v-card>
  </div>
</template>
<script>
export default {
  data:()=>({
    cursoId : 0,
    itemsNav : [],
    itemsUnidades : [],
    editar_curso : {
      nombre_curso : '',
      clave_interna : '',
      unidades : [],
    },
    estructura_curso : []
  }),
  mounted() {
    this.getUnidades();
    this.cursoId = this.$route.query.cursoId;
    this.showCurso();
    this.itemsNav = [
      {
        text : 'Cursos',
        disabled : false,
        href : '/cursos-all'
      },
      /*{
        text : 'Detalles',
        disabled : true,
        href : '',
      },*/
      {
        text : 'Cargando...',
        disabled : true,
        href : '',
      },
    ];
  },
  methods : {
    printUnidad(itemUnidades) {
      let unidades = unidad.split('/');
      for ( let key in unidades ) {
        let unidad = unidades[key].split('|');
      }
    },
    getUnidades() {
      this.$http.get('/api/aprende/unidades')
          .then(response => {
            this.itemsUnidades = response.data;
          })
          .catch(error => {

          })
    },
    guardarCambios() {
      this.$http.post('/api/aprende/cursos/' + this.cursoId, {
        _method : 'PATCH',
        nombre_curso : this.editar_curso.nombre_curso,
        clave_interna : this.editar_curso.clave_interna,
        unidades : this.editar_curso.unidades,
      })
      .then(response => {
        this.itemsNav[1].text = this.editar_curso.nombre_curso;
        this.editar_curso.nombre_curso = this.editar_curso.nombre_curso;
        this.editar_curso.clave_interna = this.editar_curso.clave_interna;
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Curso editado correctamente',
          showConfirmButton: false,
          timer: 2500
        })
      })
      .catch(error => {

      })
    },
    showCurso() {


      this.$http.get('/api/aprende/cursos/' + this.cursoId)
      .then(response => {
        this.itemsNav[1].text = response.data.nombre_curso;
        this.editar_curso.nombre_curso = response.data.nombre_curso;
        this.editar_curso.clave_interna = response.data.clave_interna;
        this.editar_curso.unidades = response.data.unidades;
      })
      .catch(error => {

      })

      this.$http.get('/api/aprende/cursos/get-estructura/' + this.cursoId)
      .then(response => {
        this.estructura_curso = response.data;
      })
      .catch(error => {

      })

    }
  }
}
</script>
<template>
  <v-card
    class="mx-auto"
    max-width="100%"
    tile
  >

    <v-list shaped>
      <v-subheader>Mis exámenes</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item v-for="item in cursos" :key="item.id">
           <v-list-item-icon>
            <v-icon >mdi-flag</v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="abrirExamen(item)">
            <v-list-item-title v-text="( item.nombre_examen != null ) ? item.nombre_examen : '' " ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>


  </v-card>
</template>

<script>
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = 'http://localhost:8000'

  export default {
    data: () => ({
/*       item: 1,
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ], */
        cursos: [],
        page: 1,
        transition: 'slide-y-reverse-transition',
        color: ''
    }),
    mounted() {
        this.getCursos()
        //this.infoDocente()
    },    
    methods: {
          init () {
     // this.loginGoogle()
    },
        getCursos () {
          /*
            let url = '/api/cursos'
            this.$http.get(url)
            .then((response) => {
               this.cursos = response.data;
               console.log(this.cursos)
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )*/

             this.cursos = [{
              nombre_examen : 'Examen de prueba',
              idExamen : 3
             }];

        },
        abrirExamen(item) {
          console.log(item);
          this.$router.push({ path: '/vistaexamenalumno', query: item }) 
            //this.$router.push({ path: '/listadoalumnos', query: { id: item.id, curso: item.nombre, course_id: item.course_id } }) 
        },
        loginGoogle(){
          axios.get('/sanctum/csrf-cookie').then(() => {
                window.location = "http://localhost:8000/redirect"
            })
            .then(response =>{
              console.log(response);
            //this.$router.push({ name: 'Dashboard'})
            }).catch(function (error){
             // console.log(error)
            })
        },
        infoDocente(){
          this.$http.get('/api/user').then(response => {
            //console.log(response)
           // console.log(response)
           // this.email = response.data.email
          })
        }
    }

  }
</script>
<template>
  <div >
    <contenidodocente v-if="current_role_user == 5"></contenidodocente>
    <contenidoalumno v-if="current_role_user != 5 && current_role_user > 0"></contenidoalumno>
  </div>
</template>
<script>
import contenidodocente from "@/views/Aprende/ContenidoDocente";
import contenidoalumno from "@/views/Aprende/ContenidoAlumno";
export default {
  components : {contenidodocente, contenidoalumno},
  data:() => ({
    current_role_user : 0
  }),
  mounted() {
    this.getRoleUser()
  },
  methods : {
    getRoleUser() {
      this.$http.get("/api/aprende/users/get-current-role")
          .then(response => {
            this.current_role_user = response.data;
          }).catch(error => {

      })
    },
  }
}
</script>
import Vue                  from 'vue'
import VueRouter            from 'vue-router'
/* actualizacion a laravel 8*/
import SistemaEducativo               from '../views/Directiva/SistemaEducativo.vue'
import bancoescuela                   from '../views/Evaluacion/banco_escuelas.vue'
import NivelEducativo                 from '../views/Directiva/NivelesEducativos.vue'
import Grados                         from '../views/Directiva/Grados.vue'
import AreaConocimiento               from '../views/Directiva/AreaConocimiento.vue'
import Asignaturas                    from '../views/Directiva/Asignaturas.vue'
import BancoReactivos                 from '../views/Evaluacion/BancoReactivos.vue'
import ReactivoUnico                  from '../views/Evaluacion/reactivo_unico.vue'
import ReactivoRellenar               from '../views/Evaluacion/reactivorellenar.vue'
import bancoexamen                    from '../views/Evaluacion/bancoexamen.vue'
import BancoExamenMultiple            from '../views/Evaluacion/BancoExamenMultiple.vue'
import evaluaciones                   from '../views/EvaluacionesReporte.vue'
import Examen                         from '../views/Evaluacion/examen.vue'
import componentedrive                from '../views/test/componentedrive.vue'
import UsuariosOnLine                 from '../views/Evaluacion/UsuariosOnLine.vue'
import CargaAcademica                 from '../views/Evaluacion/CargaAcademica.vue'
import BancoReactivosCargaAcademica   from '../views/Evaluacion/bancoReactivosCargaAcademica.vue'
import CreadorDeReactivos             from '../views/Evaluacion/CreadorDeReactivos.vue'
/*actualizacion laravel 8*/




import CalcularCalificaciones from "../views/CalcularCalificaciones.vue";
import drive from '../views/drive3.vue'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import DataEdit from '../views/DataEdit.vue'
import Crud from '../views/Crud.vue'
import Login from '../views/Login.vue'
import ReporteDocente from '../views/ReporteDocente.vue'
import ListadoAlumnos from '../views/ListadoAlumnos.vue'
import AlumnosporCursos from '../views/Evaluacion/listadoalumnosporcurso.vue'
import Clases from '../views/Clases.vue'
import Reportes from '../views/Reportes/Reportes.vue';
import ShowEventoStream from '../views/Aprende/ShowEventoStream.vue';

//import drive from '../views/drive.vue'
import Requisitos from '../views/Requisitos.vue'
import RptTrimestral from '../views/RptTrimestral.vue'






import ReactivoRelacion from '../views/Evaluacion/reactivo_relacion.vue'
import ReactivoAbierto from '../views/Evaluacion/reactivo_abierto.vue'





import vistaexamen from '../views/Evaluacion/vistaexamen.vue'
import Escuela from '../views/Evaluacion/escuela.vue'
import vistaexamenalumno from '../views/Evaluacion/vistaexamenalumno.vue'
import examenesalumno from '../views/examenesAlumno.vue'

import inscribirse from '../views/inscribirse.vue';
import vernotificaciones from '../views/vernotificaciones.vue';

import SesionesDocente from '../views/Aprende/SesionesDocente.vue';
import CursosEduca from '../views/Aprende/Cursos.vue';
import CursosShow from '../views/Aprende/ShowCursos.vue';

import CurriculasEduca from '../views/Aprende/Curriculas.vue';
import ShowCurriculasEduca from '../views/Aprende/ShowCurricula';
import ContenidoCurricula from '../views/Aprende/ContenidoCurricula';
import UsuariosSistema from '../views/UsuariosSistema';
import grupo_curricula_oferta from '../views/Aprende/grupo_curricula_oferta';

import editCurriculasEduca from '../views/Educa/edit-Curricula';
import newCurriculasEduca from '../views/Educa/new-Curricula';
import UnidadesEduca from '../views/Educa/Unidades.vue';
import addUnidadesToCurso from '../views/Educa/add-unidades';
import newUnidades from '../views/Educa/new-unidades';
import addObjetivosToUnidad from '../views/Educa/add-objetivos';
import newObjetivos from '../views/Educa/new-objetivos';
import addIndicadores from '../views/Educa/add-indicadores';
import newMateriales from '../views/Educa/new-material';

import objetivosAprende from '../views/Aprende/dashboardObjetivos';
import unidadesAprende from '../views/Aprende/dashboardUnidades';
import unidadesobjetivosAprende from '../views/Aprende/dashboardUnidadesObjetivos';
import indicadoresAprende from '../views/Aprende/dashboardindicadores';
import materialesAprende from '../views/Aprende/dashboardMaterial';

import requisitosUnidadesAprende from '../views/Aprende/dashboardRequisitosUnidades';
import requisitosCursosAprende from '../views/Aprende/dashboardRequisitosCursos';
import ofertaAcademica from '../views/Aprende/OfertaAcademica';
import cursante from '../views/Aprende/Cursante';
import AprendeCursoVer from '../views/Aprende/curso-show';
import CurriculaUsuario from '../views/Aprende/CurriculaUsuario.vue';
import Sesiones from '../views/Aprende/Sesiones.vue';

import MainEstadisticas from '../views/Estadistica/Main';
import curricula_curso_oferta from '../views/Aprende/CurriculaCursoOferta.vue';
import detalleSesion from '../views/Aprende/detalleSesion.vue';
import completarDatos from '../views/Aprende/CompletarDatos';
import Error from '../views/ErrorAcceso';
import CategoriasIndicadores from '../views/Aprende/CategoriasIndicadores';
import Subindicadores from '../views/Aprende/Subindicadores';
import EventoSesion from '../views/Aprende/EventoSesion';
import MaterialSesion from '../views/Aprende/MaterialSesion';
import ContenidoCurriculaOnline from "@/views/Aprende/ContenidoCurriculaOnline";

import Encuestas from '../views/Encuestas/Encuestas.vue';
import EncuestasCreate from '../views/Encuestas/Encuestas-create.vue';
import EncuestasEdit from '../views/Encuestas/Encuestas-edit.vue';
import EncuestasMain from '../views/Encuestas/Main.vue';
import EventosStream from "@/views/Aprende/EventosStream";
import DetallesEvento from "@/views/Aprende/DetalleEvento";
import Chat from "../views/Aprende/chat";
import AltaAlumnos from "../views/Aprende/AltaAlumnos.vue";
import importarAlumnos from "../views/Aprende/ImportarAlumnos.vue";
import AuthenticateUser from "../views/AuthenticateUser.vue";
Vue.use(VueRouter)

  const routes = [
    {
      path: '/crearReactivos',
      name: 'Crear Reactivos',
      component: CreadorDeReactivos
    },
    {
      path: '/BancoReactivosCargaAcademica',
      name: 'Banco Reactivos Carga Academica',
      component: BancoReactivosCargaAcademica
    },
    {
      path: '/carga-academica',
      name: 'Carga Academica Docente',
      component: CargaAcademica
    },
    {
      path: '/banco-examen-multiple',
      name: 'Banco de Examen Multiple',
      component: BancoExamenMultiple
    },
    {
      path: '/user-online',
      name: 'Usuarios On Line',
      component: UsuariosOnLine
    },
    {
      path: '/calcular-calificaciones',
      name: 'Calcular calificaciones',
      component: CalcularCalificaciones
    },
    {
      path: '/authenticate-user',
      name: 'Autenticación de usuario',
      component: AuthenticateUser
    },
    {
      path: '/importarAlumnos',
      name: 'importarAlumnos',
      component: importarAlumnos
    },
    {
      path: '/altaalumnos',
      name: 'altaalumnos',
      component: AltaAlumnos
    },
    {
      path: '/componentedrive',
      name: 'componentedrive',
      component: componentedrive
    },
    {
      path: '/sistemaeducativo',
      name: 'SistemaEducativo',
      component: SistemaEducativo
    },
    {
      path: '/bancoescuelas',
      name: 'Escuelas',
      component: bancoescuela
    },
    {
      path: '/niveleducativo',
      name: 'NivelEducativo',
      component: NivelEducativo
    },
    {
      path: '/grados',
      name: 'Grados',
      component: Grados
    },
    {
      path: '/areaconocimiento',
      name: 'AreaConocimiento',
      component: AreaConocimiento
    },
    {
      path: '/asignaturas',
      name: 'Asignaturas',
      component: Asignaturas
    },
    {
      path: '/bancoreactivos',
      name: 'BancoReactivos',
      component: BancoReactivos
    },
    {
      path: '/reactivounico',
      name: 'ReactivoUnico',
      component: ReactivoUnico
    },
    {
      path: '/reactivorellenar',
      name: 'ReactivoRellenar',
      component: ReactivoRellenar
    },
    {
      path: '/bancoexamen',
      name: 'bancoexamen',
      component: bancoexamen
    },
    {
      path: '/Evaluaciones',
      name: 'Evaluaciones',
      component: evaluaciones
    },
    {
      path: '/examen',
      name: 'Examen',
      component: Examen
    },





    {
      path : '/encuestas-main',
      name : 'Encuestas Creador main',
      component: EncuestasMain
    },
    {
      path : '/encuestas-create',
      name : 'Encuestas Creador create',
      component: EncuestasCreate
    },
    {
      path : '/encuestas-edit/:id',
      name : 'Encuestas Editar',
      component: EncuestasEdit
    },
    {
      path : '/encuestas-recreo/:id',
      name : 'Encuestas',
      component: Encuestas
    },
    {
      path : '/show-evento-stream',
      name : 'ShowEventoStream',
      component: ShowEventoStream
    },
    {
      path : '/detalle-evento',
      name : 'DetallesEvento',
      component: DetallesEvento
    },
    {
      path : '/eventos-stream',
      name : 'Eventos Stream',
      component: EventosStream
    },
    {
      path : '/chat',
      name : 'Chat',
      component: Chat
    },
    {
      path : '/sesionesdocente',
      name : 'Sesiones Docente',
      component: SesionesDocente
    },

    {
      path : '/materialsesion',
      name : 'MaterialSesion',
      component: MaterialSesion
    },

    {
      path : '/eventosesion',
      name : 'EventoSesion',
      component: EventoSesion
    },

    {
      path : '/subindicadores',
      name : 'Subindicadores',
      component: Subindicadores
    },
    {
      path : '/categorias-indicadores',
      name : 'CategoriasIndicadores',
      component: CategoriasIndicadores
    },
    {
      path : '/error',
      name : 'Error al ingresar',
      component: Error
    },
    {
      path : '/completardatos',
      name : 'Completar datos',
      component: completarDatos
    },
    {
      path : '/detalleSesion',
      name : 'detalleSesion',
      component : detalleSesion
    },

    {
      path : '/contenido-curricula-curso',
      name : 'Contenidos',
      component : CurriculaUsuario
    },
    {
      path: '/usuarios',
      name: 'Usuarios',
      component: UsuariosSistema
    },
    {
      path: '/drive2',
      name: 'drive',
      component: drive
    },
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard
    },
 /*
   {
    path: '/',
    name: 'Home',
    component: Home
  }
  ,*/
  {
      path: '/alumnosporcurso',
      name: 'AlumnosporCursos',
      component: AlumnosporCursos
   },
  {
    path: '/escuela',
    name: 'Escuela',
    component: Escuela
  },
  {
    path: '/clases',
    name: 'Clases',
    component: Clases
  },
/*    {
      path: '/drive',
      name: 'drive',
      component: drive
    },*/

  {
    path: '/dataEdit',
    name: 'DataEdit',
    component: DataEdit
  },
  {
    path: '/crud',
    name: 'Crud',
    component: Crud
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reportedocente',
    name: 'ReporteDocente',
    component: ReporteDocente
  },
  {
    path: '/rptTrimestral',
    name: 'RptTrimestral',
    component: RptTrimestral
  },
  {
    path: '/listadoalumnos',
    name: 'ListadoAlumnos',
    component: ListadoAlumnos
  },
  {
    path: '/requisitos',
    name: 'Requisitos',
    component: Requisitos
  },

  {
    path: '/reactivorelacion',
    name: 'ReactivoRelacion',
    component: ReactivoRelacion
  },


  ,

  ,
  {
    path: '/vistaexamen',
    name: 'vistaexamen',
    component: vistaexamen
  },
  {
    path: '/reactivoabierto',
    name: 'ReactivoAbierto',
    component: ReactivoAbierto
  },

  {
    path: '/vistaexamenalumno',
    name: 'Aplicando examen',
    component: vistaexamenalumno
  },
  {
    path: '/examenesalumno',
    name: 'Examenes del alumno',
    component: examenesalumno
  },


    {
      path: '/unidades-all',
      name: 'Unidades',
      component: UnidadesEduca
    },
    {
      path: '/unidades-new',
      name: 'Unidadesnew',
      component: newUnidades
    },
    {
      path: '/objetivos-add',
      name: 'objetivosadd',
      component: addObjetivosToUnidad
    },
    {
      path: '/objetivos-new',
      name: 'objetivosnew',
      component: newObjetivos
    },
    {
      path: '/indicadores-add',
      name: 'indicadores',
      component: addIndicadores
    },
    {
      path: '/materiales-new',
      name: 'materiales',
      component: newMateriales
    },
    {
      path: '/estadisticas-all',
      name: 'Estadisticas',
      component: MainEstadisticas
    },


  {
    path: '/inscribirse',
    name: 'inscribirse',
    component: inscribirse
  },


  {
    path: '/vernotificaciones',
    name: 'Notificaciones',
    component: vernotificaciones
  },

    {
      path: '/cursos-all',
      name: 'Cursosall',
      component: CursosEduca
    },
    {
      path: '/contenido-curricula',
      name: 'Contenido de currículas',
      component: ContenidoCurricula
    },
    {
      path: '/contenido-curricula-curso-online',
      name: 'Contenido online',
      component: ContenidoCurriculaOnline
    },
    {
      path: '/cursos-show',
      name: 'CursosShow',
      component: CursosShow,
    },
    {
      path: '/curriculas-all',
      name: 'CurriculasAll',
      component: CurriculasEduca
    },
    {
      path: '/curriculas-new',
      name: 'CurrículasNew',
      component: newCurriculasEduca
    },
  {
    path: '/dashboardObjetivos',
    name: 'dashboardObjetivos',
    component: objetivosAprende
  },
 {
      path: '/dashboardUnidades',
      name: 'dashboardUnidades',
      component: unidadesAprende
    },
    {
      path: '/dashboardUnidadesObjetivos',
      name: 'dashboardUnidadesObjetivos',
      component: unidadesobjetivosAprende
    },
    {
      path: '/dashboardRequisitosUnidades',
      name: 'dashboardRequisitosUnidades',
      component: requisitosUnidadesAprende
    },
    {
      path: '/dashboardRequisitosCursos',
      name: 'dashboardRequisitosCursos',
      component: requisitosCursosAprende
    },

  {
      path: '/dashboardindicadores',
      name: 'dashboardindicadores',
      component: indicadoresAprende
  },
    {
      path: '/dashboardMateriales',
      name: 'dashboardMateriales',
      component: materialesAprende
    },
    {
      path: '/ofertaAcademica',
      name: 'ofertaAcademica',
      component: ofertaAcademica
    },
    {
      path: '/sesiones',
      name: 'Sesiones',
      component: Sesiones
    },
    {
      path: '/cursante',
      name: 'cursante',
      component: cursante
    },
    {
      path: '/AprendeCursoVer',
      name: 'Aprende',
      component: AprendeCursoVer
    },
    {
      path: '/cursos-all',
      name: 'Cursos',
      component: CursosEduca
    },
/*    {
      path: '/contenido-curricula',
      name: 'Contenido de currículas',
      component: ContenidoCurricula
    },*/
/*    {
      path: '/cursos-show',
      name: 'Cursos',
      component: CursosShow,
    },*/
/*
    {
      path: '/curriculas-all',
      name: 'Currículas',
      component: CurriculasEduca
    },
*/
/*    {
      path: '/curriculas-new',
      name: 'Currículas',
      component: newCurriculasEduca
    },*/
    {
      path: '/curriculas-show',
      name: 'CurrículasShow',
      component: ShowCurriculasEduca
    },
    {
      path: '/curriculas-edit',
      name: 'CurrículasEdit',
      component: editCurriculasEduca
    },

    {
      path: '/unidades-all',
      name: 'UnidadesAll',
      component: UnidadesEduca
    },
//Aprende
    {
      path: '/reportes',
      name: 'Reportes',
      component: Reportes
    },
    {
      path: '/curricula-curso-oferta',
      name: 'curricula_curso_oferta',
      component: curricula_curso_oferta
    },
    {
      path: '/grupo-curricula-oferta',
      name: 'grupo_curricula_oferta',
      component: grupo_curricula_oferta
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'Estudio APP';
  if (to.meta.Auth && !window.localStorage.getItem('_token')) {

   // next({ path: '/login' });
    //this.$router.push({ name: 'Dashboard'})
  } else {

    next();
    //this.$router.push({ name: 'Login'})
  }
});

export default router

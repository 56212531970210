<template>
  <div>
    <v-card>
      <v-card-title>
        <v-icon large class="mr-2" color="green">mdi-calendar</v-icon><h3>Eventos de los streaming <v-btn @click="nuevoStream()" class="primary">Agregar nuevo</v-btn></h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headerTable" :items="itemsEventosStream">
          <template v-slot:item.thumbnail="{item}">
            <v-img max-width="100" max-height="100" :src="item.thumbnail_video"></v-img>
          </template>
          <template v-slot:item.descripcion_video="{item}">
            {{ item.descripcion_video.substr(0,50) }}...
          </template>
          <template v-slot:item.eliminar="{item}">
            <v-btn @click="deleteStreamEvent(item)" class="primary white--text" fab small icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.detalles="{item}">
            <v-btn @click="detailsStreamEvent(item)" class="primary white--text" fab small icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.habilitar_chat="{item}">
            <v-switch
                @change="habilitarChat(item)"
                v-model="item.chat_habilitado"
            ></v-switch>
          </template>
          <template v-slot:item.habilitar_encuestas="{item}">
            <v-switch
                @change="habilitarEncuestas(item)"
                v-model="item.encuestas_habilitado"
            ></v-switch>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
        v-model="dialogStream"
        max-width="800"
    >
      <v-card>
        <v-card-title class="headline">
          {{ titulo_dialogo }}
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-text>


          <v-select
          :items="itemsEventosSesiones"
          v-model="eventoStream"
          label="Seleccionar evento"
          item-text="nombre_evento_sesion"
          prepend-icon="mdi-calendar"
          item-value="evento_id"
          return-object>
          </v-select>

          <v-autocomplete
              v-model="model"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              item-text="title"
              label="Buscar streamings creados en Youtube"
              placeholder="Escriba para filtrar la búsqueda"
              prepend-icon="mdi-video"
              return-object
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <img :src="data.item.thumbnails.default.url">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.title + ' (Empieza el '+(data.item.scheduledStartTime)+') '"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.description.substr(0,100) + '...' "></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>

          </v-autocomplete>

          <v-expand-transition>

            <v-container v-if="model">
              <v-alert
                  dense
                  type="info"
                  class="pa-5"
              >
                A continuación se desglosa la <strong>información del video</strong> elegido desde el filtro
              </v-alert>
              <h2 class="mt-5 mb-2">{{ fields.title }}</h2>
              <p class="ma-0">Fecha de inicio prorgamada del streaming: <strong>{{ fields.scheduledStartTime }}</strong></p>
              <p class="ma-0">Estatus del video: <strong>{{ getStatus(fields.privacyStatus) }}</strong></p>
              <v-divider class="mt-2 mb-2"></v-divider>
              <p>{{ fields.description }}</p>
              <v-img :src="fields.thumbnails.high.url"></v-img>
            </v-container>

          </v-expand-transition>

          <v-btn @click="guardarStreamEvento" class="primary">Finalizar</v-btn>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="dialogStream = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>

export default {
  data:()=>({
    listStatus : {'private' : 'Privado', 'public' : 'Público', 'unlisted' : 'No listado'},
    eventoStream : null,
    descriptionLimit: 25,
    entries: [],
    isLoading: false,
    model: null,
    search: null,

    itemsEventosStream : [],
    itemsEventosSesiones : [],
    titulo_dialogo : '',
    dialogStream : false,
    headerTable : [
      { text : '#', value : 'id' },
      { text : 'Foto', value : 'thumbnail' },
      { text : 'Nombre', value : 'titulo_video' },
      { text : 'Evento', value : 'evento_id' },
      { text : 'Detalles', value : 'descripcion_video' },
      { text : 'Habilitar chat', value : 'habilitar_chat' },
      { text : 'Habilitar encuestas', value : 'habilitar_encuestas' },
      { text : 'Eliminar', value : 'eliminar' },
      { text : 'Detalles', value : 'detalles' },
    ],
  }),
  computed: {
    fields () {
      if (!this.model) return []

      return this.model

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a',
        }
      })
    },
    items () {
      return this.entries.map(entry => {
        const Description = entry.description.length > this.descriptionLimit
            ? entry.description.slice(0, this.descriptionLimit) + '...'
            : entry.description

        return Object.assign({}, entry, { Description })
      })
    },
  },

  watch: {
    search (val) {
      // Items have already been loaded
      if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true


      this.getSearch()

    },
  },
  mounted() {
    this.getStreamingVideos();
    this.getEventosSesiones()

  },
  methods : {

    // tipo_control 1 = chat, 2 = encuesta
    habilitarChat(item) {
      this.$http.post("/api/aprende/eventos-stream/habilitar-chat-stream", {
        evento_streaming_id : item.id,
        chat_habilitado : ( item.chat_habilitado ) ? 1 : 0
      }).then(res => {
        Swal.fire({
          icon : 'success',
          title : 'Excelente',
          html : 'Se actualizó correctamente el streaming'
        });
      }).catch(err => {

      })
    },
    habilitarEncuestas(item) {
      this.$http.post("/api/aprende/eventos-stream/habilitar-encuesta-stream", {
        evento_streaming_id : item.id,
        encuestas_habilitado : ( item.encuestas_habilitado ) ? 1 : 0
      }).then(res => {
        Swal.fire({
          icon : 'success',
          title : 'Excelente',
          html : 'Se actualizó correctamente el streaming'
        });
      }).catch(err => {

      })
    },
    getSearch(callback = null) {
      this.$http.get("/api/aprende/eventos-stream/search-youtube")
          .then(res => {
            this.count = res.data.count
            this.entries = res.data.entries
            if ( callback ) {
              callback()
            }
          })
          .finally(() => (this.isLoading = false))
    },
    deleteStreamEvent(item) {
      Swal.fire({
        icon : 'info',
        title: '¿Confirma usted eliminar este registro?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$http.post("/api/aprende/eventos-stream/" + item.id, {
            _method : 'DELETE'
          })
          .then(res => {
            Swal.fire({
              icon : 'success',
              title: 'Excelente',
              html : 'Evento de streaming eliminado correctamente'
            })
            this.getStreamingVideos()
          })
          .catch(err => {

          })
        }
      })
    },
    detailsStreamEvent(item) {
      this.titulo_dialogo = "Editando stream: " + item.titulo_video;
      this.dialogStream = true;
      this.$http.get("/api/aprende/eventos-stream/" + item.id)
      .then(res => {
        this.eventoStream = res.data.evento_sesion;

        this.getSearch(() => {
          try {
            console.log(res.data.youtubeInfo)
            this.model = res.data.youtubeInfo
          } catch(e) {

          }
        })

      })
      .catch(err => {

      })
    },
    guardarStreamEvento() {
      this.$http.post("/api/aprende/eventos-stream", {
        evento_id : this.eventoStream.evento_id,
        youtube_video_id : this.model.videoId,
        titulo_video : this.model.title,
        descripcion_video : this.model.description,
        thumbnail_video : this.model.thumbnails.high.url,
      })
      .then(res => {
        Swal.fire({
          icon : 'success',
          title: 'Excelente',
          html : 'Evento de streaming guardado correctamente'
        })
        this.getStreamingVideos()
        try {
          this.dialogStream = false
          this.eventoStream = null;
          this.model = null;
        }
        catch(e) {
          console.log(e)
        }
      })
      .catch(err => {

      })
    },
    getStatus(statusVideo) {
      return this.listStatus[statusVideo]
    },
    searchYoutubeStreams() {
      console.log(this.streming_youtube_choosed)
    },
    getStreamingVideos() {
      this.$http.get("/api/aprende/eventos-stream")
      .then(res => {
        this.itemsEventosStream = res.data;
      })
      .catch(err => {

      })
    },
    getEventosSesiones() {
      this.$http.get("/api/aprende/sesiones-docente/get-sesiones-for-stream")
      .then(res => {
        this.itemsEventosSesiones = res.data;
      })
      .catch(err => {

      })
    },
    nuevoStream() {
      this.dialogStream = true;
      this.titulo_dialogo = "Agregar nuevo Stream";
    }
  }
}
</script>
<template>
  <v-container>

        <template>
            <v-row justify="center">
                <v-dialog v-if="dialogIndicador" :value="true" @input="dialogIndicador = false" persistent max-width="790">
                    <v-card elevation="2" class="mx-auto">
                        <v-card-title>
                            <v-icon large class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon><h3>Asignar encuesta al evento</h3>
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                                </v-row>
                                <v-data-table
                                    :headers="headerEncuestas"
                                      :items="resultSet"
                                      class="elevation-1"
                                      :search="search"
                                >
                                    <template v-slot:item.actions="{ item }">

                                        <v-icon v-if="(item.assigned==0)"
                                                class="mr-2"
                                                color="green"
                                                title="Asignar encuestas"
                                                @click="anexarEncuesta(item)"
                                        >
                                           mdi-check-circle
                                        </v-icon>

                                      <v-icon   v-if="(item.assigned==1)"
                                                class="mr-2"
                                                color="red"
                                                title="Des-asignar la encuestas"
                                                @click="desAsignar(item)"
                                        >
                                           mdi-minus-circle
                                        </v-icon>

                                    </template>
                                </v-data-table>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="closeImportar()"
                            >
                                Cerrar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>

    <v-card  elevation="2" class="mx-auto">
      <v-card-title>
        <v-icon large class="mr-2" color="green">mdi-calendar</v-icon><h3>Eventos de las sesiones del coach</h3>
        <v-spacer></v-spacer>

          <router-link style="text-decoration: none; color: inherit;"  
          to="/encuestas-create">
            <v-btn  
                    color="primary"
                      dark
                      class="my-2 ml-auto "
                    >
                Crear encuesta
            </v-btn>
          </router-link>

      </v-card-title>
      <v-data-table :headers="headers" :items="itemsSesiones">
        <template v-slot:item.fecha_evento="{item}">
          {{ item.fecha_consumo_inicio_formated }} - {{ item.fecha_seleccion_termino_formated }}
        </template>
        <template v-slot:item.hora_evento="{item}">
          {{ item.hora_inicio }} - {{ item.hora_fin }}
        </template>
        <template v-slot:item.visualizar_sesion="{item}">
          <v-btn @click="verContenido(item)" fab small class="green white--text"><v-icon>mdi-eye</v-icon></v-btn>
        </template>
                <template v-slot:item.visualizar_encuestas="{item}">
          <v-btn @click="showEncuesta(item)" fab small class="blue white--text"><v-icon>mdi mdi-file-document</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data:() => ({
   
    evento_id:'',
    sesion_id:'',
     search:'',
     resultSet:[],
    dialogIndicador: false,
    encuestas:[],
    itemsSesiones : [],
    headers: [
      {text: '#', sortable : false, value : 'evento_id'},
      {text: 'Nombre de la sesión', sortable : false, value : 'nombre_sesion'},
      {text: 'Fecha del evento', sortable : false, value : 'fecha_evento'},
      {text: 'Currícula', sortable : false, value : 'nombre_curricula'},
      {text: 'Hora del evento', sortable : false, value : 'hora_evento'},
      {text: 'Visualizar', sortable : false, value : 'visualizar_sesion'},
      {text: 'Asignar encuestas', sortable : false, value : 'visualizar_encuestas'},
    ],
    headerEncuestas: [
            {text: '#',align: 'start',sortable: true,value: 'id'},
            { text: 'Nombre', value: 'nombre', sortable: true , align: 'justify'},
            { text: 'Acciones', value: 'actions', sortable: true, align: 'center'},
        ],
  }),
  mounted() {
    this.getSesionesDocente();
  },
  methods : {               
     desAsignar(item){

              this.$http.post('/api/unassign-encuesta', {
                  'encuesta_id': item.id,
                  'evento_id': this.evento_id,
                  'sesion_id': this.sesion_id
              })
                  .then((response) => {
                      Swal.fire({
                          position: 'top-end',
                          icon: 'success',
                          title: 'La encuesta fue des-asignada a ese evento',
                          showConfirmButton: false,
                          timer: 1500
                      })
                    this.closeImportar();
                  })
                  .catch(function (error) {
                  })

     }
    ,showEncuesta(item) {
            this.evento_id          = item.evento_id;
            this.sesion_id          = item.id;
            this.dialogIndicador     = true;
            this.getData();
        },
        anexarEncuesta(item) {

              this.$http.post('/api/asign-encuesta', {
                  'encuesta_id': item.id,
                  'evento_id': this.evento_id,
                  'sesion_id': this.sesion_id
              })
                  .then((response) => {








                    
                      Swal.fire({
                          position: 'top-end',
                          icon: 'success',
                          title: 'Encuesta asignada al evento exitosamente',
                          showConfirmButton: false,
                          timer: 1500
                      })
                    this.closeImportar();
                  })
                  .catch(function (error) {
                  })
        },
        getData(){
                  this.$http.get("/api/get-encuestas")
                  .then((response) => {
                    this.resultSet = response.data;
                    console.log(this.resultSet);
                  })
        }, 
        closeImportar(){
            this.evento_id='';
            this.sesion_id='';
            this.dialogIndicador    =   false
        },
        verEncuestas(item){



        },
        verContenido(item) {
          let curricula_id = item.curricula_id,
              unidad_id = item.unidad_id,
              curso_id = item.curso_id,
              url = '';
          //url = '?id='+curricula_id+'&curso_id=' + curso_id + '&unidad_id=' + unidad_id;
          url = '/contenido-curricula-curso-online?curricula_id=' + curricula_id+"&evento_id=" + item.evento_id+"&sesion_id=" + item.sesion_id + "&nombre_sesion=" + item.nombre_sesion;
          window.open(process.env.VUE_APP_URL + url, 'blank_');
        },
        getSesionesDocente() {
          this.$http.get("/api/aprende/sesiones-docente")
          .then(response => {
            this.itemsSesiones = response.data;
          })
          .catch(error => {

          });
        }//getSesionesDocente
  }//methods
}
</script>
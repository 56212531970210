<template>
    <v-container>
        <template>
            <v-row justify="center">
                <v-dialog v-if="dialogIndicador" :value="true" @input="dialogIndicador = false" persistent max-width="790">
                    <v-card elevation="2" class="mx-auto">
                        <v-card-title>
                            <v-icon large class="mr-2" color="green">mdi-share-variant</v-icon><h3>Indicador</h3>
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <v-card-text>
                                <v-row >
                                    <v-col cols="12" sm="12">
                                        <v-text-field
                                            label="Nombre del indicador"
                                            v-model="nombre_indicador"
                                            v-bind:value="nombre_indicador"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="12">
                                        <v-text-field
                                            label="Clave"
                                            v-model="clave_indicador"
                                            :readonly="modolectura"
                                            v-bind:value="clave_indicador"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <template>
                                <v-select
                                    label='Categoría:'
                                    v-model='idCategoria'
                                    :items='categorias'
                                    item-value='id'
                                    item-text='categoria'
                                    outlined
                                    return-object
                                >
                                  <template slot='selection' slot-scope='{ item }'>
                                    {{ item.categoria}}
                                  </template>
                                  <template slot='item' slot-scope='{ item }'>
                                    {{ item.categoria}}
                                  </template>
                                </v-select>
                              </template>
                            </v-col>
                          </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-btn @click="guardarIndicador" small>
                                        {{txtboton}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="closeImportar()"
                            >
                                Cerrar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-if="dialogSubIndicador" :value="true" @input="dialogSubIndicador = false" persistent max-width="790">
                <v-card elevation="2" class="mx-auto">
                  <v-card-title>
                    <v-icon large class="mr-2" color="green">mdi-arrange-bring-to-front</v-icon><h4>SubIndicador(es) de Indicador: {{nombre_indicador}}</h4>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-row >
                      <v-col cols="12" sm="12">
                        <v-text-field
                            label="Nombre del subindicador"
                            v-model="nombre_subindicador"
                            outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-btn @click="guardarSubIndicador" small>
                          {{txtboton}}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12">
                      <v-card elevation="2" class="mx-auto">
                        <v-card-title></v-card-title>
                        <!--INICIO DATA TABLE SUBINDICADOR -->
                         <v-data-table :headers="headers2" :items="subindicadores" class="elevation-1">
                           <template v-slot:item.nombre_subindicador="{ item }">
                             <v-text-field
                                 v-model="item.nombre_subindicador"
                                 v-bind:value="item.nombre_subindicador"
                                 dense
                                 @change="guardarNombreSubIndicador(item.nombre_subindicador,item.id)"
                             ></v-text-field>
                           </template>
                            <template v-slot:item.actions="{ item }">
                              <div class="text-center">
                                <v-btn
                                    fab elevation="2"
                                    color="red"
                                    small
                                    x-small
                                    style="margin-right: 10px"
                                    @click="deleteSubIndicador(item)"
                                    title="Eliminar Indicador"
                                >
                                  <v-icon small color="white">mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </template>
                      </v-data-table>
                        <!--FIN DATA TABLE SUBINDICADOR -->
                      </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closeSubIndicador()"
                    >
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
        </template>
        <v-card elevation="2" class="mx-auto">
            <v-card-title>
<!--                <v-breadcrumbs :items="items"></v-breadcrumbs>-->

                <v-icon large class="mr-2" color="green">mdi-share-variant</v-icon><h3>Indicadores</h3>
                <v-breadcrumbs :items="items" large ></v-breadcrumbs>
                <v-spacer></v-spacer>

            </v-card-title>
            <v-card elevation="2">
                <v-card-title>
                    <v-row
                        align="center"
                        justify="space-around"

                    >
                        <v-btn
                            color="green"
                            small
                            @click="showIndicador()"
                            title="Crear Indicador"
                        >
                            <v-icon  small color="white" >mdi-share-variant</v-icon>
                        </v-btn>
                    </v-row>
                </v-card-title>
            </v-card>
            <v-card elevation="2">
                <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
                <v-data-table :headers="headers" :items="indicadores" class="elevation-1">
                    <template v-slot:item.detalles="{ item }">
                            <v-btn
                                fab elevation="2"
                                color="green"
                                small
                                x-small
                                @click="editIndicador(item)"
                                style="margin-right: 10px"
                                title="Editar Indicador"
                            >
                                <v-icon color="white">mdi-pencil</v-icon>
                            </v-btn>
                    </template>
                    <template v-slot:item.eliminar="{ item }">
                      <v-btn
                          fab elevation="2"
                          color="red"
                          small
                          x-small
                          style="margin-right: 10px"
                          @click="deleteIndicador(item)"
                          title="Eliminar Indicador"
                      >
                        <v-icon color="white">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.subindicadores="{ item }">
                      <div class="text-center">
                        <v-btn
                            fab elevation="2"
                            color="blue"
                            small
                            x-small
                            title="Subindicadores"
                            @click="showSubIndicadores(item)"
                        >
                          <v-icon color="white">mdi-arrange-bring-to-front</v-icon>
                        </v-btn>
                      </div>
                    </template>
                </v-data-table>

            </v-card>
        </v-card>
    </v-container>
</template>

<script>
export default {

    data:() => ({
        search:'',
        nombre:'',
        item:[],
        items:[{
                text: 'Objetivos',
                disabled: false,
                href: '/dashboardobjetivos',
            },
            {
                text: 'Cargando...',
                disabled: true,
            }
        ],
        indicadores:[],
        headers: [
            {text: '#',                  value: 'id'                           ,align: 'start'   ,sortable: true},
            { text: 'Nombre',             value: 'nombre_indicador'             ,align:'center'},
            { text: 'Clave',              value: 'clave_indicador'              ,align:'center' },
            { text: 'Categoría',          value: 'categorias.categoria'         ,align:'center' },
            { text: 'Detalles',           value: 'detalles'                     ,align: 'center'},
            { text: 'Eliminar',           value: 'eliminar'                     ,align: 'center'},
            { text: 'SubIndicadores',     value: 'subindicadores'               ,align:'center'},
        ],
        headers2: [
          {text: '#'                    ,value: 'id'                           ,align: 'start'   ,sortable: true},
          { text: 'Nombre'               ,value: 'nombre_subindicador'},
          { text: 'Acciones'             ,value: 'actions'                      ,align: 'center'  ,sortable: true },
        ],
        overlay: false,
        absolute:true,
        isModalVisible: false,
        dialog: false,
        dialogIndicador: false,
        dialogSubIndicador: false,
        clave_indicador:'',
        txtboton:'',
        estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
        estatusAccionTablaSubIndicador:0,    //1.- Create 2.- Update 3. Delete
        idIndicador:0,
        idObjetivo:0,
        idCategoria:'',
        categorias:[],
        nombre_objetivo:'',
        nombre_indicador:'',
        modolectura:false,
        nombre_subindicador:'',
        subindicadores:[]
    }),
    created () {
        //this.init();
    },
    methods: {
        init () {
        },
        closeSubIndicador(){
          this.nombre_subindicador   =   '';
          this.txtboton              =   '';
          this.dialogSubIndicador    =   false
          this.modolectura           =   false;
        },
        closeImportar(){
            this.nombre_indicador   =   '';
            this.clave_indicador    =   '';
            this.descripcion        =   '';
            this.txtboton           =   '';
            this.dialogIndicador    =   false
            this.modolectura        =   false;
        },
        deleteIndicador (item){
            Swal.fire({
                title: '¿Desea eliminar el indicador: '+ item.nombre_indicador + '?',
                showDenyButton: true,
                confirmButtonText: `Eliminar`,
                denyButtonText: `Cancelar`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    let url = `/api/aprende/indicadores/${item.id}`
                    this.$http
                        .delete(url)
                        .then((response) => {
                            this.getIndicadores()
                          Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Indicador eliminado exitosamente.',
                            showConfirmButton: false,
                            timer: 2500
                          })
                        })
                        .catch(function (error){
                            console.log(error)
                            //this.overlay = false;
                        })

                } else if (result.isDenied) {
                    //Swal.fire('Changes are not saved', '', 'info')
                }
            })
                //this.overlay = true;



        },
        deleteSubIndicador (item){
        Swal.fire({
          title: '¿Desea eliminar el Subindicador: '+ item.nombre_subindicador + '?',
          showDenyButton: true,
          confirmButtonText: `Eliminar`,
          denyButtonText: `Cancelar`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            let url = `/api/aprende/subindicador/${item.id}`
            this.$http
                .delete(url)
                .then((response) => {
                  this.getSubIndicadores()
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'SubIndicador eliminado exitosamente.',
                    showConfirmButton: false,
                    timer: 2500
                  })
                })
                .catch(function (error){
                  console.log(error)
                })

          } else if (result.isDenied) {
            //Swal.fire('Changes are not saved', '', 'info')
          }
        })
        //this.overlay = true;



      },
        editIndicador(item) {
            this.idIndicador            =   item.id;
            this.nombre_indicador       =   item.nombre_indicador;
            this.clave_indicador        =   item.clave_indicador;
            this.dialogIndicador        =   true;
            this.txtboton               =   "Actualizar"
            this.estatusAccionTabla     =   2;
            this.modolectura            =   true;
            this.idCategoria            =   item.categorias
        },
        getIndicadores(){
            let url = `/api/aprende/indicadores/`+this.idObjetivo
            console.log(url);
            this.$http.get(url)
                .then((response) => {
                    this.indicadores = response.data;
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        getSubIndicadores(){
          this.subindicadores = [];
        let url = `/api/aprende/subindicador/`+this.idIndicador.id
        console.log(url);
        this.$http.get(url)
            .then((response) => {
              this.subindicadores = response.data;
            })
            .catch(function (error){
              console.log(error)
            })
        },
        getCategorias(){
        let url = `/api/aprende/categoria-indicador`
        this.$http.get(url)
            .then((response) => {
              this.categorias = response.data;
            })
            .catch(function (error){
              console.log(error)
            })
        },
        getCatalogos(){
            let url = `/api/niveleseducativos/get-NivelEducativoAreaConocimiento`
            this.$http.get(url)
                .then((response) => {
                    //console.log(response)
                    this.niveles = response.data.nivel_educativo;
                    this.areas   = response.data.area_conocimiento;
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        getTipoReactivo () {
            let url = '/api/tiporeactivo'
            this.$http.get(url)
                .then((response) => {
                    this.tiporeactivo = response.data;
                    //console.log(this.periodos)
                })
                .catch(function (error){
                        console.log(error)
                    }
                )
        },
        guardarIndicador(){
            if(this.nombre_indicador=='')
                confirm('Debe capturar el nombre del indicador')
            else if (this.clave_indicador==''){
                confirm('Debe capturar una Clave interna')
            }
            else{
                if(this.estatusAccionTabla==1){
                    this.$http.post('/api/aprende/indicadores', {
                        'nombre_indicador'  : this.nombre_indicador,
                        'clave_indicador'   : this.clave_indicador,
                        'objetivo_id'       : this.idObjetivo,
                        'categoria_indicador_id'    : this.idCategoria.id
                    })
                        .then((response) => {
                            if(response.data){
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Indicador guardado exitosamente.',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                                this.getIndicadores()  //llenar el arreglo
                                //this.indicadores.unshift(response.data) //inserta el elemento al principio del Arreglo
                                this.nombre_indicador   = '';
                                this.clave_indicador    = '';
                                this.descripcion        = '';
                                this.objetivo_id        =   0;
                            }
                            else{
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: 'Indicador existente, verificar Clave interna.',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
                else if(this.estatusAccionTabla==2){
                    console.log("valor categoria id")
                    console.log(this.idCategoria)
                    this.$http.post('/api/aprende/indicadores/'+ this.idIndicador, {
                        _method:    'PUT',
                        'nombre_indicador'       : this.nombre_indicador,
                        'categoria_indicador_id'    : this.idCategoria.id
                    })
                        .then((response) => {
                            if(response.data){
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Indicador actualizado exitosamente.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.getIndicadores()
                                //this.nombre_indicador = '';
                                //this.clave_indicador = '';
                            }
                            else{
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Indicador no actualizado. Verificar.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            }
        },
        guardarSubIndicador(){
        if(this.nombre_subindicador=='')
          confirm('Debe capturar el nombre del subindicador')
        else{
          if(this.estatusAccionTablaSubIndicador==1){
            this.$http.post('/api/aprende/subindicador', {
              'nombre_subindicador'  : this.nombre_subindicador,
              'indicador_id'         : this.idIndicador.id,
            })
                .then((response) => {
                  if(response.data){
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'SubIndicador guardado exitosamente.',
                      showConfirmButton: false,
                      timer: 2500
                    })
                    //this.getIndicadores()  llenar el arreglo
                    //this.subindocadoresindicadores.unshift(response.data) //inserta el elemento al principio del Arreglo
                    this.getSubIndicadores()
                    this.nombre_subindicador = '';
                  }
                  else{
                    Swal.fire({
                      position: 'top-end',
                      icon: 'warning',
                      title: 'Favor de intentar más tarde.',
                      showConfirmButton: false,
                      timer: 2500
                    })
                  }
                })
                .catch(function (error) {
                  console.log(error)
                })
          }
          else if(this.estatusAccionTablaSubIndicador==2){
            console.log("valor categoria id")
            console.log(this.idCategoria)
            this.$http.post('/api/aprende/indicadores/'+ this.idIndicador, {
              _method:    'PUT',
              'nombre_subindicador'       : this.nombre_indicador,
            })
                .then((response) => {
                  if(response.data){
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'SubIndicador actualizado exitosamente.',
                      showConfirmButton: false,
                      timer: 1500
                    })
                    this.getIndicadores()
                    //this.nombre_indicador = '';
                    //this.clave_indicador = '';
                  }
                  else{
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Indicador no actualizado. Verificar.',
                      showConfirmButton: false,
                      timer: 1500
                    })
                  }
                })
                .catch(function (error) {
                  console.log(error)
                })
          }
        }
      },
      guardarNombreSubIndicador(nombre,id){
        if(nombre=='')
          confirm('Debe capturar el nombre del subindicador')
        else{
            this.$http.post('/api/aprende/subindicador/'+ id, {
              _method:    'PUT',
              'nombre_subindicador'       : nombre,
            })
           .then((response) => {
                  if(response.data){
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'SubIndicador actualizado exitosamente.',
                      showConfirmButton: false,
                      timer: 1500
                    })
                    this.getIndicadores()
                  }
                  else{
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'SubIndicador no actualizado. Verificar.',
                      showConfirmButton: false,
                      timer: 1500
                    })
                  }
                })
                .catch(function (error) {
                  console.log(error)
                })

        }
      },
        showImportar(){
            this.dialogIndicador = true;
        },
        showModal() {
            this.dialog = true;
        },
        showIndicador() {
            this.dialogIndicador     = true;
            this.txtboton            = "Guardar";
            this.estatusAccionTabla  =   1;
        },
        showSubIndicadores(item) {
          this.dialogSubIndicador             = true;
          this.txtboton                       = "Guardar";
          this.nombre_indicador               = item.nombre_indicador
          this.idIndicador                    = item
          this.estatusAccionTablaSubIndicador = 1;
          this.getSubIndicadores();
        },
    },
    mounted(){
        this.idObjetivo         =    atob(this.$route.query.idObjetivo);
        this.nombre_objetivo    =    atob(this.$route.query.nombre_objetivo);
        this.items[1].text      =    atob(this.$route.query.nombre_objetivo);
        //this.items[2].text      =    atob(this.$route.query.descripcion);
        this.getIndicadores()
        this.getCategorias()
    }
}
</script>
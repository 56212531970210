<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog v-if="dialogIndicador" :value="true" @input="dialogIndicador = false" persistent max-width="790">
          <v-card elevation="2" class="mx-auto">
            <v-card-title>
              <v-icon large class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon><h3>Unidades</h3>
              <v-spacer></v-spacer>

            </v-card-title>
            <v-card-text>
              <v-container>
<!--                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <template>
                      <v-select
                          label='Unidades:'
                          v-model='idUnidad'
                          :items='unidades'
                          item-value='id'
                          item-text='nombre_unidad'
                          outlined
                          return-object
                          @change="getUnidadSeleccionada"
                      >
                        <template slot='selection' slot-scope='{ item }'>
                          {{ item.clave_interna }} - {{ item.nombre_unidad }}
                        </template>
                        <template slot='item' slot-scope='{ item }'>
                          {{ item.clave_interna }} - {{ item.nombre_unidad }}
                        </template>
                      </v-select>
                    </template>
                  </v-col>
                </v-row>-->
                <v-row>
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                </v-row>
                <v-data-table
                    :headers="headersUnidades"
                    :items="unidades"
                    class="elevation-1"
                    :search="search"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon v-if=" !((arrayUnidades).indexOf(parseInt(item.id))>=0)"
                            class="mr-2"
                            color="green"
                            title="Agregar Unidad"
                            @click="agregar_unidad(item)"
                    >
                      mdi-cloud-download
                    </v-icon>
                  </template>
                </v-data-table>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="closeImportar()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-card elevation="2" class="mx-auto">
      <v-card-title>
        <v-icon large class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon><h3>Detalles Sesion</h3>
        <v-breadcrumbs :items="items" large ></v-breadcrumbs>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card elevation="2">
        <v-card-title>
          <v-row
              align="center"
              justify="space-around"
          >
            <v-btn
                color="green"
                small
                @click="showUnidad()"
                title="Agregar Unidad"
            >
              <v-icon  small color="white" >mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card elevation="2">
        <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table :headers="headers" :items="concentrado_unidades" class="elevation-1">
          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <!--                            <v-btn
                                              fab elevation="2"
                                              color="green"
                                              small
                                              x-small
                                              @click="editIndicador(item)"
                                              style="margin-right: 10px"
                                              title="Editar Indicador"
                                          >
                                              <v-icon small color="white">mdi-pencil</v-icon>
                                          </v-btn>-->

              <v-btn
                  fab elevation="2"
                  color="red"
                  small
                  x-small
                  style="margin-right: 10px"
                  @click="deleteUnidadObjetivo(item)"
                  title="Eliminar Unidad-Objetivo"
              >
                <v-icon small color="white">mdi-delete</v-icon>
              </v-btn>

            </div>

          </template>
        </v-data-table>

      </v-card>
    </v-card>
  </v-container>
</template>

<script>
export default {

  data:() => ({
    opacity: 0.46,
    search:'',
    zIndex: 5,
    nombre:'',
    escuela_id:1,
    refrescar_tabla:0,
    abreviatura:'',
    item:[],
    items:[{
      text: 'Sesión Académica',
      disabled: false,
      href: '/sesiones',
    },
      {
        text: 'Cargando...',
        disabled: true,
      }
    ],
    concentrado_unidades:[],
    snack: false,
    snackColor: '',
    snackText: '',
    respuestasTotal:0,
    max25chars: v => v.length <= 25 || 'Input too long!',
    pagination: {},
    headers: [
            //{text: '#',                   value: 'id',                          sortable: true,     align: 'start'},
            { text: 'Clave Unidad',       value: 'unidad.clave_interna',        sortable: true,     align: 'start'},
            { text: 'Unidad',             value: 'unidad.nombre_unidad',        sortable: true,     align: 'start'},
            //{ text: 'Clave Objetivo',     value: 'objetivo.clave_interna',      sortable: true ,    align: 'center'},
            //{ text: 'Objetivo',           value: 'objetivo.nombre_objetivo',    sortable: true,     align: 'start'},
            { text: 'Acciones',           value: 'actions',                     sortable: true ,    align: 'center'},
    ],
    headersUnidades: [
            //{text: '#',                   value: 'id',                          sortable: true,     align: 'start'},
            { text: 'Clave',              value: 'clave_interna',               sortable: true ,    align: 'center'},
            { text: 'Unidad',             value: 'nombre_unidad',               sortable: true ,    align: 'center'},
            { text: 'Acciones',           value: 'actions',                     sortable: true,     align: 'center'},
    ],
    overlay: false,
    absolute:true,
    isModalVisible: false,
    dialog: false,
    dialogIndicador: false,
    dialogAyuda:false,
    tipoaccion:'',
    buscar_contador:0,
    checkstatus : null,
    clave_indicador:'',
    txtboton:'',
    estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
    idIndicador:0,
    idCurso:0,
    nombre_sesion:'',
    nombre_indicador:'',
    modolectura:false,
    arrayUnidades: [],
    unidades:[],      idUnidad:'',
    objetivos:[],     idObjetivo:'',
    idSesion: '',
  }),
  created () {
    //this.init();
  },
  methods: {
    init () {
      //obtiene los reactivos filtrados por el usuario actual.
    },
    agregar_unidad(item) {
      this.guardando = true;
      this.$http.post('/api/aprende/detalle-sesion', {
        //'objetivo_id': item.idobjetivo,
        'unidad_id':  item.id,
        'sesion_id':  this.idSesion
      })
          .then((response) => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Unidad agregada exitosamente.',
              showConfirmButton: false,
              timer: 1500
            })
            this.getUnidadesAgregadasSesion();
          })
          .catch(function (error) {
          })
    },
    closeModal() {
      this.dialog = false
    },
    closeAyuda(){
      this.dialogAyuda =  false
    },
    closeImportar(){
      this.nombre_indicador   =   '';
      this.clave_indicador    =   '';
      this.descripcion        =   '';
      this.txtboton           =   '';
      this.dialogIndicador    =   false
      this.modolectura        =   false;
    },
    consultar(id){
      this.idTipoReactivo = id;
      if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
        this.showModal();
      }
      else if (this.idTipoReactivo==6)
        this.showModal();
    },
    consultarNivelEducativo(){
      let url = '';
      url = `/api/grados?idnivel=${this.idNivel.id}`
      this.$http.get(url)
          .then((response) => {
            this.grados = response.data;
            if(this.tipoaccion==2 ) {
              this.idGrado = this.grados.find(item => item.id == this.grado_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    consultarArea(){
      let url = '';
      url = `/api/asignaturas?idArea=${this.idArea.id}`
      this.$http.get(url)
          .then((response) => {
            this.asignaturas = response.data;
            if(this.tipoaccion==2 ){
              this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    deleteUnidadObjetivo (item){
      console.log(item)
      let index = this.arrayUnidades.indexOf(item);
      let index2 = this.concentrado_unidades.indexOf(item);
      Swal.fire({
        title: '¿Desea eliminar la Unidad con Clave: '+ item.unidad.clave_interna + '?',
        showDenyButton: true,
        confirmButtonText: `Eliminar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let url = `/api/aprende/detalle-sesion/${item.id}`
          this.$http
              .delete(url)
              .then((response) => {
                this.getUnidadesAgregadasSesion()
                Swal.fire('Unidad Objetivo eliminado satisfactoriamente.', '', 'success')
              })
              .catch(function (error){
                console.log(error)
                //this.overlay = false;
              })

        } else if (result.isDenied) {

        }
      })
    },
    editReactivo(item){
      this.item                 =   item;
      this.dialogIndicador       =   true;
      this.idTipoReactivo       =   item.tiporeactivo.tiporeactivo_concentrado.id;
      this.tipoaccion           =   2;
      this.estatusAccionTabla   =   2;
    },
    editIndicador(item) {
      this.idIndicador            =   item.id;
      this.nombre_indicador       =   item.nombre_indicador;
      this.clave_indicador        =   item.clave_indicador;
      this.dialogIndicador        =   true;
      this.txtboton               =   "Actualizar"
      this.estatusAccionTabla     =   2;
      this.modolectura            =   true;
    },
    getUnidades(){
      let url = `/api/aprende/cursos/`+this.idCurso
      this.unidades = []
      this.$http.get(url)
          .then((response) => {
            this.unidades = response.data.unidades;
            console.log("UNIDADES----->")
            console.log(this.unidades)
          })
          .catch(function (error){
            console.log(error)
          })
    },
    getUnidadesAgregadasSesion(){
      let url = `/api/aprende/detalle-sesion/`+this.idSesion
      this.arrayUnidades = []
      this.$http.get(url)
          .then((response) => {
            this.concentrado_unidades = response.data;
            //console.log("concentrado_unidades")
            //console.log(this.concentrado_unidades)
            for (let item = 0; item < this.concentrado_unidades.length; item++) {
              this.arrayUnidades.push(this.concentrado_unidades[item].unidad.id)
            }
            //console.log("concentrado unidades:")
            //console.log(this.arrayUnidades)
          })
          .catch(function (error){
            console.log(error)
          })
    },
    getUnidadSeleccionada(item){

      let url = `/api/aprende/unidadesobjetivos/`+this.idUnidad.id;
      this.objetivos = [];
      this.$http.get(url)
          .then((response) => {
            this.objetivos = response.data
            console.log("objetivos seleccionados")
            console.log(this.objetivos)
          })
          .catch(function (error){
            console.log(error)
          })
    },
    showImportar(){
      this.dialogIndicador = true;
    },
    showModal() {
      this.dialog = true;
    },
    showUnidad() {
      this.dialogIndicador     = true;
      this.txtboton           = "Guardar";
      this.estatusAccionTabla =   1;
      this.getUnidades();
    },
  },
  mounted(){
    this.idCurso          =    atob(this.$route.query.idCurso);
    this.idSesion         =    atob(this.$route.query.idSesion);
    this.nombre_sesion    =    atob(this.$route.query.nombre_sesion);
    this.items[1].text    =    'Sesión: '+ atob(this.$route.query.nombre_sesion);
    this.getUnidadesAgregadasSesion()
  }
}
</script>
<template>
  <div>
    <div class="grey--text text-darken-1 mb-2">
        {{nombre_curso}}
        <v-col class="d-flex" cols="12" sm="6">
            <template>
                <v-select
                label='Periodo:'
                v-model='idPeriodo'
                :items='periodos'
                item-value='id'
                item-text='fecha_inicio'
                @change="consultar"
                >
                 <template slot='selection' slot-scope='{ item }'>
                    {{ formatDate(item.fecha_inicio) }} - {{ formatDate(item.fecha_fin)}}
                </template>
                <template slot='item' slot-scope='{ item }'>
                    {{ formatDate(item.fecha_inicio) }} - {{  formatDate(item.fecha_fin) }}
                </template> 

                </v-select>
            </template>       
        </v-col>
    </div> 
  <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table
          :headers="headers"
          :items="alumnos"
        >
                        
            <template v-slot:item.aprovechamiento="{ item }">
                    
                    <v-text-field
                   
                    v-model="item.aprovechamiento"
                    v-bind:value="item.aprovechamiento"
                    type="number"
                    :rules="[numberRule]"
                    dense
                    @change="guardarAprovechamiento(item.aprovechamiento,item.id)"
                    ></v-text-field>
                
            </template>
            
            <template v-slot:item.alumnocontactado="{ item }">
               
                <v-switch 
                v-model="item.alumnocontactado" 
                v-bind:value="item.alumnocontactado"
                @change="guardarAlumnoContactado(item)"
                ></v-switch>
            </template>
            <template v-slot:item.entregoactividades="{ item }">
              
                <v-switch 
                v-model="item.entregoactividades"
                v-bind:value="item.entregoactividades"
                @change="guardarEntregoActividades(item.entregoactividades,item.id)" 
                ></v-switch>
            </template>  
            <template v-slot:item.mediodecontacto="{ item }">
               
                    <v-select          
                    v-model="item.mediodecontacto"
                    v-bind:value="item.mediodecontacto"
                    :items='medios'
                    :disabled="!item.alumnocontactado"
                    item-value='id'
                    item-text='mediocontacto'
                    @change="guardarMediodeContacto(item.mediodecontacto,item.id)"
                    >
                    <template slot='selection' slot-scope='{ item }'>
                        {{ item.mediocontacto }}
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{ item.mediocontacto }}
                    </template> 
                    </v-select>
              </template> 
        </v-data-table>
    
  </div>
</template>

<script>
   import moment from 'moment';
   moment.locale('es');
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  export default {
    data:() => ({
        absolute: false,
        opacity: 0.46,
        zIndex: 5,
        idCurso: null,
        idProfesor:null,
        course_id:null,
        idPeriodo:'',
        nombre_curso:'',
        alumnos:[],
        datos:[],      
        periodos:[],       
        medios:[],
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        headers: [
          {
            text: 'Nombre del Alumno',align: 'start',sortable: false,value: 'alumno_concentrado.nombre',
          },
          { text: 'Aprovechamiento', value: 'aprovechamiento'},   
          { text: 'Entregó actividades', value: 'entregoactividades' },
          { text: 'Se contacto al estudiante', value: 'alumnocontactado' },
          { text: 'Medio de contacto', value: 'mediodecontacto' },
        ],
        overlay: false,
        absolute:true,
     
    }),
    created () {
        this.idCurso = this.$route.query.id
        this.nombre_curso = this.$route.query.curso
        this.course_id= this.$route.query.course_id
        this.init()
        
    },    
    methods: {
    init () {
      this.getPeriodos(),
      this.getMediosContacto()
    }, 
    formatDate(value) {
      return moment(value,"DDMMYYYY").format('ll');
    }, 
     numberRule: v  => {
      //if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 5 && v <= 10) {return true;}
      {return 'La calificación debe ser entre 5 y 10; en caso contrario no se guardará';}
    },
    guardarAprovechamiento(aprovechamiento,id){
      if(aprovechamiento>=5 && aprovechamiento<=10){
                this.overlay = true;
                this.$http.post('/api/concentrados', {
               
                'id':               id,
                'atributo':         1,
                'aprovechamiento':  aprovechamiento,
                
                })
                 .then((response) => {
                   // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                   // console.log(error)
                    this.overlay = false;
                }) 
      }
                
    },
    guardarAlumnoContactado(item){
      if(item.alumnocontactado==null){
       
        item.mediodecontacto=null;
        this.guardarMediodeContacto(null,item.id)
         item.alumnocontactado = 0;
      }
      else
        item.alumnocontactado = 1;
      //console.log('alumnoContactado actualizado: ' + alumnocontactado + 'id:' + id)
                this.overlay = true;
                this.$http.post('/api/concentrados', {
              
                'id':               item.id,
                'atributo':         2,
                'alumnocontactado':  item.alumnocontactado,
               
                })
                 .then((response) => {
                   // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                   // console.log(error)
                    this.overlay = false;
                })
                
    },
    guardarEntregoActividades(entregoactividades,id){
      if(entregoactividades==null)
        entregoactividades = 0;
      else
        entregoactividades = 1;
      //console.log('alumnoContactado actualizado: ' + alumnocontactado + 'id:' + id)
                this.$http.post('/api/concentrados', {
               
                'id':               id,
                'atributo':         3,
                'entregoactividades':  entregoactividades,
               
                })
                 .then((response) => {
                   // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                  //  console.log(error)
                    this.overlay = false;
                })
    },
    guardarMediodeContacto(mediodecontacto,id){
        //console.log('medio contactado:' + mediodecontacto + 'del id:' + id);
                this.$http.post('/api/concentrados', {
                
                'id':               id,
                'atributo':         4,
                'mediodecontacto':  mediodecontacto,
               
                })
                 .then((response) => {
                   // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                   // console.log(error)
                    this.overlay = false;
                })
    },
    consultar(id){
            this.idPeriodo = id;
            
            //console.log("idPeriodo:" + this.idPeriodo + " idCurso:" + this.idCurso + " idProfesor:" + this.idProfesor + " course_id:" + this.course_id);
            this.$http.get('/api/concentrados', {
                params: {
                'idPeriodo':        this.idPeriodo,
                'idCurso':          this.idCurso,
                'idProfesor':       this.idProfesor,
                'course_id':        this.course_id,
                'students':         this.students
                }
                })
                .then((response) => {
                  this.alumnos = response.data;
                   // console.log(this.alumnos)
                })
                .catch(function (error){
                    console.log(error)
                })
                
                
    },
    getAlumnos () {
            let url = '/api/concentrados'
            this.$http.get(url)
            .then((response) => {
               this.datos = response.data;
               this.alumnos = this.datos['profile'];
              // console.log(this.alumnos)
            })
            .catch(function (error){
                    console.log(error)
            }
          
        )
    },
    getPeriodos () {
            let url = '/api/periodos'
            this.$http.get(url)
            .then((response) => {
               this.periodos = response.data;
               //console.log(this.periodos)
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
    },
    getMediosContacto () {
            let url = '/api/medioscontacto'
            this.$http.get(url)
            .then((response) => {
               this.medios = response.data;
              // console.log(this.medios)
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
    },

    },
    mounted(){
      
          this.$http.get('/api/user').then(response => {
            //console.log(response)
            //console.log(response)
            this.idProfesor = response.data.id
          })

            let url = '/api/periodos'
            this.$http.get(url)
            .then((response) => {
               this.periodos = response.data;
               //console.log(this.periodos)
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
    } 
  }
</script>
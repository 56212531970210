<template>
  <div>
    <div v-if="examen_finalizado==0">
      <h3>Tiempo restante: <span class="red--text">{{ countdown }}</span></h3>
    </div>
  </div>
</template>

<script>
import firebase from "@/firebase/init"
import moment from 'moment';
import moment_timezone from 'moment-timezone'
moment.locale('es');
/*
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'http://localhost:8000'
*/
export default {
  name: 'CountDown',
  props: {
    firebase_id: {
      type: String,
      required: false
    },
    examen_alumno_id: {
      type: Number,
      required: false
    },
    iniciar_contador: {
      type: Number,
      required: false
    },
    current_question: {
      type: Number,
      required: false
    },
    tiempo_actual_examen: {
      type: Number,
      required: false
    },
    tiempo_minutos_responder: {
      type: Number,
      required: false
    },
    show_from_user: {
      type: Number,
      required: false
    },
    examen_finalizado: {
      type: Number,
      required: false,
    },
    resume: {
      type: Number,
      required: false,
    },
    paused: {
      type: Number,
      required: false,
    },
    idExamen: {
      type: Number,
      required: true,
    },
    detener_examen: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      databaseNameStream: process.env.VUE_APP_FIREBASE_DB_EXAMS_NAME,
      examen_finalizado_aux: 0,
      seconds: 0,
      countdown: null,
      expires_in: null,
      interval: null,
      checkstatus: null,
      paused_action: 0,
      resume_action: 0,
      exam_finished: 0,
      current_timestamp: ''
    }
  },
  mounted() {
    // new
    if (parseInt(this.iniciar_contador) == 1) {
      let tiempo_examen_real = this.tiempo_minutos_responder * 60,
          tiempoRestante = this.tiempo_actual_examen;
      if (tiempoRestante != null && tiempoRestante > 0) {
        this.seconds = tiempoRestante;
      } else {
        this.seconds = tiempo_examen_real;
      }
      this.countdown = moment.utc(this.seconds).format('HH:mm:ss');
      this.expires_in = this.seconds;
      this._checkStatus();
      this._setInterval();
    }
  },
  destroyed() {
    this.countdown = '00:00:00';
  },
  watch: {
    detener_examen: function(val) {
      this._stopAll()
    }
  },
  methods: {
    __pingStatus() {
      var database = firebase.database().ref(this.databaseNameStream);
      this.current_timestamp = moment_timezone().tz("America/Mexico_City").unix()
      firebase.database().ref(this.databaseNameStream + "/" + this.firebase_id )
          .update( {
            examen_alumno_id: this.examen_alumno_id,
            examen_id: this.idExamen,
            segundos_actuales: this.expires_in,
            current_question: this.current_question,
            current_timestamp: this.current_timestamp
          } )
      if ( parseInt(this.examen_finalizado) == 1 ) {
        this._stopAll()
      }
    },
    _checkStatus: function () {
      this.checkstatus = setInterval(() => {
        if (this.resume == 1) {
          this._resumeCounter();
        }
        if (this.paused == 1) {
          this._pauseCounter();
        }
        this.$emit('student_stauts_exam', {
          leftSecondsExam: this.expires_in,
          exam_finished: this.exam_finished,
        });
      }, 1000);
    },

    _resumeCounter: function () {
      this._setInterval();
      this.$emit("paused", 0);
      this.$emit("resume", 0);
    },

    _stopAll: function () {

      this._pauseCounter();
      clearInterval(this.checkstatus);
      this.countdown = '00:00:00';
      this.exam_finished = 1;
      // alert('Tu examen ha finalizado');

      this.$emit("tiempo_finalizado", 1);

      this.$emit("showPuaseButton", 0);
      this.$emit("showPuaseButton", 0);
      this.$emit('student_stauts_exam', {
        leftSecondsExam: this.expires_in,
        exam_finished: this.exam_finished,
      });
      clearInterval(this.interval);
      setTimeout(() => {
        this.$emit('mostrar_resultados', 1)
      },2000)
      // this.confirmEnviarExamen()
    },

    _pauseCounter: function () {
      clearInterval(this.interval);
      this.interval = null;
      this.$emit('paused', 0);
      this.$emit('resume', 0);
    },

    _setInterval: function () {
      let counter = 0;

      this.interval = setInterval(() => {
        if (this.expires_in < 1) {

          this.countdown = '00:00:01';
          this.$emit("examen_finalizado", 1);
        }
        this.countdown = moment.utc(this.expires_in * 1000).format('HH:mm:ss');
        this.expires_in -= 1;
        if (counter % 3 == 0) {
          this.__pingStatus();
        }
        counter++;
        this.$emit('contador_iniciado', 1);
      }, 1000);
    },
  }
}
</script>

<template>
<v-container>
    <div class="home">
        <center>

            Tiempo restante: <CountDown :seconds="1200" />
        </center>
    </div>
        <form action="#">
          <center><h1>{{this.titulo}}</h1></center><br>
          <center><h4>{{this.instrucciones}}</h4></center><br>
            <div v-for="pregunta in preguntas.reactivos">
                
                <label><h3>{{ pregunta.descripcion }}</h3></label>
                <label><h5>{{ pregunta.instrucciones }}</h5></label>
                   <div v-if="pregunta.tiporeactivo_id === 1">
                       <v-radio-group v-model="respuestasSeleccionadas[pregunta.id]">
                           <v-radio
                               v-for="descripcion in pregunta.respuestas"
                               :key="descripcion.id"
                               :label="descripcion.respuesta"
                               :value="descripcion.id"
                               @click="leerRespuesta(pregunta.id,descripcion.id)"
                           ></v-radio>
                       </v-radio-group>
                    </div>
                    <div v-else-if="pregunta.tiporeactivo_id === 2">
                        <v-checkbox
                            v-for="descripcion in pregunta.respuestas"
                            :key="descripcion.id"
                            :label="descripcion.respuesta"
                            :value="descripcion.id"

                        ></v-checkbox>
                    </div>
                <div v-else-if="pregunta.tiporeactivo_id === 6">
                    <v-text-field
                        v-for="(descripcion,index) in pregunta.respuestas"
                        :key="descripcion.id"
                        :label="conversion(index)"

                    ></v-text-field>
                </div>
                    <div v-else>
                       ninguna de las anteriores
                    </div>

                    <br>
            </div>
        </form>
 </v-container>
</template>

<script>
  import CountDown from '@/views/Evaluacion/coundown.vue'
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = process.env.VUE_APP_API_URL


  export default {
      components: {
          CountDown
      },

    props: {
      source: String,
      
    },
    data: () => ({
      drawer: null,
      idExamen:'',
      preguntas:[],
      examenes:[],
      tiempo:0,
      respuestasSeleccionadas:[],
      selected:'',
      titulo:'',
      instrucciones:'',
      items: [
        {
          /* icon: 'mdi-account-check',
          'icon-alt': 'mdi-account-check', */
            icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'Directiva',
          //model: true,
          children: [
            { icon: 'mdi-school',                 text: 'Asignaturas',          link:'/asignaturas' },
            {  icon:'mdi-timeline-check-outline', text: 'Áreas',link:'/areaconocimiento' },
            {  icon:'mdi-office-building-outline',text: 'Grados',link:'/grados' },
            {  icon:'mdi-table',                  text: 'Niveles Educativos',link:'/NivelEducativo' },
            {  icon:'mdi-table-clock',            text: 'Sistemas Educativos',link:'/SistemaEducativo' },
          ],
        },
        {
          /* icon: 'mdi-account-check',
          'icon-alt': 'mdi-account-check', */
            icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'Evaluación',
          //model: true,
          children: [
            { icon: 'mdi-school',                 text: 'Examen',          link:'/examen' },
            {  icon:'mdi-timeline-check-outline', text: 'Banco de Reactivos',link:'/bancoreactivos' },
          ],
        },         
        {
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'Reportes',
          children: [
            { icon: 'mdi-text-box-check-outline',     text: 'Docente', link:'/ReporteDocente' },
            { icon: 'mdi-grid',     text: 'Trimestral', link:'/RptTrimestral' },

          ],
        },
        {
          /* icon: 'mdi-account-check',
          'icon-alt': 'mdi-account-check', */
            icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'Capacitación',
          //model: true,
          children: [
            { icon: 'mdi-school',                 text: 'Clases',          link:'/Clases' },
            {  icon:'mdi-timeline-check-outline', text: 'Requisitos' },
            {  icon:'mdi-office-building-outline',text: 'Ciclos' },
            {  icon:'mdi-table',                  text: 'Alternativas' },
            {  icon:'mdi-table-clock',            text: 'Horarios' },
            {  icon:'mdi-table',            text: 'Instructores' },
          ],
        },
/*         {
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'More',
          model: false,
          children: [
            { text: 'Import' },
            { text: 'Export' },
            { text: 'Print' },
            { text: 'Undo changes' },
            { text: 'Other contacts' },
          ],
        }, */
        /* { icon: 'mdi-cog', text: 'Settings' },
        { icon: 'mdi-message', text: 'Send feedback' },
        { icon: 'mdi-help-circle', text: 'Help' },
        { icon: 'mdi-cellphone-link', text: 'App downloads' },
        { icon: 'mdi-keyboard', text: 'Go to the old version' }, */
      ],

      email: ''
    }),
   
    created () {
      //console.log(this.idExamen);

      this.idExamen = crypt.decrypt(this.$route.query.idExamen) ;
      this.consultarExamen()
    },
      computed:{
          conversion(){
              return index => `Respuesta ${index+1}`
          }
      },
    mounted() {
        

    },    
    methods: {
    init () {
      //this.loginGoogle()
    },
        leerRespuesta(id,idrespuesta){
         //   console.log("el id pregunta es:" + id + "id respuesta:" + idrespuesta)
        },
        consultarExamen(){

          var decipher = crypt.decrypt(this.idExamen);
          let url = `/api/examenes?idExamen=${decipher}`

          // let url = `/api/examenes?idExamen=${this.idExamen}`

            this.$http.get(url)
            .then((response) => {
               this.examenes = response.data;
             //  console.log("Eexamen" + this.examenes)
               this.titulo = this.examenes[0].titulo;
               this.tiempo = this.examenes[0].tiempo_minutos_responder *60;
               this.instrucciones = this.examenes[0].instrucciones;
            })
            .catch(function (error){
             //       console.log(error)
            }          
            )


            let url2 = `/api/detalles?idExamen=${this.idExamen}`
            this.$http.get(url2)
            .then((response) => {
               this.preguntas = response.data;
             //  console.log(this.preguntas)
            })
            .catch(function (error){
                 //   console.log(error)
            }          
            )
    },

    }
  }
</script>
<template>
  <div>
  	<v-card>
	  	<v-card-title>
	  		<h3>Reporte de evaluaciones</h3>
	  	</v-card-title>

	  	<div class="ml-3 mr-3">
<!--			<v-select
			width="100%"
			item-text="nombre"
			item-value="id"
			v-model="selected"
			:items="items"
			label="Filtrar por cursos"
			multiple
			outlined
			@change="getExamenes()">
			<template v-slot:prepend-item>
				<v-list-item
				  ripple
				  @click="toggle"
				>
				<v-list-item-action>
				  <v-icon :color="selected.length > 0 ? 'purple' : ''">
				    {{ icon }}
				  </v-icon>
				</v-list-item-action>
				<v-list-item-content>
				  <v-list-item-title>
				    Seleccionar todos
				  </v-list-item-title>
				</v-list-item-content>
				</v-list-item>
				<v-divider class="mt-2"></v-divider>
			</template>
			</v-select>-->
		</div>
	    <v-data-table
      :loading="flagLoading"
      loading-text="Cargando, espere"
      :headers="headers"
      :items="desserts"
      item-key="name"
      class="elevation-1"
	    >
      <template v-slot:item.exportar="{item}">
        <v-btn @click="exportarReporte(item)" class="primary" small>
          <v-icon>mdi-export</v-icon>
        </v-btn>
      </template>
	    <template v-slot:item.detalles_examen="{ item }">
			<v-dialog :retain-focus="false" v-model="dialog" persistent max-width="1200px">
			  	<template v-slot:activator="{ on, attrs }">
				  	<v-btn @click="loadDetallesExamen(item.examen_id)" v-bind="attrs" v-on="on" class="mx-2 primary white--text" fab dark small>
				  		<v-icon >mdi-eye</v-icon>
				  	</v-btn>
				</template>
				<v-card>
					<v-card-title>
						<span class="headline">Detalles del examen</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-data-table
							loading-text="Cargando, espere"
							:loading="innerLoading"
							:headers="headersInner"
							:items="dessertsInner"
							item-key="name"
							class="elevation-1">
								<template v-slot:item.historial="{ item }">
									<v-dialog :retain-focus="false" v-model="dialog2" persistent max-width="1200px">
									  	<template v-slot:activator="{ on, attrs }">
										  	<v-btn @click="historialExamenAlumno(item.examen_id_aux, item.user_id_aux)" depressed v-bind="attrs" v-on="on" >
										  		Visualizar
										  	</v-btn>
										</template>
										<v-card>
											<v-card-title>
												<span class="headline">Historial de exámenes</span>
											</v-card-title>
											<v-card-text>
												<v-container>
													<v-data-table
													loading-text="Cargando, espere"
													:loading="innerLoadingHistorial"
													:headers="headersInnerHistorial"
													:items="dessertsInnerHistorial"
													item-key="name"
													class="elevation-1">
                            <template v-slot:item.ver_intento="{item}">
                              <v-btn v-if="item.examen_finalizado!='Sin terminar'" target="_blank" class="primary" small :href="'/vistaexamenalumno?idExamen=' + item.examen_id+'&show_from_user='+item.user_id_aux">Ver intento</v-btn>
                              <span v-else>-</span>
                            </template>
													</v-data-table>
												</v-container>
											</v-card-text>
										    <v-card-actions>
										  		<v-spacer></v-spacer>
										  		<v-btn color="blue darken-1" text @click="dialog2 = false"> Cerrar</v-btn>
										    </v-card-actions>
										</v-card>
									</v-dialog>
								</template>
							</v-data-table>
						</v-container>
					</v-card-text>
				    <v-card-actions>
				  		<v-spacer></v-spacer>
				  		<v-btn color="blue darken-1" text @click="dialog = false"> Cerrar</v-btn>
				    </v-card-actions>
				</v-card>
			</v-dialog>
	    </template>
	    <template v-slot:item.sincronizar="{ item }">
		    <v-dialog
		    :retain-focus="false"
		      v-model="dialogSync"
		      scrollable
		      max-width="600px"
		    >
		      <template v-slot:activator="{ on, attrs }">
		        <v-btn
		        @click="listadoCalificacionExamen(item.examen_id)"
            class="mx-2 primary" fab dark small
            v-bind="attrs"
            v-on="on"
		        >
		        <v-icon class="white--text">mdi-sync</v-icon>
		        </v-btn>
		      </template>
		      <v-card>
		        <v-card-title>Listado de calificaciones</v-card-title>
		        <v-divider></v-divider>
		        <v-card-text style="height: 300px;">
					<v-row align="center" v-for="(calificacion, index) in calificaciones">
					  <v-col
					    cols="12"
					    sm="6"
					  >
					    <v-subheader>{{ calificacion.user_name }}</v-subheader>
					  </v-col>
					  <v-col
					    cols="12"
					    sm="6"
					  >
						<v-select
						v-model="calificacion.ultima_calificacion"
						:value="calificacion.ultima_calificacion"
						:items="calificacion.calificaciones_finales"
						label="Elegir calificación"
						></v-select>
					  </v-col>
					</v-row>
		        </v-card-text>
		        <v-divider></v-divider>
		        <v-card-actions>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialogSync = false"
		          >
		            Cerrar
		          </v-btn>
					<v-btn
					color="blue darken-1"
					text
					@click="envioCalificaciones"
					>
					Confirmar
					</v-btn>
		        </v-card-actions>
		      </v-card>
		    </v-dialog>
	    </template>
	    </v-data-table>
    </v-card>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
export default {
	data: () => ({
		dessertsInner : [],
		dessertsInnerHistorial : [],
		dialog: false,
		dialog2 : false,
		dialogSync : false,
		flagLoading : true,
		innerLoading : true,
		innerLoadingHistorial : true,
		search: '',
		calories: '',
		desserts: [],
		items : [],
		selected : [],
		selectedSync : [],
		calificaciones : [],
	}),
	mounted() {
		this.init();
	},
	computed: {
		likesAllFruit () {
			return this.selected.length === this.items.length
		},
		likesSomeFruit () {
			return this.selected.length > 0 && !this.likesAllFruit
		},
		icon () {
			if (this.likesAllFruit) return 'mdi-close-box'
			if (this.likesSomeFruit) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		},
		headersInnerHistorial() {
			return [
				{ text: 'Fecha del intento', sortable: false, value: 'fecha_intento', },
				{ text: 'Calificación obtenida', sortable: false, value: 'calificacion_obtenida', },
				//{ text: 'Tiempo del examen', sortable: false, value: 'tiempo_examen', },
				{ text: 'Estatus examen', sortable: false, value: 'examen_finalizado', },
				//{ text: 'Reactivos preguntados', sortable: false, value: 'reactivos_preguntados', },
        { text: 'Tiempo de realización', value: 'tiempo_de_realizacion_examen' },
        { text: 'Tiempo total examen', value: 'tiempo_total_examen' },
        { text: 'Ver intento', value: 'ver_intento' },
			]
		},
		headersInner() {
			return [
				{ text: 'Nombre del alumno(a)', sortable: false, value: 'nombre_alumno', },
				{ text: 'Intentos restantes', sortable: false, value: 'numero_intentos', },
				{ text: 'Última calificación', sortable: false, value: 'ultima_calificacion', },
				{ text: 'Historial de intentos', sortable: false, value: 'historial', },

			]
		},
		headers () {
			return [
				{ text: '#', sortable: false, value: 'examen_id', },
				{ text: 'Título', sortable: false, value: 'titulo_examen', },
				// { text: 'Curso', sortable: false, value: 'nombre_curso', },
				{ text: 'Alumnos que aplicaron', sortable: false, value: 'alumnos_aplicados', },
				{ text: 'Detalles', sortable: false, value: 'detalles_examen', },
        //{ text: 'Sincronizar', sortable: false, value: 'sincronizar', },
        { text: 'Exportar', sortable: false, value: 'exportar', },
				{ text: 'Fecha de inicio', sortable: false, value: 'fecha_inicio', },
				{ text: 'Fecha final', sortable: false, value: 'fecha_fin', },
			]
		},
	},
	methods: {
    exportarReporte (item){
      Swal.fire({
        icon: 'success',
        title: 'Tu reporte se está generando, espera un momento...',
        showConfirmButton: false,
        timer: 2000
      })
      this.$http.post("/api/evaluacionalumno/exportar-reporte", {
        examen_id: item.examen_id
      }).then(res => {
        window.open(res.data, "_blank");
      }).catch(err => {

      })
    },
		envioCalificaciones() {
			this.$http.post('/api/escuelas/asignarCalificacion', {
				calificaciones : this.calificaciones
			})
			.then(response => {
				alert("Se sincronizaron las calificaciones correctamente");
				//console.log(response.data);
			});
		},
		listadoCalificacionExamen(examen_id) {
			this.$http.get('api/evaluacionalumno/get-alumno-calificaciones-examen?examen_id=' + examen_id)
			.then(response => {
				this.calificaciones = response.data;
			});
			return false;

			this.calificaciones = [];
			for(let item = 0; item < 20; item++){
				this.calificaciones.push({
					id : item,
					name : 'Fernando Torres',
					calificaciones : [8,9,10],
					last_calificacion : 9
				});
			}
		},
		toggle () {
			this.$nextTick(() => {
				if (this.likesAllFruit) {
					this.selected = []
				} else {
					this.selected = this.items.slice()
				}

			})
		},
		loadDetallesExamen(examen_id) {
			this.dessertsInner = [];
			this.innerLoading = true;
			this.$http.get('/api/evaluacionalumno/detalles-examen-alumnos?examen_id=' + examen_id)
			.then(response => {
				this.dessertsInner = response.data;
			})
			.then(() => {
				this.innerLoading = false;
			})
		},
		historialExamenAlumno(examen_id, user_id) {
			this.innerLoadingHistorial = true;
			this.dessertsInnerHistorial = [];
			this.$http.post("/api/evaluacionalumno/historial-examen-alumno", {
				examen_id : examen_id,
				user_id : user_id,
			})
			.then(response => {
				this.dessertsInnerHistorial = response.data;
			})
			.then(() => {
				this.innerLoadingHistorial = false;
			})
		},
		init() {
			this.getExamenes();
			//this.getCursos();
		},
		getCursos() {
      return true
			/*this.$http.get('api/cursos')
			.then(response => {
				this.items = response.data;
				//this.selected = response.data;
			})
			.then(() => {
				this.getExamenes()
			})*/
		},
		getExamenes() {
			this.$http.get("/api/evaluacionalumno/get-examenes-docente", {
				//courses : this.selected
			})
			.then(response => {
				this.desserts = response.data;
			})
			.then(() => {
				this.flagLoading = false;
			})
		},
	},
}
</script>

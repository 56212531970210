<template>
	<div>
	<h1>Notificaciones</h1>
      <v-list >
      <template v-if="itemsNotifications.length <= 0">
          <v-list-item align="center">
            <a href="#!">{{ (itemsNotifications.length == 0) ? 'No se encontraron notificaciones' : 'Cargando notificaciones' }}</a>
          </v-list-item>        
      </template>
      <template  v-for="(item, index) in itemsNotifications">
          <v-list-item link>
            <v-list-item-avatar>
              <v-img src="https://secure.gravatar.com/avatar/979bcb8bf9a658b004257aa6581a2307?s=128&d=mm&r=g"></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="px-5 py-5">
              <v-list-item-title>{{ item.titulo_notificacion }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.contenido_notificacion }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text>{{ item.carbon_date }}</v-list-item-action-text>
            </v-list-item-action>

          </v-list-item>


          <v-divider
            :key="index"
          ></v-divider>
          

      </template>
      </v-list>
	</div>
</template>
<script type="text/javascript">
export default {
	name : 'vernotificaciones',
	data:()=>({
		itemsNotifications: [],
	}),
	mounted() {
		this.getNotifications();
		this.readNotifications();
	},
	methods : {
	  readNotifications() {
	    this.$http.post('/api/readnotifications')
      .then(response => {

      });
    },
		getNotifications() {
		  this.$http.get('/api/notifications?all=1')
		  .then(response => {
		    this.itemsNotifications = response.data;
		  })
	},
	}	
}
</script>
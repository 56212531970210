var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[[_c('v-row',{attrs:{"justify":"center"}},[(_vm.dialogObjetivo)?_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"790"},on:{"input":function($event){_vm.dialogObjetivo = false}}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-message-text-outline")]),_c('h3',[_vm._v("Agregar Oferta Académica - Currícula Curso")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('v-select',{attrs:{"label":"Oferta Académica:","items":_vm.ofertas,"item-value":"id","item-text":"nombre_oferta_academica","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_oferta_academica)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_oferta_academica)+" ")]}}],null,false,705426492),model:{value:(_vm.idOferta),callback:function ($$v) {_vm.idOferta=$$v},expression:"idOferta"}})]],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('v-select',{attrs:{"label":"Curricula:","items":_vm.curriculas,"item-value":"id","item-text":"nombre_curricula","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_curricula)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_curricula)+" ")]}}],null,false,1387764412),model:{value:(_vm.idCurricula),callback:function ($$v) {_vm.idCurricula=$$v},expression:"idCurricula"}})]],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.guardar}},[_vm._v(" "+_vm._s(_vm.txtboton)+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.closeImportar()}}},[_vm._v(" Cerrar ")])],1)],1)],1):_vm._e()],1)],_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-message-text-outline")]),_c('h3',[_vm._v("Grupo - Oferta Académica - Currícula")]),_c('v-spacer')],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"green","small":"","title":"Crear Unidad"},on:{"click":function($event){return _vm.showUnidad()}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-message-text-outline")])],1)],1)],1)],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.concentrado},scopedSlots:_vm._u([{key:"item.eliminar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","elevation":"2","color":"red","small":"","x-small":"","title":"Eliminar Oferta Académica"}},[_c('v-icon',{attrs:{"small":"","title":"Eliminar Oferta Académica - Currícula","color":"white"},on:{"click":function($event){return _vm.deleteOferta(item)}}},[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
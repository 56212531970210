var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey--text text-darken-1 mb-2"},[_vm._v(" "+_vm._s(_vm.nombre_curso)+" "),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[[_c('v-select',{attrs:{"label":"Periodo:","items":_vm.periodos,"item-value":"id","item-text":"fecha_inicio"},on:{"change":_vm.consultar},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_inicio))+" - "+_vm._s(_vm.formatDate(item.fecha_fin))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_inicio))+" - "+_vm._s(_vm.formatDate(item.fecha_fin))+" ")]}}]),model:{value:(_vm.idPeriodo),callback:function ($$v) {_vm.idPeriodo=$$v},expression:"idPeriodo"}})]],2)],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.alumnos},scopedSlots:_vm._u([{key:"item.aprovechamiento",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.aprovechamiento,"type":"number","rules":[_vm.numberRule],"dense":""},on:{"change":function($event){return _vm.guardarAprovechamiento(item.aprovechamiento,item.id)}},model:{value:(item.aprovechamiento),callback:function ($$v) {_vm.$set(item, "aprovechamiento", $$v)},expression:"item.aprovechamiento"}})]}},{key:"item.alumnocontactado",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"value":item.alumnocontactado},on:{"change":function($event){return _vm.guardarAlumnoContactado(item)}},model:{value:(item.alumnocontactado),callback:function ($$v) {_vm.$set(item, "alumnocontactado", $$v)},expression:"item.alumnocontactado"}})]}},{key:"item.entregoactividades",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"value":item.entregoactividades},on:{"change":function($event){return _vm.guardarEntregoActividades(item.entregoactividades,item.id)}},model:{value:(item.entregoactividades),callback:function ($$v) {_vm.$set(item, "entregoactividades", $$v)},expression:"item.entregoactividades"}})]}},{key:"item.mediodecontacto",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"value":item.mediodecontacto,"items":_vm.medios,"disabled":!item.alumnocontactado,"item-value":"id","item-text":"mediocontacto"},on:{"change":function($event){return _vm.guardarMediodeContacto(item.mediodecontacto,item.id)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mediocontacto)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mediocontacto)+" ")]}}],null,true),model:{value:(item.mediodecontacto),callback:function ($$v) {_vm.$set(item, "mediodecontacto", $$v)},expression:"item.mediodecontacto"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
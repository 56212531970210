<script src="../router/index.js"></script>
<template>
  <div>
    <div v-if="es_alumno == 6">
      <h1>Bienvenido a NivelAndo</h1>
      <v-alert type="info">
        A continuación se enlistan todos los eventos disponibles en su
        institución
      </v-alert>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" sm="4" v-for="(sesion, i) in sesiones_aprende">
          <v-card class="pa-0" elevation="2">
            <v-img height="250" :src="sesion.imagen"></v-img>
            <v-card-text>
              <div class="">
                Programado el {{ sesion.fecha_consumo_inicio_formated }} de
                {{ sesion.hora_inicio }} - {{ sesion.hora_fin }}
              </div>
              <p class="display-1 text--primary">
                {{ sesion.nombre_sesion }}
              </p>
              <p class="blue--text">
                Inscripciones disponibles del
                {{ sesion.fecha_seleccion_inicio_formated }} -
                {{ sesion.fecha_seleccion_termino_formated }}
              </p>
              <div class="text--primary">
                {{ sesion.descripcion_sesion }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-5">
              <v-btn
                v-if="
                  sesion.evento_disponible_inscripcion &&
                    sesion.evento_inscrito == 0
                "
                class="primary white--text"
                @click="inscribirse(sesion.evento_id, sesion.id)"
                >Inscribirse</v-btn
              >
              <v-btn
                @click="verContenido(sesion)"
                v-if="
                  sesion.evento_inscrito == 1 &&
                    sesion.evento_disponible_consumo == 1
                "
                class="primary white--text"
                >Ir al evento ahora</v-btn
              >
              <p
                class="blue--text"
                v-if="
                  sesion.evento_inscrito == 1 &&
                    sesion.evento_disponible_consumo == 0
                "
              >
                <small
                  ><v-icon class="blue--text">mdi-information</v-icon> Usted
                  está inscrito pero aún no llega la hora de consumir este
                  evento, por favor vuelva cuando sea la hora de inicio</small
                >
              </p>
              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!--        <v-list shaped>
            <v-subheader>Bienvenido(a)</v-subheader>
            <v-list-item-group color="primary">
                <v-list-item v-for="item in cursos" :key="item.id">
                    <v-list-item-icon>
                        <v-icon >mdi-flag</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.description !=null ? item.nombre + '('+item.description+')': item.nombre" ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>-->

    <!--      <div v-if="es_alumno == 7">
        <EventoSesion></EventoSesion>
      </div>-->
  </div>
</template>

<script>
//import EventoSesion from '@/views/Eventos/EventoSesion.vue';
/*import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'http://localhost:8000'*/

export default {
  //components : { EventoSesion },
  data: () => ({
    loading: false,
    sesiones_aprende: [],
    es_alumno: 0,
    cursos: [],
    page: 1,
    transition: "slide-y-reverse-transition",
    color: "",
  }),
  mounted() {
    //this.getUser();
  },
  methods: {
    verContenido(item) {
      let curricula_id = item.curricula_id,
        unidad_id = item.unidad_id,
        curso_id = item.curso_id,
        url = "",
        nombre_sesion = "";
      //url = '?id='+curricula_id+'&curso_id=' + curso_id + '&unidad_id=' + unidad_id;
      url =
        "/contenido-curricula-curso-online?curricula_id=" +
        curricula_id +
        "&evento_id=" +
        item.evento_id +
        "&sesion_id=" +
        item.sesion_id +
        "&nombre_sesion=" +
        item.nombre_sesion;
      window.open(process.env.VUE_APP_URL + url, "blank_");
    },
    construirLink(curricula_id, curso_id, unidad_id) {
      try {
        return (
          "/contenido-curricula-curso?id=" +
          curricula_id +
          "&curso_id=" +
          curso_id +
          "&unidad_id=" +
          unidad_id
        );
      } catch (e) {
        return "#!";
      }
    },
    inscribirse(evento_id, sesion_id) {
      var r = confirm("Confirma usted inscribirse a este evento?");
      if (r == true) {
        this.$http
          .post("/api/aprende/inscribirse-sesion", {
            //sesion_id : item.id,
            evento_id: evento_id,
            sesion_id: sesion_id,
          })
          .then((res) => {
            if (parseInt(res.data) == 1) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Te has inscrito de manera exitosa a esta sesión",
                showConfirmButton: false,
                timer: 1500,
              });
              this.getSesionesAlumno();
            }
          })
          .catch((error) => {});
      }
    },
    getUser() {
      this.$http
        .get("api/menu/get-menu")
        .then((res) => {
          this.es_alumno = res.data.auth.role_id;
          //rol de alumno

          if (this.es_alumno == 6) {
            this.getSesionesAlumno();
          }

          if (res.data.redirect == "/error") {
            this.$router.push("/error");
          }
        })
        .catch((err) => {});
    },
    init() {},
    getSesionesAlumno() {
      this.$http
        .get("/api/aprende/sesiones/get-sesiones-by-fecha")
        .then((res) => {
          this.sesiones_aprende = res.data;
          console.log("sesiones ---->");
          console.log(this.sesiones_aprende);
        })
        .catch((err) => {});
    },
    getCursos() {
      let url = "/api/cursos";
      this.$http
        .get(url)
        .then((response) => {
          this.cursos = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getCursoTopics() {
      let url = "/api/cursotopic/getTopics";
      this.$http
        .get(url)
        .then((response) => {})
        .catch(function(error) {
          console.log(error);
        });
    },

    getTopics() {
      let url = "/api/cursotopic/getTopics";
      this.$http
        .get(url)
        .then((response) => {})
        .catch(function(error) {
          console.log(error);
        });
    },
    infoDocente() {
      this.$http.get("/api/user").then((response) => {
        //console.log(response)
        // console.log(response)
        // this.email = response.data.email
      });
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("Reporte de evaluaciones")])]),_c('div',{staticClass:"ml-3 mr-3"}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.flagLoading,"loading-text":"Cargando, espere","headers":_vm.headers,"items":_vm.desserts,"item-key":"name"},scopedSlots:_vm._u([{key:"item.exportar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){return _vm.exportarReporte(item)}}},[_c('v-icon',[_vm._v("mdi-export")])],1)]}},{key:"item.detalles_examen",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"1200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 primary white--text",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.loadDetallesExamen(item.examen_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Detalles del examen")])]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading-text":"Cargando, espere","loading":_vm.innerLoading,"headers":_vm.headersInner,"items":_vm.dessertsInner,"item-key":"name"},scopedSlots:_vm._u([{key:"item.historial",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"1200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":""},on:{"click":function($event){return _vm.historialExamenAlumno(item.examen_id_aux, item.user_id_aux)}}},'v-btn',attrs,false),on),[_vm._v(" Visualizar ")])]}}],null,true),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Historial de exámenes")])]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading-text":"Cargando, espere","loading":_vm.innerLoadingHistorial,"headers":_vm.headersInnerHistorial,"items":_vm.dessertsInnerHistorial,"item-key":"name"},scopedSlots:_vm._u([{key:"item.ver_intento",fn:function(ref){
var item = ref.item;
return [(item.examen_finalizado!='Sin terminar')?_c('v-btn',{staticClass:"primary",attrs:{"target":"_blank","small":"","href":'/vistaexamenalumno?idExamen=' + item.examen_id+'&show_from_user='+item.user_id_aux}},[_vm._v("Ver intento")]):_c('span',[_vm._v("-")])]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog2 = false}}},[_vm._v(" Cerrar")])],1)],1)],1)]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar")])],1)],1)],1)]}},{key:"item.sincronizar",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"retain-focus":false,"scrollable":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 primary",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.listadoCalificacionExamen(item.examen_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-sync")])],1)]}}],null,true),model:{value:(_vm.dialogSync),callback:function ($$v) {_vm.dialogSync=$$v},expression:"dialogSync"}},[_c('v-card',[_c('v-card-title',[_vm._v("Listado de calificaciones")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px"}},_vm._l((_vm.calificaciones),function(calificacion,index){return _c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-subheader',[_vm._v(_vm._s(calificacion.user_name))])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"value":calificacion.ultima_calificacion,"items":calificacion.calificaciones_finales,"label":"Elegir calificación"},model:{value:(calificacion.ultima_calificacion),callback:function ($$v) {_vm.$set(calificacion, "ultima_calificacion", $$v)},expression:"calificacion.ultima_calificacion"}})],1)],1)}),1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogSync = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.envioCalificaciones}},[_vm._v(" Confirmar ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
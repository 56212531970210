<template>
	<div>

    <v-alert
        outlined
        type="success"
        text
    >
      <strong>Examen finalizado con éxito</strong>
      <p v-if="revision_estudiante==1 || revision_estudiante==2">
        Calificación final: <strong class="purple--text">{{ calificacion_final }}</strong>
      </p>
      <p v-if="revision_estudiante==3">
        Su docente le dará más información acerca de su calificación en esta evaluación presentada
      </p>
      <v-btn class="success">Salir</v-btn>
    </v-alert>



		<div v-if="revision_estudiante == 1">
			<h3 class="mt-5 mb-5 text-center">Resumen de su examen</h3>
			<v-card v-for="( pregunta, index ) in preguntas_contestadas" class="py-5 px-5 mt-5" elevation="2" >
				<v-row>
					<v-col class="text-left" lg="9" md="12" sm="12">
						<h3>{{ index + 1 + '.- ' }} {{ (pregunta.reactivo.tiporeactivo_id == 6) ? 'Complete las preguntas en blanco' : pregunta.reactivo.descripcion }}</h3>
            <div v-if="pregunta.url_justificante.url_justificante">
            <a :href="pregunta.url_justificante.url_justificante" target="_blank">Ver justificante</a>
            </div>
            <v-img
                :src="pregunta.reactivo.urlimagen_pregunta"
                width="150px"
                height="auto"
                v-if="pregunta.reactivo.urlimagen_pregunta"
            ></v-img>
					</v-col>
					<v-col class="text-right" lg="1" md="12" sm="12">
						<p class="text-center">Puntos</p>
						<h3 class="text-center">{{ pregunta.porcentaje_esperado_reactivo }}</h3>
					</v-col>
					<v-col class="text-right" lg="2" md="12" sm="12">
						<p class="text-center">Obtenido</p>
						<h3 class="text-center" v-html="drawValue(pregunta.porcentaje_obtenido, pregunta.porcentaje_esperado_reactivo)"></h3>
					</v-col>
				</v-row>

        <iframe v-if="( pregunta.reactivo.enlace_externo_youtube )" :src="'https://www.youtube.com/embed/' + pregunta.reactivo.enlace_externo_youtube" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <iframe v-if="( pregunta.reactivo.youtube_video_id )" :src="'https://www.youtube.com/embed/' + pregunta.reactivo.youtube_video_id" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

				<div v-if="pregunta.reactivo.tiporeactivo_id == 1 || pregunta.reactivo.tiporeactivo_id == 3 ">
					<v-radio-group :value="( pregunta.reactivo.reactivo_contestado ) ? pregunta.reactivo.reactivo_contestado.respuesta_id : 0">
						<v-radio
						:disabled="true"
						:id="'respuesta-' + itemradio.id"
						v-for="(itemradio, index) in pregunta.reactivo.respuestas"
						:value="itemradio.id"
						:label="itemradio.respuesta">
							<template v-slot:label>
                <img
                    :src="itemradio.imagen_respuesta"
                    v-if="itemradio.imagen_respuesta"
                    style="margin-left: 10px;margin-right: 10px"
                ></img>
							  <div>{{ itemradio.respuesta }} <span v-if="(pregunta.reactivo.reactivo_contestado)"><strong v-if="(itemradio.correcta == 1) && (itemradio.id == pregunta.reactivo.reactivo_contestado.respuesta_id)" class="green--text">Correcta</strong></span></div>
							</template>
						</v-radio>
					</v-radio-group>
				</div>

				<div v-if="pregunta.reactivo.tiporeactivo_id == 2 ">
					<v-checkbox
					v-for="descripcion in pregunta.reactivo.respuestas"
					:input-value="( descripcion.reactivo_contestado && (descripcion.id == descripcion.reactivo_contestado.respuesta_id) ) ? true : false"
					:disabled="true">
					<template v-slot:label>
            <img
                :src="descripcion.imagen_respuesta"
                v-if="descripcion.imagen_respuesta"
                style="margin-left: 10px;margin-right: 10px"
            ></img>
						<div>{{ descripcion.respuesta }} <strong v-if="(descripcion.correcta == 1 && descripcion.reactivo_contestado ) && (descripcion.id == descripcion.reactivo_contestado.respuesta_id)" class="green--text">Correcta</strong></div>
					</template>
					</v-checkbox>
				</div>

				<div v-if="pregunta.reactivo.tiporeactivo_id == 6 ">
					<component :idExamen="parseInt(idExamen)" :onlyread="1" :pregunta="pregunta.reactivo" is="Test" />
				</div>
			</v-card>
		</div>
	</div>
</template>


<style scoped>
iframe {
  width: 800px;
  height: 500px;
  margin: 10px;
}
@media only screen and (max-width: 768px) {
  iframe {
    width: 100%;
    height: 100%;
    margin: 10px;
  }
}
</style>

<script>

import Test from '@/views/Evaluacion/Test.vue'
import _ from 'lodash'
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default {
	name: 'Test2',
	components: { Test },
	props: ['reactivoscontestados', 'calificacion_final', 'examen_alumno_id', 'idExamen', 'revision_estudiante','show_from_user'],
	data: () => ({
		preguntas_contestadas : [],
	}),
	mounted() {
		if ( this.examen_alumno_id > 0 ) {
			axios.get('/api/evaluacionalumno/get-reactivos-contestados-exam?examen_alumno_id=' + this.examen_alumno_id + '&show_from_user=' + this.show_from_user)
			.then(responseReactivosContestados => {
				this.preguntas_contestadas = responseReactivosContestados.data;
			})
		}
	},
	methods : {
		drawValue(porcentaje_obtenido, porcentaje_esperado_reactivo) {
			let html = '';
			if ( porcentaje_obtenido != porcentaje_esperado_reactivo ) {
				html += '<span class="orange darken-2 rounded-sm">';
					html += '<span class="px-3 py-3 white--text">'+(porcentaje_obtenido)+'</span>';
				html += '</span>';
			}
			else {
				html += '<span class="light-green rounded-sm">';
					html += '<span class="px-3 py-3 white--text">'+(porcentaje_obtenido)+'</span>';
				html += '</span>';
			}
			return html;
		},
	}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->


<template>
  <v-container>

    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
      <div class="main mt-5">
        <div class="jumbotron" style="background-image: linear-gradient(90deg, rgb(0, 42, 92), rgb(255, 228, 152));">
          <h1 class="display-4" style="color: whitesmoke">Drive para maestros</h1>
          <hr class="my-4">
          <p style="color: whitesmoke">La mejor forma aprovechar todo el potencial de las herramientas de Google.</p>
          <p class="lead">
            <a class="btn btn-lg" href="#" role="button" style="color: whitesmoke">Comienza el aprendizaje</a>
          </p>
        </div>
        <div class="row my-5">
          <div class="col-7" style="border-right: 1px solid #dedede" >
            <h3 class="txt_title ml-5" style="font-weight: 700;">
             Acerca de este curso
            </h3>
            <hr>
            <p class="text-justify">El Centro de profesores de Google for Education es una plataforma en línea interactiva y gratuita, con un plan de estudios creado por educadores para educadores. Ya sea que te sientas a gusto usando tecnología en el aula o que recién estés empezando a usarla, encontrarás cursos que ampliarán el aprendizaje en cualquier nivel.</p>
            <br>
            <h3 class="txt_title ml-5" style="font-weight: 700;">
              Que habilidades obtendrás
            </h3>

            <p class="text-justify">
              El Centro de profesores de Google for Education es una plataforma en línea interactiva y gratuita, con un plan de estudios creado por educadores para educadores. Ya sea que te sientas a gusto usando tecnología en el aula o que recién estés empezando a usarla, encontrarás cursos que ampliarán el aprendizaje en cualquier nivel.
            </p>








          </div><!--col-7-->
          <div class="col-5">
            <h3 class="txt_title ml-5" style="font-weight: 700;">
              Contenido
            </h3>
            <hr>
            <ul style="list-style-type: none;">
              <li class="my-2">
                <span class="badge badge-pill badge-info">
                    <i class="fa fa-star 2x" aria-hidden="true"></i>
                </span>
                Conceptos básicos
              </li>
              <li class="my-2">
                <span class="badge badge-pill badge-info">
                    <i class="fa fa-star" aria-hidden="true"></i>
                </span>
                Capacitación avanzada
              </li>
              <li class="my-2">
                <span class="badge badge-pill badge-info">
                    <i class="fa fa-star" aria-hidden="true"></i>
                </span>
                Capacitación sobre dispositivos
              </li>
              <li class="my-2">
                <span class="badge badge-pill badge-info">
                    <i class="fa fa-star" aria-hidden="true"></i>
                </span>
                Herramientas para la capacitación de alumnos diversos
              </li>

              <li class="my-2">
                <span class="badge badge-pill badge-warning">
                    <i class="fa fa-trophy" style="color: white !important;" aria-hidden="true"></i>
                </span>
                Certificado
              </li>

            </ul>





          </div>
        </div><!--row-->

        <div class="row">
          <div class="col-12">
            <h3 class="txt_title my-5 ml-5" style="font-weight: 700;">
              Capacítate con un experto
            </h3>
            <hr>
            <p>
              Encuentra un capacitador individual o un socio de desarrollo profesional certificado de tu zona para obtener capacitación y asistencia en persona o virtual.
            </p>

          </div><!--col-12-->
        </div><!--row-->

        <div class="row mt-5">
          <div class="col-6">

            <img class="img-responsive" width="450px" height="auto" src="images/image_drive.jpg">
          </div>

          <div class="col-6">
            <h3 class="txt_title my-5 ml-5" style="font-weight: 700;">
              Aumenta la productividad y la colaboración y dale más sentido a la enseñanza
            </h3>
            <hr>
            <p>
              Google trabajó con educadores de todo el país para crear Classroom: una herramienta ágil y fácil de usar que ayuda a los profesores a administrar el trabajo del curso. Con Classroom, los educadores pueden crear clases, repartir deberes, calificar, enviar comentarios y tener acceso a todo desde un solo lugar.
            </p>

          </div><!--col-6-->
        </div><!--row-->



















      </div><!--main-->









  </v-container>
</template>


<script>
export default {

}
</script>



<template>
    <div>
  <h1>
       Editar
  </h1>

<hr>

  <v-row>
                <v-col :cols="12">                     
                     <v-card elevation="4">
                    <v-card-title>
                        <v-icon large class="mr-2" color="purple">
                            mdi mdi-file-document
                        </v-icon>
                        <h3>Eidición de encuestas</h3>
                             <v-btn
                                  id=""
                                  color="primary"
                                  dark
                                  class="my-2 ml-auto "
                                  @click="save()"
                              >Guardar
                              </v-btn>
                    </v-card-title>
                    <v-card-text>
                    
                    <v-text-field
                        v-model="nombreEncuesta"
                        label="Nombre de la encuesta"
                        required
                    ></v-text-field>
                        <v-row>
                                <v-col :cols="12"> 
                                    <div class="form-group" v-for="(input,k) in inputs" :key="k">
                                            <v-row>                                                
                                                <v-col :cols="9"> 
                                                    <v-text-field
                                                        v-model="input.name"
                                                        label="Pregunta"
                                                        filled
                                                        rounded
                                                    ></v-text-field>
                                                
                                                    Posibles respuestas
                                                    
                                               <div class="form-group" v-for="(answer,j) in input.answers" :key="j">      
        
                                                    <v-row 
                                                     >
                                                        <v-col :cols="9" >
                                                            <v-text-field 
                                                               v-model="answer.name"
                                                                class="mr-2 v-text-field-small"
                                                                placeholder="Ej. Bien"
                                                                outlined
                                                                dense
                                                                rounded 
                                                            ></v-text-field>
                                                            
                                                        </v-col>
                                                        <v-col align="left" :cols="3">
                                                        <v-icon 
                                                                large class="mr-2" 
                                                                color="purple"
                                                                @click="add2(k)"
                                                                 v-show="j == input.answers.length-1"
                                                                >
                                                            mdi mdi-plus
                                                        </v-icon>
                                                        <v-icon 
                                                                large class="mr-2" 
                                                                color="red"
                                                                @click="remove2(j,k)"
                                                                v-show="j || ( !j && input.answers.length > 1)"
                                                                >
                                                            mdi mdi-delete
                                                        </v-icon>
                                                        </v-col>

                                                    </v-row>
                                               </div>
                                       
                                                </v-col>

                                                <v-col align="left" :cols="3"> 
                                                <span>
                                                    <v-icon 
                                                            large class="mr-2" 
                                                            color="purple"
                                                            @click="add(k)"
                                                            v-show="k == inputs.length-1"
                                                            >
                                                        mdi mdi-plus
                                                    </v-icon>
                                                     <v-icon 
                                                            large class="mr-2" 
                                                            color="red"
                                                            @click="remove(k)"
                                                            v-show="k || ( !k && inputs.length > 1)"
                                                            >
                                                        mdi mdi-delete
                                                    </v-icon>
                                                </span>
                                                </v-col>

                                       </v-row>
                                    </div>
                                </v-col>  
                            </v-row>

                    </v-card-text>
                    </v-card>
                         
                </v-col>    
               
           </v-row>

</div>
</template>

<script>
export default {
data() {
    return {
            nombreEncuesta: String,
            inputs: [
                        { name: '' , answers: [] }
                    ]     
    }
},//data
    methods:{
            getData(){
               const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
               const url = `/api/edit-encuesta/${id}`;
                    this.$http.get(url)
                  .then((response) => {                               
                    this.nombreEncuesta = response.data.nombreEncuesta;
                    this.inputs = response.data.preguntas;

                  })
                },//getData
                  
        add(index) {
 
         this.inputs.push({ name: '', answers: [
             { name:''}
         ] });

        },
        save(){

        let nombreEncuesta  = (this.nombreEncuesta)   ? this.nombreEncuesta : 0;
        let preguntas       = (this.inputs)           ? this.inputs         : 0;
        const id            = (this.$route.params.id) ? (this.$route.params.id) : 0;
        
        if( !nombreEncuesta.trim() ) { 
            Swal.fire(
            '¡Uuppss!',
            'El nombre de la encuesta no puede ir vacío',
            'error'
            )
            return 
        }
     
        this.$http.post("/api/update-encuesta", {
          nombreEncuesta    : nombreEncuesta,
          preguntas         : preguntas,
          id                : id
        }).then((response) => {
            console.log(response.data)
            Swal.fire(
                        'Muy bien!',
                        'La encuesta ha sido actualizada',
                        'success'
                        )
            })
            .catch(function (error) {
              console.log(error);
                Swal.fire(
                    '¡Uuppss!',
                    'Ha ocurrido un error al actualizar',
                    'error'
                    )
            return 
            });
        
        },
        remove(index) {
            this.inputs.splice(index, 1);
        },

        
        add2(index2) {
         this.inputs[index2].answers.push( {  name: '' } );

        },
        remove2(respuesta,pregunta) {
                this.inputs[pregunta].answers.splice(respuesta,1);
            }
    },//methods
    created(){


    }//created
    ,mounted() {
        this.getData();
    
    },

}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="grey--text text-darken-1 mb-2">
        Reporte Trimestral
      <v-row align="center">
        <v-col class="d-flex" cols="4" sm="4">
            <template>
                <v-select
                label='Curso:'
                v-model='idCurso'
                :items='cursos'
                item-value='id'
                item-text="nombre"
                return-object
                >
                 <template slot='selection' slot-scope='{ item }'>
                    {{item.description !=null ? item.nombre + '('+item.description+')': item.nombre}}
                </template>
                <template slot='item' slot-scope='{ item }'>
                    {{item.description !=null ? item.nombre + '('+item.description+')': item.nombre}}
                </template>  
                </v-select>
            </template>       
        </v-col>
        <v-col class="d-flex" cols="4" sm="4">
            <template >
                <v-select
                label='Trimestre:'
                v-model='idTrimestre'
                :items='trimestres'
                item-value='id'
                item-text='fecha_inicio'
                return-object
                >
                 <template slot='selection' slot-scope='{ item }'>
                    {{ formatDate(item.fecha_inicio) }} - {{ formatDate(item.fecha_fin)}}
                </template>
                <template slot='item' slot-scope='{ item }'>
                    {{ formatDate(item.fecha_inicio) }} - {{  formatDate(item.fecha_fin) }}
                </template> 
                </v-select>
            </template>       
        </v-col>

        <v-col class="d-flex" cols="4" sm="4">
                <v-btn small @click="consultarPeriodos()">Consultar</v-btn>
        </v-col>        
      </v-row>
    </div> 
    Concentrado(s) generado(s)
         <v-data-table
          :headers="headers_rpt"
          :items="reportes"

        >
        <template #item.url="{ value }">
        <a target="_blank" :href="value" >
          {{ value }}
        </a>
    </template>
        <template v-slot:item.id="{ item }">
      <v-icon
        small
        @click="eliminarHoja(item)"
      >
        mdi-delete
      </v-icon>
    </template>
        </v-data-table> 
  <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
  Periodo(s) comprendido(s) de Trimestre:
         <v-data-table
          v-model="selected"
          :headers="headers"
          :items="periodos"
          :single-select="singleSelect"
          show-select
        >
            
            <template v-slot:item.periodo_concentrado.fecha_inicio= "{ item }">
                {{ item.periodo_concentrado.fecha_inicio | fecha }}
            </template>
            <template v-slot:item.periodo_concentrado.fecha_fin= "{ item }">
                {{ item.periodo_concentrado.fecha_fin | fecha }}
            </template>

        </v-data-table> 
            <div class="grey--text text-darken-1 mb-2">
        <v-row align="center">
                <v-col class="d-flex" cols="4" sm="4">
                    <v-btn small @click="generarReporte()">Generar Concentrado</v-btn>
                </v-col> 
        </v-row>
    </div>
    
  </div>
</template>

<script>
   import moment from 'moment';
   moment.locale('es');
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = 'http://localhost:8000'
  export default {
    data:() => ({
        absolute: false,
        singleSelect: false,
        selected: [],
        opacity: 0.46,
        zIndex: 5,
        idCurso: null,
        idTrimestre: null,
        idProfesor:null,
        idPeriodo:'',    
        periodos:[],  
        reportes:[],       
        cursos: [],
        trimestres: [],
        pagination: {},
        nombre_curso:null,
        variable:[],
        periodos_select:null,
        headers: [
           { text: 'Fecha inicio',value: 'periodo_concentrado.fecha_inicio'},   
          { text: 'Fecha fin',value:'periodo_concentrado.fecha_fin'}, 
        ],
        headers_rpt: [
          { text: 'Nombre',value: 'nombre'}, 
          { text: "Ver Concentrado", value: "url" },  
          { text: 'Acciones',value:'id'},
        ],
        overlay: false,
        absolute:true,
    }),
    created () {
       
    },  
    filters: {
        fecha: function (value) {
            return moment(value,"DDMMYYYY").format('ll');
        }
    },  
    methods: {
    init () {
            
    }, 
     
    formatDate(value) {
      return moment(value,"DDMMYYYY").format('ll');
  },
    eliminarHoja(item){
        //console.log("id de hoja a eliminar"+item.id);
              var refs = ''
      refs = confirm('¿Deseas eliminar el archivo: ' + item.nombre + '?')
      if (refs) {
          //console.log('registro eliminado')
          this.overlay = true;
          let url = `/api/reportes/${item.id}`
          this.$http
          .delete(url)
          .then((response) => {
            //console.log(response.data)
           // this.overlay = false;
            //this.getClases()
          })
          .catch(function (error){
            console.log(error)
            //this.overlay = false;
          })
            //console.log("PARA EL REPORTE idProfesor:"+this.idProfesor+" curso:"+this.idCurso.id+"idTrimestre:"+this.idTrimestre['id']);
            this.$http.get('/api/reportes', {
              params: {
                'idProfesor':       this.idProfesor,
                'idCurso':          this.idCurso.id,
                'idTrimestre':      this.idTrimestre['id']
              }       
                })
                 .then((resp) => {
                        this.reportes = resp.data;
                })
                .catch(function (error){
                    //console.log(error)
                }) 
                this.overlay = false;
      }
      else {
        // cancel
      } 
    },
    getCursos () {
            let url = '/api/cursos'
            this.$http.get(url)
            .then((response) => {
               this.cursos = response.data;
            })
            .catch(function (error){
                    console.log(error)
            }
        )
    },
    getIdCurso(id,nombre){
        this.idCurso = id;
        this.nombre_curso = nombre;
    },
    getidTrimestre(id){
        this.idTrimestre = id;
    },
    consultarPeriodos(){
            this.nombre_curso = this.idCurso.nombre;
            console.log("idProfesor:"+this.idProfesor+" curso:"+this.idCurso.id+" trimestre fecha_inicio:"+this.idTrimestre.fecha_inicio+" trimestre fecha_fin:"+this.idTrimestre.fecha_fin+"idTrimestre:"+this.idTrimestre['id']+"nombre curso:"+this.idCurso.nombre);
           this.$http.get('/api/periodos', {
                params: {
                'idProfesor':       this.idProfesor,
                'idCurso':          this.idCurso.id,
                'fecha_inicio':     this.idTrimestre.fecha_inicio,
                'fecha_fin':        this.idTrimestre.fecha_fin,
                }                
                })
                 .then((response) => {
                     if(response.data[0].id>0){
                        this.periodos = response.data;
                     }
                })
                .catch(function (error){
                    console.log(error)
                }) 
                this.$http.get('/api/reportes', {
              params: {
                'idProfesor':       this.idProfesor,
                'idCurso':          this.idCurso.id,
                'idTrimestre':      this.idTrimestre['id'],
              }    
                })
                 .then((resp) => {
                        this.reportes = resp.data;
                })
                .catch(function (error){
                    console.log(error)
                })  
    },
    generarReporte(){
        
        //this.periodos_select = this.selected['periodo_concentrado'];
        this.variable = Array.prototype.map.call(this.selected, s => s.id).toString();
        //console.log('periodos para enviar datos '+ this.variable)
                  this.$http.post('/api/periodos', {
                    params: {
                        'periodosSeleccionados':       this.variable,
                        'nombre_curso': this.nombre_curso,
                        'id_trimestre':this.idTrimestre['id']
                    }
                              
                }) 
                
         this.$http.get('/api/reportes', {
               params: {
                'idProfesor':       this.idProfesor,
                'idCurso':          this.idCurso.id,
                'idTrimestre':      this.idTrimestre['id']
               }               
                })
                 .then((response) => {
                     if(response.data.length>0)
                        this.reportes = response.data;
                })
                .catch(function (error){
                    console.log(error)
                }) 
    },
    getPeriodos () {
            let url = '/api/periodos'
            this.$http.get(url)
            .then((response) => {
               this.periodos = response.data;
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
    },
    getTrimestres () {
            let url = '/api/trimestres'
            this.$http.get(url)
            .then((response) => {
               this.trimestres = response.data;
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
    },
    getidProfesor(){
            this.$http.get('/api/user').then(response => {
            this.idProfesor = response.data.id
            })
    }
    },
    mounted(){
        this.getCursos();
        this.getTrimestres();
        this.getidProfesor()

    } 
  }
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":1}}),_c('v-col',{attrs:{"cols":10}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"purple"}},[_vm._v(" mdi mdi-file-document ")]),_c('h3',[_vm._v("Mi panel de encuestas")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":10}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":2}},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":"/encuestas-create"}},[_c('v-btn',{staticClass:"my-2 ml-auto ",attrs:{"color":"primary","dark":""}},[_vm._v(" Nueva ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.resultSet,"items-per-page":7,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"item.duplicar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"blue"},on:{"click":function($event){return _vm.duplicar(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-content-duplicate ")])],1)]}},{key:"item.editar",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/encuestas-edit/" + (item.id))}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"green"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}},{key:"item.eliminar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"item.activar",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.encuestaActivar(item)}},model:{value:(item.ready),callback:function ($$v) {_vm.$set(item, "ready", $$v)},expression:"item.ready"}})]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":1}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
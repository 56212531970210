<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog v-if="dialogObjetivo" :value="true" @input="dialogObjetivo = false" persistent max-width="790">
          <v-card elevation="2" class="mx-auto">
            <v-card-title>
              <v-icon  color="green">mdi-account-multiple</v-icon><h3>Sesiones Académicas</h3>
              <v-spacer></v-spacer>

            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <template>
                    <v-select
                        label='Oferta Académica - Curricula:'
                        v-model='idOfertaCurricula'
                        :items='ofertascurriculas'
                        item-value='id'
                        item-text='nombre_oferta_academica'
                        outlined
                        return-object
                        @change="getOfertaSeleccionada"
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        {{ item.oferta_academica.nombre_oferta_academica }}-{{ item.curricula_curso.nombre_curricula }}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ item.oferta_academica.nombre_oferta_academica }}-{{ item.curricula_curso.nombre_curricula }}
                      </template>
                    </v-select>
                  </template>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <template>
                    <v-select
                        label='Curso:'
                        v-model='idCurso'
                        :items='cursos_items'
                        item-value='curso_curricula.id'
                        item-text='curso_curricula.nombre_curso'
                        outlined
                        return-object
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        {{ item.curso_curricula.nombre_curso }}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ item.curso_curricula.nombre_curso }}
                      </template>
                    </v-select>
                  </template>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                      label="Nombre"
                      v-model="nombre_sesion"
                      outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8" sm="12">
                  <v-textarea
                      label="Descripción:"
                      v-model="descripcion"
                      :rows="3"
                      outlined
                  ></v-textarea>
                </v-col>

                <v-col  cols="12" sm="6">
                  <v-text-field
                      label="Imagen"
                      v-model="imagen"
                      outlined
                  ></v-text-field>
                </v-col>

              </v-row>
<!--              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <template>
                    <v-select
                        label='Coach Organizador de Evento:'
                        v-model='idOrganizador'
                        :items='organizadores'
                        item-value='id'
                        item-text='nombre'
                        outlined
                        return-object
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        {{ item.name}}-{{ item.email}}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ item.name}}-{{ item.email}}
                      </template>
                    </v-select>
                  </template>
                </v-col>
              </v-row>-->
<!--              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-dialog ref="dialoghorainicio" v-model="modalhorainicio" :return-value.sync="timeinicio"
                            persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="timeinicio"
                          label="Hora de inicio"
                          append-icon="mdi-clock-time-four-outline"
                          readonly
                          outlined
                          id="txttimeinicio"
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="modalhorainicio" v-model="timeinicio" full-width>
                      &lt;!&ndash;          <v-spacer></v-spacer>&ndash;&gt;
                      <v-btn text color="primary" @click="modalhorainicio = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="$refs.dialoghorainicio.save(timeinicio)">
                        Aceptar
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-dialog ref="dialoghorafin" v-model="modalhorafin" :return-value.sync="timefin"
                            persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="timefin"
                          label="Hora de finalizacion"
                          append-icon="mdi-clock-time-four-outline"
                          readonly
                          outlined
                          id="txttimefin"
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="modalhorafin" v-model="timefin" full-width>
                      &lt;!&ndash;          <v-spacer></v-spacer>&ndash;&gt;
                      <v-btn text color="primary" @click="modalhorafin = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="$refs.dialoghorafin.save(timefin)">
                        Aceptar
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>-->

<!--              <v-row>
                <v-col
                    cols="12"
                    lg="6"
                >
                  <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="fecha_consumo_inicio"
                          label="Fecha inicio consumo"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="date_inicio = parseDate(fecha_consumo_inicio)"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_inicio"
                        no-title
                        @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                    cols="12"
                    lg="6"
                >
                  <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="fecha_consumo_termino"
                          label="Fecha término consumo"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_finalizacion"
                        no-title
                        @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>-->
<!--              <v-row>
                <v-col  cols="12" sm="12">
                  <v-switch
                      v-model="switchpublicado"
                      label="Publicado"
                      v-bind:value="switchpublicado"
                  ></v-switch>
                </v-col>
              </v-row>-->

<!--              <v-row>
                <v-col
                    cols="12"
                    lg="6"
                >
                  <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="fecha_seleccion_inicio"
                          label="Fecha inicio selección"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="date_inicio_publicacion = parseDate(fecha_seleccion_inicio)"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_inicio_publicacion"
                        no-title
                        @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                    cols="12"
                    lg="6"
                >
                  <v-menu
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="fecha_seleccion_termino"
                          label="Fecha fin selección"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_fin_publicacion"
                        no-title
                        @input="menu4 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-btn @click="guardar" small>
                    {{txtboton}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="close()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-card elevation="2" class="mx-auto">
      <v-card-title>
        <v-icon large class="mr-2" color="green">mdi-account-multiple</v-icon><h3>Sesiones Académicas</h3>
      </v-card-title>
      <v-card-subtitle align="center">
        <v-btn
            color="green"
            small
            @click="showUnidad()"
            title="Crear Sesión"
        >
          <v-icon color="white" >mdi-account-multiple</v-icon>
        </v-btn>
      </v-card-subtitle>

      <v-card elevation="2">
        <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table :headers="headers" :items="sesiones" class="elevation-1">
          <template v-slot:item.publicado="{ item }">
            <v-icon v-if="(parseInt(item.publicado)>=1)"
                    class="mr-2"
                    color="green"
                    title="Oferta publicada"
            >
              mdi-arrow-up-bold-circle-outline
            </v-icon>
            <v-icon v-if="(parseInt(item.publicado)==0)"
                    class="mr-2"
                    color="orange"
                    title="Oferta No publicada"
            >
              mdi-flag-outline
            </v-icon>
          </template>
          <template v-slot:item.fecha_consumo_inicio="{ item }">
            {{formatDate(item.fecha_consumo_inicio)}}
          </template>
          <template v-slot:item.fecha_consumo_termino="{ item }">
            {{formatDate(item.fecha_consumo_termino)}}
          </template>
          <template v-slot:item.fecha_seleccion_inicio="{ item }">
            {{formatDate(item.fecha_seleccion_inicio)}}
          </template>
          <template v-slot:item.fecha_seleccion_termino="{ item }">
            {{formatDate(item.fecha_seleccion_termino)}}
          </template>
<!--          <template v-slot:item.estatus="{item}">
            <v-chip
                class="ma-2"
                :color="(item.estatus==1)?'green':'orange'"
                text-color="white"
            >
              {{(item.estatus==1)?'Evento programado':'Pendiente de programar'}}
            </v-chip>
          </template>-->
          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <v-btn
                  fab elevation="2"
                  color="red"
                  small
                  x-small
                  title="Eliminar Objetivo"
              >
                <v-icon @click="deleteOferta(item)" title="Eliminar Oferta Académica" color="white">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.detalles="{ item }">
            <div class="text-center">
              <v-btn
                  fab elevation="2"
                  color="blue"
                  small
                  x-small
                  title="Detalles Sesión"
                  @click="showDetallesSesion(item)"
              >
                <v-icon color="white">mdi-message-text-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.agregar_material="{item}">
            <v-btn
                fab
                small
                x-small
                class="green"
                title="Material Sesión"
                @click="showMaterialesSesion(item)"
            >
              <v-icon class="white--text" >mdi-book</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.eventos="{ item }">
            <div class="text-center">
              <v-btn
                  fab elevation="2"
                  color="orange"
                  small
                  x-small
                  title="Eventos Sesión"
                  @click="showEventoSesion(item)"
              >
                <v-icon color="white">mdi-alarm</v-icon>
              </v-btn>
            </div>
          </template>
<!--          <template v-slot:item.contenido="{item}">
            <a :href="construirLink(item)"><v-btn small fab class="primary white&#45;&#45;text"><v-icon>mdi-google-chrome</v-icon></v-btn></a>
          </template>-->
         </v-data-table>
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data: vm => ({
    opacity: 0.46,
    search:'',
    zIndex: 5,
    nombre:'',
    idTipoReactivo:'',
    escuela_id:1,
    refrescar_tabla:0,
    abreviatura:'',
    tiporeactivo:[],
    banco:[],
    item:[],
    sesiones:[],
    historial_importaciones:[],
    snack: false,
    snackColor: '',
    snackText: '',
    respuestasTotal:0,
    headers: [
      {text: '#',align: 'start',      value: 'id',              sortable: true,},
      { text: 'Sesión',               value: 'nombre_sesion'},
      { text: 'Oferta Académica',     value:'ofertascurriculas.oferta_academica.nombre_oferta_academica'},
      { text: 'Currícula',            value: 'ofertascurriculas.curricula_curso.nombre_curricula' },
      { text: 'Curso',                value: 'curso_sesion.nombre_curso', },
/*
      { text: 'Inicio consumo', value: 'fecha_consumo_inicio' },
      { text: 'Fin consumo', value: 'fecha_consumo_termino' },
      { text: 'Inicio selección', value: 'fecha_seleccion_inicio' },
      { text: 'Fin selección', value: 'fecha_seleccion_termino' },
*/
      //{ text: 'Estatus', value: 'estatus' },

      { text: 'Detalles', value: 'detalles',align:'center'},
      { text: 'Agregar material', value: 'agregar_material',align:'center'},

      //{ text: 'Contenido', value: 'contenido',align:'center'},
      { text: 'Eventos', value: 'eventos',align:'center'},
      { text: 'Acciones', value: 'actions',align:'center'},
    ],
    overlay: false,
    absolute:true,
    isModalVisible: false,
    dialog: false,
    dialogObjetivo: false,
    dialogAyuda:false,
    tipoaccion:'',
    buscar_contador:0,
    checkstatus : null,
    nombre_unidad:'',
    clave_interna:'',
    txtboton:'',
    estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
    modolectura:false,
    //variables consideradas de la tabla para cada campo solicitado
    nombre_sesion:'',
    limite_usuarios:0,
    url_meet:'',
    estatus:'',
    imagen:'',
    publicado:0,
    switchpublicado:1,
    date_inicio: new Date().toISOString().substr(0, 10),
    fecha_consumo_inicio: vm.formatDate(new Date().toISOString().substr(0, 10)),
    date_finalizacion: new Date().toISOString().substr(0, 10),
    fecha_consumo_termino: vm.formatDate(new Date().toISOString().substr(0, 10)),

    date_inicio_publicacion: new Date().toISOString().substr(0, 10),
    fecha_seleccion_inicio: vm.formatDate(new Date().toISOString().substr(0, 10)),

    date_fin_publicacion: new Date().toISOString().substr(0, 10),
    fecha_seleccion_termino: vm.formatDate(new Date().toISOString().substr(0, 10)),
    copia_date:new Date().toISOString().substr(0, 10),
    copia_fecha:vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    idOfertaCurricula: '',  ofertascurriculas: [],
    idCurso: '',            cursos: [], cursos_items: [],
    descripcion:'',
    modalhorainicio: false,
    timeinicio: null,
    modalhorafin: false,
    timefin: null,
    idOrganizador: '',            organizadores: [],
  }),
  created () {
    this.init();
  },
  computed: {
    /*   computedDateFormatted:function  () {
         return this.formatDate(this.date_inicio)
       },
       computedfecha_finalizacion:function  () {
         return this.formatDate(this.date_finalizacion)
       },*/
  },
  watch: {
    date_inicio (val) {
      this.fecha_consumo_inicio = this.formatDate(this.date_inicio)
    },
    date_finalizacion (val) {
      this.fecha_consumo_termino = this.formatDate(this.date_finalizacion)
    },
    date_inicio_publicacion (val) {
      this.fecha_seleccion_inicio = this.formatDate(this.date_inicio_publicacion)
    },
    date_fin_publicacion (val) {
      this.fecha_seleccion_termino = this.formatDate(this.date_fin_publicacion)
    },

  },
  methods: {
    construirLink(sesion) {
      try {
        return '/contenido-curricula-curso?id='+(sesion.ofertascurriculas.CURRICULA_CURSO_ID)+'&curso_id='+(sesion.curso_id)+'&unidad_id=' + (sesion.objetivos_sesion[0].unidad_id);
      } catch(e) {
        return '#!';
      }


    },
    getDateTime(date) {
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    init () {
      this.getSesiones()       //obtiene los reactivos filtrados por el usuario actual.
      //this.getOfertasCurriculas()
      //this.getOrganizadores()
    },
    closeModal() {
      this.dialog = false
    },
    closeAyuda(){
      this.dialogAyuda =  false
    },
    close(){
      this.nombre_unidad    =  '';
      this.clave_interna      =  '';
      this.url_meet        =  '';
      this.txtboton           =  '';
      this.dialogObjetivo     =  false
      this.modolectura        =  false;
    },
    consultar(id){
      this.idTipoReactivo = id;
      if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
        this.showModal();
      }
      else if (this.idTipoReactivo==6)
        this.showModal();
    },
    consultarNivelEducativo(){
      let url = '';
      url = `/api/grados?idnivel=${this.idNivel.id}`
      this.$http.get(url)
          .then((response) => {
            this.grados = response.data;
            if(this.tipoaccion==2 ) {
              this.idGrado = this.grados.find(item => item.id == this.grado_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    consultarArea(){
      let url = '';
      url = `/api/asignaturas?idArea=${this.idArea.id}`
      this.$http.get(url)
          .then((response) => {
            this.asignaturas = response.data;
            if(this.tipoaccion==2 ){
              this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },

    deleteOferta (item){
      Swal.fire({
        title: '¿Desea eliminar la Sesión: '+ item.nombre_sesion + '?',
        showDenyButton: true,
        confirmButtonText: `Eliminar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          let url = `/api/aprende/sesiones/${item.id}`
          this.$http
              .delete(url)
              .then((response) => {
                //console.log("id--->")
                //console.log(response.data.id)
                if(response.data.id>0){
                  this.getSesiones()
                  Swal.fire('Sesión eliminada satisfactoriamente.', '', 'success')
                }
                else{
                  Swal.fire('La Sesión tiene eventos, no es posible eliminarla.', '', 'success')
                }

              })
              .catch(function (error){
                console.log(error)
              })

        } else if (result.isDenied) {

        }
      })
    },
    editReactivo(item){
      this.item                 =   item;
      this.dialogObjetivo       =   true;
      this.idTipoReactivo       =   item.tiporeactivo.tiporeactivo_concentrado.id;
      this.tipoaccion           =   2;
      this.estatusAccionTabla   =   2;
    },
    editOferta(item) {
      this.idOfertaAcademica          =   item.id;
      this.nombre_sesion    =   item.nombre_sesion;
      this.limite_usuarios         = item.limite_usuarios;
      this.url_meet                =   item.url_meet;
      this.imagen                     =   item.imagen;
      this.publicado                  =   item.publicado;
      if (this.publicado)
        this.switchpublicado=true
      else
        this.switchpublicado=false;

      this.date_inicio                =   item.fecha_consumo_inicio;
      this.fecha_consumo_inicio               =   this.formatDate(item.fecha_consumo_inicio);

      this.date_finalizacion          =   item.fecha_consumo_termino;
      this.fecha_consumo_termino         =   this.formatDate(item.fecha_consumo_termino);

      this.date_inicio_publicacion   =   item.fecha_seleccion_inicio;
      this.fecha_seleccion_inicio   =   this.formatDate(item.fecha_seleccion_inicio);

      this.date_fin_publicacion      =   item.fecha_seleccion_termino;
      this.fecha_seleccion_termino      =   this.formatDate(item.fecha_seleccion_termino);

      this.txtboton                   =   "Actualizar"
      this.modolectura                =   true;
      this.estatusAccionTabla         =   2;
      this.dialogObjetivo             =   true;
    },
    getSesiones(){
      let url = `/api/aprende/sesiones`
      this.$http.get(url)
          .then((response) => {
            this.sesiones = response.data;
            //console.log("SESIONES ---->")
            //console.log(this.sesiones)
          })
          .catch(function (error){
            console.log(error)
          })
    },
    getOfertasCurriculas(){
      this.ofertascurriculas = [];
      let url = `/api/aprende/curricula-curso-oferta`
      this.$http.get(url)
          .then((response) => {
            this.ofertascurriculas = response.data;
            //console.log("ofertas curriculas ------>")
            //console.log(response.data)
          })
          .catch(function (error){
            console.log(error)
          })
    },

    getOrganizadores(){
      let role = 5;
      this.$http.get('/api/aprende/users/get-users-by-role/' + role)
          .then(response => {
            this.organizadores = response.data;
            //console.log(response)
          }).catch(error => {
            console.log(error)
      })

    },
    getOfertaSeleccionada(){
      this.$http.get('/api/aprende/curricula-curso/' + this.idOfertaCurricula.curricula_curso.id)
      //this.$http.get(`/api/aprende/curricula-curso/${this.idOfertaCurricula.id}`)
          .then(response => {
            //console.log("cursooo------>>>")
            //console.log(response.data)
            //this.cursos = response.data;
            this.cursos_items = response.data;
          }).catch(error => {
            console.log(error)
      })

    },
    guardar(){
      if(this.nombre_sesion=='')
        confirm('Debe capturar el nombre de la Sesión')
      else if (this.descripcion==''){
        confirm('Debe capturar una descripción')
      }
      else{
        if(this.estatusAccionTabla==1){
          if(this.switchpublicado==true)
            this.publicado = 1
          else
            this.publicado = 0
         // console.log(this.fecha_consumo_inicio);
          this.$http.post('/api/aprende/sesiones', {
            'nombre_sesion':                  this.nombre_sesion,
            //'limite_usuarios':                this.limite_usuarios,0
            'descripcion_sesion':             this.descripcion,
            //'url_meet':                       this.url_meet,
            'curricula_oferta_id':            this.idOfertaCurricula.curricula_curso.id,  //this.idOfertaCurricula.id,
            'curso_id':                       this.idCurso.curso_curricula.id,
            'imagen':                         this.imagen,
/*
            'user_id_organizer':              this.idOrganizador.id,
            'fecha_consumo_inicio':           this.getDateTime(this.fecha_consumo_inicio),
            'fecha_consumo_termino':          this.getDateTime(this.fecha_consumo_termino),
            'fecha_seleccion_inicio':         this.getDateTime(this.fecha_seleccion_inicio),
            'fecha_seleccion_termino'   :     this.getDateTime(this.fecha_seleccion_termino),
            'hora_inicio': this.timeinicio,
            'hora_fin': this.timefin,
*/
          })
              .then((response) => {
                if(response.data){
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Sesión guardada exitosamente.',
                    showConfirmButton: false,
                    timer: 2500
                  })
                  //this.getSesiones()  llenar el arreglo
                  //this.sesiones.unshift(response.data) //inserta el elemento al principio del Arreglo
                  this.setLimpiarVariables();
                  this.getSesiones();       //obtiene los reactivos filtrados por el usuario actual.

                }
                else{
                  Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Intente nuevamente.',
                    showConfirmButton: false,
                    timer: 2500
                  })
                }
              })
              .catch(error => {
                console.log(error)
              })
        }
        else if(this.estatusAccionTabla==2){
          if(this.switchpublicado==true)
            this.publicado = 1
          else
            this.publicado = 0
          this.$http.post('/api/aprende/ofertaAcademica/'+ this.idOfertaAcademica, {
            _method:    'PUT',
            'nombre_sesion':                  this.nombre_sesion,
            'url_meet':                       this.url_meet,
            //'limite_usuarios':                this.limite_usuarios,
            'publicado':                      this.publicado,
/*
            'fecha_consumo_inicio':           this.getDateTime(this.fecha_consumo_inicio),
            'fecha_consumo_termino':          this.getDateTime(this.fecha_consumo_termino),
            'fecha_seleccion_inicio':         this.getDateTime(this.fecha_seleccion_inicio),
            'fecha_seleccion_termino'   :     this.getDateTime(this.fecha_seleccion_termino),
*/
          })
              .then((response) => {
                if(response.data){
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Sesión actualizada exitosamente.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.getSesiones()
                }
                else{
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Sesión no actualizada. Favor de intentar más tarde.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
              })
              .catch(function (error) {
                console.log(error)
              })
        }
      }
    },
    setLimpiarVariables(){
      this.nombre_sesion                                    = '';
      this.url_meet                                         = '';
      this.descripcion                                      = '';
      this.imagen                                           = '';
      this.publicado                                        =0;
      this.limite_usuarios                                  =0;
      //this.switchpublicado                                  = 1;
      this.date_inicio                                      = this.copia_date
      this.fecha_consumo_inicio                             = this.copia_fecha
      this.date_finalizacion                                = this.copia_date
      this.fecha_consumo_termino                            = this.copia_fecha

      this.date_inicio_publicacion                          = this.copia_date
      this.fecha_seleccion_inicio                           = this.copia_fecha
      this.date_fin_publicacion                             = this.copia_date
      this.fecha_seleccion_termino                          = this.copia_fecha
    },
    showImportar(){
      this.dialogObjetivo = true;
    },
    showObjetivos(item){
      this.$router.push({ path: '/dashboardUnidadesObjetivos', query: { idUnidad: btoa(item.id),nombre_unidad:btoa(item.nombre_unidad),descripcion:btoa(item.descripcion)} })
    },
    showDetallesSesion(item){
      this.$router.push({ path: '/detalleSesion', query: { idSesion:btoa(item.id),idCurso: btoa(item.curso_id),nombre_sesion:btoa(item.nombre_sesion)} })
    },
    showEventoSesion(item){
      this.$router.push({ path: '/eventosesion', query: { idSesion:btoa(item.id),nombre_sesion:btoa(item.nombre_sesion)} })
    },
    showMaterialesSesion(item){
      this.$router.push({ path: '/materialsesion', query: { idSesion:btoa(item.id),nombre_sesion:btoa(item.nombre_sesion)} })
    },
    showRequisitosCursos(item){
      this.$router.push({ path: '/dashboardRequisitosCursos', query: { idUnidad: btoa(item.id),nombre_unidad:btoa(item.nombre_unidad),descripcion:btoa(item.descripcion)} })
    },

    showModal() {
      this.dialog = true;
    },
    showUnidad() {
      this.dialogObjetivo     = true;
      this.txtboton           = "Guardar";
      this.estatusAccionTabla =   1;
      //this.ofertascurriculas = [];
      this.getOfertasCurriculas();

    },
    totalrespuestas(total){
      this.respuestasTotal =  total;
    },
  },
  mounted(){
  }
}
</script>
<template>
  <div>
    <v-file-input
        v-if="status_uploading_justificante==0"
        v-model="justificanteinput"
        :accept="extensions.join(',')"
        label="Subir justificante"
        @change="subirJustificante"
    ></v-file-input>
    <div v-else-if="status_uploading_justificante==1">
      <v-progress-circular
          color="primary"
          indeterminate
      ></v-progress-circular>
      <span v-text="getEstatus(status_uploading_justificante)"></span>
    </div>
    <div v-else-if="status_uploading_justificante==2">

      <v-icon class="green--text">mdi-checkbox-marked-circle</v-icon>
      <span class="green--text" v-text="getEstatus(status_uploading_justificante)"></span>
      <div>
        <a href="#!">{{ file_name_uploaded }}</a>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'uploadpdf',
  props: {
    justificante_data: {
      type: Object,
      required: false
    },
    url_justificante: {
      type: Number,
      required: false
    },
    examen_id: {
      type: Number,
      required: false
    },
    examen_aplicado_alumno_id: {
      type: Number,
      required: false
    },
    indexpregunta: {
      type: Number,
      required: false
    },
  },
  data() {
    return {
      file_name_uploaded: '',
      file: '',
      success: '',
      //0 aun no enviado, 1 enviado y procesando, 2 procesado y subido
      status_uploading_justificante: 0,
      justificanteinput: null,
      extensions: ['application/pdf'],
      tipos_estatus: ['En espera del documento', 'Cargando documento, espere un momento...', 'Su archivo justificante se envió con éxito'],
    }
  },
  methods: {
    getEstatus(estatus) {
      return " " + this.tipos_estatus[estatus]
    },
    subirJustificante(e) {

      var r = confirm("Atención: Está a punto de subir un justificante, ¿confirma usted este proceso? Una vez confirmada la acción, no podrá volver a subir otro justificante a la respuesta");

      if ( r == true ) {
        this.status_uploading_justificante = 1
        let currentObj = this;

        const config = {
          headers: {'content-type': 'multipart/form-data'}
        }

        let formData = new FormData();
        formData.append('file', this.justificanteinput)
        formData.append('examen_id', this.examen_id)
        formData.append('examen_aplicado_alumno_id', this.examen_aplicado_alumno_id)
        var data_response = [];
        this.$http.post('/api/justificantes/upload-document', formData, config)
            .then((res) => {
              data_response = res.data
            })
            .catch((err) => {
            })
            .finally((res) => {
              console.log(data_response)
              this.file_name_uploaded = data_response.name
              this.status_uploading_justificante = 2
            });
      }

    }
  },
  mounted() {
    // significa que ya subio el justificante previamente
    if ( this.url_justificante == 1 ) {
      this.status_uploading_justificante = 2
      this.file_name_uploaded = "Justificante correctamente enviado al docente."
    }
  }
}
</script>

<template>
  <v-row justify="center" align="center" class="fill-height">
    <v-col align="center">
      <v-img
          src="https://i.imgur.com/MZtu8IN.png"
          aspect-ratio="1"
          height="100px"
          width="100px"
      />
      <p>Usted no tiene acceso al contenido de esta página</p>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({

  }),
  mounted() {

  }
}
</script>
<template>
  <v-container>
    <!--    <link rel="stylesheet" href="node_modules/mathlive/dist/mathlive-fonts.css">-->
    <v-card
        class="mx-auto"
    >
      <v-card-title class="black--text  darken-4">
        <v-row dense>
          <v-col cols="12">
            <v-icon large class="mr-2" color="green">mdi-checkbox-marked-outline</v-icon>
            Crear Reactivos
          </v-col>
        </v-row>
        <!--        <v-spacer></v-spacer>-->
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-layout justify-center>
              <v-card-actions>
                <v-select
                    :items="cargaAcademica"
                    item-value='id'
                    item-text='nombre'
                    v-model="idDocente"
                    outlined
                    dense
                    label="Carga Académica"
                    return-object
                    @change="selectCargaAcademica"
                >
<!--                  <template slot='selection' slot-scope='{ item }'>
                     {{item.gradoEducativoNombre}} - {{item.areaConocimientoNombre}}-{{item.asignaturasNombre}}
                  </template>
                  <template slot='item' slot-scope='{ item }'>
                    {{item.gradoEducativoNombre}} - {{item.areaConocimientoNombre}}-{{item.asignaturasNombre}}
                  </template>-->
                </v-select>
              </v-card-actions>
            </v-layout>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-card>
              <v-tabs
                  centered
                  v-model="tabs"
              >
                <v-tab
                    :disabled="tabPregunta"
                    id="1"
                >
                  Pregunta
                </v-tab>
                <v-tab
                    :disabled="tabRespuesta"
                    id="2"
                >
                  Respuesta
                </v-tab>
                <v-tab
                    :disabled="tabConfiguracion"
                    id="3"
                >
                  Configuración
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <!-- TAB PREGUNTA -->
                  <v-card flat>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12">
                          <v-layout justify-center>
                            <v-card-actions>
                              <v-select
                                  :items="tiposReactivos"
                                  item-value='id'
                                  item-text='nombre'
                                  v-model="idTipoReactivo"
                                  outlined
                                  dense
                                  label="Tipo de Pregunta"
                                  return-object
                                  @change="getTipoPregunta"
                              ></v-select>
                            </v-card-actions>
                          </v-layout>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <div id="app">
                            <editor
                                v-model="textoPregunta"
                                api-key="7rthidfcjaoeuox25n1r7xshe4hefp1u77nokdcp04kyxhiz"
                                :init="{
                                   height: 200,
                                   menubar: false,
                                   plugins: [
                                     'advlist autolink lists link image charmap print preview anchor',
                                     'searchreplace visualblocks code fullscreen',
                                     'insertdatetime media table paste code help wordcount'
                                   ],
                                   toolbar:
                                     'undo redo | formatselect | bold italic backcolor | \
                                     alignleft aligncenter alignright alignjustify | \
                                     bullist numlist outdent indent | removeformat | help'
                                 }"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <span>Insertar ecuación (opcional):</span>
                          <math-field
                              id="prueba"
                              v-model="formulaPregunta"
                              v-on:change="setIt($event.target.value)"
                              smart-mode virtual-keyboard-mode="manual"
                              fonts-directory="//unpkg.com/mathlive/dist/fonts/"
                              style="
                                    font-size: 22px;
                                    margin: 2px;
                                    padding: 1px;
                                    border-radius: 8px;
                                    border: 1px solid rgba(0, 0, 0, .3);
                                    box-shadow: 0 0 8px rgba(0, 0, 0, .2);">
                          </math-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="8" sm="8">
                          <div v-if="imagen_seleccionada==1">
                            <a :href="url_imagen_seleccionada" target="_blank">
                              <v-img
                                  :src="url_imagen_seleccionada"
                                  width="150px"
                                  height="auto"
                              ></v-img>
                            </a>
                          </div>
                        </v-col>
                        <v-col cols="4" sm="4">
                          <drive :idarchivo="idarchivo" @idarchivo="idarchivo=$event"></drive>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col lg="12">
                          <v-switch v-model="agregar_otro_youtube"
                                    label="Agregar video de otra cuenta de youtube"></v-switch>

                          <v-text-field v-if="agregar_otro_youtube" v-model="enlace_externo_youtube"
                                        label="Pegar enlace de youtube"></v-text-field>

                          <v-autocomplete
                              v-else
                              v-model="youtubeModel"
                              :items="items"
                              :loading="isLoading"
                              :search-input.sync="search"
                              hide-no-data
                              hide-selected
                              item-text="title"
                              label="Buscar videos en mi canal de Youtube"
                              placeholder="Escriba para filtrar la búsqueda"
                              prepend-icon="mdi-video"
                              return-object
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-avatar>
                                  <img :src="data.item.thumbnails.default.url">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title
                                      v-html="data.item.title + ' (Empieza el '+(data.item.scheduledStartTime)+') '"></v-list-item-title>
                                  <v-list-item-subtitle
                                      v-html="data.item.description.substr(0,100) + '...' "></v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </template>

                          </v-autocomplete>

                          <v-expand-transition>

                            <v-container v-if="youtubeModel">
                              <v-alert
                                  dense
                                  type="info"
                                  class="pa-5"
                              >
                                A continuación se desglosa la <strong>información del video</strong> elegido desde el
                                filtro
                              </v-alert>
                              <h2 class="mt-5 mb-2">{{ fields.title }}</h2>
                              <p class="ma-0">Link del video: <strong><a target="_blank"
                                                                         v-text="'https://www.youtube.com/watch?v=' + fields.videoId.videoId"
                                                                         :href="'https://www.youtube.com/watch?v=' + fields.videoId.videoId"></a></strong>
                              </p>
                              <v-divider class="mt-2 mb-2"></v-divider>
                              <p>{{ fields.description }}</p>
                              <!--              <iframe width="560" height="315" :src="'https://www.youtube.com/embed/'+fields.videoId.videoId" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                              <v-img :src="fields.thumbnails.high.url"></v-img>
                            </v-container>

                          </v-expand-transition>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                              color="white"
                              class="primary"
                              small
                              @click="savePregunta"
                          >
                            GUARDAR PREGUNTA
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <!-- TAB RESPUESTA -->
                  <v-card flat>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="4">
                          <!--respuestas-->
                          <v-row dense>
                            <v-col
                                v-for="(item, i) in dtRespuestas"
                                :key="i"
                                cols="12"
                            >
                              <v-card white  class="mb-4" v-if="idTipoPregunta.id==1">
                                <div class="d-flex flex-no-wrap justify-space-between">
                                  <div>
                                    <v-card-title
                                        class="text-h6"
                                    >
                                      <v-btn
                                          class="ml-1 mt-1"
                                          fab
                                          icon
                                          height="20px"
                                          right
                                          width="20px"
                                          color="blue"
                                      >
                                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                                      </v-btn>
                                      <v-btn
                                          class="ml-2 mt-5"
                                          v-if="item.correcta === 1"
                                          rounded
                                          small
                                          color="#BDECB6"
                                      >
                                        Correcta
                                      </v-btn>
                                    </v-card-title >
                                    <v-card-text v-text="item.respuesta"></v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            fab elevation="2"
                                            color="green"
                                            small
                                            class="mr-11"
                                            absolute right bottom
                                        >
                                          <v-icon small @click="editarExamen(item)" title="Editar Examen" color="white">
                                            mdi-pencil
                                          </v-icon>
                                        </v-btn>
                                        <v-btn
                                            fab elevation="2"
                                            color="red"
                                            small
                                            absolute right bottom
                                        >
                                          <v-icon  small @click="deleteExamen(item)" title="Eliminar Reactivo" color="white">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                  </div>
                                  <v-avatar
                                      class="ma-3"
                                      size="50"
                                      tile
                                  >
                                    <v-img :src="item.src"></v-img>
                                  </v-avatar>
                                </div>
                              </v-card>
                              <v-card white  class="mb-4" v-if="idTipoPregunta.id==2">
                                <div class="d-flex flex-no-wrap justify-space-between">
                                  <div>
                                    <v-card-title
                                        class="text-h6"
                                    >
                                      <v-checkbox

                                          @change="saveRespuestaCheckbox(item.id,$event)"
                                      >
                                        <template v-slot:label>
                                          {{ item.respuesta }}
                                        </template>
                                      </v-checkbox>
<!--                                      <v-btn
                                          class="ml-1 mt-1"
                                          fab
                                          icon
                                          height="20px"
                                          right
                                          width="20px"
                                          color="blue"
                                      >
                                        <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                                      </v-btn>
                                      <v-btn
                                          class="ml-2 mt-5"
                                          v-if="item.correcta === 1"
                                          rounded
                                          small
                                          color="#BDECB6"
                                      >
                                        Correcta
                                      </v-btn>-->
                                    </v-card-title >
<!--                                    <v-card-text v-text="item.respuesta"></v-card-text>-->
                                    <v-card-actions>
                                      <v-btn
                                          fab elevation="2"
                                          color="green"
                                          small
                                          class="mr-11"
                                          absolute right bottom
                                      >
                                        <v-icon small @click="editarExamen(item)" title="Editar Examen" color="white">
                                          mdi-pencil
                                        </v-icon>
                                      </v-btn>
                                      <v-btn
                                          fab elevation="2"
                                          color="red"
                                          small
                                          absolute right bottom
                                      >
                                        <v-icon  small @click="deleteExamen(item)" title="Eliminar Reactivo" color="white">mdi-delete</v-icon>
                                      </v-btn>
                                    </v-card-actions>
                                  </div>
                                  <v-avatar
                                      class="ma-3"
                                      size="50"
                                      tile
                                  >
                                    <v-img :src="item.src"></v-img>
                                  </v-avatar>
                                </div>
                              </v-card>
                            </v-col>
                          </v-row>

                          <!-- respuestas dadas de alta -->
                        </v-col>
                        <v-col cols="8">
                          <v-row dense>
                            <v-col cols="12">
                            <div id="app2">
                              <editor
                                  v-model="textoRespuesta"
                                  api-key="7rthidfcjaoeuox25n1r7xshe4hefp1u77nokdcp04kyxhiz"
                                  :init="{
                                     height: 200,
                                     menubar: false,
                                     plugins: [
                                       'advlist autolink lists link image charmap print preview anchor',
                                       'searchreplace visualblocks code fullscreen',
                                       'insertdatetime media table paste code help wordcount'
                                     ],
                                     toolbar:
                                       'undo redo | formatselect | bold italic backcolor | \
                                       alignleft aligncenter alignright alignjustify | \
                                       bullist numlist outdent indent | removeformat | help',
                                  }"
                              />
                            </div>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12">
                              <span>Insertar ecuación (opcional):</span>
                              <math-field
                                  id="prueba"
                                  v-model="formulaPregunta"
                                  v-on:change="setIt($event.target.value)"
                                  smart-mode virtual-keyboard-mode="manual"
                                  fonts-directory="//unpkg.com/mathlive/dist/fonts/"
                                  style="
                                    font-size: 22px;
                                    margin: 2px;
                                    padding: 1px;
                                    border-radius: 8px;
                                    border: 1px solid rgba(0, 0, 0, .3);
                                    box-shadow: 0 0 8px rgba(0, 0, 0, .2);">
                              </math-field>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="8" sm="8">
                              <div v-if="imagen_seleccionada_respuesta==1">
                                <a :href="url_imagen_seleccionada_respuesta" target="_blank">
                                  <v-img
                                      :src="url_imagen_seleccionada_respuesta"
                                      width="150px"
                                      height="auto"
                                  ></v-img>
                                </a>
                              </div>
                            </v-col>
                            <v-col cols="4" sm="4">
                              <drive_respuestas :idarchivo_respuesta="idarchivo_respuesta"
                                                @idarchivo_respuesta="idarchivo_respuesta=$event"></drive_respuestas>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12">
                              <v-btn
                                  color="white"
                                  class="primary"
                                  small
                                  @click="saveRespuesta"
                              >
                                GUARDAR RESPUESTA
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <!--TAB CONFIGURACION -->
                  <v-card>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="3">
                          <v-select
                              :items="docentes"
                              item-value='id'
                              item-text='name'
                              v-model="idDocente"
                              outlined
                              dense
                              label="Tiempo en responder"
                              return-object
                              @change="dtCargaAcademica"
                          ></v-select>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                              :items="docentes"
                              item-value='id'
                              item-text='name'
                              v-model="idDocente"
                              outlined
                              dense
                              label="Nivel de Pregunta"
                              return-object
                              @change="dtCargaAcademica"
                          ></v-select>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                              :items="docentes"
                              item-value='id'
                              item-text='name'
                              v-model="idDocente"
                              outlined
                              dense
                              label="Tipo de Visibilidad"
                              return-object
                              @change="dtCargaAcademica"
                          ></v-select>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                              :items="docentes"
                              item-value='id'
                              item-text='name'
                              v-model="idDocente"
                              outlined
                              dense
                              label="Criterio para Calificar Pregunta"
                              return-object
                              @change="dtCargaAcademica"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="4" sm="4" md="4">
                          <template>
                            <v-switch
                                label="Permitir justificante"
                                v-model="permitir_justificante_val"
                            ></v-switch>
                          </template>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import drive from "@/components/drive";
import drive_respuestas from "@/components/drive_respuestas";
import Editor from '@tinymce/tinymce-vue'
import {MathfieldElement} from 'mathlive';

const mfe = new MathfieldElement();
mfe.setOptions({
  fontsDirectory: 'node_modules/mathlive/dist/fonts',
  keypressSound: 'null',
});
/*mfe.applyStyle({color: "red", fontSize: 7 }, { range: [0, 2] });*/
export default {
  components: {
    'editor': Editor,
    drive, drive_respuestas
  },
  data: () => ({
    idPregunta:'',
    idCargaAcademica:'',
    idTipoPregunta:'',
    tabs: null,
    //active_tab:'',
    text: 'texto .',
    loadTable: true,
    search: '',
    selected: [],
    textoPregunta: '',
    textoRespuesta: '',
    formulaPregunta: '',
    formulaRespuesta: '',
    headers: [
      {text: 'Nivel Educativo', align: 'start', value: 'carga_academica_nivel_educativo.nombre', sortable: true},
      {text: 'Grado', value: 'carga_academica_grado_educativo.nombre', align: 'center', sortable: true},
      {text: 'Área Conocimiento', value: 'carga_academica_area_conocimiento.nombre', align: 'center', sortable: true},
      {text: 'Asignatura', value: 'carga_academica_asignatura_academica.nombre', align: 'center', sortable: true},
      {text: 'Nombre del Docente', value: 'user_carga_academica.name', align: 'center', sortable: true},
      {text: 'Estatus', value: 'estatus', align: 'center', sortable: true},
      {text: 'Fecha Activación', value: 'fecha_alta', align: 'center', sortable: true},
      {text: 'Acciones', value: 'actions', align: 'center', sortable: true}
    ],
    docentes: [],
    idDocente: '',
    //VARIABLES IMAGEN
    idarchivo: [],
    tiposReactivos:[],
    idTipoReactivo:'',
    imagen_seleccionada: 0,
    url_imagen_seleccionada: '',
    url_imagen_seleccionada_copia: '',
    obj_imagen_pregunta: [],
    //RESPUESTAS
    idarchivo_respuesta: [],
    imagen_seleccionada_respuesta: 0,
    url_imagen_seleccionada_respuesta: '',
    obj_imagen_pregunta_respuesta: [],

    //VARIABLES YOUTUBE
    enlace_externo_youtube: '',
    agregar_otro_youtube: false,
    listStatus: {'private': 'Privado', 'public': 'Público', 'unlisted': 'No listado'},
    entries: [],
    cargaAcademica:[],
    isLoading: false,
    youtubeModel: null,
    dtRespuestas: [],
    tabPregunta:true,
    tabRespuesta:true,
    tabConfiguracion:true,
    permitir_justificante_val: 0,
  }),
  mounted() {
    this.dtTipoPregunta()
    this.dtCargaAcademica()
  },
  computed: {
    fields() {
      if (!this.youtubeModel) return []
      return this.youtubeModel
      return Object.keys(this.youtubeModel).map(key => {
        return {
          key,
          value: this.youtubeModel[key] || 'n/a',
        }
      })
    },
    items() {
      return this.entries.map(entry => {
        const Description = entry.description.length > this.descriptionLimit
            ? entry.description.slice(0, this.descriptionLimit) + '...'
            : entry.description

        return Object.assign({}, entry, {Description})
      })
    },
    isReadOnly() {
      if (this.tiporeactivo == 3)
        return true;
      else
        return false;
    },
    istexto() {
      return "ACTUALIZAR REACTIVO";
    }
  },
  watch: {
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return
      // Items have already been requested
      if (this.isLoading) return
      this.isLoading = true
      this.getSearch()
    },
    idarchivo: function (val) {
      this.imagen_seleccionada = 1;
      this.url_imagen_seleccionada = "https://drive.google.com/uc?id=" + val.id
      this.obj_imagen_pregunta = val
    },
    idarchivo_respuesta: function (respuesta) {
      this.imagen_seleccionada_respuesta = 1;
      this.url_imagen_seleccionada_respuesta = "https://drive.google.com/uc?id=" + respuesta.id
      this.obj_imagen_pregunta_respuesta = respuesta
    }
  },
  methods: {
    dtTipoPregunta(){
      this.loadTable= true
      let url = `/api/tiporeactivo`
      this.$http.get(url)
          .then((response) => {
            this.tiposReactivos = response.data
          })
          .catch(function (error){
          })
    },
    getRespuestas() {
      let url = '';
/*      this.totalRespuestas              = 0;
      this.totalRespuestasSeleccionadas = 0;*/
      url = `/api/respuestas/getRespuestasIdReactivo?reactivo_id=${this.idPregunta.id}`
      this.$http.get(url)
          .then((response) => {
            this.dtRespuestas = response.data;
            console.log("dtRespuestas--->")
            console.log(this.dtRespuestas)
/*            this.totalRespuestas = this.banco.length
            for (let item = 0; item < this.totalRespuestas; item++) {
              if(this.banco[item].correcta==1)
                this.totalRespuestasSeleccionadas++;
            }
            console.log("Respuestas seleccionadas" + this.totalRespuestasSeleccionadas)
            this.$emit("gettotalRespuestasSeleccionadas",this.totalRespuestasSeleccionadas);
            this.$emit("getTotalRespuestas",this.totalRespuestas);*/
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getTipoPregunta(valueSelected){
      this.idTipoPregunta = valueSelected
    },
    getSearch(callback = null) {
      this.$http.get("/api/eventos-stream/search-youtube")
          .then(res => {
            this.count = res.data.count
            this.entries = res.data.entries
            if (callback) {
              callback()
            }
          })
          .finally(() => (this.isLoading = false))
    },
    getStatus(statusVideo) {
      return this.listStatus[statusVideo]
    },
    getTab()
    {
      console.log("")
    },
    dtCargaAcademica(){
      this.loadTable= true
      let url = `/api/cargaAcademica/getSelectCargaAcademica`
      this.$http.get(url)
          .then((response) => {
            this.cargaAcademica = response.data
            //console.log("array Carga Academica--->")
            //console.log(response.data)
          })
          .catch(function (error){
          })
    },
    savePregunta() {
      let valocriterio = 0;
      let megas = 0;
      let actualizar_imagen = 0
      megas = ((this.obj_imagen_pregunta.sizeBytes) / 1024) / 1024
      this.loadTable = true
      //SE INICIALIZAN LAS VARIABLES A ENVIAR PARA ALMACENAR EN LA TABLA reactivos
      this.$http.post("/api/pregunta/save-pregunta",{
        descripcion:                  this.textoPregunta,
        idNivelEducativo:             this.idCargaAcademica.idNivelEducativo,
        idGradoEducativo:             this.idCargaAcademica.idGradoEducativo,
        idAreaConocimiento:           this.idCargaAcademica.idAreaConocimiento,
        idAsignatura:                 this.idCargaAcademica.idAsignatura,
        idTipoPregunta:               this.idTipoPregunta.id,
        formulaPregunta:              this.formulaPregunta,
        obj_imagen_pregunta:          this.obj_imagen_pregunta,
        youtubeVideo:                 this.youtubeModel,
        enlace_externo_youtube:       this.enlace_externo_youtube,
        agregar_otro_youtube:         (this.agregar_otro_youtube)?1:0,

      })
       .then((response) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Pregunta creada éxitosamente.',
          showConfirmButton: false,
          timer: 1500
        })
         this.idPregunta  = response.data
         this.tabRespuesta = false
        //this.tabs = 1

      }).catch(err => {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'Favor de verificar la información. No se guardó la Pregunta.',
          showConfirmButton: false,
          timer: 1500
        })
      })
      /*.finally(resp => {
        this.dtCargaAcademica()
        this.loadTable = false
        this.resetSelect()
      })*/
    },
    saveRespuesta(){
      let megas = 0;
      megas = ((this.obj_imagen_pregunta_respuesta.sizeBytes) / 1024) / 1024
      if (this.respuesta == '') {
        Swal.fire(
            'Atención',
            'Debe ingresar la Respuesta.',
            'warning'
        )
        respuesta.focus();
      }
      else if (megas > 3) {
        Swal.fire(
            'Atención',
            'El tamaño máximo del archivo no debe exceder los 3 megabytes.',
            'warning'
        )
        respuesta.focus();
      }
      else{
        this.guardando = true;
        this.$http.post('/api/respuestas/save-respuestas', {
          'respuesta': this.textoRespuesta,
          'reactivo_id': this.idPregunta.id,
          'imagen_respuesta': this.obj_imagen_pregunta_respuesta,
        })
            .then((response) => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Respuesta creada éxitosamente.',
                showConfirmButton: false,
                timer: 1500
              })
              this.getRespuestas()
              this.textoRespuesta = ''
/*              this.totalRespuestas++;
              this.$emit("getTotalRespuestas",this.totalRespuestas);
              this.respuesta = '';
              this.obj_imagen_pregunta_respuesta = [];
              this.imagen_seleccionada_respuesta = 0;
              this.url_imagen_seleccionada_respuesta = '';
              this.getRespuestas();
              this.guardando = false;
              setTimeout(() => {
                this.getRespuestas();
              }, 3000) //3 segundos de espera para volver a ejecutar el cargado de respuestas*/
            })
            .catch(function (error) {
            })
      }
    },
    saveRespuestaCheckbox(idRespuesta, ev = null){
      let respuestaSeleccionada = (ev) ? 1 : 0

      console.log("idRespuesta" + idRespuesta)
      console.log(respuestaSeleccionada)
    },
    selectCargaAcademica(idCargaSelected){
      this.tabPregunta = false
      //this.active_tab  = 0
      this.tabs = 0
      this.idCargaAcademica = idCargaSelected

    },
    setIt(event) {
      this.formulaPregunta = event;
    },
  }
}
</script>

<style>
.ML__keyboard.apple.is-visible {
  background-color: #8e44ad !important;
  margin-bottom: 35px;
}

math-field::part(virtual-keyboard-toggle) {
  color: red;
  background: #ddd;
  padding: 20px;

}
</style>
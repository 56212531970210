var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[[_c('v-row',{attrs:{"justify":"center"}},[(_vm.dialogObjetivo)?_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"790"},on:{"input":function($event){_vm.dialogObjetivo = false}}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-alarm")]),_c('h3',[_vm._v("Agregar Evento")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"label":"Coach Organizador de Evento:","items":_vm.organizadores,"item-value":"id","item-text":"nombre","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" Coach:"+_vm._s(item.name)+" - "+_vm._s(item.email)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" Coach:"+_vm._s(item.name)+" - "+_vm._s(item.email)+" ")]}}],null,false,876483452),model:{value:(_vm.idOrganizador),callback:function ($$v) {_vm.idOrganizador=$$v},expression:"idOrganizador"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Tipo de evento","items":_vm.opcionesEvento,"item-value":"id","item-text":"name","outlined":""},model:{value:(_vm.tipo_evento),callback:function ($$v) {_vm.tipo_evento=$$v},expression:"tipo_evento"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha del Evento","prepend-icon":"mdi-calendar","outlined":""},on:{"blur":function($event){_vm.date_inicio = _vm.parseDate(_vm.fecha_consumo_inicio)}},model:{value:(_vm.fecha_consumo_inicio),callback:function ($$v) {_vm.fecha_consumo_inicio=$$v},expression:"fecha_consumo_inicio"}},'v-text-field',attrs,false),on))]}}],null,false,510815009),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date_inicio),callback:function ($$v) {_vm.date_inicio=$$v},expression:"date_inicio"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Límite de usuarios","type":"number","outlined":""},model:{value:(_vm.limite_usuarios),callback:function ($$v) {_vm.limite_usuarios=$$v},expression:"limite_usuarios"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-dialog',{ref:"dialoghorainicio",attrs:{"return-value":_vm.timeinicio,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.timeinicio=$event},"update:return-value":function($event){_vm.timeinicio=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora de inicio del evento","append-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","id":"txttimeinicio"},model:{value:(_vm.timeinicio),callback:function ($$v) {_vm.timeinicio=$$v},expression:"timeinicio"}},'v-text-field',attrs,false),on))]}}],null,false,1137388653),model:{value:(_vm.modalhorainicio),callback:function ($$v) {_vm.modalhorainicio=$$v},expression:"modalhorainicio"}},[(_vm.modalhorainicio)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.timeinicio),callback:function ($$v) {_vm.timeinicio=$$v},expression:"timeinicio"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalhorainicio = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialoghorainicio.save(_vm.timeinicio)}}},[_vm._v(" Aceptar ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-dialog',{ref:"dialoghorafin",attrs:{"return-value":_vm.timefin,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.timefin=$event},"update:return-value":function($event){_vm.timefin=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora de finalizacion del evento","append-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","id":"txttimefin"},model:{value:(_vm.timefin),callback:function ($$v) {_vm.timefin=$$v},expression:"timefin"}},'v-text-field',attrs,false),on))]}}],null,false,720587987),model:{value:(_vm.modalhorafin),callback:function ($$v) {_vm.modalhorafin=$$v},expression:"modalhorafin"}},[(_vm.modalhorafin)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.timefin),callback:function ($$v) {_vm.timefin=$$v},expression:"timefin"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalhorafin = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialoghorafin.save(_vm.timefin)}}},[_vm._v(" Aceptar ")])],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha inicio selección","prepend-icon":"mdi-calendar","outlined":""},on:{"blur":function($event){_vm.date_inicio_publicacion = _vm.parseDate(_vm.fecha_seleccion_inicio)}},model:{value:(_vm.fecha_seleccion_inicio),callback:function ($$v) {_vm.fecha_seleccion_inicio=$$v},expression:"fecha_seleccion_inicio"}},'v-text-field',attrs,false),on))]}}],null,false,3046926651),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.date_inicio_publicacion),callback:function ($$v) {_vm.date_inicio_publicacion=$$v},expression:"date_inicio_publicacion"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha fin selección","prepend-icon":"mdi-calendar","outlined":""},model:{value:(_vm.fecha_seleccion_termino),callback:function ($$v) {_vm.fecha_seleccion_termino=$$v},expression:"fecha_seleccion_termino"}},'v-text-field',attrs,false),on))]}}],null,false,2781597871),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.date_fin_publicacion),callback:function ($$v) {_vm.date_fin_publicacion=$$v},expression:"date_fin_publicacion"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.guardarEvento}},[_vm._v(" "+_vm._s(_vm.txtboton)+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.closeImportar()}}},[_vm._v(" Cerrar ")])],1)],1)],1):_vm._e()],1)],_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-alarm")]),_c('h3',[_vm._v("Eventos")]),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"large":""}}),_c('v-spacer')],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"green","small":"","title":"Crear Evento"},on:{"click":function($event){return _vm.showUnidad()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-alarm")])],1)],1)],1)],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.eventos},scopedSlots:_vm._u([{key:"item.detalle_organizador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.detalle_organizador.name)+"-"+_vm._s(item.detalle_organizador.email)+" ")]}},{key:"item.horario",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(item.hora_inicio)+"-"+_vm._s(item.hora_fin)+" ")])]}},{key:"item.publicado",fn:function(ref){
var item = ref.item;
return [((parseInt(item.publicado)>=1))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","title":"Oferta publicada"}},[_vm._v(" mdi-arrow-up-bold-circle-outline ")]):_vm._e(),((parseInt(item.publicado)==0))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange","title":"Oferta No publicada"}},[_vm._v(" mdi-flag-outline ")]):_vm._e()]}},{key:"item.fecha_consumo_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_consumo_inicio))+" ")]}},{key:"item.fecha_seleccion_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_seleccion_inicio))+" ")]}},{key:"item.fecha_seleccion_termino",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_seleccion_termino))+" ")]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":(item.estatus==1)?'green':'orange',"text-color":"white"}},[_vm._v(" "+_vm._s((item.estatus==1)?'Evento programado':'Pendiente de programar')+" ")])]}},{key:"item.detalles",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"fab":"","elevation":"2","color":"blue","small":"","x-small":"","title":"Detalles Evento"},on:{"click":function($event){return _vm.showDetallesEvento(item)}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-message-text-outline")])],1)],1)]}},{key:"item.eventos",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"fab":"","elevation":"2","color":"green","small":"","x-small":"","title":"Eventos Sesión"},on:{"click":function($event){return _vm.showEventoSesion(item)}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-message-text-outline")])],1)],1)]}},{key:"item.estatus_visibilidad",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"small",attrs:{"label":"Activada"},on:{"change":function($event){return _vm.disponibilidadEvento(item.estatus_visibilidad, item.id)}},model:{value:(item.estatus_visibilidad),callback:function ($$v) {_vm.$set(item, "estatus_visibilidad", $$v)},expression:"item.estatus_visibilidad"}})]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
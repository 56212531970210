<template>
  <v-container>
    <v-card
        class="mx-auto"
    >
      <v-card-title class="black--text  darken-4">
        <v-icon large class="mr-2" color="green">mdi-checkbox-marked-outline</v-icon>
        Carga Académica
        <v-spacer></v-spacer>
        <v-btn
            color="white"
            class="text--primary"
            fab
            small
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense >
          <v-col cols="12">
            <v-layout justify-center>
              <v-card-actions>
                <v-select
                    :items="docentes"
                    item-value='id'
                    item-text='name'
                    v-model="idDocente"
                    outlined
                    dense
                    label="Docente"
                    return-object
                    @change="setDocente"
                ></v-select>
              </v-card-actions>
            </v-layout>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
              cols="6"
              sm="3"
          >
            <v-select
                :items="nivelEducativo"
                :disabled="( onlyReadNivelEducativo == 1 ) ? true : false"
                item-value='id'
                item-text='nombre'
                v-model="idNivelEducativo"
                outlined
                dense
                label="Nivel Educativo"
                return-object
                @change="getGradoAcademico"
                ref="selectedNivelEducativo"
            ></v-select>
          </v-col>
          <v-col
              cols="6"
              sm="3"
          >
            <v-select
                :items="gradoAcademico"
                :disabled="( onlyReadGrado == 1 ) ? true : false"
                item-value='id'
                item-text='nombre'
                outlined
                dense
                label="Grado"
                return-object
                @change="setGradoAcademico"
                ref="selectedGradoAcademico"
            ></v-select>
          </v-col>
          <v-col
              cols="6"
              sm="3"
          >
            <v-select
                :items="areaConocimiento"
                :disabled="( onlyReadAreaConocimiento == 1 ) ? true : false"
                item-value='id'
                item-text='nombre'
                outlined
                dense
                label="Área de conocimiento"
                @change="getAsignaturaAcademica"
                return-object
                ref="selectedAreaConocimiento"
            ></v-select>
          </v-col>
          <v-col
              cols="6"
              sm="3"
          >
            <v-select
                :items="asignaturaAcademica"
                :disabled="( onlyReadAsignatura == 1 ) ? true : false"
                item-value='id'
                item-text='nombre'
                outlined
                dense
                label="Asignatura"
                @change="setAsignaturaAcademica"
                return-object
                ref="selectedAsignaturaAcademica"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense >
          <v-col cols="12">
            <v-layout justify-center>
              <v-card-actions>
                <v-btn
                    color="primary"
                    @click="guardarCargaAcademica"
                    :disabled="loadTable"
                >
                  <span>Asignar</span>
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" >
            <div class="mt-2" v-if="loadTable" v-model="loadTable">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              Actualizando información, espere por favor...
            </div>
            <v-data-table :loading="loadTable" loading-text="Actualizando información" v-model="selected" :headers="headers" :items="concentradoDocentes" :search="search" class="elevation-1">
              <template v-slot:top>
                <v-text-field
                    class="mx-4"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                >
                </v-text-field>
              </template>
              <template v-slot:item.actions="{ item }">
<!--                <v-btn
                    fab elevation="2"
                    color="green"
                    small
                    x-small
                    style="margin-right: 5px"
                >
                  <v-icon small @click="editarExamen(item)" title="Editar Examen" color="white">
                    mdi-pencil
                  </v-icon>
                </v-btn>-->
                <v-btn
                    fab elevation="2"
                    color="red"
                    small
                    x-small
                >
                  <v-icon small @click="deleteCargaAcademica(item)" title="Eliminar Asignación Académica" color="white">mdi-delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.estatus="{item}">
                <div v-if="(item.estatus==1)">
                  <v-chip
                      class="ma-2"
                      color="green"
                      text-color="white"
                  >
                    Activo
                  </v-chip>
                </div>
                <div v-if="(item.estatus==0)">
                  <v-chip
                      class="ma-2"
                      color="red"
                      text-color="white"
                  >
                    Inactivo
                  </v-chip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
      //inicializacion de variables
      loadTable: true,
      search: '',
      selected: [],
      onlyReadNivelEducativo:1,
      onlyReadGrado:1,
      onlyReadAreaConocimiento:1,
      onlyReadAsignatura:1,
      headers: [
        {text: 'Nivel Educativo', align: 'start', value: 'nombreNivelEducativo', sortable: true},
        {text: 'Grado', value: 'nombreGradoEducativo', align: 'center', sortable: true},
        {text: 'Área Conocimiento', value: 'nombreAreaConocimiento', align: 'center', sortable: true},
        {text: 'Asignatura', value: 'nombreAsignaturaAcademica', align: 'center', sortable: true},
        {text: 'Nombre del Docente', value: 'nombreDocente', align: 'center', sortable: true},
        {text: 'Estatus', value: 'estatus', align: 'center', sortable: true},
        {text: 'Fecha Activación', value: 'fecha_alta', align: 'center', sortable: true},
        {text: 'Acciones', value: 'actions', align: 'center', sortable: true}
      ],
      email: '',
      concentradoDocentes: [],
      docentes:[],                  idDocente:'',
      nivelEducativo:[],            idNivelEducativo:'',
      gradoAcademico:[],            idGradoAcademico:'',
      areaConocimiento:[],          idAreaConocimiento:'',
      asignaturaAcademica:[],       idAsignaturaAcademica:'',
  }),
  created() {

  },
  mounted() {
    this.getCargaAcademica();
    this.getDocentes();
    this.getNivelEducativoAreaConocimiento()
  },
  methods: {
    deleteCargaAcademica(item){
      Swal.fire({
        icon : 'warning',
        title: '¿Confirma que desea eliminar la carga academica seleccionada?' ,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.loadTable = true
          this.$http.post("/api/cargaAcademica/" + item.id, {
            _method : 'DELETE'
          })
              .then(res => {

              })
              .catch(err => {

              })
              .finally(() => {
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Carga Académica Eliminada correctamente.',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.getCargaAcademica()
                this.loadTable = false
              })
        }
      })
    },
    getCargaAcademica(){
      this.loadTable= true
      let url = `/api/cargaAcademica/getCargaAcademica`
      this.$http.get(url)
          .then((response) => {
            this.concentradoDocentes = response.data
            this.loadTable = false
          })
          .catch(function (error){
          })
    },
    getAsignaturaAcademica(selectedOption){
        this.idAreaConocimiento = selectedOption.id
        this.onlyReadAsignatura = 0
        this.$http.get("/api/asignaturaAcademica/getAsignaturaAcademica?idAreaConocimiento=" + selectedOption.id).then(res => {
          this.asignaturaAcademica = res.data;
        }).catch(err => {
        })
    },
    getDocentes(){
      let url = `/api/getDocentes`
      this.$http.get(url)
          .then((response) => {
            this.docentes = response.data;
          })
          .catch(function (error){
          })
    },
    getGradoAcademico(selectedOption) {
        this.idNivelEducativo = selectedOption.id
        this.onlyReadGrado = 0
        this.$http.get("/api/gradoAcademico/getGradoAcademico?idNivelAcademico=" + selectedOption.id).then(res => {
          this.gradoAcademico = res.data;
        }).catch(err => {
        })
    },
    getNivelEducativoAreaConocimiento(){
      let url = `/api/niveleseducativos/getNivelEducativo`
      this.$http.get(url)
          .then((response) => {
            this.nivelEducativo       =   response.data.nivelEducativo;
            this.areaConocimiento     =   response.data.areaConocimiento;
          })
          .catch(function (error){
          })
    },
    setAsignaturaAcademica(selectedOption){
      this.idAsignaturaAcademica  =  selectedOption.id
    },
    guardarCargaAcademica(){
      this.loadTable = true
      this.$http.post("/api/cargaAcademica", {
        idDocente :                 this.idDocente,
        idNivelEducativo:           this.idNivelEducativo,
        idGradoAcademico:           this.idGradoAcademico,
        idAreaConocimiento:         this.idAreaConocimiento,
        idAsignaturaAcademica:      this.idAsignaturaAcademica
      }).then(res => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Carga académica asignada éxitosamente.',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(err => {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'Favor de verificar los datos seleccionados.',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .finally(resp => {
        this.getCargaAcademica()
        this.loadTable = false
        this.resetSelect()
      })
    },
    setGradoAcademico(selectedOption){
      this.idGradoAcademico         = selectedOption.id
      this.onlyReadAreaConocimiento = 0
    },
    setDocente(selectedOption){
      this.onlyReadNivelEducativo=0
      this.idDocente  = selectedOption.id
    },
    resetSelect(){
      this.idNivelEducativo         = ''
      this.idGradoAcademico         = ''
      this.idAreaConocimiento       = ''
      this.idAsignaturaAcademica    = ''
      this.onlyReadNivelEducativo   = 0
      this.onlyReadGrado            = 1
      this.onlyReadAreaConocimiento = 1
      this.onlyReadAsignatura       = 1
      this.$refs["selectedNivelEducativo"].reset()
      this.$refs["selectedGradoAcademico"].reset()
      this.$refs["selectedAreaConocimiento"].reset()
      this.$refs["selectedAsignaturaAcademica"].reset()
      this.loadTable =  true
    }
  }
}
</script>
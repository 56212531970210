<template>
<v-container>
    <v-card
      elevation="2"
     >
     <v-card-title>
            <h3>Configuración de Asignaturas</h3>
          </v-card-title>
          <v-container class="grey lighten-5">
      <v-row >
        <v-col cols="3" sm="3">
            <template>
                <v-select
                label='Área:'
                v-model='idArea'
                :items='areas'
                item-value='id'
                item-text='nombre'
                dense
                @change="getIdArea"
                >
                    <template slot='selection' slot-scope='{ item }'>
                        {{item.nombre}}
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{item.nombre}}
                    </template> 
                </v-select>
            </template> 
        </v-col>
          <v-col cols="4" sm="4">
                    <v-text-field  
                        label="Nombre de Asignatura"         
                        v-model="nombre"
                        v-bind:value="nombre"
                        dense
                    ></v-text-field>      
        </v-col>
                <v-col cols="3" sm="3">
                    <v-text-field  
                        label="Abreviatura"         
                        v-model="abreviatura"
                        dense
                    ></v-text-field>      
        </v-col>

        </v-row >
        <v-row>
            <v-col cols="12" sm="12">
                <v-btn
                color="primary"
                dark
                class="mb-2"
                title="Agregar Asignatura"
                @click="guardarAsignatura()"
                >Agregar Asignatura</v-btn>
            </v-col>
        </v-row>
          </v-container>
  <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>

        <v-data-table
          :headers="headers"
          :items="asignaturas"
          class="elevation-1"
        >
            <template v-slot:item.nombre="{ item }">               
                    <v-text-field                
                    v-model="item.nombre"
                    v-bind:value="item.nombre"
                    @change="actualizarNombre(item)"
                    ></v-text-field>    
            </template>
            <template v-slot:item.abreviatura="{ item }">               
                    <v-text-field                
                    v-model="item.abreviatura"
                    v-bind:value="item.abreviatura"
                    @change="actualizarAbreviatura(item)"
                    ></v-text-field>    
            </template>
            <template v-slot:item.activo="{ item }">               
                <v-switch 
                v-model="item.activo" 
                v-bind:value="item.activo"
                @change="actualizarActivo(item)"
                ></v-switch>
            </template>            
        </v-data-table>
    </v-card>
 </v-container>
</template>

<script>
   import moment from 'moment';
   moment.locale('es');
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  export default {
    data:() => ({
        nombre:'',
        orden:'',
        areas:[],
        asignaturas:[],
        idArea:'',
        abreviatura:'',
        activo:1,
        pagination: {},
        headers: [
          //{text: 'Id',align: 'start',sortable: false,value: 'id'},
          {text: 'Nombre Asignatura',align: 'start',sortable: false,value: 'nombre'},
          { text: 'Abreviatura', value: 'abreviatura'},   
          { text: 'Área', value: 'area_concentrado.nombre'}, 
          { text: 'Activo', value: 'activo' },
        ],
        overlay: false,
        absolute:true,
     
    }),
    created () {
      this.init();
    },    
    methods: {
    init () {
      this.getAreas()
      this.getAsignaturas()
    }, 
    getIdArea(id){
        this.idArea = id;

    },
    guardarAsignatura(){
        if(this.nombre=='')
        confirm('Debe capturar nombre de la asignatura')
        else if (this.idArea==''){
        confirm('Debe Seleccionar un área de conocimiento')
        }
        else{
           this.$http.post('/api/asignaturas', {
            'nombre':               this.nombre,
            'abreviatura':          this.abreviatura,
            'area_conocimiento_id': this.idArea
          })
          .then((response) => {
            this.getAsignaturas()
            this.nombre='';
            this.abreviatura='';
            this.idArea='';
          })
          .catch(function (error){
            console.log(error)
          })
        }
    },
    actualizarActivo(item){
      if(item.alumnocontactado==null){
         item.activo = 0;
      }
      else
        item.activo = 1;
      //console.log('alumnoContactado actualizado: ' + alumnocontactado + 'id:' + id)
                this.overlay = true;
                this.$http.post('/api/niveleseducativos', {
              
                'id':               item.id,
                'atributo':         2,
                'alumnocontactado':  item.alumnocontactado,
               
                })
                 .then((response) => {
                   // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                   // console.log(error)
                    this.overlay = false;
                })
                
    },
    getAsignaturas(){
            let url = `/api/asignaturas`
            this.$http.get(url)
            .then((response) => {
               this.asignaturas = response.data;
            })
            .catch(function (error){
               //console.log(error)
            })
    },
    getAreas () {
            let url = '/api/areas'
            this.$http.get(url)
            .then((response) => {
               this.areas = response.data;
            })
            .catch(function (error){
               //console.log(error)
            })
    }    
    },
    mounted(){
      
    } 
  }
</script>
<template>
  <form>
<!--    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="email"

            label="E-mail"
            required
          ></v-text-field>          
          <v-text-field
          label="Password"
            v-model="password"
            counter
          ></v-text-field>
    <v-btn class="mr-4" @click="login">submit</v-btn>
    <v-btn @click="clear">clear</v-btn>          
        </v-col>        
      </v-row>
    </v-container> -->
  </form>
</template>        

<script>
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = 'http://localhost:8000'
  export default {
    data: () => ({
    }),  
    mounted() {
        //this.login()
    },  
    methods:{     
/*       login(){
        axios.get('/sanctum/csrf-cookie').then(() => {
              window.location = "http://localhost:8000/redirect"
          })
          .then(response =>{
            console.log(response);
            this.$router.push({ name: 'ReporteDocente'})
          }).catch(function (error){
            console.log(error)
          }
            
          )
      },  */
    } 
  }
</script>
<template>
	<div>

	<v-dialog
	      v-model="dialog"
	      persistent
	      max-width="600px"
	    >
	 
	      <v-card>
	        <v-card-title>
	          <span class="headline">Formulario de registro</span>
	        </v-card-title>
	        <v-card-text>
	          	<v-container>
					<v-alert
					v-if="seinscribio==1"
					outlined
					type="success"
					text
					>
					Tu registro se ha realizado correctamente, gracias.
					</v-alert>
					<div v-else>
		                <v-text-field
		                v-model="userinfo.nombreusuario"
		                  label="(*) Nombre completo"
		                  placeholder="Campo requerido"
		                  required
		                ></v-text-field>
		                
		                <v-text-field
		                v-model="userinfo.correousuario"
		                  label="(*) Correo electrónico"
		                  placeholder="Campo requerido"
		                  required
		                ></v-text-field>

						<v-select
						v-model="userinfo.paisusuario"
						outlined
						
						:items="paises"
						label="País"
						item-text="name"
						item-value="value"
						dense
						></v-select>


						<v-select
						v-model="userinfo.generousuario"
						outlined
					
						:items="generos"
						label="Género"
						item-text="name"
						item-value="value"
						dense
						></v-select>


		                <v-text-field
		                v-model="userinfo.legalidusuario"
		                  label="(*) Identificación legal (CURP, DNI, etc)"
		                  placeholder="Campo requerido"
		                  required
		                ></v-text-field>

						<small>* Campo obligatorio</small>
						<br>

	                </div>
	          	</v-container>

				<v-progress-linear
				  v-if="loading==true"
				  indeterminate
				  color="yellow darken-2"
				></v-progress-linear>

	        </v-card-text>
	        <v-card-actions>
	          <v-spacer></v-spacer>
	          <v-btn
	          	v-if="! loading"
	            color="blue darken-1"
	            text
	            @click="hideDialog"
	          >
	            Cerrar
	          </v-btn>
	          <v-btn
	          	v-if="(! loading) && ( seinscribio == 0 )"
	            color="blue darken-1"
	            text
	          	@click="completarRegistro"
	          >
	            Confirmar
	          </v-btn>
	        </v-card-actions>
	      </v-card>
	    </v-dialog>
	</div>
</template>
<script type="text/javascript">
	export default {
		name : 'registroformulario',
		props : ['dialog', 'curso_id', 'seinscribio'],	
		data: () => ({
			loading : false,
			userinfo : {
				nombreusuario : "",
				correousuario : "",
				paisusuario : "",
				generousuario : "",
				legalidusuario : "",
				curso_id : 0,
			},
			genero : 0,
			pais : 0,
			generos : [{
				value : 1,
				name : "Masculino" 
			},
			{
				value : 2,
				name : "Femenino" 
			},
			{
				value : 3,
				name : "Prefiero no especificar" 
			}],

			paises : [{
				value : 1,
				name : "México" 
			},],
		}),
		mounted() {
			this.loading = false;
			//this.dialogReference = this.dialog;
			//alert(this.dialog)
		},
		methods : {
			hideDialog() {
				this.$emit('dialog', false);
			},
			completarRegistro() {
				this.loading = true;
				this.userinfo.curso_id = this.curso_id
				this.$http.post('/api/interestedusers', this.userinfo)
				.then(response => {
					alert("Se creó el registro correctamente")
					this.loading = false;
					//this.dialog = false;

					this.userinfo = {
						nombreusuario : "",
						correousuario : "",
						paisusuario : "",
						generousuario : "",
						legalidusuario : "",
						curso_id : 0,
					};
					
					this.$emit('seinscribio', 1);

				})
				.catch(response => {
					alert("Todos los campos son obligatorios(*)")
					this.loading = false;
				});
			}
		}
	}
</script>
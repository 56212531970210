
<template>
  <v-container>

    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

    <div class="container">

      <div class="main mt-5">

        <div class="card shadow">
          <div class="card-body">

            <h1 class="display-3 my-5">Bienvenido cursante</h1>

            <nav class="navbar navbar-expand-lg navbar-light bg-light mt-5">
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                  <li class="nav-item active">
                    <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">En curso</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">Completado</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link disabled" href="#">
                      <i class="fa fa-heart-o" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>

            <div class="row">
              <div class="col-12">

                <h3 class="txt_title my-5 ml-5" style="font-weight: 700;">CONTENIDO DE LA OFERTA ENERO-MAYO 2021  </h3>
                <hr>
              </div><!--col-6-->
            </div><!--row-->




            <div class="row">
              <div class="col-4">

                <v-card
                    class="mx-auto"
                    max-width="400"
                >
                  <v-img
                      class="white--text align-end"
                      height="200px"
                      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  >
                    <v-card-title>CURRICULA:Classroom 1</v-card-title>
                  </v-img>

                  <v-card-subtitle class="pb-0">
                    Number 10
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div>Whitehaven Beach</div>

                    <div>Whitsunday Island, Whitsunday Islands</div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                        color="orange"
                        text
                    >
                      MATRICULARSE
                    </v-btn>

                    <v-btn
                        color="orange"
                        text
                    >
                      EXPLORAR CURRICULA
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </div><!--col-4-->

              <div class="col-4">

                <v-card
                    class="mx-auto"
                    max-width="400"
                >
                  <v-img
                      class="white--text align-end"
                      height="200px"
                      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  >
                    <v-card-title>CURRICULA: CLASSROOM 1</v-card-title>
                  </v-img>

                  <v-card-subtitle class="pb-0">
                    Number 10
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
<!--                    <div>IN</div>-->

                    <div>Whitsunday Island, Whitsunday Islands</div>
                  </v-card-text>

                  <v-card-actions>
<!--                    <v-btn
                        color="orange"
                        text
                    >
                      Share
                    </v-btn>-->

                    <v-btn
                        color="orange"
                        text
                    >
                      CONTINUA TU APRENDIZAJE
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </div><!--col-4-->

              <div class="col-4">

                <v-card
                    class="mx-auto"
                    max-width="400"
                >
                  <v-img
                      class="white--text align-end"
                      height="200px"
                      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  >
                    <v-card-title>Curso GCE1</v-card-title>
                  </v-img>

                  <v-card-subtitle class="pb-0">
                    Number 10
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div>Whitehaven Beach</div>

                    <div>Whitsunday Island, Whitsunday Islands</div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                        color="orange"
                        text
                    >
                      VER SESIONES
                    </v-btn>

                    <v-btn
                        color="orange"
                        text
                    >
                      Explore
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </div><!--col-4-->

            </div><!--row-->


            <div class="row">
              <div class="col-12">

                <h3 class="txt_title my-5 ml-5" style="font-weight: 700;">CURRICULAS MAS POPULARES</h3>
                <hr>
              </div><!--col-6-->
            </div><!--row-->


            <div class="row">
              <div class="col-4">

                <v-card
                    class="mx-auto"
                    max-width="400"
                >
                  <v-img
                      class="white--text align-end"
                      height="200px"
                      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  >
                    <v-card-title>Cloud para maestros</v-card-title>
                  </v-img>

                  <v-card-subtitle class="pb-0">
                    Number 10
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div>Whitehaven Beach</div>

                    <div>Whitsunday Island, Whitsunday Islands</div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                        color="orange"
                        text
                    >
                      Share
                    </v-btn>

                    <v-btn
                        color="orange"
                        text
                    >
                      Explore
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </div><!--col-4-->

              <div class="col-4">

                <v-card
                    class="mx-auto"
                    max-width="400"
                >
                  <v-img
                      class="white--text align-end"
                      height="200px"
                      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  >
                    <v-card-title>Cloud professional</v-card-title>
                  </v-img>

                  <v-card-subtitle class="pb-0">
                    Number 10
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div>Whitehaven Beach</div>

                    <div>Whitsunday Island, Whitsunday Islands</div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                        color="orange"
                        text
                    >
                      Share
                    </v-btn>

                    <v-btn
                        color="orange"
                        text
                    >
                      Explore
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </div><!--col-4-->

              <div class="col-4">

                <v-card
                    class="mx-auto"
                    max-width="400"
                >
                  <v-img
                      class="white--text align-end"
                      height="200px"
                      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                  >
                    <v-card-title>Drive expert</v-card-title>
                  </v-img>

                  <v-card-subtitle class="pb-0">
                    Number 10
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div>Google drive ex</div>

                    <div>Whitsunday Island, Whitsunday Islands</div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="orange" text>
                      Share
                    </v-btn>

                    <v-btn
                        color="orange"
                        text
                    >
                      Explore
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </div><!--col-4-->

            </div><!--row-->




            <div class="row">
              <div class="col-12">

                <h3 class="txt_title my-5 ml-5" style="font-weight: 700;">Explorar rutas de aprendizaje de carreras profesionales</h3>
                <hr>

                <div class="jumbotron">
                  <small>Recorridos de aprendizaje de carrera profesional</small>
                  <h2>
                    Explorar currículas profesionales
                  </h2>

                  <p class="lead">
                    Desarrolla habilidades relevantes para tu carrera profesional con cursos impartidos por expertos en el área

                  </p>

                  <hr class="my-4">
                  <p class="lead">
                    <button class="btn btn-primary btn-lg" href="#" role="button">Ver más</button>
                  </p>
                </div>

              </div><!--col-6-->
            </div><!--row-->





          </div><!--card-body-->
        </div><!--card-->




      </div><!--main-->


    </div><!--container-->






  </v-container>
</template>


<script>
export default {

}
</script>



<template>
	<div>		
		<v-container>
			<h1 class="">Cursos disponibles</h1>
			<v-divider class="my-2"></v-divider>
			<v-row>
				<v-col v-for="curso in cursos" lg="4" md="4" sm="4">
					<v-card 
				class="mx-auto"
			    max-width="374"
			  >
			    <v-img
			      height="250"
			      src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
			    ></v-img>

			    <v-card-title>{{ curso.name }}</v-card-title>

			    <v-card-text>
			      <v-row
			        align="center"
			        class="mx-0 my-2"
			      >
			        <v-rating
			          :value="4.5"
			          color="amber"
			          dense
			          half-increments
			          readonly
			          size="14"
			        ></v-rating>

			        <div class="grey--text ml-4">
			          4.5 (413)
			        </div>
			      </v-row>

		

			      <div class="text-justify">Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.</div>
			    </v-card-text>

			    <v-divider class="mx-4"></v-divider>

			    <!--
			    <v-card-title>Fechas de inscripción</v-card-title>

			    <v-card-text>
			      <v-chip-group
			        
			        active-class="deep-purple accent-4 white--text"
			        column
			      >
			        <v-chip>13 de enero de 2020</v-chip>

			        <v-chip>15 de enero de 2020</v-chip>

			        <v-chip>20 de enero de 2020</v-chip>

			      </v-chip-group>
			    </v-card-text>

			    -->

			    <v-card-actions>
			      <v-btn
			        color="deep-purple lighten-2"
			        text
			        @click="inscribirse(curso.id)"
			      >
			        Matricularse
			      </v-btn>
			    </v-card-actions>
			  </v-card>
				</v-col>
			</v-row>
		</v-container>
		<registroformulario @dialog="dialog = $event" :dialog="dialog" :curso_id="curso_id" @seinscribio="seinscribio = $event" :seinscribio="seinscribio" />
	</div>
</template>
<script type="text/javascript">
import registroformulario from '@/views/registroformulario.vue';
export default {
	components : {registroformulario},
	data:() => ({
		seinscribio : 0,
		curso_id : 0,
		cursos : [],
		dialog : false,
	}),
	methods : {
		inscribirse(curso_id) {
			this.$http.get('/api/interestedusers/' + curso_id)
			.then(response => {
				this.dialog = true;
				this.curso_id = curso_id;
				this.seinscribio = 0;
				if ( response.data ) {
					this.seinscribio = 1;
				}
			})
		}
	},
	mounted() {
		this.cursos = [
			{
				name : "Uno",
				id : 1
			},
			{
				name : "Dos",
				id : 2
			},
			{
				name : "Tres",
				id : 3
			},
		];
	}, 
}
</script>
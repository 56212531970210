<template>
	<div >
		<br>
		<span v-for="(word, index) in words">

			<span v-if="( onlyread == 1 )">
				<div style="display: inline-block;border:1px solid #f2f2f2;" class="py-2 px-2" v-if="typeof word != 'string'">
					<span v-html="drawCorrect(word.respuesta, word.respuesta_guardada)"></span>
				</div>
				<span v-else> {{ word }} </span>
			</span>
			<span v-else>
				<span v-if="typeof word != 'string'">
					<v-text-field
					:disabled="( onlyread == 1 || previsualizacion_examen == 1 ) ? true : false"
					:value="(previsualizacion_examen==1) ? word.respuesta_rellenar : word.respuesta_guardada"
					:id="'text-' + pregunta.respuestas[word.index].id"
					hint="Favor de rellenar la pregunta que crea correcta"
					label=""
					solo
					v-on:keyup="log(pregunta.id, word.pregunta_id, pregunta.tiporeactivo_id, $event.target.value, idExamen, student_stauts_exam)"
					style="margin-right: 20px; width: 15%; display: inline-flex;"
					></v-text-field>
				</span>
				<span v-else>{{ word }} </span>
			</span>
		</span>
	</div>
</template>
<script>
import _ from 'lodash'
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default {
	name: 'Test2',
	props: ['pregunta', 'leftSecondsExam', 'idExamen', 'student_stauts_exam', 'onlyread', 'previsualizacion'],
	data: () => ({
    previsualizacion_examen: 0,
		concat : '',
		words : [],
		counter : 0,
	}),
	mounted() {
	  if ( this.previsualizacion ) {
	    this.previsualizacion_examen = 1
    }

		let arr_cadena = this.pregunta.descripcion.split(' '),
		aux = [],
		counter = 0;

		for ( let palabra = 0; palabra < arr_cadena.length; palabra++ ) {

			let blocComm = arr_cadena[palabra].match(/\/\*[\w\d\s]*\*\//g);
			if ( blocComm ) {
			  let params_pregunta = {
          name : '[ESPACIO]',
          index : counter,
          pregunta_id : this.pregunta.respuestas[counter].id,
          respuesta_guardada : (this.pregunta.respuestas[counter].reactivo_contestado != null ) ? this.pregunta.respuestas[counter].reactivo_contestado.string_respuesta : "",
          respuesta : (this.pregunta.respuestas[counter].reactivo_contestado != null ) ? this.esRespuestaCorrecta(this.pregunta.respuestas[counter]) : '',
          respuesta_rellenar: this.pregunta.respuestas[counter].respuesta
        };
				this.words.push( params_pregunta );
        console.log(params_pregunta)

			  counter++;
			}
			else {
			   this.words.push( arr_cadena[palabra] );
			}
		}


	},
	methods : {
		drawCorrect(type, respuesta_guardada) {
			let html = '';
			if ( ! type ) {
				html += '<span class="red--text text-center" style="display:block;"><strong>Incorrecto</strong></span><h3 class="text-center" style="display:block;">' + respuesta_guardada + '</h3>';
			}
			else {
				html += '<span class="green--text text-center" style="display:block;"><strong>Correcto</strong></span><h3 class="text-center" style="display:block;">' + respuesta_guardada + '</h3>';
			}
			return html;
		},
		esRespuestaCorrecta(field = '') {
            let explode_respuestas = field.respuesta.split(";"),
            	respuesta_correcta = 0;

            switch ( field.tiporespuesta ) {
                //Coincidencia exacta
                case 1 :
                    //...ssss
                   	let unica_respuesta = explode_respuestas[0];
                    if (field.reactivo_contestado.string_respuesta == unica_respuesta ) {
                        respuesta_correcta = 1;
                    }
                    break;
                //Contiene coincidencia
                case 2 :
                    for ( let word in explode_respuestas ) {
                        if ( field.reactivo_contestado.string_respuesta == explode_respuestas[word] ) {
                           	respuesta_correcta = 1;
                            break;
                        }
                    }
                    //...ssss
                    break;
            }
            return respuesta_correcta;
		},
		log  : _.debounce((reactivo_id, id_respuesta, tiporeactivo_id, response, idExamen, student_stauts_exam) => {
			//console.log(reactivo_id, id_respuesta, tiporeactivo_id, ev);

		        axios.post('/api/evaluacionalumno', {
		          reactivo_id : reactivo_id,
		          id_respuesta : id_respuesta,
		          tiporeactivo_id : tiporeactivo_id,
		          examen_id : idExamen,
		          leftSecondsExam : student_stauts_exam.leftSecondsExam,
		          response : response
		        })
		        .then(response => {

		        });

		}, 500),
		saveWord(value) {
			alert(value);
		}
	}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clases},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Requisito(s) de la clase: "+_vm._s(_vm.nombre_clase))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("Nueva Clase")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":""}},[_c('v-text-field',{attrs:{"label":"Nombre"},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"0"}},[_c('v-text-field',{attrs:{"label":"Descripión"},model:{value:(_vm.descripcion),callback:function ($$v) {_vm.descripcion=$$v},expression:"descripcion"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.guardarClase(_vm.nombre,_vm.descripcion)}}},[_vm._v("Guardar")])],1)],1)],1)],1)]},proxy:true},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item.indispensable",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.indispensable},on:{"change":function($event){return _vm.actualizarIndispensable(item.indispensable,item.id)}},model:{value:(item.indispensable),callback:function ($$v) {_vm.$set(item, "indispensable", $$v)},expression:"item.indispensable"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="800"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Favor de autenticarse por medio de su correo electrónico
        </v-card-title>
        <v-card-text>
          <v-alert
              class="pa-5"
              dense
              type="info"
          >Debe de pre-autenticarse por medio de su correo electrónico para iniciar sesión
          </v-alert>
          <v-text-field v-model="email" label="Ingrese su dirección de correo electrónico"></v-text-field>

          <v-alert
              v-if="resultado_autenticacion"
              dense
              outlined
              type="error"
              class="mt-0 mb-5"
          >
            Su correo electrónico no es válido y usted no puede iniciar sesión en este proceso
          </v-alert>

          <v-btn v-if="!loading_autenticando" class="primary" @click="authenticateUser">Continuar</v-btn>

          <div v-if="loading_autenticando" v-model="loading_autenticando">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
            Autenticando, espere...
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading_autenticando: false,
      dialog: true,
      email: '',
      resultado_autenticacion: false
    }
  },
  mounted() {

  },
  methods: {
    authenticateUser() {
      this.resultado_autenticacion = false
      this.loading_autenticando = true
      this.$http.post("/api/authenticate-user/request-user", {
        email: this.email
      }).then(res => {
        if ( res.data.user == true ) {
          window.location.href = process.env.VUE_APP_API_URL + "/api/authenticate-user/redirect-user?email=" + this.email;
        }
        else {
          this.resultado_autenticacion = true
          this.loading_autenticando = false
        }
      }).catch(err => {

      }).finally(() => {


      })
    }
  }
}
</script>

<template>
  <div>
    <div v-if="cargandoReactivos" class="mt-5 mb-5">
      <v-progress-circular
          color="primary"
          indeterminate
      ></v-progress-circular>
      <strong class="ml-3">Cargando reactivos</strong>, por favor espere...
    </div>
    <div v-if="!cargandoReactivos">
      <preguntasexamen class="mt-5" :examen_alumno_id="examen_alumno_id" :preguntas="preguntas" :examen_id="examen_id"></preguntasexamen>
      <div class="text-right" v-if="responsePager.current_number_question < responsePager.total_questions">
        <v-btn @click="nextQuestion" title="Avanzar a la siguiente pregunta" class="primary"><v-icon large fab>mdi-chevron-right</v-icon></v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import preguntasexamen from "../views/PreguntasExamen";
export default {
  name: 'examenpaginado',
  props: ['examen_id', 'examen_alumno_id', 'current_question'],
  components: {preguntasexamen},
  data() {
    return {
      cargandoReactivos: true,
      // en realidad este se ocupa como una lista de objetos y se le pasa siempre 1 sola pregunta que es la actual del paginado para reutilizar el componente de PreguntasExamen.vue
      preguntas: [],
      current_page_question: 0,
      responsePager: 0
    }
  },
  mounted() {
    this.current_page_question = this.current_question
    this.getQuestionsPaginated( this.current_question )
  },
  methods: {
    nextQuestion (){
      Swal.fire({
        title: 'Atención',
        html: 'Una vez confirmada esta acción de pasar a la siguiente pregunta, no podrá volver contestar los reactivos anteriores',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Confirmar y continuar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.current_page_question = parseInt(this.current_page_question)
          this.current_page_question++
          this.$emit('current_question', ( this.current_page_question ) )
          this.getQuestionsPaginated( this.current_page_question )
        } else if (result.isDenied) {
          Swal.close()
        }
      })
    },
    getQuestionsPaginated ( current_page = 0 ){
      this.cargandoReactivos = true
      this.$http.get(`/api/evaluacionalumno/paged-questions/${this.examen_alumno_id}/${current_page}`)
      .then(res => {
        this.responsePager = res.data
      })
      .then(() => {
        this.preguntas = []
        this.preguntas = this.responsePager.current_question
        let string_paginacion = this.responsePager.current_number_question + "/" + this.responsePager.total_questions
        this.$emit('paginacion_preguntas', string_paginacion)
        setTimeout(() => {
          this.cargandoReactivos = false
        }, 2000)
      })
    }
  }
}
</script>

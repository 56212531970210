var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.es_alumno == 6)?_c('div',[_c('h1',[_vm._v("Bienvenido a NivelAndo")]),_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" A continuación se enlistan todos los eventos disponibles en su institución ")]),_c('v-divider'),_c('v-row',_vm._l((_vm.sesiones_aprende),function(sesion,i){return _c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"2"}},[_c('v-img',{attrs:{"height":"250","src":sesion.imagen}}),_c('v-card-text',[_c('div',{},[_vm._v(" Programado el "+_vm._s(sesion.fecha_consumo_inicio_formated)+" de "+_vm._s(sesion.hora_inicio)+" - "+_vm._s(sesion.hora_fin)+" ")]),_c('p',{staticClass:"display-1 text--primary"},[_vm._v(" "+_vm._s(sesion.nombre_sesion)+" ")]),_c('p',{staticClass:"blue--text"},[_vm._v(" Inscripciones disponibles del "+_vm._s(sesion.fecha_seleccion_inicio_formated)+" - "+_vm._s(sesion.fecha_seleccion_termino_formated)+" ")]),_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(sesion.descripcion_sesion)+" ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-5"},[(
                sesion.evento_disponible_inscripcion &&
                  sesion.evento_inscrito == 0
              )?_c('v-btn',{staticClass:"primary white--text",on:{"click":function($event){return _vm.inscribirse(sesion.evento_id, sesion.id)}}},[_vm._v("Inscribirse")]):_vm._e(),(
                sesion.evento_inscrito == 1 &&
                  sesion.evento_disponible_consumo == 1
              )?_c('v-btn',{staticClass:"primary white--text",on:{"click":function($event){return _vm.verContenido(sesion)}}},[_vm._v("Ir al evento ahora")]):_vm._e(),(
                sesion.evento_inscrito == 1 &&
                  sesion.evento_disponible_consumo == 0
              )?_c('p',{staticClass:"blue--text"},[_c('small',[_c('v-icon',{staticClass:"blue--text"},[_vm._v("mdi-information")]),_vm._v(" Usted está inscrito pero aún no llega la hora de consumir este evento, por favor vuelva cuando sea la hora de inicio")],1)]):_vm._e(),(_vm.loading)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1)],1)],1)}),1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clases},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Clases")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("Nueva Clase")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Agregar Clase")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":""}},[_c('v-text-field',{attrs:{"label":"Nombre"},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"0"}},[_c('v-text-field',{attrs:{"label":"Descripión"},model:{value:(_vm.descripcion),callback:function ($$v) {_vm.descripcion=$$v},expression:"descripcion"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.guardarClase(_vm.nombre,_vm.descripcion)}}},[_vm._v("Guardar")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogRequisito),callback:function ($$v) {_vm.dialogRequisito=$$v},expression:"dialogRequisito"}},[_c('div',{staticClass:"grey--text text-darken-1 mb-2"},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Detalles Clase: "+_vm._s(_vm.nombreClase))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"label":"Clase Precedente:","items":_vm.clasesPrecedente,"item-value":"id","item-text":"nombre","return-object":""},on:{"change":function($event){return _vm.setClasePrecedente()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idClasePrecedente),callback:function ($$v) {_vm.idClasePrecedente=$$v},expression:"idClasePrecedente"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"8","sm":"8"}},[_c('v-select',{attrs:{"label":"Clase requisito:","items":_vm.clasesRequisito,"item-value":"id","item-text":"nombre","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idClaseRequisito),callback:function ($$v) {_vm.idClaseRequisito=$$v},expression:"idClaseRequisito"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","sm":"4"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.setClaseRequisito()}}},[_vm._v("Agregar")])],1),_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headerRequisitos,"items":_vm.requisitos},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","title":"Eliminar Clase Requisito","color":"red"},on:{"click":function($event){return _vm.deleteClaseRequisito(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeRequisito}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.guardarClase(_vm.nombre,_vm.descripcion)}}},[_vm._v("Guardar")])],1)],1)],1)])],1)]},proxy:true},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.nombre,"regular":"","dense":""},on:{"change":function($event){return _vm.actualizarClaseNombre(item.nombre,item.id)}},model:{value:(item.nombre),callback:function ($$v) {_vm.$set(item, "nombre", $$v)},expression:"item.nombre"}})]}},{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"value":item.descripcion,"rows":1,"dense":""},on:{"change":function($event){return _vm.actualizarClaseDescripcion(item.descripcion,item.id)}},model:{value:(item.descripcion),callback:function ($$v) {_vm.$set(item, "descripcion", $$v)},expression:"item.descripcion"}})]}},{key:"item.activo",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"value":item.activo},on:{"change":function($event){return _vm.actualizarClaseActiva(item.activo,item.id)}},model:{value:(item.activo),callback:function ($$v) {_vm.$set(item, "activo", $$v)},expression:"item.activo"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","title":"Eliminar Clase","color":"red"},on:{"click":function($event){return _vm.deleteClase(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green","title":"Requisitos"},on:{"click":function($event){return _vm.getClasePrecedente(item)}}},[_vm._v(" mdi-clipboard-text-outline ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
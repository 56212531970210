<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-icon large class="mr-2" color="green">mdi-checkbox-marked-outline</v-icon> <h3>Usuarios en Línea</h3>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-title>
      <v-card elevation="2">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table v-model="selected" :headers="headers" :items="users" :search="search">

        </v-data-table>
      </v-card>
    </v-card>




  </v-container>

</template>

<script>
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default {

  data: () => ({
    search: '',
    users:[],
    selected: [],
    headers: [
        {text: 'Id',align: 'start',sortable: true,value: 'id'},
        {text: 'Usuario',align: 'start',sortable: true,value: 'nombre'},
        {text: 'Email',                     value: 'email'},
        {text: 'Grupo',                     value: 'grupo'},
        {text: 'IP',                        value: 'ip'},
        {text: 'Última actividad',          value: 'last_activity'},
        {text: 'Agente',                    value: 'user_agent'},

    ],
    email: '',
  }),
  created () {
    this.consultarExamen()
  },
  mounted() {
  },
  methods: {
    consultarExamen(){
      let url = '/api/user-online'
      this.$http.get(url)
          .then((response) => {
            this.users = response.data;
          })
          .catch(function (error){
                console.log(error)
              }
          )
    }
  }
}
</script>

<template>
  <v-container>
    <v-card
        class="mx-auto"
    >
      <v-card-title class="black--text  darken-4">
        <v-icon large class="mr-2" color="green">mdi-checkbox-marked-outline</v-icon>
        Banco de Preguntas
        <v-spacer></v-spacer>
        <v-btn
            color="white"
            class="text--primary"
            fab
            small
            @click="callCreadorDeReactivos"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
<!--        <v-row dense >
          <v-col cols="12">
            <v-layout justify-center>
              <v-card-actions>
                <v-btn
                    color="primary"
                    @click="setCargaAcademica"
                    :disabled="loadTable"
                >
                  <span>Asignar</span>
                </v-btn>
              </v-card-actions>
            </v-layout>
          </v-col>
        </v-row>-->
        <v-row dense>
          <v-col cols="12" >
            <div class="mt-2" v-if="loadTable" v-model="loadTable">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              Actualizando información, espere por favor...
            </div>
            <v-data-table :headers="headers" :items="banco" :search="search" class="elevation-1">
              <template v-slot:item.descripcion="{ item }">
                <div v-html="item.descripcion"></div>
              </template>
              Descripcion
              <template v-slot:top>
                <v-text-field
                    class="mx-4"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                >
                </v-text-field>
              </template>
              <template v-slot:item.tipovisibilidad="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    color="orange"
                    v-if="item.tipovisibilidad==1"
                    title="Privado"
                >
                  mdi-account
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    color="blue"
                    v-if="item.tipovisibilidad==2"
                    title="Institucional"
                >
                  mdi-web
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="text-center">
                  <v-btn
                      fab elevation="1"
                      color="green"
                      x-small
                  >
                    <v-icon small color="white" title="Editar reactivo" @click="editReactivo(item)">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                      fab elevation="1"
                      color="red"
                      x-small
                      v-if="item.tipovisibilidad!=2"
                      class="ml-1"
                  >
                    <v-icon small @click="deleteReactivo(item)" title="Eliminar Reactivo" color="white">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import reactivounico from './reactivo_unico.vue'
import reactivorellenar from './reactivorellenar.vue'

export default {
  components: {
    reactivounico,
    reactivorellenar
  },
  data: () => ({
    loadTable: true,
    show_youtube_info: 0,
    search: '',
    nombre: '',
    idTipoReactivo: '',
    escuela_id: 1,
    refrescar_tabla: 0,
    abreviatura: '',
    tiporeactivo: [],
    banco: [],
    item: [],
    historial_importaciones: [],
    respuestasTotal: 0,
    headers: [
      {text: 'Id', align: 'start', sortable: true, value: 'id'},
      // {text: 'Escuela',align: 'start',sortable: false,value: 'escuela.escuela_concentrado.nombre'},
      {text: 'Grado', value: 'grado.grado_concentrado.nombre', align: 'center'},
      //{ text: 'Área', value: 'area.area_concentrado.nombre'},
      {text: 'Asignatura', value: 'asignatura.asignatura_concentrado.nombre', align: 'center'},
      {text: 'Descripcion', value: 'descripcion', align: 'justify'},
      {text: 'Tipo de Pregunta', value: 'tiporeactivo.tiporeactivo_concentrado.nombre', align: 'center'},
      {text: 'Tipo Visibilidad', value: 'tipovisibilidad', align: 'center'},
      {text: 'Dificultad', value: 'nivelreactivo', align: 'center'},
      {text: 'Acciones', value: 'actions', align: 'center'},
    ],
    headers_rpt: [
      {text: 'Nombre', value: 'nombre_documento_importado'},
      {text: "Estatus", value: "estatus"},
      {text: "Preguntas importadas", value: "reactivos_importados", align: "center"},
      {text: 'Acciones', value: 'actions'},
    ],
    overlay: false,
    cargando_historial: false,
    absolute: true,
    isModalVisible: false,
    dialog: false,
    dialogImportar: false,
    dialogAyuda: false,
    tipoaccion: '',
    idNivel: '',
    idGrado: '',
    idArea: '',
    idAsignatura: '',
    niveles: [],
    grados: [],
    areas: [],
    asignaturas: [],
    buscar_contador: 0,
    checkstatus: null,
    totalRespuestas: 0,
    totalSeleccionadas: 0

  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTipoReactivo()
      this.getReactivos()
      this.getCatalogos()
    },
    buscarImportaciones(){
      this.buscar_contador++
      if (this.buscar_contador == 1 && (this.idNivel.id > 0) && (this.idGrado.id > 0) && (this.idArea.id > 0) && (this.idAsignatura.id > 0)) {
        this.checkstatus = setInterval(() => {
          console.log("actualizando historial...")
          this.buscarImportaciones();
          this.getReactivos();
        }, 10000)
      }
      this.$http.post('/api/importarreactivos', {
        'idNivel': this.idNivel.id,
        'idGrado': this.idGrado.id,
        'idArea': this.idArea.id,
        'idAsignatura': this.idAsignatura.id
      }).then((response) => {
        console.log(response.data)
        this.historial_importaciones = response.data
      }).catch(function (error) {
        console.log(error)
      })
    },
    callCreadorDeReactivos(){
      this.$router.push({path: '/crearReactivos'})
    },
    importarReactivos(item){
      this.$http.post('/api/importarreactivos/get-preguntas', {
        'sheetId': item.sheetId,
      })
          .then((response) => {
            this.buscarImportaciones(); //Actualizar el dataTable de Importaciones
            this.getReactivos();        //Actualizar el Banco de Reactivos
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Importando Preguntas...',
              showConfirmButton: false,
              timer: 1500
            })
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    setSelected(value) {
      this.idTipoReactivo = value;
      this.dialog = true;
      this.tipoaccion = 1;
    },
    showModal() {
      this.dialog = true;
    },
    showImportar() {
      this.dialogImportar = true;
      this.cargando_historial = true;

    },
    closeModal() {
      //alert("respuestas seleccionadas" + this.totalSeleccionadas)
      if (this.totalRespuestas <= 0) {
        Swal.fire(
            'Atención',
            'Debe capturar una opción de respuesta.',
            'warning'
        )
      } else if (this.totalSeleccionadas <= 0) {
        Swal.fire(
            'Atención',
            'Debe seleccionar una respuesta correcta.',
            'warning'
        )
      } else {
        //this.totalSeleccionadas = 0

        this.getReactivos()
        this.dialog = false
      }

    },
    closeAyuda() {
      this.dialogAyuda = false
    },
    closeImportar() {
      this.dialogImportar = false
      clearInterval(this.checkstatus);
      this.buscar_contador = 0;
    },
    consultar(id) {
      this.idTipoReactivo = id;
      if (this.idTipoReactivo == 1 || this.idTipoReactivo == 2 || this.idTipoReactivo == 3) {
        //this.isModalVisible = true;
        this.showModal();
        //this.$router.push({ path: '/reactivounico', query: { tiporeactivo: this.idTipoReactivo,tipoaccion:1} })
      } else if (this.idTipoReactivo == 6)
        this.showModal();
    },
    actualizarActivo(item) {
      if (item.alumnocontactado == null) {
        item.activo = 0;
      } else
        item.activo = 1;
      //console.log('alumnoContactado actualizado: ' + alumnocontactado + 'id:' + id)
      this.overlay = true;
      this.$http.post('/api/sistemaseducativos', {

        'id': item.id,
        'atributo': 2,
        'alumnocontactado': item.alumnocontactado,

      })
          .then((response) => {
            // console.log(response.data)
            this.overlay = false;
          })
          .catch(function (error) {
            // console.log(error)
            this.overlay = false;
          })

    },
    deleteReactivo(item) {


      Swal.fire({
        title: 'Atención',
        html: 'Una vez que confirme, usted deberá actualizar las calificaciones finales de los exámenes donde este reactivo se haya preguntado. <br><a target="_blank" href="/calcular-calificaciones">Ir al módulo de cálculo de calificaciones</a>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {

          //this.overlay = true;
          let url = `/api/reactivos/${item.id}`
          this.$http
              .delete(url)
              .then((response) => {
                this.getReactivos()
              })
              .catch(function (error) {
                console.log(error)
                //this.overlay = false;
              }).finally(() => {
            Swal.fire(
                'Excelente',
                'El reactivo se eliminó correctamente',
                'success'
            )
          })


        }
      })

      /*      var refs = ''
            refs = confirm('¿Deseas eliminar la respuesta: ' + item.descripcion + '?')
            if (refs) {

            }
            else {
              // cancel
            }*/
    },
    editReactivo(item) {
      this.item = item;
      this.dialog = true;
      this.idTipoReactivo = item.tiporeactivo.tiporeactivo_concentrado.id;
      this.tipoaccion = 2;
      if (this.item.youtube_video_id) {
        this.show_youtube_info = 1
      }
      //console.log("item")
      //console.log(item)
      //this.$router.push({ path: '/reactivounico', query: { tipoaccion:2,escuela_id: item.escuela.escuela_concentrado.id,nivel_id:item.nivel.nivel_concentrado.id,grado_id:item.grado.grado_concentrado.id,area_id:item.area.area_concentrado.id,asignatura_id:item.asignatura.asignatura_concentrado.id,tiporeactivo_id:item.tiporeactivo.tiporeactivo_concentrado.id,descripcion:item.descripcion,instrucciones:item.instrucciones,id:item.id}})
    },
    getReactivos() {
      let url = `/api/reactivos/getReactivos`
      this.$http.get(url)
          .then((response) => {
            this.banco = response.data
            this.loadTable = false
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getCatalogos() {
      let url = `/api/niveleseducativos/get-NivelEducativoAreaConocimiento`
      this.$http.get(url)
          .then((response) => {
            //console.log(response)
            this.niveles = response.data.nivel_educativo;
            this.areas = response.data.area_conocimiento;
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    listener(indicador) {
      if (indicador)
        this.getReactivos();

    },
    listenerTotalRespuestas(event) {
      this.totalRespuestas = event;
    },
    listenerRespuestasSelect(val) {
      this.totalSeleccionadas = val;
    },
    totalrespuestas(total) {
      this.respuestasTotal = total;
    },
    consultarNivelEducativo() {
      let url = '';
      url = `/api/grados?idnivel=${this.idNivel.id}`
      this.$http.get(url)
          .then((response) => {
            this.grados = response.data;
            if (this.tipoaccion == 2) {
              this.idGrado = this.grados.find(item => item.id == this.grado_id)
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    consultarArea() {
      let url = '';
      url = `/api/asignaturas?idArea=${this.idArea.id}`
      this.$http.get(url)
          .then((response) => {
            this.asignaturas = response.data;
            if (this.tipoaccion == 2) {
              this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getTipoReactivo() {
      let url = '/api/tiporeactivo'
      this.$http.get(url)
          .then((response) => {
            this.tiporeactivo = response.data;
          })
          .catch(function (error) {
                console.log(error)
              }
          )
    }
  },

  mounted() {

  }
}
</script>


<link scoped src="@/assets/styles/dc.css"></link>

<template>
<v-container>

  <script type="application/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script type="application/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
  <script type="application/javascript" src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>

   <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

  <body style="background-color: #ffffff;">
  <div class="container-fluid">
    <div class="row my-5">
      <div class="col-lg-12">
        <h1 class="text-center title_color">Estadísticas <i class="fa fa-bar-chart" aria-hidden="true"></i></h1>
      </div>
    </div><!--row-->
    <div class="row mt-5">

      <div class="col-lg-5">

        <div class="card shadow" style="position: fixed; left:16%" >
          <div class="card-body">
            <h4 class="card-title text-center title_color"> Argumentos </h4>
            <h6 class="card-subtitle mb-2 text-muted">Elegir opciones</h6>

            <form action="#">
              <div class="form-group">
                <label for="sel_param1">Aplicación</label>
                <select v-model="param1" class="form-control" id="sel_param1">
                  <option value="" selected disabled>Elegir</option>
                  <option value="classroom">classroom</option>
                  <option value="drive">drive</option>
                  <option value="accounts">accounts</option>
                  <option value="docs"> docs</option>
                  <option value="meet"> meet</option>
                  <option value="gmail"> gmail</option>
                </select>
              </div>
              <br/>
              <div class="form-group">
                <label for="sel_param1">Dominio</label>

                <select v-model="dominio" class="form-control" id="dominio">
                  <option value="" selected disabled>Elegir</option>
                  <option value="cambridge.edu.co">cambridge.edu.co</option>
                  <option value="colegioeinstein.cl">colegioeinstein.cl</option>
                  <option value="colegiomavila.cl">colegiomavila.cl</option>
                  <option value="colegiomontedeasis.cl"> colegiomontedeasis.cl</option>
                  <option value="cormumel.cl"> cormumel.cl</option>
                  <option value="cpfd.cl"> cpfd.cl</option>

                  <option value="daemcalbuco.cl"> daemcalbuco.cl</option>
                  <option value="dpastora.cl"> dpastora.cl</option>
                  <option value="educangol.cl"> educangol.cl</option>
                  <option value="encuentro.cl"> encuentro.cl</option>

                  <option value="heinrich.cl"> heinrich.cl</option>
                  <option value="icel.cl"> icel.cl</option>
                  <option value="itjqro.edu.mx"> itjqro.edu.mx</option>
                  <option value="laarboleda.edu.co"> laarboleda.edu.co</option>

                  <option value="liceodelvalle.edu.mx"> liceodelvalle.edu.mx</option>
                  <option value="lla.cl"> lla.cl</option>
                  <option value="lml.cl"> lml.cl</option>
                  <option value="lpsa.cl"> lpsa.cl</option>

                </select>
              </div>
              <br/>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="date_from">Desde</label>
                    <input v-model="date_from" type="date" class="form-control" id="date_from" placeholder="ej 03/09/2020">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="date_to">Hasta</label>
                    <input v-model="date_to" type="date" class="form-control" id="date_to" placeholder="ej 03/09/2020">
                  </div>
                </div>
              </div>
              <div>

                <div style="float: left" >
                  <button type="button"
                          class="btn btn-lg btn-secondary"
                          @click="clean()"
                  >
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                </div>
                <div style="float: right" >
                  <button type="button"
                          class="btn btn-lg btn-secondary"
                          @click="generate()"
                  >Generar</button>
                </div>

              </div>
            </form>


          </div>
        </div>
      </div>
      <div class="col-lg-7" >

        <div class="card shadow">
          <div class="card-body">
            <h5 class="card-title title_color">Barras Horizontales</h5>
            <div id="RowChart"></div>
          </div><!--card-body-->
        </div>

        <br>
        <div class="card shadow">
          <div class="card-body">
            <h5 class="card-title title_color">Tabla</h5>
            <div id="paging">
              Mostrando <span id="begin"></span>-<span id="end"></span> de <span id="size"> {{ size }}  </span> <span id="totalsize"></span>
              <input id="last" class="btn" type="Button" value="Atrás" @click="last()" />
              <input id="next" class="btn" type="button" value="Siguiente" @click="next()"/>
            </div>
                  <table id="chartTable" class="table table-striped table-hover"></table>
          </div><!--card-body-->
        </div>
      </div>

    </div><!--row-->

  </div><!--container-->

  </body>




 </v-container>
</template>



<script>

import * as d3 from "d3";
import * as crossfilter from "crossfilter";
import * as dc from "dc";

  export default {
    data:() => ({
        param1: "",
        dominio: "",
        date_from:"",
        date_to:"",
        Api:process.env.VUE_APP_ESTADISTICAS,
        petitionUrl:"",
        ofs : 0,
        pag : 17,
        end: 0,
        chartTable: null,
        ndx:null,
        size: null
    }),
    methods:{
      clean(){
        this.date_to="";
        this.date_from="";
        this.dominio ="";
        this.param1="";
        this.petitionUrl="";
        this.ofs = 0;
        this.pag = 17;
        this.end = 0;
        this.chartTable= null;
        this.ndx=null;
        this.size= null;


        this.grafica1();
        this.grafica2();
        this.grafica3();
      },

      generate() {
        this.petitionUrl="";
        if(this.param1 == "" && this.date_from == "" && this.date_to=="" && this.dominio =="")
        {
          console.log("ROUTA 1");
          this.petitionUrl=this.Api+"get";
        }else if(this.param1 != "" && this.date_from == "" && this.date_to=="" && this.dominio =="")
        {
          console.log("ROUTA 2");
          this.petitionUrl=this.Api+"get/app/"+this.param1;
          console.log(this.petitionUrl);
        }else if(this.param1 == "" && this.date_from != "" && this.date_to!="" && this.dominio =="")
        {
          console.log("ROUTA 3");
          this.petitionUrl=this.Api+"get/dates/"+this.date_from+"/"+this.date_to;
        }else if(this.param1 == "" && this.date_from != "" && this.date_to=="" && this.dominio =="")
        {
          console.log("ROUTA 4");
          this.petitionUrl=this.Api+"get/dateFrom/"+this.date_from;
        }else if(this.param1 == "" && this.date_from == "" && this.date_to!="" && this.dominio =="")
        {
          console.log("ROUTA 5");
          this.petitionUrl=this.Api+"get/dateTo/"+this.date_to;
        }else if(this.param1 != "" && this.date_from != "" && this.date_to!="" && this.dominio =="")
        {
          console.log("ROUTA 6");
          this.petitionUrl=this.Api+"get/app/dateFrom/dateTo/"+this.param1+"/"+this.date_from+"/"+this.date_to;
        }else if(this.param1 != "" && this.date_from != "" && this.date_to=="" && this.dominio =="")
        {
          console.log("ROUTA 7");
          this.petitionUrl=this.Api+"get/app/dateFrom/"+this.param1+"/"+this.date_from;
        }else if(this.param1 != "" && this.date_from == "" && this.date_to!="" && this.dominio =="")
        {
          console.log("ROUTA 8");
          this.petitionUrl=this.Api+"get/app/dateTo/"+this.param1+"/"+this.date_to;
        }
        else if(this.param1 == "" && this.date_from == "" && this.date_to=="" && this.dominio !="")
        {
          console.log("ROUTA 9");
          this.petitionUrl=this.Api+"get/domain/"+this.dominio;
        }
        else if(this.param1 != "" && this.date_from == "" && this.date_to=="" && this.dominio !="")
        {
          console.log("ROUTA 10");
          this.petitionUrl=this.Api+"get/app/domain/"+this.param1+"/"+this.dominio;
          console.log(this.petitionUrl);
        }
        else if(this.param1 != "" && this.date_from != "" && this.date_to=="" && this.dominio !="")
        {
          console.log("ROUTA 11");
          this.petitionUrl=this.Api+"get/app/domain/from/"+this.param1+"/"+this.dominio+"/"+this.date_from;
        }
        else if(this.param1 != "" && this.date_from == "" && this.date_to!="" && this.dominio !="")
        {
          console.log("ROUTA 12");
          this.petitionUrl=this.Api+"get/app/domain/to/"+this.param1+"/"+this.dominio+"/"+this.date_to;
        }
        else if(this.param1 != "" && this.date_from != "" && this.date_to!="" && this.dominio !="")
        {
          console.log("ROUTA 13");
          this.petitionUrl=this.Api+"get/app/domain/from/to/"+this.param1+"/"+this.dominio+"/"+this.date_from+"/"+this.date_to;
        }
        else if(this.param1 == "" && this.date_from != "" && this.date_to!="" && this.dominio !="")
        {
          console.log("ROUTA 14");
          this.petitionUrl=this.Api+"get/domain/from/to/"+this.dominio+"/"+this.date_from+"/"+this.date_to;
        }
        else if(this.param1 == "" && this.date_from != "" && this.date_to=="" && this.dominio !="")
        {
          console.log("ROUTA 15");
          this.petitionUrl=this.Api+"get/domain/from/"+this.dominio+"/"+this.date_from;
        }
        else if(this.param1 == "" && this.date_from == "" && this.date_to!="" && this.dominio !="")
        {
          console.log("ROUTA 16");
          this.petitionUrl=this.Api+"get/domain/to/"+this.dominio+"/"+this.date_to;
        }
        this.grafica1();
        this.grafica2();
        this.grafica3();
      }//generate

      ,grafica1()
      {
        var RowChart = new dc.RowChart("#RowChart");
        d3.json(this.petitionUrl).then(function(experiments) {
          experiments.forEach(function(x) {
            //  x.name = +x.cnt;
          });

          var ndx                   = crossfilter(experiments),
              fruitDimension        = ndx.dimension(function(d) {return d.name;}),
              sumGroup              = fruitDimension.group().reduceSum(function(d) {return d.cnt;});

          RowChart
              .width(608)
              .height(480)
              .x(d3.scaleLinear().domain([6,20]))
              .elasticX(true)
              .dimension(fruitDimension)
              .group(sumGroup)
              .render();
        });

      },//grafica1


      grafica2()
      {
/*
        var chart6 = new dc.LineChart('#LineChart');

        d3.json(this.petitionUrl).then(function(counts) {
          var ndx            = crossfilter(counts),
              fruitDimension = ndx.dimension(function(d) {return d.name;}),
              sumGroup       = fruitDimension.group().reduceSum(function(d) {return d.cnt;});

          chart6
              .width(608)
              .height(580)
              .x(d3.scaleBand())
              .xUnits(dc.units.ordinal)
              .brushOn(false)
              .elasticX(true)
              .xAxisLabel('Parameter')
              .yAxisLabel('Value')
              .dimension(fruitDimension)
              .group(sumGroup);

          chart6.render();
        });

*/
      },//grafica1

      grafica3()
      {
        this.chartTable     = new dc.DataTable("#chartTable");
        var piechart        = new dc.PieChart("#piechart");
        this.ndx;
        d3.json(this.petitionUrl).then((experiments) => {

          this.ndx = crossfilter(experiments);
          var fmt = d3.format('02d');
          var runDimension = this.ndx.dimension(function(d) {return [fmt(+d.cnt),fmt(+d.name)];}),
              experimentDimension = this.ndx.dimension(function(d) {return d.name;}),
              experimentGroup = experimentDimension.group().reduceCount();

          piechart.width(500).height(580)
              .innerRadius(100).externalLabels(50).externalRadiusPadding(50).drawPaths(true)
              .dimension(experimentDimension).group(experimentGroup)
              .legend(dc.legend())
              .controlsUseVisibility(true);

          this.chartTable
              .width(300)
              .height(480)
              .dimension(runDimension)
              .size(Infinity)
              .showSections(false)
              .columns(['cnt', 'name'])
              .sortBy(function (d) { return [fmt(+d.name),fmt(+d.cnt)]; })
              .order(d3.ascending)
              .on('preRender', this.update_offset)
              .on('preRedraw', this.update_offset)
              .on('pretransition', this.display);

          dc.renderAll();
        });
         //var ofs = 0, pag = 17;



      },//grafica3
      display() {
          var totFilteredRecs = this.ndx.groupAll().value();
          this.end = this.ofs + this.pag > totFilteredRecs ? totFilteredRecs : this.ofs + this.pag;
          d3.select('#begin')
              .text(this.end === 0? this.ofs : this.ofs + 1);
          d3.select('#end')
              .text(this.end);
          d3.select('#last')
              .attr('disabled', this.ofs-this.pag<0 ? 'true' : null);
          d3.select('#next').attr('disabled', this.ofs+this.pag>=totFilteredRecs ? 'true' : null);
          d3.select('#size').text(totFilteredRecs);
          if(totFilteredRecs != this.ndx.size()){
            d3.select('#totalsize').text("(filtered Total: " + ndx.size() + " )");
          }else{
            d3.select('#totalsize').text('');
          }
        },


      update_offset()
      {
        var totFilteredRecs = this.ndx.groupAll().value();
        this.end = this.ofs + this.pag > totFilteredRecs ? totFilteredRecs : this.ofs + this.pag;
        this.ofs = this.ofs >= totFilteredRecs ? Math.floor((totFilteredRecs - 1) / this.pag) * this.pag : this.ofs;
        this.ofs = this.ofs < 0 ? 0 : this.ofs;

        this.chartTable.beginSlice(this.ofs);
        this.chartTable.endSlice(this.ofs+this.pag);
      },

      next() {
        this.ofs += this.pag;
        this.update_offset();
        this.chartTable.redraw();
      },
      last() {
        this.ofs -= this.pag;
        this.update_offset();
        this.chartTable.redraw();
      }


    },

    mounted() {

      this.generate();

    }//mounted
  }
</script>
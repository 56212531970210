<template>

  <v-container fluid>
       <v-row>
          <v-col :cols="1"></v-col>
            <v-col :cols="10">     
                    <v-card elevation="4">
                    <v-card-title>
                        <v-icon large class="mr-2" color="purple">
                            mdi mdi-file-document
                        </v-icon>
                        <h3>Mi panel de encuestas</h3>
                    </v-card-title>
                       <v-card-text>
                    <v-row>
                      <v-col :cols="10">
                              <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Búscar"
                                single-line
                                hide-details
                              ></v-text-field>
                      </v-col>
                        <v-col :cols="2">

                      <router-link style="text-decoration: none; color: inherit;"  
                      to="/encuestas-create">
                        <v-btn  
                                color="primary"
                                  dark
                                  class="my-2 ml-auto "
                                >
                            Nueva
                        </v-btn>
                      </router-link>


                        </v-col>
                    </v-row>

                      <v-data-table
                        :headers="headers"
                       
                        :search="search"
                        :items="resultSet"
                        :items-per-page="7"
                        class="elevation-1 mt-5"
                        loading-text="Cargando..."
                        
                      >
                        <template v-slot:item.duplicar="{ item }">
                           <v-btn
                               @click="duplicar(item)"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="blue"
                            >
                              <v-icon dark>
                                mdi-content-duplicate
                              </v-icon>
                            </v-btn>
                                             </template>
                        <template v-slot:item.editar="{ item }">
                          <router-link style="text-decoration: none;" :to="`/encuestas-edit/${item.id}`">
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="green"
                            >
                              <v-icon dark>
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                           </router-link>
                        </template>
                        <template v-slot:item.eliminar="{ item }">
                           <v-btn
                               @click="deleteItem(item)"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="red"
                            >
                              <v-icon dark>
                                mdi-delete
                              </v-icon>
                            </v-btn>

                        </template>

                       <template v-slot:item.activar="{ item }">
                        
                            <v-switch
                            v-model="item.ready"
                            @change="encuestaActivar(item)"
                             ></v-switch>

                        </template>
                      
                      </v-data-table>



                       </v-card-text>
                    </v-card>
            </v-col>
            <v-col :cols="1"></v-col>
       </v-row>
    
  </v-container>

</template>

<script>
export default {
    data(){
        return{
                resultSet:[],
                headers: [
                   {  text: 'Id', 
                      align: 'start', 
                      value: 'id' 
                   }
                  ,{
                    text: 'Encuesta',
                    value: 'nombre'
                  }
                 ,{
                    text: 'Fecha',
                    value: 'updated_at'
                  },
                  { text: 'Eliminar',               
                    sortable: false,    
                    value: 'eliminar',    
                    align:'center' 
                  },
                  { text: 'Editar',
                    sortable: false,    
                    value: 'editar',      
                    align:'center'
                  },
                  { text: 'Duplicar',
                    sortable: false,    
                    value: 'duplicar',      
                    align:'center'
                  },
                  { text: 'Activar',
                    sortable: false,    
                    value: 'activar',      
                    align:'center'
                  },
                 
                ],
              
                search: '',
                radio1:'',
                id          :'',
                evento_id   :'',
        }
    },
    mounted(){
         this.getData();
    },
    methods:{

      encuestaActivar(item) {

          this.$http.post('/api/ready-encuesta', {
                  'encuesta_id': item.id,
                  'switch' :    ((item.ready) ? 1:0)
              }).then(response => {
                
                    if(response.data.code == 17){
                      Swal.fire(response.data.message, '', 'success')
                    }else if(response.data.code == 16){
                      Swal.fire(response.data.message, 'Primero, asigne esta encuesta a algún evento antes de poder activarla', 'error')
                    }else if(response.data.code == 18){
                      Swal.fire(response.data.message, '', 'success')
                    }
          })
          .catch(error => {
            return
          })          

    
   
       
       
       },//encuestaActivar


duplicar(item){
   let index = this.resultSet.indexOf(item);
      Swal.fire({
        title: '¿Está seguro que desea DUPLICAR esta encuesta?  ',
        //showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        
        if (result.isConfirmed) {
        const url = `/api/duplicate-encuesta/${item.id}`;
          this.$http.get(url)
          .then(response => {
                 this.getData();
          })
          .catch(error => {
            return
          })          
          Swal.fire('Encuesta duplicada!  ', '', 'success')
        } else if (result.isDenied) {
          return
        }
      })


}
    ,deleteItem(item) {
      let index = this.resultSet.indexOf(item);
      Swal.fire({
        title: '¿Está seguro que desea eliminar esta encuesta? ',
        //showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        
        if (result.isConfirmed) {
        /*
          this.$http.post('/api/aprende/curriculas/' + item.id, {
            _method : 'DELETE'
          })
          */
        const url = `/api/delete-encuesta/${item.id}`;
          this.$http.get(url)
          .then(response => {
            console.log("entra a success");
            this.resultSet.splice(index, 1);
          })
          .catch(error => {
            console.log("entra a error");
              
          })
          
          Swal.fire('ELiminado correctamente!', '', 'success')
        } else if (result.isDenied) {
           console.log("cancelado!")
        }
      })
    }

    ,getData(){
                  this.loading = true;
                  this.$http.get("/api/get-encuestas")
                  .then((response) => {
                    this.resultSet = response.data;
                  })
      this.loading = false;
        }
    }

}
</script>

<style scoped>
    #col-save{
        text-align: right !important;

    }
</style>
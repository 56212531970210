<template>
    <v-container>
        <template>
            <v-row justify="center">
                <v-dialog v-if="dialogIndicador" :value="true" @input="dialogIndicador = false" persistent max-width="790">
                    <v-card elevation="2" class="mx-auto">
                        <v-card-title>
                            <v-icon large class="mr-2" color="green">mdi-share-variant</v-icon><h3>Material</h3>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-row >
                                    <v-col cols="12" sm="12">

                                        <v-text-field
                                            label="Nombre"
                                            v-model="nombre_material"
                                            v-bind:value="nombre_material"
                                            outlined
                                        ></v-text-field>
                                        <v-textarea
                                            label="Instrucciones:"
                                            v-model="instrucciones"
                                            :rows="2"
                                            outlined
                                            id="txttitulo"
                                        ></v-textarea>
                                        <template>
                                            <v-select
                                                label='Tipo de Material:'
                                                v-model='tipo_material'
                                                :items='tipomaterial'
                                                item-value='id'
                                                item-text='nombre_tipo_material'
                                                outlined
                                                return-object
                                            >
                                                <template slot='selection' slot-scope='{ item }'>
                                                    {{ item.nombre_tipo_material }}
                                                </template>
                                                <template slot='item' slot-scope='{ item }'>
                                                    {{ item.nombre_tipo_material }}
                                                </template>
                                            </v-select>
                                        </template>
                                        <v-text-field
                                            label="URL Documento"
                                            v-model="ruta_documento"
                                            v-bind:value="ruta_documento"
                                            outlined
                                        ></v-text-field>
                                        <v-text-field
                                            label="Orden de visualización"
                                            v-model="orden_visualizacion"
                                            v-bind:value="orden_visualizacion"
                                            outlined
                                        ></v-text-field>

                                    </v-col>
                                </v-row>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-btn @click="guardarMaterial" small>
                                        {{txtboton}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="closeImportar()"
                            >
                                Cerrar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <v-card elevation="2" class="mx-auto">
            <v-card-title>
                <v-icon large class="mr-2" color="green">mdi-share-variant</v-icon><h3>Materiales</h3>
                <v-breadcrumbs :items="items" large ></v-breadcrumbs>
                <v-spacer></v-spacer>

            </v-card-title>
            <v-card elevation="2">
                <v-card-title>
                    <v-row
                        align="center"
                        justify="space-around"

                    >
                        <v-btn
                            color="green"
                            small
                            @click="showMaterial()"
                            title="Crear Material"
                        >
                            <v-icon  small color="white" >mdi-share-variant</v-icon>
                        </v-btn>
                    </v-row>
                </v-card-title>
            </v-card>
            <v-card elevation="2">
                <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
                <v-data-table :headers="headers" :items="materiales" class="elevation-1">
                  <template v-slot:item.estatus_visibilidad="{item}">
                    <v-switch
                        @change="visibilidadMaterialObjetivo(item)"
                        v-model="item.estatus_visibilidad"
                        label=""
                    ></v-switch>
                  </template>
                  <template v-slot:item.instrucciones="{item}">
                    <span title="Para ver las instrucciones completas, favor de visualizar los detalles del material">{{ item.instrucciones.substr(0,50) }}...</span>
                  </template>
                    <template v-slot:item.actions="{ item }">
                        <div class="text-center">
                            <v-btn
                                fab elevation="2"
                                color="green"
                                small
                                x-small
                                @click="editMaterial(item)"
                                style="margin-right: 10px"
                                title="Editar Material"
                            >
                                <v-icon small color="white">mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn
                                fab elevation="2"
                                color="red"
                                small
                                x-small
                                style="margin-right: 10px"
                                @click="deleteMaterial(item)"
                                title="Eliminar Material"
                            >
                                <v-icon small color="white">mdi-delete</v-icon>
                            </v-btn>

                        </div>

                    </template>
                </v-data-table>

            </v-card>
        </v-card>
    </v-container>
</template>

<script>
export default {

    data:() => ({
        opacity: 0.46,
        search:'',
        zIndex: 5,
        nombre:'',
        idTipoReactivo:'',
        escuela_id:1,
        refrescar_tabla:0,
        abreviatura:'',
        tiporeactivo:[],
        banco:[],
        item:[],
        items:[{
            text: 'Objetivos',
            disabled: false,
            href: '/dashboardobjetivos',
        },
            {
                text: 'Cargando...',
                disabled: true,
            }
        ],
        materiales:[],
        historial_importaciones:[],
        snack: false,
        snackColor: '',
        snackText: '',
        respuestasTotal:0,
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        headers: [
            {text: 'Id',align: 'start',             value: 'id',sortable: true,},
            { text: 'Nombre',                       value: 'nombre_material',sortable: true},
            { text: 'Instrucciones',                value: 'instrucciones' },
            { text: 'Tipo de material',             value: 'tipomaterial_concentrado.nombre_tipo_material' },
            { text: 'Ruta documento',               value: 'ruta_documento' },
            { text: 'Orden de visualización',       value: 'orden_visualizacion',sortable: true },
            // { text : 'Visibilidad', value : 'estatus_visibilidad' },
            { text: 'Acciones',                     value: 'actions', sortable: true , align: 'center'},
        ],
        overlay: false,
        absolute:true,
        isModalVisible: false,
        dialog: false,
        dialogIndicador: false,
        dialogAyuda:false,
        tipoaccion:'',
        buscar_contador:0,
        checkstatus : null,
        clave_indicador:'',
        txtboton:'',
        estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
        idMaterial:0,
        idObjetivo:0,
        nombre_objetivo:'',
        nombre_material:'',
        modolectura:false,
        instrucciones:'',
        tipo_material: 0,        tipomaterial: [],        tipo_material_id: '',
        ruta_documento:'',
        orden_visualizacion:0,

    }),
    created () {
        //this.init();

    },
    methods: {
      visibilidadMaterialObjetivo(material) {
        this.$http.post("/api/aprende/materiales/estatus-material-objetivo", {
          material : material
        })
        .then(response => {

        })
        .catch(error => {

        })
      },
        init () {
            //obtiene los reactivos filtrados por el usuario actual.
        },
        actualizarActivo(item){
            if(item.alumnocontactado==null){
                item.activo = 0;
            }
            else
                item.activo = 1;
            //console.log('alumnoContactado actualizado: ' + alumnocontactado + 'id:' + id)
            this.overlay = true;
            this.$http.post('/api/sistemaseducativos', {

                'id':               item.id,
                'atributo':         2,
                'alumnocontactado':  item.alumnocontactado,

            })
                .then((response) => {
                    // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                    // console.log(error)
                    this.overlay = false;
                })

        },
        closeModal() {
            this.dialog = false
        },
        closeAyuda(){
            this.dialogAyuda =  false
        },
        closeImportar(){
            this.nombre_indicador   =   '';
            this.clave_indicador    =   '';
            this.descripcion        =   '';
            this.txtboton           =   '';
            this.dialogIndicador    =   false
            this.modolectura        =   false;
        },
        consultar(id){
            this.idTipoReactivo = id;
            if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
                this.showModal();
            }
            else if (this.idTipoReactivo==6)
                this.showModal();
        },
        consultarNivelEducativo(){
            let url = '';
            url = `/api/grados?idnivel=${this.idNivel.id}`
            this.$http.get(url)
                .then((response) => {
                    this.grados = response.data;
                    if(this.tipoaccion==2 ) {
                        this.idGrado = this.grados.find(item => item.id == this.grado_id)
                    }
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        consultarArea(){
            let url = '';
            url = `/api/asignaturas?idArea=${this.idArea.id}`
            this.$http.get(url)
                .then((response) => {
                    this.asignaturas = response.data;
                    if(this.tipoaccion==2 ){
                        this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
                    }
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        deleteMaterial (item){
            Swal.fire({
                title: '¿Desea eliminar el indicador: '+ item.nombre_indicador + '?',
                showDenyButton: true,
                confirmButtonText: `Eliminar`,
                denyButtonText: `Cancelar`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    let url = `/api/aprende/materiales/${item.id}`
                    this.$http
                        .delete(url)
                        .then((response) => {
                            this.getMateriales()
                            Swal.fire('Material eliminado satisfactoriamente.', '', 'success')
                        })
                        .catch(function (error){
                            console.log(error)
                            //this.overlay = false;
                        })

                } else if (result.isDenied) {
                    //Swal.fire('Changes are not saved', '', 'info')
                }
            })
            //this.overlay = true;



        },
        editReactivo(item){
            this.item                 =   item;
            this.dialogIndicador       =   true;
            this.idTipoReactivo       =   item.tiporeactivo.tiporeactivo_concentrado.id;
            this.tipoaccion           =   2;
            this.estatusAccionTabla   =   2;
        },
        editMaterial(item) {
            this.idMaterial             =   item.id;
            this.nombre_material        =   item.nombre_material;
            this.tipo_material_id       =   item.tipo_material_id;
            this.instrucciones          =   item.instrucciones;
            this.ruta_documento         =   item.ruta_documento;
            this.orden_visualizacion    =   item.orden_visualizacion;

            this.dialogIndicador        =   true;
            this.txtboton               =   "Actualizar"
            this.estatusAccionTabla     =   2;
            this.modolectura            =   true;
            this.tipoaccion             =   2;
            this.getTipoMaterial()
        },
        getMateriales(){
            let url = `/api/aprende/materiales/`+this.idObjetivo
            //console.log(url);
            this.$http.get(url)
                .then((response) => {
                    this.materiales = response.data;
                    //console.log("materiales")
                    //console.log(this.materiales)
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        getTipoMaterial() {
            let url = '';
            url = '/api/aprende/tipomateriales'
            this.$http.get(url)
                .then((response) => {
                    this.tipomaterial = response.data;
                    if (this.tipoaccion == 2) {
                        if (this.tipo_material_id > 0)
                            this.tipo_material = this.tipomaterial.find(item => item.id == this.tipo_material_id)
                        else
                            this.tipo_material = 0
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        guardarMaterial(){
            if(this.nombre_material=='')
                confirm('Debe capturar el nombre del material')
            /*else if (this.clave_indicador==''){
                confirm('Debe capturar una Clave interna')
            }*/
            else{
                if(this.estatusAccionTabla==1){
                    if (this.tipo_material == 0)
                        this.tipo_material = 0
                    else
                        this.tipo_material = this.tipo_material.id
                    console.log("id tipo material")
                    console.log(this.tipo_material);
                    this.$http.post('/api/aprende/materiales', {
                        'nombre_material'       : this.nombre_material,
                        'instrucciones'         : this.instrucciones,
                        'tipo_material_id'      : this.tipo_material,
                        'ruta_documento'        : this.ruta_documento,
                        'orden_visualizacion'   : this.orden_visualizacion,
                        'objetivo_id'           : this.idObjetivo
                    })
                        .then((response) => {
                            if(response.data){
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Material guardado exitosamente.',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                                this.getMateriales() // llenar el arreglo
                                //this.materiales.unshift(response.data) //inserta el elemento al principio del Arreglo
                                this.nombre_indicador   = '';
                                this.clave_indicador    = '';
                                this.descripcion        = '';
                                this.objetivo_id        =   0;
                            }
                            else{
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: 'Indicador existente, verificar Clave interna.',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
                else if(this.estatusAccionTabla==2){
                    if (this.tipo_material == 0)
                        this.tipo_material = 0
                    else
                        this.tipo_material = this.tipo_material.id
                    this.$http.post('/api/aprende/materiales/'+ this.idMaterial, {
                        _method:    'PUT',
                        'nombre_material'       : this.nombre_material,
                        'instrucciones'         : this.instrucciones,
                        'tipo_material_id'      : this.tipo_material,
                        'ruta_documento'        : this.ruta_documento,
                        'orden_visualizacion'   : this.orden_visualizacion,
                    })
                        .then((response) => {
                            if(response.data){
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Material actualizado exitosamente.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.getMateriales()
                                //this.nombre_indicador = '';
                                //this.clave_indicador = '';
                            }
                            else{
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Material no actualizado. Verificar.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            }
        },
        showImportar(){
            this.dialogIndicador = true;
        },
        showModal() {
            this.dialog = true;
        },
        showMaterial() {
            this.dialogIndicador     = true;
            this.txtboton           = "Guardar";
            this.estatusAccionTabla =   1;
        },
    },
    mounted(){
        this.idObjetivo         =    atob(this.$route.query.idObjetivo);
        this.nombre_objetivo    =    atob(this.$route.query.nombre_objetivo);
        this.items[1].text      =    atob(this.$route.query.nombre_objetivo);
        //this.items[2].text      =    atob(this.$route.query.descripcion);
        this.getMateriales()
        this.getTipoMaterial()
    }
}
</script>
<template>
  <div>
    <h1 class="mb-3">Calificaciones finales de exámenes</h1>
    <p>Usuarios seleccionados: <strong>{{ alumnosSelccionados }}</strong></p>
    <v-btn class="mt-5 mb-5 mr-5 primary" @click="seleccionarTodos">Seleccionar a todos</v-btn>
    <v-btn class="mt-5 mb-5 mr-5 default" @click="deseleccionarTodos">Deseleccionar a todos</v-btn>
    <v-btn class="mt-5 mb-5 success" @click="sincronizarCalificaciones">Sincronizar ahora</v-btn>
    <v-data-table class="elevation-2 pa-5" :loading="loading" :headers="headers" :items="items" disable-pagination>
      <template v-slot:item.seleccionar="{item}">
        <v-checkbox v-model="item.seleccionado"></v-checkbox>
      </template>
      <template v-slot:item.examen_finalizado="{item}">
        {{ ( item.examen_finalizado == 0 ) ? 'No finalizado' : 'Finalizado' }}
      </template>
    </v-data-table>

    <v-dialog
        v-model="dialogLoading"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Sincronizando calificaciones, espere, por favor...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogLoading: false,
      headers: [
        { text: 'Seleccionar', value: 'seleccionar' },
        { text: 'Nombre alumno', value: 'name' },
        { text: 'Examen', value: 'titulo' },
        { text: 'Calificación obtenida', value: 'calificacion_final_obtenida' },
        { text: 'Nueva calificación', value: 'calificacion_recalculada' },
        { text: 'Examen finalizado', value: 'examen_finalizado' },
      ],
      items: [],
      loading: true
    }
  },
  mounted() {
    this.getExamenesCalcular()
  },
  computed: {
    alumnosSelccionados (){
      let seleccionados = 0
      for ( let key in this.items ) {
        if ( parseInt(this.items[key].seleccionado) == 1 ) {
          seleccionados++
        }
      }
      return seleccionados
    }
  },
  methods: {
    seleccionarTodos (){
      for ( let key in this.items ) {
        this.items[key].seleccionado = 1
      }
    },
    deseleccionarTodos (){
      for ( let key in this.items ) {
        this.items[key].seleccionado = 0
      }
    },
    sincronizarCalificaciones (){
      Swal.fire({
        title: 'Atención',
        text: "Usted está a punto de actualizar calificaciones finales, acepte para continuar con el proceso",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogLoading = true
          this.$http.post("/api/examenes/sincronizar-calificaciones", {
            estudiantes: this.items
          }).then(res => {
          }).catch(err => {

          }).finally(() => {
            this.dialogLoading = false
            Swal.fire({
              icon: 'success',
              title: 'Excelente',
              text: 'Calificaciones sincronizadas correctamente'
            });
            this.getExamenesCalcular()
            swal.close()
          })
        }
      })

    },
    getExamenesCalcular (){
      this.loading = true
      this.$http.get("/api/examenes/examenes-calificaciones").then(res => {
        this.items = res.data
      }).catch(err => {

      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

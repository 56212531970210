<template>
  <div class="container-contador-home">
    <div v-if="examen_finalizado==1&&mostrar_resultados==0" class="py-5 px-5"
         elevation="2">
      <v-progress-circular
          color="primary"
          indeterminate
      ></v-progress-circular>
      <strong class="ml-3">Finalizando examen y cargando resultados</strong>, por favor espere...
    </div>
    <div v-if="mostrar_resultados == 1">
      <component is="ExamenFinalizado" :calificacion_final="calificacion_final"
                 :examen_alumno_id="examen_alumno_id" :idExamen="idExamen" :revision_estudiante="revision_estudiante"
                 :show_from_user="parseInt(show_from_user)"/>
    </div>
    <div v-else>
      <div v-if="examen_dentro_de_tiempo==1 && examen_invalido_tiempo == 0">
        <div
            v-if="showLoading"
            class="py-5 px-5"
            elevation="2"
        >
          <v-progress-circular
              color="primary"
              indeterminate
          ></v-progress-circular>
          <strong class="ml-3">Cargando examen</strong>, espere, por favor...
        </div>
        <div class="">
          <v-card class="elevation-10 container-contador mx-auto">
            <div
                v-if="contador_iniciado==0"
                class="py-5 px-5"
                elevation="2"
            >
              <v-progress-circular
                  color="primary"
                  indeterminate
              ></v-progress-circular>
              <strong class="ml-3">Contabilizando tiempo</strong>, espere, por favor...
            </div>
            <CountDown
                :current_question="current_question"
                :detener_examen="detener_examen"
                @contador_iniciado="contador_iniciado = $event"
                @mostrar_resultados="mostrar_resultados = $event"
                :examen_alumno_id="examen_alumno_id"
                @examen_finalizado="examen_finalizado = $event"
                :examen_finalizado="examen_finalizado"
                :firebase_id="firebase_id"
                :idExamen="parseInt(idExamen)"
                :iniciar_contador="iniciar_contador"
                :paused="paused"
                :resume="resume"
                :show_from_user="parseInt(show_from_user)"
                :tiempo_actual_examen="configuracion_examen.tiempo_actual_examen"
                :tiempo_finalizado="tiempo_finalizado"
                :tiempo_minutos_responder="configuracion_examen.tiempo_minutos_responder"
                @paused="paused = $event"
                @resume="resume = $event"
                @showPuaseButton="showPuaseButton = $event"
                @showResumeButton="showResumeButton = $event"
                @student_stauts_exam="student_stauts_exam = $event"
            />
            <div v-if="permitir_pausa == 1 && contador_iniciado == 1">
              <v-btn
                  v-if="showPuaseButton && examen_finalizado == 0"
                  class="blue white--text"
                  large
                  v-on:click="paused = 1; showResumeButton = 1; showPuaseButton = 0; ">
                Pausar examen
              </v-btn>
              <v-btn
                  v-if="showResumeButton && examen_finalizado == 0"
                  class="ml-3 blue white--text"
                  large
                  v-on:click="resume = 1; showResumeButton = 0; showPuaseButton = 1;">
                Reanudar
              </v-btn>
              <v-btn
                  v-if="(examen_finalizado==0||tiempo_finalizado==1)&&(contador_iniciado==1)"
                  class="ml-3 primary"
                  large
                  v-on:click="enviarExamen()"
              >
                Finalizar examen
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                  v-if="(examen_finalizado==0||tiempo_finalizado==1)&&(contador_iniciado==1)"
                  class="primary"
                  large
                  v-on:click="enviarExamen()"
              >
                Finalizar examen
              </v-btn>
            </div>
          </v-card>
        </div>

        <div class="espacio-contador"></div>
        <v-simple-table v-if="(iniciar_contador == 1 && mostrar_resultados == 0)&&(examen_finalizado==0)">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Estudiante:</td>
                <td>{{ nombre_aplicante }}</td>
              </tr>
              <tr>
                <td>Fecha y hora del inicio del examen:</td>
                <td>{{ fecha_inicio_examen }}</td>
              </tr>
              <tr>
                <td>Nombre del examen:</td>
                <td>{{ examen_res.configuration_exam.titulo }}</td>
              </tr>
              <tr>
                <td>Instrucciones del examen:</td>
                <td>{{ examen_res.configuration_exam.instrucciones }}</td>
              </tr>
              <tr>
                <td>Valor del examen:</td>
                <td>{{ examen_res.configuration_exam.valor }} puntos</td>
              </tr>
              <tr>
                <td>Total de reactivos:</td>
                <td>{{ total_reactivos }} reactivos</td>
              </tr>
              <tr v-if="tipo_paginado_preguntas==1">
                <td>Paginación de reactivos:</td>
                <td>{{ paginacion_preguntas }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div v-if="examen_finalizado==0 && contador_iniciado == 1" class="container-preguntas">
          <examenpaginado
              @paginacion_preguntas="paginacion_preguntas = $event"
              @current_question="current_question = $event"
              :current_question="current_question"
              :examen_alumno_id="examen_alumno_id"
              :examen_id="idExamen" :preguntas="preguntas"
              v-if="tipo_paginado_preguntas==1"></examenpaginado>
          <examencompleto
              :examen_alumno_id="examen_alumno_id"
              :examen_id="idExamen"
              :preguntas="preguntas"
              v-if="tipo_paginado_preguntas==2"></examencompleto>
        </div>


        <div v-if="! showLoading">
          <v-divider class="mt-5 mb-5"></v-divider>
          <v-btn
              v-if="examen_finalizado==0||tiempo_finalizado==1"
              class="primary"
              x-large
              v-on:click="enviarExamen()"
          >
            Finalizar examen
          </v-btn>
        </div>

      </div><!-- v-if="visible==1"-->
      <div v-else>
        <div v-if="examen_invalido_tiempo==1" class="py-5 px-5"
             elevation="2">
          <v-alert
              border="right"
              colored-border
              type="error"
              elevation="2"
          >
            Este examen no es válido ya que no ha llegado la fecha de su comienzo o bien, el examen ha expirado
          </v-alert>
        </div>
        <div v-if="examen_invalido_tiempo==0" class="py-5 px-5"
             elevation="2">
          <v-progress-circular
              color="primary"
              indeterminate
          ></v-progress-circular>
          <strong class="ml-3">Validando examen</strong>, por favor espere...
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
iframe {
  width: 800px;
  height: 500px;
  margin: 10px;
}
.container-contador-home {
  position: relative;
}
.container-contador {
  position: fixed;
  z-index: 9999;
  padding: 10px;
  top: 6.9vh;
  right: 0;
}
.espacio-contador {
  display: block;
}
/*.container-preguntas {
  max-height: 45vh;
  overflow-y: scroll;
  padding: 10px;
  margin-bottom: 3vh;
}*/
@media only screen and (max-width: 768px) {
  iframe {
    width: 100%;
    height: 100%;
    margin: 10px;
  }
/*  .container-preguntas {
    max-height: 100%;
    padding: 10px;
    margin-bottom: 3vh;
  }*/
}
</style>
<script>
import examenpaginado from '@/views/ExamenPaginado.vue'
import examencompleto from '@/views/ExamenCompleto.vue'


import ExamenFinalizado from '@/views/Evaluacion/examenfinalizado.vue'
import CountDown from '@/views/Evaluacion/coundown.vue'
import drive from "@/components/drive_file";
import firebase from "@/firebase/init";
import moment_timezone from 'moment-timezone'
/*
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'http://localhost:8000'
*/
export default {
  components: {
    CountDown, ExamenFinalizado, drive, examenpaginado, examencompleto
  },

  props: {
    source: String,

  },

  data: () => ({
    total_reactivos: 0,
    paginacion_preguntas: '',
    examen_invalido_tiempo: 0,
    detener_examen: 0,
    contador_iniciado: 0,
    mostrar_resultados: 0,
    databaseNameStream: process.env.VUE_APP_FIREBASE_DB_EXAMS_NAME,
    iniciar_contador: 0,
    configuracion_examen: {
      tiempo_actual_examen: 0,
      tiempo_minutos_responder: 0,
    },
    status_uploading_justificante: 0, //0 aun no enviado, 1 enviado y procesando, 2 procesado y subido
    justificante_data: null,
    src_imagen_visualizar: '',
    dialogVisualizarImagen: false,
    idarchivo: null,
    key_id: -1,
    examen_actual_id: -1,
    imagen_seleccionada: 0,
    url_imagen_seleccionada: '',
    url_imagen_seleccionada_copia: '',
    obj_imagen_pregunta: [],
    examen_aplicado_alumno_id: 0,
    examen_dentro_de_tiempo: false,
    show_from_user: null,
    tiempo_finalizado: 0,
    calificacion_final: 0,
    radiorespuestas: 0,
    showLoading: 1,
    permitir_pausa: 0,
    questions_answered: [],
    showPuaseButton: 1,
    showResumeButton: 0,
    student_stauts_exam: {},
    paused: 0,
    resume: 0,
    respuestas_alumno: [],
    tiempo_examen: 0,
    drawer: null,
    //idExamen:'',
    preguntas: [],
    examenes: [],
    tiempo: 0,
    respuestasSeleccionadas: [],
    selected: '',
    titulo: '',
    instrucciones: '',
    answers: [],
    examen_finalizado: 0,
    revision_estudiante: -1,
    email: '',
    examen_alumno_id: 0,
    examen_res: [],
    firebase_id: '',
    examen_nuevo: 1,
    comportamiento_contador: 0,
    tipo_paginado_preguntas: 0,
    current_question: 1,
    nombre_aplicante: '',
    fecha_inicio_examen: ''
  }),
  created() {
    if (this.$route.query.show_from_user) {
      if (parseInt(this.$route.query.show_from_user) > 0) {
        this.show_from_user = this.$route.query.show_from_user
      }
    }
    this.idExamen = this.$route.query.idExamen;
  },
  watch: {
    mostrar_resultados: function(val) {

    },
    examen_finalizado: function (val) {
      if (parseInt(val) == 1) {
        this.detener_examen = 1
        if ( this.examen_nuevo == 1 ) {
          this.confirmEnviarExamen()
        }
      }
    },
  },
  mounted() {
    this.checkExamenValid();
    // this.initExamen();
  },
  methods: {
    checkExamenValid() {
      setTimeout(() => {
        this.$http.post('/api/validate-examen', {
          'idExamen': this.idExamen
        }).then((response) => {
          if (response.data.statusValidoExamen == 1) {
            this.examen_dentro_de_tiempo = true;
            this.initExamen();
          } else if (response.data.statusValidoExamen == 0) {
            this.examen_dentro_de_tiempo = false;
            this.examen_invalido_tiempo = 1
          }
        }).catch(function (error) {
          console.log(error)
        })
      }, 2000)

    },
    initExamen() {
      let params = '?examen_id=' + this.idExamen;
      if (this.show_from_user > 0) {
        this.show_from_user = this.$route.query.show_from_user
        params = params + "&show_from_user=" + this.$route.query.show_from_user
      }

      this.$http.post('/api/evaluacionalumno/get-exam-user', {
        examen_id: this.idExamen,
        show_from_user: this.show_from_user
      }).then(res => {
        this.examen_res = res.data

        this.nombre_aplicante = this.examen_res.exam_user
        this.fecha_inicio_examen = this.examen_res.start_exam_date
        this.total_reactivos = this.examen_res.total_questions
        this.configuracion_examen.tiempo_minutos_responder = this.examen_res.configuration_exam.tiempo_minutos_responder;
        if ( parseInt(this.examen_res.current_exam.examen_finalizado) == 1 ) {
          this.examen_nuevo = 0;
        }
        this.examen_finalizado = parseInt(this.examen_res.current_exam.examen_finalizado);
        this.calificacion_final = this.examen_res.current_exam.calificacion_final;
        this.examen_alumno_id = this.examen_res.current_exam.id;
        this.revision_estudiante = this.examen_res.current_exam.revision_estudiante;
        this.tiempo_finalizado = 0;


        this.tipo_paginado_preguntas = parseInt(this.examen_res.configuration_exam.tipo_paginado_preguntas);
        this.comportamiento_contador = parseInt(this.examen_res.configuration_exam.comportamiento_contador);
        this.titulo = this.examen_res.configuration_exam.titulo;
        this.instrucciones = this.examen_res.configuration_exam.instrucciones;
        this.permitir_pausa = this.examen_res.configuration_exam.permitirpausarexamen;

        this.preguntas = this.examen_res.questions_exam;


      }).catch(err => {

      }).finally(() => {
        let current_timezone = moment_timezone().tz("America/Mexico_City").unix(),
            last_timezone_saved = 0,
            diff_seconds = 0
        var database = firebase.database().ref(this.databaseNameStream);
        database
            .orderByChild('examen_alumno_id')
            .equalTo(this.examen_alumno_id).once("value").then((snapshot) => {
          // creamos el registro si no existe
          if (!snapshot.val()) {
            var newExamen = database.push();
            newExamen.set({
              examen_alumno_id: this.examen_alumno_id,
              examen_id: this.idExamen,
              segundos_actuales: 0,
              current_question: this.current_question,
              current_timestamp: ''
            })
            this.firebase_id = newExamen.key
            this.configuracion_examen.tiempo_actual_examen = this.examen_res.current_exam.tiempo_actual_examen;
          }
          // si existe, traemos el id de firebase
          else {
            var snapKey = Object.keys(snapshot.val())[0];
            this.firebase_id = snapKey
            snapshot.forEach((snap) => {
              let snapVal = snap.val()
              // flexible
              if ( this.comportamiento_contador == 1 ) {
                this.configuracion_examen.tiempo_actual_examen = parseInt(snapVal.segundos_actuales);
              }
              // estricto
              else if ( this.comportamiento_contador == 2 ) {
                last_timezone_saved = snapVal.current_timestamp
                diff_seconds = parseInt(current_timezone) - parseInt(last_timezone_saved)
                let tiempo_segundos_examen = parseInt(snapVal.segundos_actuales) - diff_seconds
                if ( tiempo_segundos_examen <= 0 ) {
                  this.confirmEnviarExamen()
                }
                else {
                  this.configuracion_examen.tiempo_actual_examen = tiempo_segundos_examen;
                }
              }

              this.current_question = snapVal.current_question
              // en caso de que el examen no permita pausarse, contamos el tiempo transcurrido
              // entre la fecha de inicio del examen y la ultima fecha y hora que contesto su ultimo
              // reactivo, esto nos sirve para restarlo al tiempo total restante del examen
            })
          }
        }).then(() => {
          this.iniciar_contador = 1
          this.showLoading = 0;
        });

      })
    },
    confirmEnviarExamen() {
      this.examen_finalizado = 1
      this.$http.post('/api/evaluacionalumno/finish-exam', {
        time_exam: this.student_stauts_exam.leftSecondsExam,
        examen_id: this.idExamen,
        examen_alumno_id: this.examen_alumno_id
      }).then(response => {
        this.calificacion_final = response.data.calificacion_final
      });
    },
    enviarExamen() {
      if ( this.permitir_pausa == 1 ) {
        this.paused = 1;
        this.showResumeButton = 1;
        this.showPuaseButton = 0;
      }
      Swal.fire({
        title: 'Atención',
        html: 'Usted está a punto de finalizar su examen, confirme ahora para continuar',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Finalizar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.examen_finalizado = 1
        } else if (result.isDenied) {
          Swal.close()
          if ( this.permitir_pausa == 1 ) {
            this.resume = 1;
            this.showResumeButton = 0;
            this.showPuaseButton = 1;
          }
        }
      })
    },
  }
}
</script>

<template>
  <v-card max-width="100%" class="mx-auto">
    <v-dialog v-if="window_responder_visible" :value="true" @input="window_responder_visible = false" persistent
              max-width="450" >
      <v-card elevation="2" tile>
        <v-toolbar
            flat
            dark
            color="primary"
        >
          <v-btn
              icon
              fab
              dark
              @click="close()"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
          <v-toolbar-title>Escribe tu comentario y pulsa enter</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                    label=""
                    v-model="respuesta"
                    @keyup.enter="guardarRespuesta()"
                    placeholder=""
                    outlined
                ></v-text-field>
<!--                <v-btn @click="guardarRespuesta()" small>
                  Comentar
                </v-btn>-->
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-list-item class="grow">
            <v-list-item-avatar>
              <v-avatar>
                <img
                    :src="usuario.avatar"
                    alt="NivelA"
                >
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                    v-model="registro_pregunta.pregunta_contenido"
                    label="Escribe tu comentario..."
                    type="text"
                    outlined
                    class="mt-1"
                    style="font-size:smaller;"
                    @keyup.enter="sendPregunta()"
                    @click:append-outer="sendPregunta()"
                    hide-details
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col
            v-for="registro_pregunta in preguntas" :key="registro_pregunta.key"
            cols="12"
        >
            <v-list-item>
              <v-list-item-avatar>

              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row>
                  <v-avatar width="25px" height="25px">
                    <img
                        :src="usuario.avatar"
                        alt="NivelA"
                    >
                  </v-avatar>
                  <v-textarea
                      v-model="registro_pregunta.pregunta_contenido"
                      outlined
                      readonly
                      hide-details
                      style="font-size:smaller;"
                      dense
                      rows="2"
                      class="mb-1 mr-3"
                  ></v-textarea>
                  </v-row>
                </v-list-item-title>
                <v-list-item-subtitle style="background-color: #ffffff">
<!--                  <div style="background-color: #ffffff">-->
                    <span class="ml-10 mr-2" style="font-size: 11px">{{registro_pregunta.nombre}}</span>
                    <v-btn
                        @click="votar(registro_pregunta.key)"
                        dark
                        fab
                        x-small
                        class=""
                        color="blue"
                        elevation="0"
                    >
                      <v-icon small style="box-shadow: #ffffff">
                        mdi-thumb-up-outline
                      </v-icon>
                      <span>{{ registro_pregunta.votos }}</span>
                    </v-btn>

                    <v-btn
                        @click="responder(registro_pregunta.key)"
                        color="blue"
                        dark
                        fab
                        x-small
                        v-if="es_coach"
                        title="Comentar"
                        class="ml-2"
                        elevation="0"
                    >
                      <v-icon small>mdi-comment-outline</v-icon>
                    </v-btn>

                    <span class="ml-7" style="font-size: 10px">{{ convertir_fecha(registro_pregunta.fechahora) }}</span>
<!--                  </div>-->

                  <!-- COMENTARIOS-->
                  <v-col
                      v-for="registro_comentario in registro_pregunta.comentarios"
                      :key="registro_comentario.key"
                      cols="12"
                  >
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-avatar width="25px" height="25px">
                          <img :src="registro_comentario.avatar" alt="NivelA">
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-textarea
                              v-model="registro_comentario.comentario"
                              outlined
                              readonly
                              hide-details
                              style="font-size:smaller;"
                              dense
                              rows="2"
                              class="mb-1"
                          ></v-textarea>
                        </v-list-item-title>
                        <v-list-item-subtitle style="background-color: #ffffff">
                          <span class="mr-2" style="font-size: 11px">{{registro_comentario.nombre}}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <!-- FIN COMENTARIOS -->

                </v-list-item-subtitle>
              </v-list-item-content>





            </v-list-item>

        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import firebaseApp from "./../firebase/init"
import moment from "moment"

export default {
  name: 'chat',
  props:
      {
        //email_logueado: String,
        id_evento: Number,
        es_coach: Boolean,
      },
  data() {
    return {
      pregunta_contenido: "",
      email: null,
      email_logueado: null,
      activeChat: 1,
      votos: 0,
      window_responder_visible: 0,
      key_pregunta_responder: null,
      correo: "",
      preguntas: [],
      respuesta: null,
      comentarios: [],
      model: 1,
      usuario: [],
      referencia_firebase: firebaseApp.database().ref('preguntas/'),
      registro_pregunta: {
        pregunta_contenido: '',
        votos: 0,
        email: null,
        evento_id: null,
        es_respuesta: false,
        comentarios: 0,
        nombre: '',
        avatar: '',
        fechahora: ''
      },
      items: [
        {
          color: '#1F7087',
          src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
          title: 'Supermodel',
          artist: 'Foster the People',
        },
        {
          color: '#952175',
          src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
          title: 'Halcyon Days',
          artist: 'Ellie Goulding',
        },
      ],
    }
  },
  created() {

  },
  methods: {
    dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
      }
    },
    dynamicSortMultiple() {
      var props = arguments;
      return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        while (result === 0 && i < numberOfProperties) {
          result = this.dynamicSort(props[i])(obj1, obj2);
          i++;
        }
        return result;
      }
    },
    convertir_fecha(fechahora) {
      moment.locale();
      return moment(fechahora, "X").format("LLL")
    },
    getUsuarioConectado() {
      let url = '/api/aprende/users/get-user'
      //console.log(url);
      this.$http.get(url)
          .then((response) => {
            this.usuario = response.data;
            this.email_logueado = this.usuario.email
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    guardarRespuesta() {
      let total_comentarios = 0
      let comentario_usuario  = null;
      comentario_usuario  = this.respuesta
      comentario_usuario  = comentario_usuario.trim()
      if(comentario_usuario.length>0) {
        var postData = {
          comentario: this.respuesta,
          emailUsuario: this.email_logueado,
          nombre: this.usuario.name,
          avatar: this.usuario.avatar
        };
        //Respuesta
        firebaseApp.database().ref().child('preguntas/' + this.key_pregunta_responder + '/respuestas').push(postData);
        //obteniendo total de comentarios actuales actuales
        firebaseApp.database().ref().child('preguntas/' + this.key_pregunta_responder).on("value", function (snapshot) {
          total_comentarios = snapshot.val().total_comentarios
          //console.log(comentarios)
        });
        //incrementando en 1 el numero de comentarios y actualizando
        var database_actualizar = firebaseApp.database();
        const ref = database_actualizar.ref('preguntas/' + this.key_pregunta_responder);
        ref.update({
          total_comentarios: parseInt(total_comentarios) + 1,
        });
        this.key_pregunta_responder = null
        this.respuesta = null
        this.close()
      }
    },
    votar(id) {
      let usuarioVoto = 0
      try {
        var emailUsuario = this.email_logueado;
        firebaseApp.database().ref().child('preguntas/' + id + '/usuarios').orderByChild('correo').equalTo(emailUsuario).on("value", function (snapshot) {
          if (snapshot.val())
            usuarioVoto = 1
          /*snapshot.forEach(function(data) {
            console.log(data.key + " aqui2");
          });*/
        });
      } catch (err) {
        console.log(err);
      }
      //fin de validar si ya votó
      if (usuarioVoto == 0) {
        try {
          let votos = 0
          var postData = {
            correo: this.email_logueado,

          };
          //Agregando usuario que está votando
          firebaseApp.database().ref().child('preguntas/' + id + '/usuarios').push(postData);

          //obteniendo votos actuales
          firebaseApp.database().ref().child('preguntas/' + id).on("value", function (snapshot) {
            votos = snapshot.val().votos
          });

          //incrementando en 1 y actualizando
          var database_actualizar = firebaseApp.database();
          const ref = database_actualizar.ref('preguntas/' + id);
          ref.update({
            votos: parseInt(votos) + 1,
          });

        } catch (err) {
          console.log(err);
        }
      }
    },
    close() {
      this.window_responder_visible = 0
    },
    responder(id) {
      this.window_responder_visible = 1
      this.key_pregunta_responder = id
    },
    sendPregunta(){
      moment.locale();
      let hora = Math.round((moment().format("x")) / 1000)
      let comentario_usuario = null
      //alert(this.registro_pregunta.comentarios)
      comentario_usuario  = this.registro_pregunta.pregunta_contenido
      comentario_usuario  = comentario_usuario.trim()
      if(comentario_usuario.length>0){
        this.registro_pregunta.evento_id = this.id_evento
        const registro_pregunta = {
          pregunta_contenido: comentario_usuario,
          email: this.email_logueado,
          votos: this.registro_pregunta.votos,
          nombre: this.usuario.name,
          avatar: this.usuario.avatar,
          total_comentarios: this.registro_pregunta.comentarios,
          evento_id: this.registro_pregunta.evento_id,
          es_respuesta: false,
          fechahora: hora,
        };
        firebaseApp
            .database()
            .ref("preguntas")
            .push(registro_pregunta);
        this.registro_pregunta.pregunta_contenido = "";
      }
    },
    loadPreguntas() {
      let coach = this.es_coach
      this.referencia_firebase.orderByChild('votos').on('value', (snapshot) => {
        this.preguntas = [];
        //get comments
        //this.comentarios = [];
        let concentrado_comentarios = []

        //let idevento = this.id_evento
        snapshot.forEach((doc) => {
          if (doc.val().evento_id == this.id_evento) {
            //llenar el arreglo de respuestas
            //console.log(doc.key)

            firebaseApp.database().ref().child('preguntas/' + doc.key + '/respuestas').on("value", function (snapshot2) {
              if (snapshot2.val()) {
                //console.log("comentario si ingreso")
                //console.log(snapshot2.val())
                snapshot2.forEach((registros) => {
                  concentrado_comentarios.push({
                    comentario: registros.val().comentario,
                    emailUsuario: registros.val().emailUsuario,
                    nombre: registros.val().nombre,
                    avatar: registros.val().avatar,
                    key: registros.key,
                  })
                });
                //console.log("concentrado_comentarios")
                //console.log(concentrado_comentarios)
              }
            });
            this.preguntas.push({
              key: doc.key,
              email: doc.val().email,
              es_respuesta: doc.val().es_respuesta,
              nombre: doc.val().nombre,
              avatar: doc.val().avatar,
              evento_id: doc.val().evento_id,
              votos: doc.val().votos,
              total_comentarios: parseInt(doc.val().total_comentarios),
              pregunta_contenido: doc.val().pregunta_contenido,
              comentarios: concentrado_comentarios,
              fechahora: doc.val().fechahora
            })
            //ordernar a preguntas con los votos
            concentrado_comentarios = []
            // this.preguntas.sort(this.dynamicSortMultiple("votos"))
          }
          if (coach) {
            this.preguntas.sort(function (a, b) {
              return b.votos - a.votos;
            });
          } else {
            this.preguntas.sort(function (a, b) {
              return b.fechahora - a.fechahora;
            });
          }
        });
        console.log(this.preguntas)
      });
    }
  },
  mounted() {
    this.getUsuarioConectado()
    this.loadPreguntas()
  },
  computed: {
    timestamp: function () {
      //return moment().locale()
      let utxtimes = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      return moment(utxtimes).format("X");
    }
  }
}
</script>
<style scoped>
/*.theme--light .v-card {
  border-left: 5px solid cadetblue !important;
}*/
</style>
<template>
  <div>
    <preguntasexamen :examen_alumno_id="examen_alumno_id" :preguntas="preguntas" :examen_id="examen_id"></preguntasexamen>
  </div>
</template>
<script>
import preguntasexamen from "../views/PreguntasExamen";
export default {
  name: 'examencompleto',
  props: ['preguntas', 'examen_id', 'examen_alumno_id'],
  components: {preguntasexamen},
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<template>
  <v-container>
    <script type="application/javascript" src="https://apis.google.com/js/api.js"></script>
    <div id="result" style="float: left" >
      <v-btn @click="showPickerDialog" small>
        Seleccionar archivo
      </v-btn>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "drive.vue",
  props:{
      idarchivo: {
        type: Object,
        required: false
      },
      examen_aplicado_alumno_id: {
        type: Number,
        required: false
      },
      key_archivo: {
        type: Number,
        required: false
      },
    examen_actual_id: {
        type: Number,
        required: false
      },

  },
  data:() => ({
//name: "drive2"
    developerKey     : 'AIzaSyAG6tR1HV3Z2M-Q2nBFjD-5vX-_6R6eoQU',
    clientId         : "95089445529-nalgm9qt29jbfnsoph7hk04ol1bv5uo2.apps.googleusercontent.com",
    appId            : "95089445529",
    scope         : ['https://www.googleapis.com/auth/drive.file'],
    pickerApiLoaded : false,
    oauthToken: '',
    archivo:[]
  }),
  methods:{
    copyFile(fileId){
      //alert(this.examen_actual_id)
      //console.clear()
      //this.idarchivo = fileId
      //this.$emit('idarchivo',fileId)
      //console.log(fileId)
            this.$http.post('/api/reactivos/copy-file', {
              'fileId'          : fileId,
              'examen_id'       : this.examen_actual_id
            })
                .then((response) => {

                })
                .catch(function (error) {

                })
    },
    showPickerDialog(){
      this.onApiLoad()
    },
    onApiLoad() {
      gapi.load('auth', { 'callback': this.onAuthApiLoad });
      gapi.load('picker');
    },
    onAuthApiLoad() {
      window.gapi.auth.authorize({
        //'client_id': '869858860532-csp3gggo7la580v396ssl4dvd4vd08th.apps.googleusercontent.com',
        'client_id': '95089445529-nalgm9qt29jbfnsoph7hk04ol1bv5uo2.apps.googleusercontent.com',
        'scope'    : ['https://www.googleapis.com/auth/drive']
      }, this.handleAuthResult);
    },
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      }
    },
    createPicker() {
      let view = new google.picker.View(google.picker.ViewId.DOCS);
      view.setMimeTypes("application/vnd.google-apps.document,application/msword,application/pdf");
      let picker = new google.picker.PickerBuilder()
          //.enableFeature(google.picker.Feature.NAV_HIDDEN)
          //.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setAppId(this.appId)
          .setOAuthToken(this.oauthToken)
          .addView(view)
          .addView(new google.picker.DocsUploadView())
          .setDeveloperKey(this.developerKey)

          //.setDeveloperKey('AIzaSyDPs9U-dgOC9h1jRFNwOwhRtARCph8_3HM')
          .setCallback(this.pickerCallback)
          .build();
      picker.setVisible(true);
    },

    pickerCallback(data) {
      let url = 'nothing';
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        let fileId = data.docs[0].id;
        this.copyFile(fileId);
        this.$emit('idarchivo',data.docs[0])
        if(this.examen_aplicado_alumno_id){
          this.$emit('examen_aplicado_alumno_id',this.examen_aplicado_alumno_id)
        }
        if(this.key_archivo>=0){
          this.$emit('key_archivo',this.key_archivo)
        }
      }

    }
  }
}
</script>

<style scoped>

</style>

<template>
    <v-container>
        <template>
            <v-row justify="center">
                <v-dialog v-if="dialogObjetivo" :value="true" @input="dialogObjetivo = false" persistent max-width="790">
                    <v-card elevation="2" class="mx-auto">
                        <v-card-title>
                            <v-icon large class="mr-2" color="green">mdi-message-text-outline</v-icon><h3>Oferta Académica</h3>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                        <v-text-field
                                            label="Nombre"
                                            v-model="nombre_oferta_academica"
                                            outlined
                                        ></v-text-field>
                                        <v-textarea
                                            label="Descripción:"
                                            v-model="descripcion"
                                            :rows="3"
                                            outlined
                                        ></v-textarea>
                                      <v-text-field
                                          label="Imagen"
                                          v-model="imagen"
                                          outlined
                                      ></v-text-field>

                              </v-col>
                            </v-row>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <template>
                                <v-select
                                    label='Curricula:'
                                    v-model='idCurricula'
                                    :items='curriculas'
                                    item-value='id'
                                    item-text='nombre_curricula'
                                    outlined
                                    return-object
                                >
                                  <template slot='selection' slot-scope='{ item }'>
                                    {{ item.nombre_curricula }}
                                  </template>
                                  <template slot='item' slot-scope='{ item }'>
                                    {{ item.nombre_curricula }}
                                  </template>
                                </v-select>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                                cols="12"
                                lg="6"
                            >
                              <v-menu
                                  ref="menu1"
                                  v-model="menu1"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="fecha_inicio"
                                      label="Fecha inicio"
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      @blur="date_inicio = parseDate(fecha_inicio)"
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_inicio"
                                    no-title
                                    @input="menu1 = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>

                            <v-col
                                cols="12"
                                lg="6"
                            >
                              <v-menu
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="fecha_finalizacion"
                                      label="Fecha finalización"
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_finalizacion"
                                    no-title
                                    @input="menu2 = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col  cols="12" sm="12">
                              <v-switch
                                  v-model="switchpublicado"
                                  label="Publicado"
                                  v-bind:value="switchpublicado"
                              ></v-switch>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col
                                cols="12"
                                lg="6"
                            >
                              <v-menu
                                  ref="menu3"
                                  v-model="menu3"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="fecha_inicio_publicacion"
                                      label="Fecha inicio publicación"
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      @blur="date_inicio_publicacion = parseDate(fecha_inicio_publicacion)"
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_inicio_publicacion"
                                    no-title
                                    @input="menu3 = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>

                            <v-col
                                cols="12"
                                lg="6"
                            >
                              <v-menu
                                  v-model="menu4"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="fecha_fin_publicacion"
                                      label="Fecha fin de publicación"
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_fin_publicacion"
                                    no-title
                                    @input="menu4 = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-btn @click="guardarUnidad" small>
                                        {{txtboton}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="closeImportar()"
                            >
                                Cerrar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <v-card elevation="2" class="mx-auto">
            <v-card-title>
                <v-icon large class="mr-2" color="green">mdi-message-text-outline</v-icon><h3>Ofertas Académicas</h3>
                <v-spacer></v-spacer>

            </v-card-title>
          <v-card-text align="center">
            <v-btn
                color="green"
                small
                @click="showUnidad()"
                title="Crear Unidad"
            >
              <v-icon  small color="white" >mdi-message-text-outline</v-icon>
            </v-btn>
          </v-card-text>
            <v-card elevation="2">
                <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
                <v-data-table :headers="headers" :items="unidades" class="elevation-1">
                  <template v-slot:item.publicado="{ item }">
                    <v-icon v-if="(parseInt(item.publicado)>=1)"
                            class="mr-2"
                            color="green"
                            title="Oferta publicada"
                    >
                      mdi-arrow-up-bold-circle-outline
                    </v-icon>
                    <v-icon v-if="(parseInt(item.publicado)==0)"
                            class="mr-2"
                            color="orange"
                            title="Oferta No publicada"
                    >
                      mdi-flag-outline
                    </v-icon>
                  </template>
                  <template v-slot:item.fecha_inicio="{ item }">
                      {{formatDate(item.fecha_inicio)}}
                  </template>
                  <template v-slot:item.fecha_finalizacion="{ item }">
                    {{formatDate(item.fecha_finalizacion)}}
                  </template>
                  <template v-slot:item.fecha_inicio_publicacion="{ item }">
                    {{formatDate(item.fecha_inicio_publicacion)}}
                  </template>
                  <template v-slot:item.fecha_fin_publicacion="{ item }">
                    {{formatDate(item.fecha_fin_publicacion)}}
                  </template>
                  <template v-slot:item.editar="{ item }">
                      <v-btn
                          fab elevation="2"
                          color="green"
                          small
                          x-small
                          @click="editOferta(item)"
                          title="Editar Oferta Académica"
                          style="margin-right: 10px"
                      >
                        <v-icon color="white">mdi-pencil</v-icon>
                      </v-btn>
                  </template>
                  <template v-slot:item.eliminar="{ item }">
                            <v-btn
                                fab elevation="2"
                                color="red"
                                small
                                x-small
                                title="Eliminar Oferta Académica"
                            >
                                <v-icon  @click="deleteOferta(item)" title="Eliminar Oferta Académica" color="white">mdi-delete</v-icon>
                            </v-btn>
                    </template>

                </v-data-table>
            </v-card>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data: vm => ({
        search:'',
        nombre:'',
        idTipoReactivo:'',
        tiporeactivo:[],
        item:[],
        unidades:[],
        respuestasTotal:0,
        headers: [
            {text: '#',align: 'start',sortable: true,value: 'id'},
            { text: 'Nombre Oferta Académica',          value: 'nombre_oferta_academica'},
            { text: 'Publicado',                        value: 'publicado' ,                  align: 'center'},
            //{ text: 'Estatus', value: 'clave_interna' },
            { text: 'Fecha inicio',                     value: 'fecha_inicio',                align:'center' },
            { text: 'Fecha finalización',               value: 'fecha_finalizacion',          align:'center' },
            { text: 'Fecha inicio publicación',         value: 'fecha_inicio_publicacion',    align:'center' },
            { text: 'Fecha fin publicación',            value: 'fecha_fin_publicacion',       align:'center' },
            { text: 'Editar',                           value: 'editar',                      align:'center'},
            { text: 'Eliminar',                         value: 'eliminar',                    align:'center'},
        ],
        overlay: false,
        absolute:true,
        dialog: false,
        dialogObjetivo: false,
        dialogAyuda:false,
        tipoaccion:'',
        nombre_unidad:'',
        clave_interna:'',
        txtboton:'',
        estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
        idOfertaAcademica:0,
        modolectura:false,
        nombre_oferta_academica:'',
        descripcion:'',
        estatus:'',
        imagen:'',
        publicado:0,
        switchpublicado:1,
        date_inicio: new Date().toISOString().substr(0, 10),
        fecha_inicio: vm.formatDate(new Date().toISOString().substr(0, 10)),
        date_finalizacion: new Date().toISOString().substr(0, 10),
        fecha_finalizacion: vm.formatDate(new Date().toISOString().substr(0, 10)),

        date_inicio_publicacion: new Date().toISOString().substr(0, 10),
        fecha_inicio_publicacion: vm.formatDate(new Date().toISOString().substr(0, 10)),

        date_fin_publicacion: new Date().toISOString().substr(0, 10),
        fecha_fin_publicacion: vm.formatDate(new Date().toISOString().substr(0, 10)),
        copia_date:new Date().toISOString().substr(0, 10),
        copia_fecha:vm.formatDate(new Date().toISOString().substr(0, 10)),
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        idCurricula: '',             curriculas: [],              curricula_id: '',
}),
    created () {
        this.init();
    },
  computed: {
 /*   computedDateFormatted:function  () {
      return this.formatDate(this.date_inicio)
    },
    computedfecha_finalizacion:function  () {
      return this.formatDate(this.date_finalizacion)
    },*/
  },
  watch: {
    date_inicio (val) {
      this.fecha_inicio = this.formatDate(this.date_inicio)
    },
    date_finalizacion (val) {
      this.fecha_finalizacion = this.formatDate(this.date_finalizacion)
    },
    date_inicio_publicacion (val) {
      this.fecha_inicio_publicacion = this.formatDate(this.date_inicio_publicacion)
    },
    date_fin_publicacion (val) {
      this.fecha_fin_publicacion = this.formatDate(this.date_fin_publicacion)
    },

  },
    methods: {

      getDateTime(date) {
        const [day, month, year] = date.split("/");
        return `${year}-${month}-${day}`;
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        //return `${month}/${day}/${year}`
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        //const [month, day, year] = date.split('/')
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
        init () {
            this.getOfertas()       //obtiene los reactivos filtrados por el usuario actual.
            this.getCurriculas()
        },
        closeModal() {
            this.dialog = false
        },
        closeAyuda(){
            this.dialogAyuda =  false
        },
        closeImportar(){
            this.nombre_unidad    =  '';
            this.clave_interna      =  '';
            this.descripcion        =  '';
            this.txtboton           =  '';
            this.dialogObjetivo     =  false
            this.modolectura        =  false;
        },
        consultar(id){
            this.idTipoReactivo = id;
            if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
                this.showModal();
            }
            else if (this.idTipoReactivo==6)
                this.showModal();
        },
        consultarNivelEducativo(){
            let url = '';
            url = `/api/grados?idnivel=${this.idNivel.id}`
            this.$http.get(url)
                .then((response) => {
                    this.grados = response.data;
                    if(this.tipoaccion==2 ) {
                        this.idGrado = this.grados.find(item => item.id == this.grado_id)
                    }
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        consultarArea(){
            let url = '';
            url = `/api/asignaturas?idArea=${this.idArea.id}`
            this.$http.get(url)
                .then((response) => {
                    this.asignaturas = response.data;
                    if(this.tipoaccion==2 ){
                        this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
                    }
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        getCurriculas(){
          let url = `/api/aprende/curriculas`
          this.$http.get(url)
              .then((response) => {
                this.curriculas = response.data;
              })
              .catch(function (error){
                console.log(error)
              })
        },
        deleteOferta (item){
            Swal.fire({
                title: '¿Desea eliminar la Oferta Académica: '+ item.nombre_oferta_academica + '?',
                showDenyButton: true,
                confirmButtonText: `Eliminar`,
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = `/api/aprende/ofertaAcademica/${item.id}`
                    this.$http
                        .delete(url)
                        .then((response) => {
                            this.getOfertas()
                            Swal.fire('Oferta Académica eliminada satisfactoriamente.', '', 'success')
                        })
                        .catch(function (error){
                            console.log(error)
                        })

                } else if (result.isDenied) {
                    //Swal.fire('Changes are not saved', '', 'info')
                }
            })
        },
        editReactivo(item){
            this.item                 =   item;
            this.dialogObjetivo       =   true;
            this.idTipoReactivo       =   item.tiporeactivo.tiporeactivo_concentrado.id;
            this.tipoaccion           =   2;
            this.estatusAccionTabla   =   2;
        },
        editOferta(item) {
            this.idOfertaAcademica          =   item.id;
            this.nombre_oferta_academica    =   item.nombre_oferta_academica;
            this.descripcion                =   item.descripcion;
            this.imagen                     =   item.imagen;
            this.publicado                  =   item.publicado;
            if (this.publicado)
              this.switchpublicado=true
            else
              this.switchpublicado=false;

            this.date_inicio                =   item.fecha_inicio;
            this.fecha_inicio               =   this.formatDate(item.fecha_inicio);

            this.date_finalizacion          =   item.fecha_finalizacion;
            this.fecha_finalizacion         =   this.formatDate(item.fecha_finalizacion);

            this.date_inicio_publicacion   =   item.fecha_inicio_publicacion;
            this.fecha_inicio_publicacion   =   this.formatDate(item.fecha_inicio_publicacion);

            this.date_fin_publicacion      =   item.fecha_fin_publicacion;
            this.fecha_fin_publicacion      =   this.formatDate(item.fecha_fin_publicacion);

            this.txtboton                   =   "Actualizar"
            this.modolectura                =   true;
            this.estatusAccionTabla         =   2;
            this.dialogObjetivo             =   true;
        },
        getOfertas(){
            let url = `/api/aprende/ofertaAcademica`
            this.$http.get(url)
                .then((response) => {
                  console.log(response.data)
                    this.unidades = response.data;
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        getCatalogos(){
            let url = `/api/niveleseducativos/get-NivelEducativoAreaConocimiento`
            this.$http.get(url)
                .then((response) => {
                    //console.log(response)
                    this.niveles = response.data.nivel_educativo;
                    this.areas   = response.data.area_conocimiento;
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        getTipoReactivo () {
            let url = '/api/tiporeactivo'
            this.$http.get(url)
                .then((response) => {
                    this.tiporeactivo = response.data;
                    //console.log(this.periodos)
                })
                .catch(function (error){
                        console.log(error)
                    }
                )
        },
        guardarUnidad(){
            if(this.nombre_oferta_academica=='')
                confirm('Debe capturar el nombre de la Oferta Académica')
            else if (this.descripcion==''){
                confirm('Debe capturar una descripción')
            }
            else{
                if(this.estatusAccionTabla==1){
                  if(this.switchpublicado==true)
                    this.publicado = 1
                  else
                    this.publicado = 0
                    console.log(this.fecha_inicio);
                    this.$http.post('/api/aprende/ofertaAcademica', {
                        'nombre_oferta_academica':        this.nombre_oferta_academica,
                        'CURRICULA_CURSO_ID':             this.idCurricula.id,
                        'descripcion':                    this.descripcion,
                        'imagen':                         this.imagen,
                        'publicado':                      this.publicado,
                        'fecha_inicio':                   this.getDateTime(this.fecha_inicio),
                        'fecha_finalizacion':             this.getDateTime(this.fecha_finalizacion),
                        'fecha_inicio_publicacion':       this.getDateTime(this.fecha_inicio_publicacion),
                        'fecha_fin_publicacion'   :       this.getDateTime(this.fecha_fin_publicacion),
                    })
                        .then((response) => {
                            if(response.data){
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Oferta académica guardada exitosamente.',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                                //this.getOfertas()  llenar el arreglo
                                this.unidades.unshift(response.data) //inserta el elemento al principio del Arreglo
                                this.setLimpiarVariables();
                            }
                            else{
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: 'Unidad existente, verificar Clave interna.',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
                else if(this.estatusAccionTabla==2){
                    if(this.switchpublicado==true)
                      this.publicado = 1
                    else
                      this.publicado = 0
                    this.$http.post('/api/aprende/ofertaAcademica/'+ this.idOfertaAcademica, {
                        _method:    'PUT',
                      'nombre_oferta_academica':        this.nombre_oferta_academica,
                      'descripcion':                    this.descripcion,
                      'imagen':                         this.imagen,
                      'publicado':                      this.publicado,
                      'fecha_inicio':                   this.getDateTime(this.fecha_inicio),
                      'fecha_finalizacion':             this.getDateTime(this.fecha_finalizacion),
                      'fecha_inicio_publicacion':       this.getDateTime(this.fecha_inicio_publicacion),
                      'fecha_fin_publicacion'   :       this.getDateTime(this.fecha_fin_publicacion),
                    })
                        .then((response) => {
                            if(response.data){
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Oferta Académica actualizada exitosamente.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.getOfertas()
                            }
                            else{
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Unidad no actualizada. Verificar.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            }
        },
        setLimpiarVariables(){
          this.nombre_oferta_academica = '';
          this.descripcion  = '';
          this.publicado  =0;
          this.imagen     ='',
          this.switchpublicado  = 1;
          this.date_inicio = this.copia_date
          this.fecha_inicio= this.copia_fecha
          this.date_finalizacion= this.copia_date
          this.fecha_finalizacion= this.copia_fecha

          this.date_inicio_publicacion= this.copia_date
          this.fecha_inicio_publicacion= this.copia_fecha
          this.date_fin_publicacion= this.copia_date
          this.fecha_fin_publicacion= this.copia_fecha
        },
        showImportar(){
            this.dialogObjetivo = true;
        },
        showObjetivos(item){
            this.$router.push({ path: '/dashboardUnidadesObjetivos', query: { idUnidad: btoa(item.id),nombre_unidad:btoa(item.nombre_unidad),descripcion:btoa(item.descripcion)} })
        },
        showRequisitosUnidades(item){
            this.$router.push({ path: '/dashboardRequisitosUnidades', query: { idUnidad: btoa(item.id),nombre_unidad:btoa(item.nombre_unidad),descripcion:btoa(item.descripcion)} })
        },
        showRequisitosCursos(item){
            this.$router.push({ path: '/dashboardRequisitosCursos', query: { idUnidad: btoa(item.id),nombre_unidad:btoa(item.nombre_unidad),descripcion:btoa(item.descripcion)} })
        },

        showModal() {
            this.dialog = true;
        },
        showUnidad() {
            this.dialogObjetivo     = true;
            this.txtboton           = "Guardar";
            this.estatusAccionTabla =   1;
        },
        totalrespuestas(total){
            this.respuestasTotal =  total;
        },
    },
    mounted(){
    }
}
</script>

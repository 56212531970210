<template>
    <v-form>
        <v-container>
            <v-card elevation="4">
                <v-card-title>
                    <v-icon large class="mr-2" color="green">
                        mdi-checkbox-marked-outline
                    </v-icon>
                    <h3>{{ this.texto }}</h3>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                label="Nombre del Colegio/Instituto"
                                v-model="nombre"
                                outlined
                                id="txtnombre"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                label="Dominio"
                                v-model="dominio"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4" md="4">
                            <v-dialog ref="dialogfechainicio" v-model="modalfechainicio" :return-value.sync="dateinicio"
                                      persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateinicio"
                                        label="Fecha inicio de contrato"
                                        append-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="dateinicio" @selected="formatDate" scrollable>

                                    <v-btn text color="primary" @click="modalfechainicio = false">Cancelar</v-btn>
                                    <v-btn text color="primary" @click="$refs.dialogfechainicio.save(dateinicio)">
                                        Aceptar
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-dialog ref="dialogfechafin" v-model="modalfechafin" :return-value.sync="datefin"
                                      persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="datefin" label="Fecha de término de contrato" append-icon="mdi-calendar"
                                                  readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="datefin" @selected="formatDate" scrollable>
                                    <!--          <v-spacer></v-spacer>-->
                                    <v-btn text color="primary" @click="modalfechafin = false">Cancelar</v-btn>
                                    <v-btn text color="primary" @click="$refs.dialogfechafin.save(datefin)">Aceptar
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4" md="4">
                            <template>
                                <v-select
                                    label='Sistema Educativo:'
                                    v-model='idSistema'
                                    :items='sistemas'
                                    item-value='id'
                                    item-text='nombre'
                                    outlined
                                    return-object
                                >
                                    <template slot='selection' slot-scope='{ item }'>
                                        {{ item.nombre }}
                                    </template>
                                    <template slot='item' slot-scope='{ item }'>
                                        {{ item.nombre }}
                                    </template>
                                </v-select>
                            </template>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-switch
                                label="Colegio/Instituto activo"
                                v-model="activo"
                                v-bind:value="activo"
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

                    <v-card elevation="2">
                        <v-card-title>Datos de Contacto</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        v-model="nombre_contacto_informatico"
                                        label="Nombre Contacto Informático"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        v-model="correo_contacto_informatico"
                                        label="Correo Contacto Informático"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        v-model="telefono_contacto_informatico"
                                        label="Teléfono Contacto Informático"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        v-model="nombre_contacto_docente"
                                        label="Nombre Contacto Docente"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        v-model="correo_contacto_docente"
                                        label="Correo Contacto Docente"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        v-model="telefono_contacto_docente"
                                        label="Teléfono Contacto Docente"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card elevation="2">
                        <v-card-title>Número de Alumnos y Profesores</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <label>Preescolar/Párvulos</label>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                                  label="Preescolar/Párvulos Número de Profesores:"
                                                  v-model="preescolar_numero_profesores"
                                                  type="number"
                                                  outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                                  label="Preescolar/Párvulos Número de Alumnos:"
                                                  v-model="preescolar_numero_alumnos"
                                                  type="number"
                                                  outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <label>Primaria Baja/Primer Ciclo Bajo</label>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                                  label="Primaria Baja/Primer Ciclo Bajo Número de Profesores:"
                                                  v-model="primariabaja_numero_profesores"
                                                  type="number"
                                                  outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                                  label="Primaria Baja/Primer Ciclo Bajo Número de Alumnos:"
                                                  v-model="primariabaja_numero_alumnos"
                                                  type="number"
                                                  outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <label>Secundaria/Segundo Ciclo </label>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        label="Secundaria/Segundo Ciclo Número de Profesores:"
                                        v-model="secundaria_numero_profesores"
                                        type="number"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        label="Secundaria/Segundo Ciclo Número de Alumnos:"
                                        v-model="secundaria_numero_alumnos"
                                        type="number"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <label>Bachillerato </label>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        label="Bachillerato Número de Profesores:"
                                        v-model="bachillerato_numero_profesores"
                                        type="number"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        label="Bachillerato Número de Alumnos:"
                                        v-model="bachillerato_numero_alumnos"
                                        type="number"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>


                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <label>Universitario</label>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        label="Universitario Número de Profesores:"
                                        v-model="universitario_numero_profesores"
                                        type="number"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        label="Universitario Número de Alumnos:"
                                        v-model="universitario_numero_alumnos"
                                        type="number"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-row>
                        <v-col cols="6" sm="6">
                            <v-btn
                                color="primary"
                                dark
                                id="btnReactivo"
                                class="mb-2"
                                @click="guardarEscuela()"
                            >Guardar
                            </v-btn>
                        </v-col>

                    </v-row>
        </v-container>
    </v-form>
</template>
<script>
import moment from 'moment';
moment.locale('es');

import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
export default {
    data: () => ({
        //variables escuelas
            idEscuela:'',
            nombre: '',
            escuelas:[],
            dominio:'',
            dateinicio: new Date().toISOString().substr(0, 10),
            datefin: new Date().toISOString().substr(0, 10),

            idSistema: '',        sistemas: [],        sistemaeducativo_id: '',
            activo:0,
            nombre_contacto_informatico:'',
            correo_contacto_informatico:'',
            telefono_contacto_informatico:'',

            nombre_contacto_docente:'',
            correo_contacto_docente:'',
            telefono_contacto_docente:'',

            preescolar_numero_profesores:0,
            preescolar_numero_alumnos:0,
            primariabaja_numero_profesores:0,
            primariabaja_numero_alumnos:0,
            secundaria_numero_profesores:0,
            secundaria_numero_alumnos:0,
            bachillerato_numero_profesores:0,
            bachillerato_numero_alumnos:0,
            universitario_numero_profesores:0,
            universitario_numero_alumnos:0,
            texto:'',
        //fin variables escuelas
        guardando: false,

        pagination: {},
        headers: [
            {text: 'Id', align: 'start', sortable: true, value: 'id'},
            {text: 'Escuela', align: 'start', sortable: false, value: 'escuela.escuela_concentrado.nombre'},
            {text: 'Nivel', sortable: true, value: 'nivel.nivel_concentrado.nombre'},
            {text: 'Grado', sortable: true, value: 'grado.grado_concentrado.nombre'},
            {text: 'Área', sortable: true, value: 'area.area_concentrado.nombre'},
            {text: 'Asignatura', sortable: true, value: 'asignatura.asignatura_concentrado.nombre'},
            {text: 'Descripcion', value: 'descripcion'},
            {text: 'Tipo Reactivo', value: 'tiporeactivo.tiporeactivo_concentrado.nombre'},

            {text: 'Acciones', value: 'actions', sortable: true},
        ],
        overlay: false,
        absolute: true,
        modalfechainicio: false,
        modalfechafin: false,
    }),
    computed: {

    },
    created() {

    },
    methods: {
        init() {

        },
        consultarNivelEducativo() {
            let url = '';
            url = `/api/grados?idnivel=${this.idNivel.id}`
            this.$http.get(url)
                .then((response) => {
                    this.grados = response.data;
                    if (this.tipoaccion == 2) {
                        if (this.grado_id > 0)
                            this.idGrado = this.grados.find(item => item.id == this.grado_id)
                        else
                            this.idGrado = 0
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        extraerExamen() {
            let url = `/api/examenes?idExamen=${this.idExamen}`
            this.examenes = [];
            this.$http.get(url)
                .then((response) => {
                    //console.log(response.data)
                    this.examenes = response.data;
                })
                .then(()=>{
                    //alert(this.examenes[0].titulo)
                    this.titulo = this.examenes[0].titulo;
                    this.valor = this.examenes[0].valor;
                    this.dateinicio = this.examenes[0].fecha_inicio;
                    this.datefin = this.examenes[0].fecha_fin;

                    //nuevos campos

                    if(this.examenes[0].personalizarexamen==0){
                        this.personalizarexamen = ''
                        this.desactivarpersonalizar = true
                        this.desactivarbtnagregarreactivo = false;
                    }
                    else{
                        this.personalizarexamen = true
                        this.desactivarpersonalizar = false
                        this.desactivarbtnagregarreactivo = true;
                    }

                    //console.log("personalizar examen:" + this.personalizarexamen + "desactivarbtnagregarreactivo" + this.desactivarbtnagregarreactivo)
                    if (this.examenes[0].nivelacademico_id > 0)
                        this.nivel_id = this.examenes[0].nivelacademico_id
                    else
                        this.nivel_id = 0

                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        getSistemasEducativos () {
            let url = '/api/sistemaseducativos'
            this.$http.get(url)
                .then((response) => {
                    this.sistemas = response.data;
                    if (this.tipoaccion == 2) {
                        if (this.idSistema > 0)
                            this.idSistema = this.sistemas.find(item => item.id == this.idSistema)
                        else
                            this.idSistema = 0
                    }
                })
                .catch(function (error){
                        console.log(error)
                })
        },
        formatDate(date) {
            return moment(date, "DDMMYYYY").format('ll');
        },
        consultarHora(id) {
            this.tiempo_hora_responder = id;
        },
        consultarMinutos(id) {
            this.tiempo_minutos_responder = id;
        },
        consultar_tipo_examen(id) {
            this.tipo_examen = id;
        },
        guardarEscuela(){


            axios.post('/api/escuelas/guardarEscuela', {
                idEscuela :                     this.idEscuela,
                nombre:                         this.nombre,
                activo :                        (this.activo == null  || this.activo.length == 0 ) ? 0 : 1,
                dominio:                        this.dominio,
                sistemaeducativo_id:            this.idSistema.id,
                fecha_inicio_contrato:          this.dateinicio,
                fecha_termino_contrato:         this.datefin,

                nombre_contacto_informatico:    this.nombre_contacto_informatico,
                correo_contacto_informatico:    this.correo_contacto_informatico,
                telefono_contacto_informatico:  this.telefono_contacto_informatico,

                nombre_contacto_docente:        this.nombre_contacto_docente,
                correo_contacto_docente:        this.correo_contacto_docente,
                telefono_contacto_docente:      this.telefono_contacto_docente,

                preescolar_numero_profesores:   this.preescolar_numero_profesores,
                preescolar_numero_alumnos:      this.preescolar_numero_alumnos,

                primariabaja_numero_profesores: this.primariabaja_numero_profesores,
                primariabaja_numero_alumnos:    this.primariabaja_numero_alumnos,

                secundaria_numero_profesores:   this.secundaria_numero_profesores,
                secundaria_numero_alumnos:      this.secundaria_numero_alumnos,

                bachillerato_numero_profesores: this.bachillerato_numero_profesores,
                bachillerato_numero_alumnos:    this.bachillerato_numero_alumnos,

                universitario_numero_profesores:this.universitario_numero_profesores,
                universitario_numero_alumnos:   this.universitario_numero_alumnos
            })
            .then(response => {
                this.idEscuela = response.data.id;
                Swal.fire(
                    'Guardado!',
                    'Escuela registrada exitosamente.',
                    'success'
                )
            });
        },
        extraerEscuela(){
            let url = `/api/escuelas/getEscuela?idEscuela=${this.idEscuela}`
            this.examenes = [];
            this.$http.get(url)
                .then((response) => {
                    this.escuelas= response.data;
                    this.nombre                             =   this.escuelas.nombre;
                    this.activo                             =   this.escuelas.activo;
                    this.dominio                            =   this.escuelas.dominio;

                    if (this.escuelas.sistemaeducativo_id > 0)
                        this.idSistema                      =   this.escuelas.sistemaeducativo_id;
                    else
                        this.idSistema = 0

                    this.dateinicio                         =   this.escuelas.fecha_inicio_contrato;
                    this.datefin                            =   this.escuelas.fecha_termino_contrato;

                    //alert(this.dateinicio)
                    this.nombre_contacto_informatico        =   this.escuelas.nombre_contacto_informatico;
                    this.correo_contacto_informatico        =   this.escuelas.correo_contacto_informatico;
                    this.telefono_contacto_informatico      =   this.escuelas.telefono_contacto_informatico;
                    this.nombre_contacto_docente            =   this.escuelas.nombre_contacto_docente;
                    this.correo_contacto_docente            =   this.escuelas.correo_contacto_docente;
                    this.telefono_contacto_docente          =   this.escuelas.telefono_contacto_docente;

                    this.preescolar_numero_profesores       =   this.escuelas.preescolar_numero_profesores;
                    this.preescolar_numero_alumnos          =   this.escuelas.preescolar_numero_alumnos;

                    this.primariabaja_numero_profesores     =   this.escuelas.primariabaja_numero_profesores;
                    this.primariabaja_numero_alumnos        =   this.escuelas.primariabaja_numero_alumnos;

                    this.secundaria_numero_profesores       =   this.escuelas.secundaria_numero_profesores;
                    this.secundaria_numero_alumnos          =   this.escuelas.secundaria_numero_alumnos;

                    this.bachillerato_numero_profesores     =   this.escuelas.bachillerato_numero_profesores;
                    this.bachillerato_numero_alumnos        =   this.escuelas.bachillerato_numero_alumnos;

                    this.universitario_numero_profesores    =   this.escuelas.universitario_numero_profesores;
                    this.universitario_numero_alumnos       =   this.escuelas.universitario_numero_alumnos;
                    this.getSistemasEducativos()
                    console.log(this.escuelas)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
    },
    mounted() {
        this.getSistemasEducativos()

        if (this.$route.query.tipoaccion == 2) {
            //Asignación de valores iniciales para edición
            this.texto = 'Editar Colegio/Instituto';
            this.mensaje_informacion = 'Los datos del Colegio han sido guardados exitosamente.'
            this.idEscuela = this.$route.query.idEscuela;
            this.tipoaccion = this.$route.query.tipoaccion;
            this.extraerEscuela()
        } else {
            this.texto = 'Crear Colegio/Instituto';
            this.mensaje_informacion = 'Los datos del Colegio han sido creados exitosamente.'
        }
    }
}
</script>
<template>
  <div>
    <v-dialog v-model="dialogVisualizarImagen">
      <v-card-title class="text-h5 white">
        Visualizando imagen
      </v-card-title>
      <v-card-text class="white">
        <v-img :src="src_imagen_visualizar"></v-img>
      </v-card-text>
      <v-card-actions class="white">
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="dialogVisualizarImagen = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-dialog>
    <v-container>
      <v-card
          elevation="2"
      >
        <v-card-title>
          <v-icon
              class="mr-2"
              color="green"
              large
          >
            mdi-checkbox-marked-outline
          </v-icon>
          <h3>{{ this.texto }}</h3>
        </v-card-title>
        <v-container class="grey lighten-5">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="2" sm="2">
              <v-text-field
                  id="txtvalor"
                  v-model="valor"
                  label="Valor examen test"
                  outlined
                  title="Valor examen en puntos"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-container fluid>
              <v-textarea
                  id="txttitulo"
                  v-model="titulo"
                  :rows="2"
                  label="Titulo:"
                  outlined
              ></v-textarea>
            </v-container>
          </v-row>
          <v-row>
            <v-container fluid>
              <v-textarea
                  v-model="instrucciones"
                  :rows="2"
                  label="Instrucciones (opcional):"
                  outlined
              ></v-textarea>
            </v-container>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="4">
              <v-dialog ref="dialogfechainicio" v-model="modalfechainicio" :return-value.sync="dateinicio"
                        persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dateinicio"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      label="Fecha inicio"
                      outlined
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateinicio" scrollable @selected="formatDate">

                  <v-btn color="primary" text @click="modalfechainicio = false">Cancelar</v-btn>
                  <v-btn color="primary" text @click="$refs.dialogfechainicio.save(dateinicio)">
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <v-dialog ref="dialoghorainicio" v-model="modalhorainicio" :return-value.sync="timeinicio"
                        persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      id="txttimeinicio"
                      v-model="timeinicio"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-clock-time-four-outline"
                      label="Hora de inicio"
                      outlined
                      readonly
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modalhorainicio" v-model="timeinicio" full-width>
                  <!--          <v-spacer></v-spacer>-->
                  <v-btn color="primary" text @click="modalhorainicio = false">Cancelar</v-btn>
                  <v-btn color="primary" text @click="$refs.dialoghorainicio.save(timeinicio)">
                    Aceptar
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="4">
              <v-dialog ref="dialogfechafin" v-model="modalfechafin" :return-value.sync="datefin"
                        persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="datefin" v-bind="attrs" v-on="on"
                                append-icon="mdi-calendar" label="Fecha fin" outlined readonly></v-text-field>
                </template>
                <v-date-picker v-model="datefin" scrollable @selected="formatDate">
                  <!--          <v-spacer></v-spacer>-->
                  <v-btn color="primary" text @click="modalfechafin = false">Cancelar</v-btn>
                  <v-btn color="primary" text @click="$refs.dialogfechafin.save(datefin)">Aceptar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-dialog
                  ref="dialoghorafin"
                  v-model="modalhorafin"
                  :return-value.sync="timefin"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      id="txttimefin"
                      v-model="timefin"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-clock-time-four-outline"
                      label="Hora de finalización"
                      outlined
                      readonly
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="modalhorafin"
                    v-model="timefin"
                    full-width
                >
                  <v-btn
                      color="primary"
                      text
                      @click="modalhorafin = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                      color="primary"
                      text
                      @click="$refs.dialoghorafin.save(timefin)"
                  >
                    Aceptar
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <!--                <v-col cols="3" sm="3">
                                        <template>
                                            <v-select
                                            label='Tiempo Hora límite:'
                                            v-model='tiempo_hora_responder'
                                            :items='horas'
                                            item-value='id'
                                            item-text='nombre'
                                            @change="consultarHora"
                                            >
                                            </v-select>
                                        </template>
                            </v-col>-->
            <!--                        <v-col cols="12" sm="4" md="4">
                                        <v-text-field
                                            label="Tiempo estimado examen"
                                            title="Valor examen en puntos"
                                            v-model="duracion_estimada"
                                            outlined
                                            readonly
                                        ></v-text-field>
                                    </v-col>-->
            <v-col cols="12" md="4" sm="4">

              <v-select
                  id="txt_tiempo_minutos_responder"
                  v-model='tiempo_minutos_responder'
                  :items='minutos'
                  item-text='nombre'
                  item-value='id'
                  label='Duración del examen:'
                  outlined
                  @change="consultarMinutos"
              >
              </v-select>

            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-select
                  id="txt_tipo_examen"
                  v-model='tipo_examen'
                  :items='array_tipo_examen'
                  item-text='nombre'
                  item-value='id'
                  label='Tipo de examen:'
                  outlined
                  @change="consultar_tipo_examen"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>

            <!--                        <v-col cols="12" sm="4" md="4">
                                        <v-text-field
                                            label="Contraseña (opcional)"
                                            title="contraseña de acceso para presentar el examen"
                                            v-model="password_examen"
                                            outlined
                                        ></v-text-field>
                                    </v-col>-->
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="4">
              <v-select
                  id="txt_orden_preguntas"
                  v-model='orden_preguntas'
                  :items='array_orden_preguntas'
                  item-text='nombre'
                  item-value='id'
                  label='Orden de preguntas:'
                  outlined
                  @change="consultar_orden_preguntas"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-select
                  id="txt_revision_estudiante"
                  v-model='revision_estudiante'
                  :items='array_revision_estudiante'
                  item-text='nombre'
                  item-value='id'
                  label='Revisión estudiante:'
                  outlined
                  @change="consultar_revision_estudiante"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="4">
              <v-select
                  v-model='numerointentosexamen'
                  :items='array_numerointentosexamen'
                  item-text='nombre'
                  item-value='id'
                  label='Número de intentos del examen:'
                  outlined
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-select
                  v-model='permitirpausarexamen'
                  :items='array_permitirpausarexamen'
                  item-text='nombre'
                  item-value='id'
                  label='Permitir pausar examen:'
                  outlined
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="4" md="4" sm="4" xs="4">
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="itemsPaginadoPreguntas"
                  label="Tipo de paginado de preguntas"
                  v-model="paginadoPreguntas" outlined></v-select>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="4">
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="itemsContadorTiempo"
                  label="Comportamiento del contador del examen"
                  v-model="comportamientoContador" outlined></v-select>
              <v-alert
                  border="top"
                  colored-border
                  type="info"
                  elevation="2"
              >{{ explicacionContador }}</v-alert>
            </v-col>
          </v-row>

          <!--                    <v-row>
                                  <v-col class="d-flex" cols="4" sm="4">
                                      <template>
                                          <v-select
                                              label='Curso:'
                                              v-model='idCurso'
                                              :items='cursos'
                                              item-value='id'
                                              outlined
                                              item-text="nombre"
                                              return-object
                                              @change="getCursoSeleccionado"
                                          >
                                              <template slot='selection' slot-scope='{ item }'>
                                                  {{item.description !=null ? item.nombre + '('+item.description+')': item.nombre}}
                                              </template>
                                              <template slot='item' slot-scope='{ item }'>
                                                  {{item.description !=null ? item.nombre + '('+item.description+')': item.nombre}}
                                              </template>
                                          </v-select>
                                      </template>
                                  </v-col>
                                  <v-col class="d-flex" cols="4" sm="4">
                                      <v-switch
                                          label="Examen sin tema"
                                          v-model="examensintema"
                                          v-bind:value="examensintema"
                                          @change="setDesactivarTema"
                                      ></v-switch>
                                  </v-col>
                              </v-row>-->
          <!--                    <v-row>
                                  <v-col class="d-flex" cols="4" sm="4">
                                      <template>
                                          <v-select
                                              label='Temas:'
                                              v-model='idTema'
                                              :items='topics'
                                              item-value='id'
                                              outlined
                                              item-text="name"
                                              return-object
                                              :disabled="desactivarSelectTema"
                                          >
                                              <template slot='selection' slot-scope='{ item }'>
                                                  {{item.name}}
                                              </template>
                                              <template slot='item' slot-scope='{ item }'>
                                                  {{item.name}}
                                              </template>
                                          </v-select>
                                      </template>
                                  </v-col>
                              </v-row>-->
          <template>
            <div>
              <div class="d-flex">
                <v-switch
                    v-model="personalizarexamen"
                    label="Examen diferente por alumno"
                    title="Habilita opción para que se generen exámenes diferentes por alumno, en base a los criterios de la seccion: Personalizar examen"
                    v-bind:value="personalizarexamen"
                    @change="revisar_personalizarexamen"
                ></v-switch>
              </div>

              <v-expansion-panels
                  v-model="panel"
                  :disabled="desactivarpersonalizar"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header><b>Personalizar Examen</b></v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <template>
                          <v-select
                              id="selectidnivel"
                              v-model='idNivel'
                              :disabled="desactivarpersonalizar"
                              :items='niveles'
                              item-text='nombre'
                              item-value='id'
                              label='Nivel Educativo:'
                              outlined
                              return-object
                              @change="consultarNivelEducativo"
                          >
                            <template slot='selection' slot-scope='{ item }'>
                              {{ item.nombre }}
                            </template>
                            <template slot='item' slot-scope='{ item }'>
                              {{ item.nombre }}
                            </template>
                          </v-select>
                        </template>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <template>
                          <v-select
                              id="selectidGrado"
                              v-model='idGrado'
                              :disabled="desactivarpersonalizar"
                              :items='grados'
                              item-text='nombre'
                              item-value='id'
                              label='Grado:'
                              outlined
                              return-object
                          >
                            <template slot='selection' slot-scope='{ item }'>
                              {{ item.nombre }}
                            </template>
                            <template slot='item' slot-scope='{ item }'>
                              {{ item.nombre }}
                            </template>
                          </v-select>
                        </template>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <template>
                          <v-select
                              id="selectidArea"
                              v-model='idArea'
                              :disabled="desactivarpersonalizar"
                              :items='areas'
                              item-text='nombre'
                              item-value='id'
                              label='Area de Conocimiento:'
                              outlined
                              return-object
                              @change="consultarArea"
                          >
                            <template slot='selection' slot-scope='{ item }'>
                              {{ item.nombre }}
                            </template>
                            <template slot='item' slot-scope='{ item }'>
                              {{ item.nombre }}
                            </template>
                          </v-select>
                        </template>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <template>
                          <v-select
                              id="selectidAsignatura"
                              v-model='idAsignatura'
                              :disabled="desactivarpersonalizar"
                              :items='asignaturas'
                              item-text='nombre'
                              item-value='id'
                              label='Asignatura:'
                              outlined
                              return-object
                          >
                            <template slot='selection' slot-scope='{ item }'>
                              {{ item.nombre }}
                            </template>
                            <template slot='item' slot-scope='{ item }'>
                              {{ item.nombre }}
                            </template>
                          </v-select>
                        </template>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <template>
                          <v-checkbox
                              v-model="activonivel1"
                              :disabled="desactivarpersonalizar"
                              label="Incluir preguntas nivel 1"
                              v-bind:value="activonivel1"
                              @change="incluirpreguntasnivel1(activonivel1)"
                          ></v-checkbox>
                        </template>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field v-model="numeroreactivosnivel1"
                                      :disabled="!activonivel1"
                                      :rules="[numerodepreguntas]"
                                      label="Cantidad de preguntas:"
                                      outlined
                                      style="width: 50%; display: inline-flex;"
                                      type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field v-model="puntajereactivosnivel1"
                                      :disabled="!activonivel1"
                                      :rules="[numerodepuntos]"
                                      label="Puntos por pregunta:"
                                      outlined
                                      style="width: 50%; display: inline-flex;"
                                      type="number"

                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <template>
                          <v-checkbox
                              v-model="activonivel2"
                              :disabled="desactivarpersonalizar"
                              label="Incluir preguntas nivel 2"
                              v-bind:value="activonivel2"
                              @change="incluirpreguntasnivel2(activonivel2)"
                          ></v-checkbox>
                        </template>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field v-model="numeroreactivosnivel2"
                                      :disabled="!activonivel2"
                                      :rules="[numerodepreguntas]"
                                      label="Cantidad de preguntas:"
                                      outlined
                                      style="width: 50%; display: inline-flex;"
                                      type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field v-model="puntajereactivosnivel2"
                                      :disabled="!activonivel2"
                                      :rules="[numerodepuntos]"
                                      label="Puntos por pregunta:"
                                      outlined
                                      style="width: 50%; display: inline-flex;"
                                      type="number"

                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <template>
                          <v-checkbox
                              v-model="activonivel3"
                              :disabled="desactivarpersonalizar"
                              label="Incluir preguntas nivel 3"
                              v-bind:value="activonivel3"
                              @change="incluirpreguntasnivel3(activonivel3)"
                          ></v-checkbox>
                        </template>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field v-model="numeroreactivosnivel3"
                                      :disabled="!activonivel3"
                                      :rules="[numerodepreguntas]"
                                      label="Cantidad de preguntas:"
                                      outlined
                                      style="width: 50%; display: inline-flex;"
                                      type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field v-model="puntajereactivosnivel3"
                                      :disabled="!activonivel3"
                                      :rules="[numerodepuntos]"
                                      label="Puntos por pregunta:"
                                      outlined
                                      style="width: 50%; display: inline-flex;"
                                      type="number"

                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </template>
          <v-row>
            <v-col cols="6" sm="6">
              <v-btn
                  id="btnReactivo"
                  class="mb-2 mt-5"
                  color="primary"
                  dark
                  @click="guardarExamen()"
              >Guardar Examen
              </v-btn>
            </v-col>

          </v-row>
        </v-container>
        <v-container>

          <v-alert
              v-if="!examen_guardado"
              dense
              type="info"
          >
            Debes de guardar primero el examen para agregar reactivos manualmente
          </v-alert>

          <v-dialog v-model="dialogReactivo">
            <v-card>
              <v-card-title>

                <v-icon class="mr-2" color="green" large>mdi-cloud-download</v-icon>
                <span class="headline">Agregar Reactivo</span>


              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-text-field v-model="search" append-icon="mdi-magnify" hide-details label="Buscar"
                                  single-line></v-text-field>
                  </v-row>
                  <v-data-table
                      :headers="headers"
                      :items="reactivos"
                      :search="search"
                      class="elevation-1"
                  >


                    <template v-slot:item.actions="{ item }">
                      <v-icon v-if=" !((reactivos_examen).indexOf(parseInt(item.id))>=0)"
                              class="mr-2"
                              color="green"
                              title="Agregar reactivo"
                              @click="anexarReactivo(item)"
                      >
                        mdi-cloud-download
                      </v-icon>

                    </template>

                  </v-data-table>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>

      </v-card>


      <div v-if="examen_guardado" class="mt-5">

        <v-alert
            border="left"
            dense
            type="warning"
        >
          Usted está visualizando una <strong>pre-visualización</strong> del examen y además tiene la posibilidad de
          añadir valores a los reactivos
        </v-alert>


        <div class="text-center">
          <v-btn
              :disabled="!examen_guardado"
              class="mb-0"
              color="primary"
              @click="abrirReactivos()"
          >Agregar reactivo
          </v-btn>
        </div>

        <form action="#" onsubmit="event.preventDefault()">
          <v-divider class="mt-5 mb-5"></v-divider>

          <h1 class="text-center">{{ this.titulo }}</h1>

          <h4 class="text-center">{{ this.instrucciones }}</h4>

          <div v-if="(parseInt(configuracion_examen.permitirpausarexamen) == 1)" class="text-center">[AQUÍ COMIENZA EL
            CONTADOR A CORRER A PARTIR DE <strong>{{ configuracion_examen.tiempo_minutos_responder }} MINUTOS</strong>]
          </div>
          <div v-else class="text-center">Este examen no puede pausarse</div>

          <div v-for="(pregunta, indexpregunta) in preguntas.reactivos">
            <div>
              <template>
                <v-card
                    class="ma-5"
                >
                  <v-card-text>

                    <v-row>
                      <v-col cols="12" lg="10" md="12" sm="12">

                        <div align="">
                          <v-btn class="red white--text" href="#!" small @click="EliminarReactivo(pregunta.id)">Eliminar
                            reactivo
                          </v-btn>
                          <br>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="2" md="12" sm="12">
                        <div align="">
                          <div>Valor reactivo</div>
                          <v-text-field :id="'txtvalorpregunta'+pregunta.id"
                                        :value="pregunta.detalle_reactivo.porcentaje"
                                        dense
                                        outlined
                                        style="width: 70%; display: inline-flex;"
                                        v-on:focusout="guardarporcentaje(pregunta.id,$event.target.value,pregunta.detalle_reactivo.porcentaje)"
                                        v-on:keyup.enter="guardarporcentaje(pregunta.id,$event.target.value,pregunta.detalle_reactivo.porcentaje)">
                          </v-text-field>
                        </div>
                      </v-col>
                    </v-row>


                    <v-divider class="mt-5 mb-5"></v-divider>

                    <p class="d-block">Pregunta # {{ indexpregunta + 1 }}</p>

                    <div style="">

                      <div v-if="pregunta.tiporeactivo_id!=6" style="">
                        <h3 class="text-justify">{{ pregunta.descripcion }}</h3>
                      </div>


                    </div>
                    <p class="text--primary text-justify">{{ pregunta.instrucciones }}</p>

                    <img v-if="pregunta.urlimagen_pregunta"
                         :src="pregunta.urlimagen_pregunta"
                         style="cursor: pointer"
                         title="Ver imagen completa"
                         @click="visualizarImagen(pregunta.urlimagen_pregunta)"></img>

                    <iframe v-if="( pregunta.enlace_externo_youtube )"
                            :src="'https://www.youtube.com/embed/' + pregunta.enlace_externo_youtube"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                            frameborder="0"
                            title="YouTube video player"></iframe>

                    <iframe v-if="( pregunta.youtube_video_id )"
                            :src="'https://www.youtube.com/embed/' + pregunta.youtube_video_id"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                            frameborder="0"
                            title="YouTube video player"></iframe>

                    <div v-if="pregunta.tiporeactivo_id == 3">
                      <v-radio-group v-model="respuestasSeleccionadas[pregunta.id]">
                        <v-radio
                            v-for="descripcion in pregunta.respuestas"
                            :key="descripcion.id"
                            :value="descripcion.id"
                            @click="leerRespuesta(pregunta.id,descripcion.id)"
                        >
                          <template v-slot:label>
                            {{ descripcion.respuesta }}
                            <v-chip v-if="(parseInt(descripcion.correcta) == 1)" class="ml-2 green white--text">
                              Respuesta correcta
                            </v-chip>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div v-else-if="pregunta.tiporeactivo_id ==2">
                      <v-checkbox
                          v-for="descripcion in pregunta.respuestas"
                          :input-value="( descripcion.reactivo_contestado && (descripcion.id == descripcion.reactivo_contestado.respuesta_id) ) ? true : false"
                          @change="saveResponses(pregunta.id, descripcion.id, pregunta.tiporeactivo_id, $event)"
                      >
                        <template v-slot:label>

                          {{ descripcion.respuesta }}
                          <img
                              v-if="descripcion.imagen_respuesta"
                              :src="descripcion.imagen_respuesta"
                              style="margin-left: 10px; cursor: pointer"

                              title="Ver imagen completa"
                              @click="visualizarImagen(descripcion.imagen_respuesta)"
                          ></img>
                          <v-chip v-if="(parseInt(descripcion.correcta) == 1)" class="ml-2 green white--text">Respuesta
                            correcta
                          </v-chip>
                        </template>
                      </v-checkbox>
                    </div>
                    <div v-else-if="pregunta.tiporeactivo_id ==1">

                      <v-radio-group
                          :value="( pregunta.reactivo_contestado ) ? pregunta.reactivo_contestado.respuesta_id : 0">
                        <v-radio
                            v-for="(itemradio, index) in pregunta.respuestas"
                            :id="'respuesta-' + itemradio.id"
                            :value="itemradio.id"

                            @change="saveResponses(pregunta.id, itemradio.id, pregunta.tiporeactivo_id)"
                        >
                          <template v-slot:label>
                            {{ itemradio.respuesta }}
                            <img
                                v-if="itemradio.imagen_respuesta"
                                :src="itemradio.imagen_respuesta"
                                style="margin-left: 10px; cursor: pointer"

                                title="Ver imagen completa"
                                @click="visualizarImagen(descripcion.imagen_respuesta)"
                            ></img>
                            <v-chip v-if="(parseInt(itemradio.correcta) == 1)" class="ml-2 green white--text">Respuesta
                              correcta
                            </v-chip>
                          </template>

                        </v-radio>
                      </v-radio-group>

                    </div>
                    <div v-else-if="pregunta.tiporeactivo_id == 6">
                      <component is="Test" :idExamen="parseInt(idExamen)"
                                 :onlyeread="1" :pregunta="pregunta" :previsualizacion="1"
                                 :student_stauts_exam="student_stauts_exam"/>
                      <!--                          <v-text-field
                                                    v-for="(descripcion,index) in pregunta.respuestas"
                                                    :key="descripcion.id"
                                                    :label="conversion(index)"
                                                ></v-text-field>-->
                    </div>

                  </v-card-text>
                  <v-card-actions>


                    <div v-if="pregunta.permitir_justificante">
                      <v-btn class="primary" href="#!">Subir justificante</v-btn>
                    </div>

                  </v-card-actions>
                </v-card>
              </template>
            </div>
          </div>
        </form>
      </div>


    </v-container>
  </div>


</template>
<style scoped>
iframe {
  width: 800px;
  height: 500px;
  margin: 10px;
}

@media only screen and (max-width: 768px) {
  iframe {
    width: 100%;
    max-height: 200px;
    min-height: 200px;
    margin: 10px;
  }
}
</style>
<script>
import moment from 'moment';
import axios from 'axios'
import Test from '@/views/Evaluacion/Test.vue'

moment.locale('es');
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
export default {
  components: {Test},
  data: () => ({
    itemsContadorTiempo: [
      {
        id: 1,
        name: 'Contador flexible',
      },
      {
        id: 2,
        name: 'Contador estricto',
      }
    ],
    comportamientoContador: [],
    itemsPaginadoPreguntas: [
      {
        id: 1,
        name: 'Paginado (recomendado)'
      },
      {
        id: 2,
        name: 'Completo (todos los reactivos se muestran al mismo tiempo)'
      }
    ],
    paginadoPreguntas: [],
    student_stauts_exam: {
      leftSecondsExam: '',
      exam_finished: '',
    },
    src_imagen_visualizar: '',
    dialogVisualizarImagen: false,
    examen_guardado: 0,
    search: '', //se ocupa para buscar reactivos
    array_tipo_examen: [
      {id: '1', nombre: 'Diagnóstico'},
      {id: '2', nombre: 'Cognitivo'},
    ],
    array_orden_preguntas: [
      {id: '1', nombre: 'Secuencial'},
      {id: '2', nombre: 'Aleatorio'},
    ],
    array_numerointentosexamen: [
      {id: '1', nombre: '1'},
      {id: '2', nombre: '2'},
      {id: '3', nombre: '3'},
    ],
    array_permitirpausarexamen: [
      {id: '0', nombre: 'No'},
      {id: '1', nombre: 'Sí'},
    ],
    array_revision_estudiante: [
      {id: '1', nombre: 'Mostrar calificación y respuestas correctas e incorrectas'},
      {id: '2', nombre: 'Mostrar sólo calificación'},
      {id: '3', nombre: 'No mostrar nada (has concluido tu examen tu docente te comunicará tu resultado).'},
    ],
    dialog: false,
    dialogReactivo: false,
    horas: [
      {id: '0', nombre: '0'},
      {id: '1', nombre: '1'},
      {id: '2', nombre: '2'},
      {id: '3', nombre: '3'},
      {id: '4', nombre: '4'},
      {id: '5', nombre: '5'},
      {id: '6', nombre: '0'},
    ],
    minutos: [
      {id: '5', nombre: '5 minutos'},
      {id: '10', nombre: '10 minutos'},
      {id: '15', nombre: '15 minutos'},
      {id: '30', nombre: '30 minutos'},
      {id: '45', nombre: '45 minutos'},
      {id: '60', nombre: '60 minutos'},
      {id: '75', nombre: '75 minutos'},
      {id: '90', nombre: '90 minutos'},
      {id: '105', nombre: '105 minutos'},
      {id: '120', nombre: '120 minutos (2 horas)'},
      {id: '150', nombre: '150 minutos'},
      {id: '180', nombre: '180 minutos (3 horas)'},
    ],
    singleSelect: true,
    selected: [],
    absolute: false,
    oculto: true,
    opacity: 0.46,
    editedIndex: -1,
    zIndex: 5,
    nombre: '',
    idEscuela: '',
    idExamen: '',
    desactivarbtnagregarreactivo: false,
    respuestasSeleccionadas: [],
    tiporeactivo: null,
    tipoaccion: '',
    guardando: false,
    escuela_id: 1,
    banco: [],
    reactivos: [],
    valor: 0,
    texto: '',
    respuesta: '',
    textbtn: 'Agregar eactivo',
    titulo: '',
    instrucciones: '',
    snack: false,
    snackColor: '',
    snackText: '',
    max25chars: v => v.length <= 25 || 'Input too long!',
    pagination: {},
    headers: [
      {text: 'Id', align: 'start', sortable: true, value: 'id'},
      {text: 'Escuela', align: 'start', sortable: false, value: 'escuela.escuela_concentrado.nombre'},
      {text: 'Nivel', sortable: true, value: 'nivel.nivel_concentrado.nombre'},
      {text: 'Grado', sortable: true, value: 'grado.grado_concentrado.nombre'},
      {text: 'Área', sortable: true, value: 'area.area_concentrado.nombre'},
      {text: 'Asignatura', sortable: true, value: 'asignatura.asignatura_concentrado.nombre'},
      {text: 'Descripcion', value: 'descripcion'},
      {text: 'Tipo Reactivo', value: 'tiporeactivo.tiporeactivo_concentrado.nombre'},

      {text: 'Acciones', value: 'actions', sortable: true},
    ],
    overlay: false,
    absolute: true,
    timeinicio: null,
    examenes: [],
    timefin: null,
    modalhorainicio: false,
    modalhorafin: false,
    modalfechainicio: false,
    modalfechafin: false,
    dateinicio: new Date().toISOString().substr(0, 10),
    datefin: new Date().toISOString().substr(0, 10),
    tiempo_hora_responder: '',
    tiempo_minutos_responder: 0,
    preguntas: [],
    duracion_estimada: 0,
    password_examen: '',
    orden_preguntas: 2,
    revision_estudiante: 2,
    expanded: false,
    personalizar_examen: 0,
    mensaje_informacion: '',
    tipo_examen: 0,
    panel: [0],
    personalizarexamen: false,

    numerointentosexamen: 1,
    permitirpausarexamen: 0,
    idNivel: '', niveles: [], nivel_id: '',
    idGrado: '', grados: [], grado_id: '',
    idArea: '', areas: [], area_id: '',
    idCurso: '', cursos: [], curso_id: '',
    idTema: 0, topics: [], tema_id: '',
    examensintema: 1,
    idAsignatura: '', asignaturas: [], asignatura_id: '',
    activonivel1: 0, numeroreactivosnivel1: 0, puntajereactivosnivel1: 0,
    activonivel2: 0, numeroreactivosnivel2: 0, puntajereactivosnivel2: 0,
    activonivel3: 0, numeroreactivosnivel3: 0, puntajereactivosnivel3: 0,
    reactivos_examen: [],
    sumatotalvalores: 0,
    calculotemporalsumatotalvalorpregunta: 0,
    totalreactivosagregadosaexamen: 0,
    desactivarpersonalizar: true,
    desactivartema: true,
    desactivarSelectTema: true,
    configuracion_examen: [],


  }),
  computed: {
    explicacionContador (){
      if ( this.comportamientoContador == 1 ) {
        return 'El contador flexible permite a cualquier persona, cerrar su examen y volverlo a abrir dentro del intervalo de fecha y hora del examen, el contador flexible siempre arrancará a contar desde el último tiempo en el que la persona tuvo por última vez abierto el examen al que está aplicando. Por ejemplo, si hay un examen con duración de 60 minutos y una persona inicia a las 10 de la mañana, y lo cierra a las 10:20 de la mañana (en ese momento le quedan 40 minutos todavía restantes), si la persona vuelve a las 10:50 de la mañana a terminarlo, el contador empezará a contar desde los 40 minutos restantes que tenía para hacer dicho examen'
      }
      else {
        return 'El contador estricto empezará a contar desde la primera vez en que la persona abrió su examen. Esto quiere decir que aunque cierre el examen y lo vuelva a abrir, el contador empezará a contar desde la primera vez en que la persona tuvo abierto dicho examen. Por ejemplo, si la persona inició a las 10 de la mañana y el examen tenía duración de 60 minutos, si la persona cierra a las 10:20 de la mañana el examen y vuelve a las 10:55, el contador estricto empezará a contar desde 5 minutos hacia abajo'
      }
    },
    conversion() {
      return index => `Respuesta ${index + 1}`
    }
  },
  created() {

  },
  methods: {
    init() {

    },
    visualizarImagen(imagen) {
      this.src_imagen_visualizar = imagen
      // this.dialogVisualizarImagen = true
      window.open(this.src_imagen_visualizar, "_blank")
    },

    abrirReactivos() {
      this.dialogReactivo = true
      this.getReactivos()
    },
    actualizarCorrecta(item) {
      this.guardando = true;
      if (item.correcta == null) {
        item.correcta = 0;
      } else
        item.correcta = 1;
      this.$http.post('/api/respuestas', {
        'id': item.id,
        'correcta': item.correcta,
        'reactivo_id': this.idExamen,
        'tiporeactivo_id': this.tiporeactivo
      })
          .then((response) => {
            this.getRespuestas();
            this.guardando = false;

          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getCursoSeleccionado() {
      //axios.get('/api/cursotopic/getCursoTopics?courseId=' + (this.idCurso.course_id) + '&curso_id=' +(this.idCurso.id), {
      axios.get('/api/cursotopic/getCursoTopics?curso_id=' + this.idCurso.id, {})
          .then((response) => {
            this.topics = response.data;

          })
          .catch(function (error) {
                console.log(error)
              }
          );
    },
    numerodepreguntas: v => {
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) {
        return true;
      }
      {
        return 'Los límites para el número de preguntas son de 0-100. Considerar cuantas preguntas tiene en su Banco de preguntas, con respecto al criterio seleccionado.';
      }
    },
    numerodepuntos: v => {
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) {
        return true;
      }
      {
        return 'Los límites para el número de puntos por pregunta es de 0-100. ';
      }
    },
    numerodevalor: v => {
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) {
        return true;
      }
      {
        return 'Los límites para el número de puntos por pregunta es de 0-100. ';
      }
    },
    consultarArea() {
      let url = '';
      url = `/api/asignaturas?idArea=${this.idArea.id}`
      this.$http.get(url)
          .then((response) => {
            this.asignaturas = response.data;
            if (this.tipoaccion == 2) {
              if (this.asignatura_id > 0)
                this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
              else
                this.idAsignatura = 0
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    consultarNivelEducativo() {
      let url = '';
      url = `/api/grados?idnivel=${this.idNivel.id}`
      this.$http.get(url)
          .then((response) => {
            this.grados = response.data;
            if (this.tipoaccion == 2) {
              if (this.grado_id > 0)
                this.idGrado = this.grados.find(item => item.id == this.grado_id)
              else
                this.idGrado = 0
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    extraerExamen() {
      let url = `/api/examenes?idExamen=${this.idExamen}`
      this.examenes = [];
      this.$http.get(url)
          .then((response) => {
            //console.log(response.data)
            this.examenes = response.data;
          })
          .then(() => {
            //alert(this.examenes[0].titulo)
            this.configuracion_examen = this.examenes[0];
            this.comportamientoContador = this.configuracion_examen['comportamiento_contador'];
            this.paginadoPreguntas = this.configuracion_examen['tipo_paginado_preguntas'];
            this.titulo = this.examenes[0].titulo;
            this.valor = this.examenes[0].valor;
            this.dateinicio = this.examenes[0].fecha_inicio;
            this.datefin = this.examenes[0].fecha_fin;
            this.instrucciones = this.examenes[0].instrucciones;
            this.timeinicio = this.examenes[0].hora_inicio;
            this.timefin = this.examenes[0].hora_fin;
            //this.tiempo_hora_responder = this.examenes[0].tiempo_hora_responder;
            this.tiempo_hora_responder = this.horas.find(item => item.id == this.examenes[0].tiempo_hora_responder)
            this.tiempo_hora_responder = this.tiempo_hora_responder.id
            this.tiempo_minutos_responder = this.minutos.find(item => item.id == this.examenes[0].tiempo_minutos_responder)
            this.tiempo_minutos_responder = this.tiempo_minutos_responder.id

            //nuevos campos
            this.tipo_examen = this.array_tipo_examen.find(item => item.id == this.examenes[0].tipo_examen)
            this.tipo_examen = this.tipo_examen.id

            this.password_examen = this.examenes[0].password_examen;

            this.orden_preguntas = this.array_orden_preguntas.find(item => item.id == this.examenes[0].orden_preguntas)
            this.orden_preguntas = this.orden_preguntas.id

            this.revision_estudiante = this.array_revision_estudiante.find(item => item.id == this.examenes[0].revision_estudiante)
            this.revision_estudiante = this.revision_estudiante.id

            this.numerointentosexamen = this.array_numerointentosexamen.find(item => item.id == this.examenes[0].numerointentosexamen)
            this.numerointentosexamen = this.numerointentosexamen.id

            this.permitirpausarexamen = this.array_permitirpausarexamen.find(item => item.id == this.examenes[0].permitirpausarexamen)
            this.permitirpausarexamen = this.permitirpausarexamen.id

            // el examen no es personalizado
            if (this.examenes[0].personalizarexamen == 0) {
              this.personalizarexamen = ''
              this.desactivarpersonalizar = true
              this.desactivarbtnagregarreactivo = false;
              this.examen_guardado = 1
            }
            // el examen si es personalizado
            else {
              this.personalizarexamen = true
              this.desactivarpersonalizar = false
              this.desactivarbtnagregarreactivo = true;
            }

            //console.log("personalizar examen:" + this.personalizarexamen + "desactivarbtnagregarreactivo" + this.desactivarbtnagregarreactivo)
            if (this.examenes[0].nivelacademico_id > 0)
              this.nivel_id = this.examenes[0].nivelacademico_id
            else
              this.nivel_id = 0

            if (this.examenes[0].grado_id > 0)
              this.grado_id = this.examenes[0].grado_id
            else
              this.grado_id = 0

            if (this.examenes[0].area_id > 0)
              this.area_id = this.examenes[0].area_id
            else
              this.area_id = 0

            if (this.examenes[0].asignatura_id > 0)
              this.asignatura_id = this.examenes[0].asignatura_id
            else
              this.asignatura_id = 0

            if (this.examenes[0].curso_id > 0)
              this.curso_id = this.examenes[0].curso_id
            else
              this.curso_id = 0

            /*                    if (this.examenes[0].tema_id > 0)
                                    this.tema_id = this.examenes[0].tema_id
                                else
                                    this.tema_id = 0*/

            /*                    if(this.examenes[0].examensintema==0){
                                    this.desactivarSelectTema = false
                                }
                                else if (this.examenes[0].examensintema==1)
                                    this.desactivarSelectTema = true*/

            this.activonivel1 = this.examenes[0].activonivel1
            this.numeroreactivosnivel1 = this.examenes[0].numeroreactivosnivel1
            this.puntajereactivosnivel1 = this.examenes[0].puntajereactivosnivel1

            this.activonivel2 = this.examenes[0].activonivel2
            this.numeroreactivosnivel2 = this.examenes[0].numeroreactivosnivel2
            this.puntajereactivosnivel2 = this.examenes[0].puntajereactivosnivel2

            this.activonivel3 = this.examenes[0].activonivel3
            this.numeroreactivosnivel3 = this.examenes[0].numeroreactivosnivel3
            this.puntajereactivosnivel3 = this.examenes[0].puntajereactivosnivel3

            //this.desactivarbtnagregarreactivo = 1;
            //console.log("personalizar" + this.personalizarexamen + "desactivarbtnagregarreactivo"+ this.desactivarbtnagregarreactivo)
            this.getNivelesEducativos()
            this.getAreas()
            //this.getCursos();

            this.consultarExamen()

          })
          .catch(function (error) {
            console.log(error)
          })
    },
    extraerExamenDespuesDeGuardar() {
      let url = `/api/examenes?idExamen=${this.idExamen}`
      this.examenes = [];
      this.$http.get(url)
          .then((response) => {
            //console.log(response.data)
            this.examenes = response.data;
          })
          .then(() => {
            this.consultarExamen()

          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getAreas() {
      let url = '';
      url = '/api/areas'
      this.idArea = '';
      this.$http.get(url)
          .then((response) => {
            this.areas = response.data;
            if (this.tipoaccion == 2) {
              if (this.area_id > 0)
                this.idArea = this.areas.find(item => item.id == this.area_id)
              else
                this.idArea = 0
              this.consultarArea(this.idArea)
            }

          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getNivelesEducativos() {
      let url = '';
      url = '/api/niveleseducativos'
      this.$http.get(url)
          .then((response) => {
            //console.log(this.nivel_id)
            this.niveles = response.data;
            if (this.tipoaccion == 2) {
              if (this.nivel_id > 0)
                this.idNivel = this.niveles.find(item => item.id == this.nivel_id)
              else
                this.idNivel = 0
              this.consultarNivelEducativo()
              /*                        if (this.tiporeactivo == 3) {
                                          this.desactivarbtnagregarreactivo = true;
                                      } else {
                                          this.desactivarbtnagregarreactivo = false;
                                      }*/
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    formatDate(date) {
      return moment(date, "DDMMYYYY").format('ll');
    },
    guardarporcentaje(idpregunta, calificacion, valoractual) {
      if (isNaN(calificacion)) {
        Swal.fire({
          icon: 'error',
          title: 'Atención',
          text: 'Debe ingresar un valor numerico.',
          onClose: function () {
            document.getElementById('txtvalorpregunta' + idpregunta).focus()
          }
        })
        return false
      }
      if (this.valor <= 0) {
        Swal.fire(
            'Atención',
            'Debe ingresar un valor a su examen.',
            'warning'
        )
        txtvalor.focus();
        return false
      }
      this.calculotemporalsumatotalvalorpregunta = 0;
      console.log("\n-----suma temporal: " + this.sumatotalvalores)
      if (valoractual != null)
        this.calculotemporalsumatotalvalorpregunta = (this.sumatotalvalores - valoractual) + parseFloat(calificacion);
      else
        this.calculotemporalsumatotalvalorpregunta = this.sumatotalvalores + parseFloat(calificacion);

      if (this.calculotemporalsumatotalvalorpregunta <= this.valor) {
        let params = {
          'idExamen': this.idExamen,
          'porcentaje': calificacion,
          'reactivo_id': parseInt(idpregunta),
          'actualizarprocentaje': 1
        }
        this.$http.post('/api/detalles', params)
            .then((response) => {
              console.log("guardando nuevo valor")
              this.sumatotalvalores = this.sumatotalvalores + this.calificacion

              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Valor de pregunta registrada exitosamente.',
                showConfirmButton: false,
                timer: 1500
              })
              this.consultarExamen()
            })
            .catch(function (error) {
              console.log(error)
            })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Valor de pregunta, no permitido',
          text: 'Con este valor a la pregunta, excede la califación total asignada al examen. Favor de cambiar el valor asignado a la pregunta',
          onClose: function () {
            document.getElementById('txtvalorpregunta' + idpregunta).focus()
          }
        })
      }

    },

    incluirpreguntasnivel1() {
      if (this.activonivel1 == true)
        this.activonivel1 = 1;
      else {
        this.activonivel1 = 0;
        this.numeroreactivosnivel1 = 0;
        this.puntajereactivosnivel1 = 0;
      }
    },

    incluirpreguntasnivel2() {
      if (this.activonivel2 == true)
        this.activonivel2 = 1;
      else {
        this.activonivel2 = 0;
        this.numeroreactivosnivel2 = 0;
        this.puntajereactivosnivel2 = 0;
      }
    },
    incluirpreguntasnivel3() {
      if (this.activonivel3 == true)
        this.activonivel3 = 1;
      else {
        this.activonivel3 = 0;
        this.numeroreactivosnivel3 = 0;
        this.puntajereactivosnivel3 = 0;
      }
    },
    revisar_personalizarexamen() {
      if (this.personalizarexamen == null) {
        console.log("1" + this.personalizarexamen)
        this.desactivarpersonalizar = true;
        this.activonivel1 = 0;
        this.numeroreactivosnivel1 = 0;
        this.puntajereactivosnivel1 = 0;
        this.activonivel2 = 0;
        this.numeroreactivosnivel2 = 0;
        this.puntajereactivosnivel2 = 0;
        this.activonivel3 = 0;
        this.numeroreactivosnivel3 = 0;
        this.puntajereactivosnivel3 = 0;
        this.idNivel = '';
        this.idGrado = '';
        this.idArea = '';
        this.idAsignatura = '';
        this.desactivarbtnagregarreactivo = false;
        // this.guardarExamen()
        // this.extraerExamen()
      } else {
        if (this.totalreactivosagregadosaexamen > 0) {
          //decirle si quiere continuar porque se borraran los reactivos que había agregado ya al examen
          Swal.fire({
            title: '¿Desea continuar?',
            text: "Ya tiene preguntas agregadas al examen, al elegir esta opción se eliminarán del examen actual.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, deseo continuar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              //this.personalizarexamen = true;
              this.desactivarpersonalizar = false
              this.desactivarbtnagregarreactivo = true;
              let params = {
                'idExamen': this.idExamen,
                'eliminardetalles': 1
              }
              //console.log("párametros de eliminacion:")
              //console.log(params)
              this.$http.post('/api/detalles', params)
                  .then((response) => {
                    this.guardarExamen()
                    this.extraerExamen()
                    /*Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Actualización realizada exitosamente.',
                        showConfirmButton: false,
                        timer: 1500,
                        onClose: function () {

                        }
                    })*/
                  })
                  .catch(function (error) {
                  })

            } else {
              this.desactivarpersonalizar = true;
              this.desactivarbtnagregarreactivo = false;
            }
          })
        } else {
          this.desactivarpersonalizar = false;
          this.desactivarbtnagregarreactivo = true;
        }
      }
    },
    actualizarRespuesta(item) {
      this.guardando = true;
      this.$http.post('/api/respuestas', {
        'id': item.id,
        'respuesta': item.respuesta
      })
          .then((response) => {
            this.getRespuestas();
            this.guardando = false;
            //this.overlay = false;
          })
          .catch(function (error) {
            // console.log(error)
            // this.overlay = false;
          })

    },
    anexarReactivo(item) {
      this.guardando = true;
      this.$http.post('/api/detalles', {
        'reactivo_id': item.id,
        'examen_id': this.idExamen
      })
          .then((response) => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Pregunta agregada a examen exitosamente.',
              showConfirmButton: false,
              timer: 1500
            })
            this.getRespuestas();
            this.guardando = false;
            this.consultarExamen()
            //this.overlay = false;
          })
          .catch(function (error) {
            // console.log(error)
            // this.overlay = false;
          })
    },
    consultarHora(id) {
      this.tiempo_hora_responder = id;
    },
    consultarMinutos(id) {
      this.tiempo_minutos_responder = id;
    },
    consultar_tipo_examen(id) {
      this.tipo_examen = id;
    },
    consultar_orden_preguntas(id) {
      this.orden_preguntas = id;
    },
    consultar_revision_estudiante(id) {
      this.revision_estudiante = id;
    },
    deleteRespuesta(item) {
      var refs = ''
      refs = confirm('¿Deseas eliminar la respuesta: ' + item.respuesta + '?')
      if (refs) {
        //this.overlay = true;
        let url = `/api/respuestas/${item.id}`
        this.$http
            .delete(url)
            .then((response) => {
              this.getRespuestas()
            })
            .catch(function (error) {
              console.log(error)
              //this.overlay = false;
            })
      } else {
        // cancel
      }
    },
    EliminarReactivo(id) {
      var refs = ''
      refs = confirm('¿Deseas eliminar el reactivo del examen ?')
      if (refs) {
        let url = `/api/detalles/${this.idExamen}`
        this.$http.post(url, {
              idpregunta: id,
              _method: 'DELETE'
            }
        )
            .then((response) => {
              this.consultarExamen()
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Pregunta eliminada exitosamente.',
                showConfirmButton: false,
                timer: 1500
              })

            })
            .catch(function (error) {
              console.log(error)
              //this.overlay = false;
            })
      } else {
        // cancel
      }
    },
    getCursos() {
      let url = '/api/cursos'
      this.idCurso = '';
      this.$http.get(url)
          .then((response) => {
            this.cursos = response.data;
            //console.log(this.curso_id);
            if (this.tipoaccion == 2) {
              if (this.curso_id > 0) {
                this.idCurso = this.cursos.find(item => item.id == this.curso_id)
                axios.get('/api/cursotopic/getCursoTopics?curso_id=' + this.idCurso.id, {})
                    .then((response) => {
                      this.topics = response.data;
                      if (this.tipoaccion == 2) {
                        if (this.tema_id > 0)
                          this.idTema = this.topics.find(item => item.id == this.tema_id)
                      } else
                        this.idTema = 0
                    })
                    .catch(function (error) {
                      console.log(error)
                    });
              } else
                this.idCurso = 0
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    guardarExamen() {
      // significa que el examen no es personalizado
      if (!this.personalizarexamen) {
        this.examen_guardado = 1
      }

      let valorpersonazalido = 0;
      let valorexamensintema = 0;
      if (this.idExamen == '') {
        if (this.valor <= 0) {
          Swal.fire(
              'Atención',
              'Debe ingresar un valor a su examen.',
              'warning'
          )
          txtvalor.focus();
        } else if (this.titulo == '') {
          Swal.fire(
              'Atención',
              'Debe ingresar un título a su examen.',
              'warning'
          )
          txttitulo.focus();
        } else if (this.timeinicio == null) {
          Swal.fire(
              'Atención',
              'Debe ingresar una hora a partir de la cual los alumnos podrán empezar a responder el examen.',
              'warning'
          )
          txttimeinicio.focus();
        } else if (this.timefin == null) {
          Swal.fire(
              'Atención',
              'Debe ingresar una hora en la cual los alumnos ya no podrán tenner acceso para responder el examen.',
              'warning'
          )
          txttimefin.focus();
        } else if (this.tiempo_minutos_responder == 0) {
          Swal.fire(
              'Atención',
              'Debe indicar qué tiempo durará el examen.',
              'warning'
          )
          txt_tiempo_minutos_responder.focus();
        } else if (this.tipo_examen == 0) {
          Swal.fire(
              'Atención',
              'Debe indicar el tipo de examen.',
              'warning'
          )
          txt_tipo_examen.focus();
        } else if (this.orden_preguntas == 0) {
          Swal.fire(
              'Atención',
              'Debe indicar el tipo de examen.',
              'warning'
          )
          txt_orden_preguntas.focus();
        } else if (this.revision_estudiante == 0) {
          Swal.fire(
              'Atención',
              'Debe indicar si habrá revisión por parte del estudiante de los resultados de su examen.',
              'warning'
          )
          txt_revision_estudiante.focus();
        } else {
          if (this.instrucciones == '')
            this.instrucciones = '';
          if (this.idNivel == 0)
            this.idNivel = 0
          else
            this.idNivel = this.idNivel.id

          if (this.idGrado == 0)
            this.idGrado = 0
          else
            this.idGrado = this.idGrado.id

          if (this.idArea == 0)
            this.idArea = 0
          else
            this.idArea = this.idArea.id

          if (this.idAsignatura == 0)
            this.idAsignatura = 0
          else
            this.idAsignatura = this.idAsignatura.id

          /*                    if (this.idCurso == 0)
                              {
                                  Swal.fire(
                                      'Atención',
                                      'Debe indicar Curso.',
                                      'warning'
                                  )
                                  return false
                              }
                              else
                                  this.idCurso = this.idCurso.id*/

          if (this.personalizarexamen == true)
            this.valorpersonazalido = 1
          else
            this.valorpersonazalido = 0

          if (this.examensintema == true)
            this.valorexamensintema = 1
          else
            this.valorexamensintema = 0

          if (this.idTema == 0)
            this.idTema = 0
          else
            this.idTema = this.idTema.id

          let params = {
            'titulo': this.titulo,
            'valor': this.valor,
            'fecha_inicio': this.dateinicio,
            'fecha_fin': this.datefin,
            'instrucciones': this.instrucciones,
            'hora_inicio': this.timeinicio,
            'hora_fin': this.timefin,
            'tiempo_hora_responder': 0,
            'tiempo_minutos_responder': this.tiempo_minutos_responder,
            'tipo_examen': this.tipo_examen,
            'password_examen': this.password_examen,
            'orden_preguntas': this.orden_preguntas,
            'revision_estudiante': this.revision_estudiante,
            'personalizarexamen': this.valorpersonazalido,
            'numerointentosexamen': this.numerointentosexamen,
            'permitirpausarexamen': this.permitirpausarexamen,
            'nivelacademico_id': this.idNivel,
            'grado_id': this.idGrado,
            'area_id': this.idArea,
            'asignatura_id': this.idAsignatura,
            //'curso_id':this.idCurso,

            'activonivel1': this.activonivel1,
            'numeroreactivosnivel1': this.numeroreactivosnivel1,
            'puntajereactivosnivel1': this.puntajereactivosnivel1,

            'activonivel2': this.activonivel2,
            'numeroreactivosnivel2': this.numeroreactivosnivel2,
            'puntajereactivosnivel2': this.puntajereactivosnivel2,

            'activonivel3': this.activonivel3,
            'numeroreactivosnivel3': this.numeroreactivosnivel3,
            'puntajereactivosnivel3': this.puntajereactivosnivel3,
            comportamientoContador: this.comportamientoContador,
            paginadoPreguntas: this.paginadoPreguntas,
            //'examensintema' : this.valorexamensintema,
            //'tema_id':this.idTema
          } //insert
          this.$http.post('/api/examenes', params)
              .then((response) => {
                this.idExamen = response.data.id;
                //this.consultarExamen()
                //this.extraerExamen()
                Swal.fire(
                    'Guardado!',
                    'Examen guardado exitosamente.',
                    'success'
                )

              })
              .catch(function (error) {
                console.log(error)
              })
        }
      } else {
        if (this.instrucciones == '')
          this.instrucciones = '';

        if (this.idNivel == 0)
          this.idNivel = 0
        else
          this.idNivel = this.idNivel.id

        if (this.idGrado == 0)
          this.idGrado = 0
        else
          this.idGrado = this.idGrado.id

        if (this.idArea == 0)
          this.idArea = 0
        else
          this.idArea = this.idArea.id

        if (this.idAsignatura == 0)
          this.idAsignatura = 0
        else
          this.idAsignatura = this.idAsignatura.id
        /*                if (this.idCurso == 0)
                            this.idCurso = 0
                        else
                            this.idCurso = this.idCurso.id

                        if (this.idTema == 0)
                            this.idTema = 0
                        else
                            this.idTema = this.idTema.id*/


        if (this.personalizarexamen == true)
          this.valorpersonazalido = 1
        else
          this.valorpersonazalido = 0
        /*                if(this.examensintema==true)
                            this.valorexamensintema = 1
                        else
                            this.valorexamensintema = 0*/

        let params = {
          'idExamen': this.idExamen,
          'titulo': this.titulo,
          'valor': this.valor,
          'fecha_inicio': this.dateinicio,
          'fecha_fin': this.datefin,
          'instrucciones': this.instrucciones,
          'hora_inicio': this.timeinicio,
          'hora_fin': this.timefin,
          'tiempo_hora_responder': 0,
          'tiempo_minutos_responder': this.tiempo_minutos_responder,
          'tipo_examen': this.tipo_examen,
          'password_examen': this.password_examen,
          'orden_preguntas': this.orden_preguntas,
          'revision_estudiante': this.revision_estudiante,
          'personalizarexamen': this.valorpersonazalido,
          'numerointentosexamen': this.numerointentosexamen,
          'permitirpausarexamen': this.permitirpausarexamen,
          'nivelacademico_id': this.idNivel,
          'grado_id': this.idGrado,
          'area_id': this.idArea,
          'asignatura_id': this.idAsignatura,
          //'curso_id':this.idCurso,

          'activonivel1': this.activonivel1,
          'numeroreactivosnivel1': this.numeroreactivosnivel1,
          'puntajereactivosnivel1': this.puntajereactivosnivel1,

          'activonivel2': this.activonivel2,
          'numeroreactivosnivel2': this.numeroreactivosnivel2,
          'puntajereactivosnivel2': this.puntajereactivosnivel2,

          'activonivel3': this.activonivel3,
          'numeroreactivosnivel3': this.numeroreactivosnivel3,
          'puntajereactivosnivel3': this.puntajereactivosnivel3,
          comportamientoContador: this.comportamientoContador,
          paginadoPreguntas: this.paginadoPreguntas,
          //'examensintema' : this.valorexamensintema,
          //'tema_id':this.idTema
        };
        //UPDATE
        this.$http.post('/api/examenes', params)
            .then((response) => {
              this.idExamen = response.data.id;
              this.editedIndex = 1;
            })
            .then(() => {
              /*
              this.extraerExamen()
              */
              Swal.fire(
                  'Guardado!',
                  'Examen actualizado exitosamente.',
                  'success'
              )
            })

            .catch(function (error) {
              console.log(error)
            })
      }
    },

    getRespuestas() {
      let url = `/api/respuestas?reactivo_id=${this.idExamen}`
      this.$http.get(url)
          .then((response) => {
            this.banco = response.data;
          })
          .catch(function (error) {
                console.log(error)
              }
          )
    },
    consultarExamen() {
      this.totalreactivosagregadosaexamen = 0;
      this.sumatotalvalores = 0;
      let url = `/api/detalles?idExamen=${this.idExamen}`
      this.$http.get(url)
          .then((response) => {
            this.preguntas = response.data;
            console.log(this.preguntas)
            this.reactivos_examen = [];
            for (let item = 0; item < this.preguntas.reactivos.length; item++) {
              this.reactivos_examen.push(this.preguntas.reactivos[item].id)
              this.totalreactivosagregadosaexamen++
              if (this.preguntas.reactivos[item].detalle_reactivo.porcentaje != null) {
                this.sumatotalvalores += parseFloat(this.preguntas.reactivos[item].detalle_reactivo.porcentaje);
              }
            }
            console.log(this.reactivos_examen);
          })

          .catch(function (error) {
                console.log(error)
              }
          )
    },

    getReactivos() {
      let url = `/api/reactivos?escuela_id=${this.escuela_id}`
      this.$http.get(url)
          .then((response) => {
            this.reactivos = response.data;
            console.log(response.data);
          })
          .catch(function (error) {
                console.log(error)
              }
          )
    },
    setDesactivarTema() {
      if (this.examensintema == null)
        this.desactivarSelectTema = false;
      else
        this.desactivarSelectTema = true

    },
    close() {
      this.dialogReactivo = false
    },
  },
  mounted() {
    this.comportamientoContador = 1
    this.paginadoPreguntas = 1
    if (this.$route.query.tipoaccion == 2) {
      //Asignación de valores iniciales para edición
      this.texto = 'Editar Examen';
      this.mensaje_informacion = 'El examen ha sido guardado exitosamente.'
      this.idExamen = this.$route.query.idExamen;
      this.tipoaccion = this.$route.query.tipoaccion;
      this.extraerExamen()
    } else {
      this.texto = 'Crear Examen';
      this.mensaje_informacion = 'El examen ha sido actualizado exitosamente.'
      this.getNivelesEducativos()
      this.getAreas()
      //this.getCursos();

    }
  }
}
</script>

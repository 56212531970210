<template>
  <v-container fluid>
    <v-row>
        <v-col :cols="12">         

                     <v-card elevation="4">
                        <v-card-title>
                            <v-icon large class="mr-2" color="purple">
                                mdi mdi-account-multiple
                            </v-icon>
                            <h3>Alta de alumnos</h3>

                        </v-card-title>
                        <v-card-text>
                        
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                    v-model="name"
                                    :rules="nameRules"
                                    label="Nombre"
                                    required
                                ></v-text-field>
                            </v-col>                        
                            <v-col :cols="6">
                              <v-text-field
                                v-model="lastName1"
                                label="Apellido paterno"
                                :rules="nameRules"
                                required 
                            ></v-text-field>
                            </v-col>
                            <v-col :cols="6">
                               <v-text-field
                                v-model="lastName2"
                                label="Apellido materno"
                                required 
                            ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                             <v-col :cols="6">
                                <v-text-field
                                    v-model="email"
                                    type="email"
                                     :rules="emailRules"
                                    label="Correo electrónico"
                                    required 
                                ></v-text-field>
                             </v-col>
                             <v-col :cols="6">
                                <v-text-field
                                    v-model="curp"
                                    v-mask="'AAAA-######-A-AA-AAA-NN'"
                                    label="CURP"
                                    required 
                                ></v-text-field>
                             </v-col>
                        </v-row>

                        <v-row>
                             <v-col :cols="6">
                             
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateBirth"
                                        label="Fecha Nacimiento"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dateBirth"
                                    @input="menu = false"
                                ></v-date-picker>
                                </v-menu>
                            
                                
                             </v-col>
                             <v-col :cols="6">
                                 <v-radio-group
                                        v-model="gender"
                                        row
                                        >
                                        <small style="color: rgba(0,0,0,.6); line-height: 20px;
                                            letter-spacing: normal; padding: 8px 0; font-size: 18px;"
                                            > Género </small>
                                        <v-radio
                                            label="Masculino"
                                            color="pink"
                                            value="1"
                                        ></v-radio>
                                        <v-radio
                                            label="Femenino"
                                             color="info"
                                            value="0"
                                        ></v-radio>
                                        </v-radio-group>
                             </v-col>
                        </v-row>

<v-row>
    <v-col :cols="6">
        <v-autocomplete
            clearable
            :items="states"
            :filter="customFilter"
            v-model="state_id"
            color="white"
            item-text="name"
            item-value="id"
            label="Estado"
        ></v-autocomplete>
    </v-col>
    <v-col :cols="6">
            <v-text-field
            v-model="school"
            :rules="nameRules"
            label="Plantel escolar"
            required 
        ></v-text-field>
    </v-col>




</v-row>
                            
                            
                             <v-btn
                                  id=""
                                  color="primary"
                                  dark
                                  class="my-2 ml-auto "
                                  @click="save()"
                              >Guardar
                              </v-btn>

                        </v-card-text>
                    </v-card>


        </v-col>

   </v-row>

  </v-container>

</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
Vue.use(VueMask)


export default {
    data() {
        return {
            state_id:'',
            valid: false,
            name: "",
            lastName1:"",
            lastName2:"",
            email:"",
             nameRules: [
                v => !!v || 'Dato requerido',
                v => v.length >= 2 || 'Mínimo 2 letras',
            ],
            emailRules: [
                    v => !!v || 'El correo es requerido',
                    v => /.+@.+/.test(v) || 'El correo es inválido',
                ],
            curp:"",
            gender:"",
            dateBirth: '',
            menu: false,
            rol:3,
            school: '',
        states: [
            { name: 'Aguascalientes', abbr: 'Ags', id: 1 },
            { name: 'Baja California', abbr: 'bc', id: 2 },
            { name: 'Baja California Sur', abbr: 'bcs', id: 3 },
            { name: 'California', abbr: 'ca', id: 4 },
            { name: 'Campeche', abbr: 'camp', id: 5 },
            { name: 'Chiapas', abbr: 'chis', id: 6 },
            { name: 'Chihuahua', abbr: 'chih', id: 7 },
            { name: 'Ciudad de México', abbr: 'cmdx', id: 8 },
            { name: 'Coahuila', abbr: 'coah', id: 9 },
            { name: 'Colima', abbr: 'col', id: 10 },
            { name: 'Durango', abbr: 'col', id: 11 },
            { name: 'Guanajuato', abbr: 'gto', id: 12 },
            { name: 'Guerrero', abbr: 'gro', id: 13 },
            { name: 'Hidalgo', abbr: 'hgo', id: 14 },
            { name: 'Jalisco', abbr: 'jal', id: 15 },
            { name: 'México', abbr: 'méx', id: 16 },
            { name: 'Michoacán', abbr: 'mich', id: 17 },
            { name: 'Morelos', abbr: 'mor', id: 17 },
            { name: 'Nayarit', abbr: 'nay', id: 18 },
            { name: 'Nuevo León', abbr: 'nl', id: 19 },
            { name: 'Oaxaca', abbr: 'oax', id: 20 },
            { name: 'Puebla', abbr: 'pue', id: 21 },
            { name: 'Querétaro', abbr: 'qro', id: 22 },
            { name: 'Quintana Roo', abbr: 'qr', id: 23 },
            { name: 'San Luis Potosí', abbr: 'slp', id: 24 },
            { name: 'Sinaloa', abbr: 'sin', id: 25 },
            { name: 'Sonora', abbr: 'son', id: 26 },
            { name: 'Tabasco', abbr: 'qro', id: 27 },
            { name: 'Tamaulipas', abbr: 'tamps', id: 28 },
            { name: 'Tlaxcala', abbr: 'tlax', id: 29 },
            { name: 'Veracruz', abbr: 'ver', id: 30 },
            { name: 'Yucatán', abbr: 'yuc', id: 31 },
            { name: 'Zacatecas', abbr: 'zac', id: 32 }


        ],
        }
    },
     computed: {

    },

    watch: {

    },

  methods: {
    save(){
        console.log(this.state_id)
    },
 customFilter (item, queryText, itemText) {
        const textOne = item.name.toLowerCase()
        const textTwo = item.abbr.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
    customFilter (item, queryText, itemText) {
            const textOne = item.name.toLowerCase()
            const textTwo = item.abbr.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
            textTwo.indexOf(searchText) > -1
        },
  }

}
</script>

<style>

</style>
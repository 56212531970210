<template>
    <v-container>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-icon large class="mr-2" color="green">mdi-school-outline</v-icon> <h3>Banco de Colegios/Institutos</h3>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" fab dark small color="purple" title="Crear registro de Colegio" @click="abrircrearcolegio()">
                        <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                </v-row>
            </v-card-title>
            <v-card elevation="2">
                <v-card-title>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table v-model="selected" :headers="headers" :items="escuelas" :search="search">
                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="editarExamen(item)" title="Editar Examen" color="blue">
                            mdi-pencil
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default {

    data: () => ({
        search: '',
        idExamen:'',
        escuelas:[],
        selected: [],
        pagination: {},
        headers: [
            {text: 'Id',align: 'start',sortable: true,value: 'id'},
            {text: 'Nombre Colegio',align: 'start',sortable: true,value: 'nombre'},
            {text: 'Dominio',align: 'start',sortable: true,value: 'dominio'},
            {text: 'Fecha inicio contrato',   value: 'fecha_inicio_contrato'},
            {text: 'Fecha termino contrato',  value: 'fecha_termino_contrato'},
            { text: 'Acciones', value: 'actions'},
        ],

        email: ''
    }),
    created () {
        this.consultarColegios()
    },
    mounted() {


    },
    methods: {
        init () {
            //this.loginGoogle()
        },
        abrircrearcolegio(){
            this.$router.push({ path: '/escuela' })
        },
        consultarColegios(){
            let url = '/api/escuelas/getEscuelas'
            this.$http.get(url)
                .then((response) => {
                    this.escuelas = response.data;
                })
                .catch(function (error){
                        console.log(error)
                    }

                )
        },
        editarExamen(item){
            this.$router.push({ path: '/escuela', query: { idEscuela: item.id, tipoaccion:2} })
        }

    }
}
</script>
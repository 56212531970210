<template>
  <v-container>

    <v-row>
      <v-col :cols="12">
        <v-subheader>Módulo de reportes</v-subheader>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-alert v-model="alert"
                 type="success"
                 dismissible
        >
          ¡Acción en proceso!
        </v-alert>
      </v-col>
    </v-row>




  <v-row>
    <v-col
        cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xg="6"
    >
      <v-autocomplete
          clearable
          placeholder="Elegir"
          label="Escuela"

          v-model="escuela_id"
          item-text="nombre"
          item-value="id"
          :items="itemsEscuelas"
          ></v-autocomplete>

    </v-col>

    <v-col
         cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xg="6"
    >

      <v-autocomplete
          clearable
          placeholder="Elegir"
          label="Coach"

          v-model="coach_id"
          item-text="name"
          item-value="id"
          :items="itemsCoaches"
         ></v-autocomplete>
    </v-col>
    
  </v-row>
  <v-row>
    <v-col
        cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xg="6"
    >

      <v-autocomplete
          clearable
          placeholder="Elegir"
          label="Curso"
          v-model="curso_id"
          item-text="nombre_curso"
          item-value="id"
          :items="itemsCursos"
          ></v-autocomplete>
    </v-col>

    <v-col
      cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xg="6"
    >
      <v-autocomplete
          clearable
          placeholder="Elegir"
          label="Alumno"
          v-model="alumno_id"
          item-text="name"
          item-value="id"
          :items="itemsAlumnos"
          ></v-autocomplete>
    </v-col>
  </v-row>

    <v-row>
    <v-col
      cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xg="6"
    >
    <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="fechaInicio"
            label="Fecha Desde"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="fechaInicio"
          @input="menu2 = false"
      ></v-date-picker>
    </v-menu>
    </v-col>

    <v-col
       cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xg="6"
    >
      <v-menu
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="fechaFin"
              label="Fecha Hasta"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="fechaFin"
            @input="menu3 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>

  </v-row>

    <v-row>
      <v-col   cols="6">
        <v-btn block class="primary"
               @click="getFilteredData"
        >
          <v-icon
          >
            mdi-file-document
          </v-icon>
          Generar reporte
        </v-btn>
      </v-col>
      <v-col   cols="6">
        <v-btn block class="warning"
               @click="clearFields"
        >
          <v-icon
          >
            mdi-eraser
          </v-icon>
         Limpiar campos
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-toolbar flat color="white">

          </v-toolbar>
        </v-card>
        <v-data-table
            v-model="dataItems"
            :headers="headers"
            :items="resultSet"
            item-key="email"
            :items-per-page="7"
            class="elevation-1 mt-5"
            loading-text="Cargando..."
        >
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
          <v-btn
              color="success"
              elevation="5"
              @click="dataSheet"
          >
            <v-icon>
              mdi-file-excel
            </v-icon>
            Descargar reporte
          </v-btn>

      </v-col>
    </v-row>

  </v-container>
</template>



<script>

export default {
 name:"Reportes",
  data(){
        return{
          escuela_id:'',
          coach_id:'',
          curso_id:'',
          alumno_id:'',

          itemsEscuelas: [],
          itemsCoaches: [],
          itemsCursos: [],
          itemsAlumnos: [],

       //   date: new Date().toISOString().substr(0, 10),
          fechaInicio: '',
          fechaFin:'',
          menu2: false,
          menu3: false,
          aviso:true,

          dataItems: [],
          resultSet:[],
          alert: false,
          headers: [
            {
              text: 'Escuela',
              align: 'start',
              sortable: false,
              value: 'escuela_nombre',
            },
            {
              text: 'Coach',
              sortable: true,
              value: 'coach_nombre',
            },
            {
              text: 'Curso',
              sortable: true,
              value: 'curso_nombre'
            },
            {
              text: 'Estudiante',
              sortable: true,
              value: 'alumno_nombre'
            },
            {
              text: 'Fecha',
              sortable: true,
              value: 'fechaInicio'
            }

          ],
        }
  },//
mounted(){
  this.getData();
  this.getCoaches();
  this.getCursos();
  this.getAlumnos();
  this.getEscuelas();
},
  methods:{
    getData(){
      this.loading = true;
        this.$http.get("/api/reportes-coach")
                  .then((response) => {
                    this.resultSet = response.data;
                    console.log("entra a getData");
                  })
      this.loading = false;
            },//getData
    getFilteredData(){
      let escuela       = (this.escuela_id)   ? this.escuela_id : 0;
      let coach         = (this.coach_id)     ? this.coach_id : 0;
      let curso         = (this.curso_id)     ? this.curso_id : 0;
      let alumno        = (this.alumno_id)    ? this.alumno_id : 0;
      let fechaInicio   = (this.fechaInicio)  ? this.fechaInicio : 0;
      let fechaFin      = (this.fechaFin)     ? this.fechaFin : 0;
      //this.fecha_seleccion_inicio = this.formatDate(this.date_inicio_publicacion)

      if (escuela==0 && coach==0 && curso==0 && alumno==0 && fechaInicio==0 && fechaFin==0){
          this.getData();
      }else{
        this.$http.post("/api/reportes-filtered", {
          escuela       : escuela,
          coach         : coach,
          curso         : curso,
          alumno        : alumno,
          fechaInicio   : fechaInicio,
          fechaFin      : fechaFin
        }).then((response) => {
              console.log("entra a getFilteredData");
              this.resultSet = [];
              this.resultSet = response.data;
              console.log(response.data)
            })
            .catch(function (error) {
              console.log(error);
            });
      }

    }//getFilteredData
    ,dataSheet(){
      this.$http.post("/api/reportes-coach2", {
        results : this.resultSet
          })
          .then((response) => {
         //   console.log(response.data)
            let url =`https://docs.google.com/spreadsheets/d/${response.data}/edit`;
            window.open(url, '_blank');
          })
        .catch(function (error) {
          console.log(error)
        });
    },//dataSheet
    getCoaches() {
      this.$http.get("/api/get-coaches")
          .then(response => {
            this.itemsCoaches = response.data;
          })
          .catch(error => {
            console.log(error)
          })
    },//getCoaches
    getCursos() {
      this.$http.get("/api/get-cursos")
          .then(response => {
            this.itemsCursos = response.data;
          })
          .catch(error => {
            console.log(error)
          })
    },//getCoaches
    getAlumnos() {
      this.$http.get("/api/get-alumnos")
          .then(response => {
            this.itemsAlumnos = response.data;
          })
          .catch(error => {
            console.log(error)
          })
    },//getAlumnos
    getEscuelas() {
      this.$http.get("/api/get-escuelas")
          .then(response => {
            this.itemsEscuelas = response.data;
          })
          .catch(error => {
            console.log(error)
          })
    },//getAlumnos
    clearFields(){
      this.escuela_id = null;
      this.coach_id   = null;
      this.curso_id   = null;
      this.alumno_id  = null;
      this.fechaInicio = null;
      this.fechaFin = null;

    }

  }


};
</script>
<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog v-if="dialogObjetivo" :value="true" @input="dialogObjetivo = false" persistent max-width="790">
          <v-card elevation="2" class="mx-auto">
            <v-card-title>
              <v-icon large class="mr-2" color="green">mdi-alarm</v-icon><h3>Agregar Evento</h3>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-select
                        label='Coach Organizador de Evento:'
                        v-model='idOrganizador'
                        :items='organizadores'
                        item-value='id'
                        item-text='nombre'
                        outlined
                        return-object
                    >
                      <template slot='selection' slot-scope='{ item }'>
                        Coach:{{ item.name}} - {{ item.email}}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        Coach:{{ item.name}} - {{ item.email}}
                      </template>
                    </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-select label="Tipo de evento" :items="opcionesEvento" item-value="id" item-text="name" v-model="tipo_evento" outlined ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y

                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="fecha_consumo_inicio"
                          label="Fecha del Evento"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          outlined
                          @blur="date_inicio = parseDate(fecha_consumo_inicio)"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_inicio"
                        no-title
                        @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col  cols="12" sm="6">
                  <v-text-field
                      label="Límite de usuarios"
                      v-model="limite_usuarios"
                      type="number"
                      outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-dialog ref="dialoghorainicio" v-model="modalhorainicio" :return-value.sync="timeinicio"
                            persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="timeinicio"
                          label="Hora de inicio del evento"
                          append-icon="mdi-clock-time-four-outline"
                          readonly
                          outlined
                          id="txttimeinicio"
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="modalhorainicio" v-model="timeinicio" full-width>
                      <v-btn text color="primary" @click="modalhorainicio = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="$refs.dialoghorainicio.save(timeinicio)">
                        Aceptar
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-dialog ref="dialoghorafin" v-model="modalhorafin" :return-value.sync="timefin"
                            persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="timefin"
                          label="Hora de finalizacion del evento"
                          append-icon="mdi-clock-time-four-outline"
                          readonly
                          outlined
                          id="txttimefin"
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="modalhorafin" v-model="timefin" full-width>
                      <!--          <v-spacer></v-spacer>-->
                      <v-btn text color="primary" @click="modalhorafin = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="$refs.dialoghorafin.save(timefin)">
                        Aceptar
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6">
                  <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="fecha_seleccion_inicio"
                          label="Fecha inicio selección"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          outlined
                          @blur="date_inicio_publicacion = parseDate(fecha_seleccion_inicio)"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_inicio_publicacion"
                        no-title
                        @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-menu
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="fecha_seleccion_termino"
                          label="Fecha fin selección"
                          prepend-icon="mdi-calendar"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_fin_publicacion"
                        no-title
                        @input="menu4 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-btn @click="guardarEvento" small>
                    {{txtboton}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="closeImportar()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-card elevation="2" class="mx-auto">
      <v-card-title>
        <v-icon large class="mr-2" color="green">mdi-alarm</v-icon><h3>Eventos</h3>
        <v-breadcrumbs :items="items" large ></v-breadcrumbs>
        <v-spacer></v-spacer>

      </v-card-title>
      <v-card elevation="2">
        <v-card-title>
          <v-row
              align="center"
              justify="space-around"

          >
            <v-btn
                color="green"
                small
                @click="showUnidad()"
                title="Crear Evento"
            >
              <v-icon  color="white" >mdi-alarm</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card elevation="2">
        <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table :headers="headers" :items="eventos" class="elevation-1">
          <template v-slot:item.detalle_organizador="{item}">
            {{item.detalle_organizador.name}}-{{item.detalle_organizador.email}}
          </template>
          <template v-slot:item.horario="{item}">
            <v-chip
                class="ma-2"
                color="green"
                text-color="white"
            >
              {{item.hora_inicio}}-{{item.hora_fin}}
            </v-chip>

          </template>
          <template v-slot:item.publicado="{ item }">
            <v-icon v-if="(parseInt(item.publicado)>=1)"
                    class="mr-2"
                    color="green"
                    title="Oferta publicada"
            >
              mdi-arrow-up-bold-circle-outline
            </v-icon>
            <v-icon v-if="(parseInt(item.publicado)==0)"
                    class="mr-2"
                    color="orange"
                    title="Oferta No publicada"
            >
              mdi-flag-outline
            </v-icon>
          </template>
          <template v-slot:item.fecha_consumo_inicio="{ item }">
            {{formatDate(item.fecha_consumo_inicio)}}
          </template>
          <template v-slot:item.fecha_seleccion_inicio="{ item }">
            {{formatDate(item.fecha_seleccion_inicio)}}
          </template>
          <template v-slot:item.fecha_seleccion_termino="{ item }">
            {{formatDate(item.fecha_seleccion_termino)}}
          </template>
          <template v-slot:item.estatus="{item}">
            <v-chip
                class="ma-2"
                :color="(item.estatus==1)?'green':'orange'"
                text-color="white"
            >
              {{(item.estatus==1)?'Evento programado':'Pendiente de programar'}}
            </v-chip>
          </template>
<!--          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <v-btn
                  fab elevation="2"
                  color="blue"
                  small
                  x-small
                  title="Ver detalles Evento"
              >
                <v-icon  small @click="show(item)" title="Ver detalles Evento" color="white">mdi-eye</v-icon>
              </v-btn>
            </div>
          </template>-->
          <template v-slot:item.detalles="{ item }">
            <div class="text-center">
              <v-btn
                  fab elevation="2"
                  color="blue"
                  small
                  x-small
                  title="Detalles Evento"
                  @click="showDetallesEvento(item)"
              >
                <v-icon small  color="white">mdi-message-text-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.eventos="{ item }">
            <div class="text-center">
              <v-btn
                  fab elevation="2"
                  color="green"
                  small
                  x-small
                  title="Eventos Sesión"
                  @click="showEventoSesion(item)"
              >
                <v-icon small  color="white">mdi-message-text-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.estatus_visibilidad="{item}">
            <v-checkbox
            v-model="item.estatus_visibilidad"
            class="small"
            label="Activada"
            @change="disponibilidadEvento(item.estatus_visibilidad, item.id)"></v-checkbox>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: vm => ({
    tipo_evento : 1,
    opcionesEvento : [],
    search:'',
    nombre:'',
    item:[],
    items:[{
      text: 'Sesión Académica',
      disabled: false,
      href: '/sesiones',
    },
      {
        text: 'Cargando...',
        disabled: true,
      }
    ],
    eventos:[],
    headers: [
      {text: '#',                   value: 'id'                           , align: 'start'  ,sortable: true,},
      { text: 'Organizador',        value: 'detalle_organizador'          , align: 'center'},
      { text: 'Fecha del Evento',   value: 'fecha_consumo_inicio'         , align: 'center'},
      { text: 'Hora del Evento',    value: 'horario'                      , align: 'center'},
      { text: 'Inicio selección',   value: 'fecha_seleccion_inicio'       , align: 'center'},
      { text: 'Fin selección',      value: 'fecha_seleccion_termino'      , align: 'center'},
      { text: 'Límite usuarios',    value: 'limite_usuarios'              , align: 'center'},
      { text: 'Detalles',           value: 'detalles'                     , align: 'center'},
      //{ text: 'Disponibilidad',     value: 'estatus_visibilidad'          , align: 'center'},
      //{ text: 'Acciones',           value: 'actions',                     sortable: true , align: 'center'},
    ],
    overlay: false,
    absolute:true,
    dialog: false,
    dialogIndicador: false,
    dialogAyuda:false,
    tipoaccion:'',
    clave_indicador:'',
    txtboton:'',
    estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
    idIndicador:0,
    idCurso:0,
    nombre_sesion:'',
    nombre_indicador:'',
    modolectura:false,
    arrayObjetivos: [],
    unidades:[],      idUnidad:'',
    objetivos:[],     idObjetivo:'',
    idSesion: '',

    date_inicio: new Date().toISOString().substr(0, 10),
    fecha_consumo_inicio: vm.formatDate(new Date().toISOString().substr(0, 10)),

    /*date_finalizacion: new Date().toISOString().substr(0, 10),
    fecha_consumo_termino: vm.formatDate(new Date().toISOString().substr(0, 10)),*/

    date_inicio_publicacion: new Date().toISOString().substr(0, 10),
    fecha_seleccion_inicio: vm.formatDate(new Date().toISOString().substr(0, 10)),

    date_fin_publicacion: new Date().toISOString().substr(0, 10),
    fecha_seleccion_termino: vm.formatDate(new Date().toISOString().substr(0, 10)),

    copia_date:new Date().toISOString().substr(0, 10),
    copia_fecha:vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    modalhorainicio: false,
    timeinicio: null,
    modalhorafin: false,
    timefin: null,
    idOrganizador: '',            organizadores: [],
    dialogObjetivo: false,
    limite_usuarios:0,
  }),
  created () {
    //this.init();
  },
  watch: {
    date_inicio (val) {
      this.fecha_consumo_inicio = this.formatDate(this.date_inicio)
    },
    date_finalizacion (val) {
      this.fecha_consumo_termino = this.formatDate(this.date_finalizacion)
    },
    date_inicio_publicacion (val) {
      this.fecha_seleccion_inicio = this.formatDate(this.date_inicio_publicacion)
    },
    date_fin_publicacion (val) {
      this.fecha_seleccion_termino = this.formatDate(this.date_fin_publicacion)
    },

  },
  methods: {
    disponibilidadEvento(estatus_evento, evento_id) {
      //alert(1)
      this.$http.post("/api/aprende/eventos/update-evento-disponibilidad", {
        estatus_evento : estatus_evento,
        evento_id : evento_id,
      })
      .then(response => {

      })
      .catch(error => {

      })
    },
    getDateTime(date) {
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    init () {
      //obtiene los reactivos filtrados por el usuario actual.
    },
    anexarUnidadObjetivo(item) {
      this.guardando = true;
      // console.log("unidad seleccionada")
      // console.groupCollapsed(this.idUnidad)
      this.$http.post('/api/aprende/detalle-sesion', {
        'objetivo_id': item.objetivos.id,
        'unidad_id': this.idUnidad.id,
        'sesion_id':  this.idSesion
      })
          .then((response) => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Objetivo agregado exitosamente.',
              showConfirmButton: false,
              timer: 1500
            })
            this.getUnidadesObjetivos();
          })
          .catch(function (error) {
          })
    },
    closeModal() {
      this.dialog = false
    },
    closeAyuda(){
      this.dialogAyuda =  false
    },
    closeImportar(){
      this.txtboton           =   '';
      this.dialogObjetivo    =   false
      this.modolectura        =   false;
    },
    consultar(id){
      this.idTipoReactivo = id;
      if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
        this.showModal();
      }
      else if (this.idTipoReactivo==6)
        this.showModal();
    },
    consultarNivelEducativo(){
      let url = '';
      url = `/api/grados?idnivel=${this.idNivel.id}`
      this.$http.get(url)
          .then((response) => {
            this.grados = response.data;
            if(this.tipoaccion==2 ) {
              this.idGrado = this.grados.find(item => item.id == this.grado_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    consultarArea(){
      let url = '';
      url = `/api/asignaturas?idArea=${this.idArea.id}`
      this.$http.get(url)
          .then((response) => {
            this.asignaturas = response.data;
            if(this.tipoaccion==2 ){
              this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    deleteUnidadObjetivo (item){
      let index = this.arrayObjetivos.indexOf(item);
      let index2 = this.concentradoObjetivosUnidades.indexOf(item);
      Swal.fire({
        title: '¿Desea eliminar la Unidad-Objetivo con ID: '+ item.id + '?',
        showDenyButton: true,
        confirmButtonText: `Eliminar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let url = `/api/aprende/detalle-sesion/${item.id}`
          this.$http
              .delete(url)
              .then((response) => {
                this.getUnidadesObjetivos()
                Swal.fire('Unidad Objetivo eliminado satisfactoriamente.', '', 'success')
              })
              .catch(function (error){
                console.log(error)
                //this.overlay = false;
              })

        } else if (result.isDenied) {

        }
      })
    },
    editReactivo(item){
      this.item                 =   item;
      this.dialogIndicador       =   true;
      this.idTipoReactivo       =   item.tiporeactivo.tiporeactivo_concentrado.id;
      this.tipoaccion           =   2;
      this.estatusAccionTabla   =   2;
    },
    editIndicador(item) {
      this.idIndicador            =   item.id;
      this.nombre_indicador       =   item.nombre_indicador;
      this.clave_indicador        =   item.clave_indicador;
      this.dialogIndicador        =   true;
      this.txtboton               =   "Actualizar"
      this.estatusAccionTabla     =   2;
      this.modolectura            =   true;
    },
    getEventos(){
      let url = `/api/aprende/eventos/`+this.idSesion
      //console.log(url);
      this.$http.get(url)
          .then((response) => {
            this.eventos = response.data;
            console.log("eventos----->")
            console.log(this.eventos)

          })
          .catch(function (error){
            console.log(error)
      })
    },
    getOrganizadores(){
      let role = 5;
      this.$http.get('/api/aprende/users/get-users-by-role/' + role)
          .then(response => {
            this.organizadores = response.data;
            //console.log(response)
          }).catch(error => {
        console.log(error)
      })

    },
    getUnidades(){
      let url = `/api/aprende/cursos/`+this.idCurso
      this.unidades = []
      this.$http.get(url)
          .then((response) => {
            this.unidades = response.data.unidades;
          })
          .catch(function (error){
            console.log(error)
          })
    },
    guardarEvento(){
        if(this.estatusAccionTabla==1){
          if(this.switchpublicado==true)
            this.publicado = 1
          else
            this.publicado = 0
          // console.log(this.fecha_consumo_inicio);
          this.$http.post('/api/aprende/eventos', {
            tipo_evento : this.tipo_evento,
                        'sesion_id':                      this.idSesion,
                        'user_id_organizer':              this.idOrganizador.id,
                        'fecha_consumo_inicio':           this.getDateTime(this.fecha_consumo_inicio),
                        //'fecha_consumo_termino':          this.getDateTime(this.fecha_consumo_termino),
                        'fecha_seleccion_inicio':         this.getDateTime(this.fecha_seleccion_inicio),
                        'fecha_seleccion_termino'   :     this.getDateTime(this.fecha_seleccion_termino),
                        'limite_usuarios':                this.limite_usuarios,
                        'hora_inicio': this.timeinicio,
                        'hora_fin': this.timefin,
          })
              .then((response) => {
                if(response.data){
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Evento guardado exitosamente.',
                    showConfirmButton: false,
                    timer: 2500
                  })
                  //this.sesiones.unshift(response.data) //inserta el elemento al principio del Arreglo
                  this.setLimpiarVariables();
                  this.getEventos();       //obtiene los reactivos filtrados por el usuario actual.
                }
                else{
                  Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Evento no guarado. Favor de intentar más tarde.',
                    showConfirmButton: false,
                    timer: 2500
                  })
                }
              })
              .catch(error => {
                console.log(error)
              })
        }
        else if(this.estatusAccionTabla==2){
          if(this.switchpublicado==true)
            this.publicado = 1
          else
            this.publicado = 0
          this.$http.post('/api/aprende/ofertaAcademica/'+ this.idOfertaAcademica, {
            _method:    'PUT',
            'nombre_sesion':                  this.nombre_sesion,
            'url_meet':                       this.url_meet,
            //'limite_usuarios':                this.limite_usuarios,
            'publicado':                      this.publicado,
            /*
                        'fecha_consumo_inicio':           this.getDateTime(this.fecha_consumo_inicio),
                        'fecha_consumo_termino':          this.getDateTime(this.fecha_consumo_termino),
                        'fecha_seleccion_inicio':         this.getDateTime(this.fecha_seleccion_inicio),
                        'fecha_seleccion_termino'   :     this.getDateTime(this.fecha_seleccion_termino),
            */
          })
              .then((response) => {
                if(response.data){
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Sesión actualizada exitosamente.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.getSesiones()
                }
                else{
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Sesión no actualizada. Favor de intentar más tarde.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
              })
              .catch(function (error) {
                console.log(error)
              })
        }

    },
    setLimpiarVariables(){
      this.date_inicio                                      = this.copia_date
      this.fecha_consumo_inicio                             = this.copia_fecha
      this.date_finalizacion                                = this.copia_date
      this.fecha_consumo_termino                            = this.copia_fecha

      this.date_inicio_publicacion                          = this.copia_date
      this.fecha_seleccion_inicio                           = this.copia_fecha
      this.date_fin_publicacion                             = this.copia_date
      this.fecha_seleccion_termino                          = this.copia_fecha
    },
    showDetallesEvento(item){
      console.log(item)
      this.$router.push({ path: '/detalle-evento', query: { idSesion:btoa(item.sesion_id),idEvento: btoa(item.id),fecha_consumo_inicio:btoa(item.fecha_consumo_inicio),hora_inicio:btoa(item.hora_inicio),hora_fin:btoa(item.hora_fin),nombre_sesion:btoa(this.nombre_sesion) } })
    },
    showModal() {
      this.dialog = true;
    },
    showUnidad() {
      this.dialogObjetivo     = true;
      this.txtboton           = "Guardar";
      this.estatusAccionTabla =   1;
      this.getOrganizadores();
    },
  },
  mounted(){
    this.idSesion                 =    atob(this.$route.query.idSesion);
    this.nombre_sesion            =    atob(this.$route.query.nombre_sesion);
    this.items[1].text            =    'Sesión: '+ atob(this.$route.query.nombre_sesion);
    this.getEventos()
    this.opcionesEvento = [
      {
        "id" : 1,
        "name" : "Limitado"
      },
      {
        "id" : 2,
        "name" : "Masivo"
      },
    ];
  }
}
</script>
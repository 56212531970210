var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[[_c('v-row',{attrs:{"justify":"center"}},[(_vm.dialogIndicador)?_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"790"},on:{"input":function($event){_vm.dialogIndicador = false}}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_c('h3',[_vm._v("Cursos para Requisitos")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[[_c('v-select',{attrs:{"label":"Currícula:","items":_vm.curriculas,"item-value":"id","item-text":"nombre_curricula","outlined":"","return-object":""},on:{"change":_vm.getCursos},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_curricula)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_curricula)+" ")]}}],null,false,1387764412),model:{value:(_vm.idCurricula),callback:function ($$v) {_vm.idCurricula=$$v},expression:"idCurricula"}})]],2),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[[_c('v-select',{attrs:{"label":"Cursos:","items":_vm.cursos,"item-value":"id","item-text":"nombre_curso","outlined":"","return-object":""},on:{"change":_vm.getUnidadesCursos},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.curso_curricula.nombre_curso)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.curso_curricula.nombre_curso)+" ")]}}],null,false,3484807004),model:{value:(_vm.idCurso),callback:function ($$v) {_vm.idCurso=$$v},expression:"idCurso"}})]],2)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerscursos,"items":_vm.unidades},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [( !((_vm.concentrado_unidades).indexOf(parseInt(item.id))>=0))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","title":"Agregar unidad"},on:{"click":function($event){return _vm.guardarCursosRequisitos(item)}}},[_vm._v(" mdi-cloud-download ")]):_vm._e()]}}],null,false,2396864007),model:{value:(_vm.unidadesselected),callback:function ($$v) {_vm.unidadesselected=$$v},expression:"unidadesselected"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.closeImportar()}}},[_vm._v(" Cerrar ")])],1)],1)],1):_vm._e()],1)],_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_c('h3',[_vm._v("Requisitos Cursos")]),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"large":""}}),_c('v-spacer')],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"green","small":"","title":"Agregar Curso"},on:{"click":function($event){return _vm.showCursos()}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-checkbox-marked-circle-outline")])],1)],1)],1)],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.requisitoscursos},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"fab":"","elevation":"2","color":"red","small":"","x-small":"","title":"Eliminar Unidad"},on:{"click":function($event){return _vm.deleteUnidad(item)}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-delete")])],1)],1)]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('h3',[_vm._v(_vm._s(_vm.texto))])]),_c('v-container',{staticClass:"grey lighten-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[[_c('v-select',{attrs:{"label":"Nivel Educativo:","items":_vm.niveles,"item-value":"id","item-text":"nombre"},on:{"change":_vm.consultarNivel},model:{value:(_vm.idNivel),callback:function ($$v) {_vm.idNivel=$$v},expression:"idNivel"}})]],2),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[[_c('v-select',{attrs:{"label":"Grado:","items":_vm.grados,"item-value":"id","item-text":"nombre"},on:{"change":_vm.consultarGrado},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idGrado),callback:function ($$v) {_vm.idGrado=$$v},expression:"idGrado"}})]],2),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[[_c('v-select',{attrs:{"label":"Area de Conocimiento:","items":_vm.areas,"item-value":"id","item-text":"nombre"},on:{"change":_vm.consultarArea},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idArea),callback:function ($$v) {_vm.idArea=$$v},expression:"idArea"}})]],2),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[[_c('v-select',{attrs:{"label":"Asignatura:","items":_vm.asignaturas,"item-value":"id","item-text":"nombre"},on:{"change":_vm.consultarAsignatura},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idAsignatura),callback:function ($$v) {_vm.idAsignatura=$$v},expression:"idAsignatura"}})]],2)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c('p',[_vm._v("Selecciona la ubicación que tendrá el "),_c('b',[_vm._v("cuadro de Texto para la respuesta")]),_vm._v(", los demás elementos es para la descripción del reactivo")]),_c('v-btn-toggle',[_c('v-btn',{on:{"click":function($event){return _vm.reactivoizquierdo()}}},[_c('v-icon',[_vm._v("mdi-format-align-left")])],1),_c('v-btn',{on:{"click":function($event){return _vm.reactivocentro()}}},[_c('v-icon',[_vm._v("mdi-format-align-center")])],1),_c('v-btn',{on:{"click":function($event){return _vm.reactivoderecho()}}},[_c('v-icon',[_vm._v("mdi-format-align-right")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hiddenizquierda),expression:"!hiddenizquierda"}],attrs:{"label":"Descripción"},model:{value:(_vm.descripcionizquierda),callback:function ($$v) {_vm.descripcionizquierda=$$v},expression:"descripcionizquierda"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Respuesta"},model:{value:(_vm.respuesta),callback:function ($$v) {_vm.respuesta=$$v},expression:"respuesta"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hiddenderecha),expression:"!hiddenderecha"}],attrs:{"label":"Descripcion"},model:{value:(_vm.descripcionderecha),callback:function ($$v) {_vm.descripcionderecha=$$v},expression:"descripcionderecha"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","id":"btnReactivo"},on:{"click":function($event){return _vm.guardarReactivo()}}},[_vm._v("Guardar Reactivo")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
<v-container>
    <v-card
      elevation="2"
     >
     <v-card-title>
            <h3>{{texto}}</h3>
          </v-card-title>
      <v-container class="grey lighten-5">
        <v-row >
          <v-col cols="6" sm="6">
                <template>
                    <v-select
                    label='Nivel Educativo:'
                    v-model='idNivel'
                    :items='niveles'
                    item-value='id'
                    item-text='nombre'                
                    @change="consultarNivel"
                    >

                    </v-select>
                </template>      
            </v-col>
            <v-col cols="6" sm="6">
                <template>
                    <v-select
                    label='Grado:'
                    v-model='idGrado'
                    :items='grados'
                    item-value='id'
                    item-text='nombre'
                    @change="consultarGrado"
                    >
                    <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template> 
                    </v-select>
                </template>      
            </v-col>
            <v-col cols="6" sm="6">
                <template>
                    <v-select
                    label='Area de Conocimiento:'
                    v-model='idArea'
                    :items='areas'
                    item-value='id'
                    item-text='nombre'
                    @change="consultarArea"
                    >
                    <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template> 
                    </v-select>
                </template>      
            </v-col>
            <v-col cols="6" sm="6">
                <template>
                    <v-select
                    label='Asignatura:'
                    v-model='idAsignatura'
                    :items='asignaturas'
                    item-value='id'
                    item-text='nombre'
                    @change="consultarAsignatura"
                    >
                    <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre}}
                    </template> 
                    </v-select>
                </template>      
            </v-col>
      </v-row>
      <v-row >
    <v-col
      cols="12"
      sm="6"
      class="py-2"
    >
      <p>Selecciona la ubicación que tendrá el <b>cuadro de Texto para la respuesta</b>, los demás elementos es para la descripción del reactivo</p>

      <v-btn-toggle>
        <v-btn @click="reactivoizquierdo()">
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>

        <v-btn @click="reactivocentro()">
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>

        <v-btn @click="reactivoderecho()">
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-col>
      </v-row>

   <v-row >
        <v-col
        cols="4"
        sm="4"
        >
            <v-text-field
            label="Descripción"
            v-model="descripcionizquierda"
            v-show="!hiddenizquierda"


            ></v-text-field> 
        </v-col>
        <v-col
        cols="4"
        sm="4"
        >
            <v-text-field
            label="Respuesta"
            v-model="respuesta"
            ></v-text-field> 
        </v-col>
        <v-col
        cols="4"
        sm="4"
        >
            <v-text-field
            label="Descripcion"
            v-model="descripcionderecha"
            v-show="!hiddenderecha"
            ></v-text-field> 
        </v-col>
   </v-row>

      <v-row >
            <v-col cols="12" sm="12">
                <v-btn
                color="primary"
                dark
                id="btnReactivo"
                class="mb-2"                
                @click="guardarReactivo()"
                >Guardar Reactivo</v-btn>
            </v-col>
      </v-row>
      </v-container>
    </v-card>
 </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  export default {
    data:() => ({
        dialog: false,
        singleSelect: true,
        selected: [],
        absolute: false,
        oculto:true,
        opacity: 0.46,
        editedIndex: -1,
        zIndex: 5,
        nombre:'',
        idNivel:'',
        idGrado:'',
        idArea:'',
        idAsignatura:'',
        idEscuela:'',
        idReactivo:'',
        activobtn:'',
        tiporeactivo:null,
        tipoaccion:'',
        guardando:false,
        niveles:[],
        banco:[],
        areas:[],
        grados:[],
        
        texto:'',
        respuesta:'',
        textbtn:'Agregar eactivo',
        asignaturas:[],
        descripcionizquierda:'',
        descripcionderecha:'',
        hiddenizquierda:true,
        hiddenderecha:false,
        respuesta:'',
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        headers: [
          {text: 'Es correcta',align: 'start',sortable: false,value: 'correcta'},
          {text: 'Respuesta',align: 'start',sortable: false,value: 'respuesta'},
          { text: 'Acciones', value: 'actions', sortable: true },
        ],
        overlay: false,
        absolute:true,
        posicion:1,
     
    }),
    computed: {
        /*   formTitle () {
            return this.editedIndex === -1 ? 'Agregar Reactivo' : 'Editar Reactivo'
          }, */
    },
    created () {
          this.texto='Nuevo reactivo: Tipo Completar';
          this.tiporeactivo = 5;
          this.idEscuela = 1;
      
    },    
    methods: {
    init () {

    },
    actualizarCorrecta(item){
      this.guardando = true;
      if(item.correcta==null){    
        item.correcta=0;
      }
      else
        item.correcta = 1;
        console.log("tipo dereactivo:"+this.tiporeactivo)
                this.$http.post('/api/respuestas', {           
                'id':               item.id,
                'correcta':         item.correcta,
                'reactivo_id':      this.idReactivo,
                'tiporeactivo_id':  this.tiporeactivo             
                })
                 .then((response) => {
                   this.getRespuestas();
                   this.guardando = false;
                    //this.overlay = false;
                })
                .catch(function (error){
                    //this.overlay = false;
                })
                
    },
    actualizarRespuesta(item){
                this.guardando = true;
                this.$http.post('/api/respuestas', {            
                  'id':               item.id,
                  'respuesta':        item.respuesta           
                })
                 .then((response) => {
                    this.getRespuestas();
                    this.guardando = false;
                    //this.overlay = false;
                })
                .catch(function (error){
                   // console.log(error)
                   // this.overlay = false;
                })
                
    },
    consultarNivel(id){
            this.idNivel = id;
            let url = `/api/grados?idnivel=${id}`
            this.$http.get(url)
            .then((response) => {
               this.grados = response.data;
                if(this.tipoaccion==2){
                  //modo edicion de reactivo
                  this.idGrado = this.grados.find(item => item.id == this.$route.query.grado_id)
                  this.idGrado = this.idGrado.id
                  //console.log("idnivel despues de ejecutarse funcion"+this.idGrado)
                }
            })
            .catch(function (error){
                    console.log(error)
            }          
            )
    },
    consultarGrado(id){
            this.idGrado = id;
    },
    consultarArea(id){
            this.idArea = id;
            let url = `/api/asignaturas?idArea=${id}`
            this.$http.get(url)
            .then((response) => {
               this.asignaturas = response.data;
                if(this.tipoaccion==2){
                 //modo edicion de reactivo
                  this.idAsignatura = this.asignaturas.find(item => item.id == this.$route.query.asignatura_id)
                  this.idAsignatura = this.idAsignatura.id
                  //console.log("idnivel despues de ejecutarse funcion"+this.idAsignatura)
                }
            })
            .catch(function (error){
                    console.log(error)
            }          
            )
    },
    consultarAsignatura(id){
            this.idAsignatura = id;
    },
    deleteRespuesta (item){
      var refs = ''
      refs = confirm('¿Deseas eliminar la respuesta: ' + item.respuesta + '?')
      if (refs) {
          //this.overlay = true;
          let url = `/api/respuestas/${item.id}`
          this.$http
          .delete(url)
          .then((response) => {
            this.getRespuestas()
          })
          .catch(function (error){
            console.log(error)
            //this.overlay = false;
          })
      }
      else {
        // cancel
      }       
    },
    guardarReactivo(){
       // console.log('medio contactado:' + mediodecontacto + 'del id:' + id);
       if (this.posicion==1 && this.descripcionderecha == ''){
            alert('Se necesita capturar la descripcion del lado derecho.')
       }
       else if (this.posicion==2 && this.descripcionizquierda == ''){
            alert('Se necesita capturar la descripcion del lado izquierdo.')
       }
       else if (this.posicion==2 && this.descripcionderecha == ''){
            alert('Se necesita capturar la descripcion del lado derecho.')
       }
       else if (this.posicion==3 && this.descripcionizquierda == ''){
            alert('Se necesita capturar la descripcion del lado izquierdo.')
       }
       else{
        if (this.idReactivo==''){
                    this.$http.post('/api/reactivos', {
                        'escuela_id':           this.idEscuela,
                        'nivel_id':             this.idNivel,
                        'grado_id':             this.idGrado,
                        'area_id':              this.idArea,
                        'asignatura_id':        this.idAsignatura,
                        'tiporeactivo_id':      this.tiporeactivo,
                        'descripcion':          '',
                        'instrucciones':        '',
                        'descripcionizquierda': this.descripcionizquierda,
                        'descripcionderecha':   this.descripcionderecha                
                    })
                    .then((response) => {
                    this.idReactivo = response.data.id;  
                        guardarRespuesta();

                    })
                    .catch(function (error){
                    // console.log(error)
                        //this.overlay = false;
                    })
            }
            else{
                    this.$http.post('/api/reactivos', {
                    'idReactivo':       this.idReactivo,
                    'escuela_id':       this.idEscuela,
                    'nivel_id':         this.idNivel,
                    'grado_id':         this.idGrado,
                    'area_id':          this.idArea,
                    'asignatura_id':    this.idAsignatura,
                    'tiporeactivo_id':  this.tiporeactivo,
                    'descripcion':       this.descripcion,
                    'instrucciones':      this.instrucciones,
                
                    })
                    .then((response) => {
                    this.idReactivo = response.data.id;
                    this.btnReactivo.text = "Actualizar reactivo"
                    this.editedIndex = 1;
                        //console.log("idreactivo:"+response.data.id)
                        //this.overlay = false;
                    })
                    .catch(function (error){
                    // console.log(error)
                        //this.overlay = false;
                    })          

            }
        }
    },
    guardarRespuesta(){
      this.guardando=true;
                this.$http.post('/api/respuestas', {
                'respuesta':        this.respuesta,
                'reactivo_id':      this.idReactivo,
                'save':             1               
                })
                 .then((response) => {
                   // console.log(response.data)
                    //this.overlay = false;
                    this.respuesta = '';
                    //this.getRespuestas();
                    this.guardando=false;
                })
                .catch(function (error){
                   // console.log(error)
                    //this.overlay = false;
                })
    },
    guardarMediodeContacto(mediodecontacto,id){
        console.log('medio contactado:' + mediodecontacto + 'del id:' + id);
                this.$http.post('/api/concentrados', {
                'id':               id,
                'atributo':         4,
                'mediodecontacto':  mediodecontacto,
               
                })
                 .then((response) => {
                   // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                   // console.log(error)
                    this.overlay = false;
                })
    },

    getAreas () {
            let url = '/api/areas'
            this.$http.get(url)
            .then((response) => {
               this.areas = response.data;
               if(this.tipoaccion==2){
                 //modo edicion de reactivo
                  this.idArea = this.niveles.find(item => item.id == this.$route.query.area_id)
                  this.idArea = this.idArea.id
                  //this.consultarNivel(this.idArea)
                  this.consultarArea(this.idArea)
                  //console.log("idnivel despues de ejecutarse funcion"+this.idNivel)
                }
            })
            .catch(function (error){
                    console.log(error)
            })
    },
    getRespuestas(){
      console.log("obtener respuesta del idreactivo:" + this.idReactivo)
            let url = `/api/respuestas?reactivo_id=${this.idReactivo}`
            this.$http.get(url)
            .then((response) => {
               this.banco = response.data;
            })
            .catch(function (error){
                    console.log(error)
            }          
            )
    },
    getNivelesEducativos () {
            let url = '/api/niveleseducativos'
            this.$http.get(url)
            .then((response) => {
               this.niveles = response.data;
               console.log(this.niveles)
               if(this.tipoaccion==2){
                 //modo edicion de reactivo
                this.idNivel = this.niveles.find(item => item.id == this.$route.query.nivel_id)
                this.idNivel = this.idNivel.id
                this.consultarNivel(this.idNivel)
                //console.log("idnivel despues de ejecutarse funcion"+this.idNivel)
                  if(this.tiporeactivo==3){
                          this.activobtn='';
                  }
                  else{
                    this.activobtn=1;
                  }
                }
               
            })
            .catch(function (error){
                    console.log(error)
            })
    },

    close() {
        this.dialog = false
    },
    reactivoizquierdo(){
        this.descripcionizquierda = '';
        this.descripcionderecha = '';
        this.hiddenizquierda = true;
        this.hiddenderecha = false;
        this.posicion = 1;
    },
    reactivoderecho(){
        this.descripcionizquierda = '';
        this.descripcionderecha = '';
        this.hiddenizquierda = false;
        this.hiddenderecha = true;
        this.posicion = 2;
    },
    reactivocentro(){
        this.descripcionizquierda = '';
        this.descripcionderecha = '';
        this.hiddenizquierda = false;
        this.hiddenderecha = false;
        this.posicion = 3;
    },
    },
    mounted(){
      this.getNivelesEducativos()
      this.getAreas()
    } 
  }
</script>
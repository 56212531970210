<template>
  <div>
    <v-alert
        text
        dismissible
        color="info"
    >
      <div>Usted se encuentra <strong>editando una currícula</strong></div>
    </v-alert>
    <v-card class="pa-3">
      <v-breadcrumbs
          class="pa-3 ma-0"
          :items="itemsNav"
          divider="/">
      </v-breadcrumbs>
      <v-divider></v-divider>

      <div class="pa-5">
        <v-text-field
            v-model="editar_curricula.nombre_curricula"
            label="Nombre del curso"
        ></v-text-field>
        <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="guardarCambios()"
        >Guardar cambios</v-btn>
      </div>

    </v-card>
  </div>
</template>
<script>
export default {
  data:()=>({
    curriculaId : 0,
    itemsNav : [],
    editar_curricula : {
      nombre_curricula : '',
    }
  }),
  mounted() {
    this.curriculaId = this.$route.query.curriculaId;
    this.showCurso();
    this.itemsNav = [
      {
        text : 'Currículas',
        disabled : false,
        href : '/curriculas-all'
      },
      {
        text : 'Cargando...',
        disabled : true,
        href : '',
      },
    ];
  },
  methods : {
    guardarCambios() {
      this.$http.post('/api/aprende/curriculas/' + this.curriculaId, {
        _method : 'PATCH',
        nombre_curricula : this.editar_curricula.nombre_curricula,
      })
      .then(response => {
        this.itemsNav[1].text = this.editar_curricula.nombre_curricula;
        this.editar_curricula.nombre_curricula = this.editar_curricula.nombre_curricula;
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Curso editado correctamente',
          showConfirmButton: false,
          timer: 2500
        })
      })
      .catch(error => {

      })
    },
    showCurso() {
      this.$http.get('/api/aprende/curriculas/' + this.curriculaId)
      .then(response => {
        this.itemsNav[1].text = response.data.nombre_curricula;
        this.editar_curricula.nombre_curricula = response.data.nombre_curricula;
      })
      .catch(error => {

      })
    }
  }
}
</script>
<template>
  <div >
    <h1 class="pa-5 ma-0">{{ contenido.nombre_curricula }} <v-chip>{{ nombre_sesion }}</v-chip></h1>
    <v-divider></v-divider>
    <p v-if="(! contenido )">No hay evento disponibles por el momento</p>
    <v-list >
      <v-list-item v-for="(item, i) in contenido.contenido">
        <v-list-group
            :value="true"
            no-action
            sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.nombre_curso }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              v-for="(unidad, i) in item.unidades"
              :key="i"
              link
          >
            <v-list-item-content>
              <v-list-item-title>
                <strong>{{ unidad.nombre_unidad }}</strong>
              </v-list-item-title>
              <v-divider></v-divider>
              <div v-for="evento_unidad in unidad.eventos_unidad_sesion">
                <p class="ma-5" v-if="(evento_unidad.evento_stream)">
                  <v-icon>mdi-alarm-check</v-icon> {{ evento_unidad.nombre_sesion }} (<small>Se realiza el {{ evento_unidad.fecha_consumo_inicio }} de {{ evento_unidad.hora_inicio + ' a ' + evento_unidad.hora_fin }}</small>) <a target="_blank" :href="'show-evento-stream?evento=' + (evento_unidad.evento_stream.evento_streaming_id)" class="white--text" ><v-btn small class="primary">
                  Unirse al stream</v-btn></a>
                </p>
                <p class="ma-5" v-else>
                  <v-icon>mdi-alarm-check</v-icon> {{ evento_unidad.nombre_sesion }} (<small>Se realiza el {{ evento_unidad.fecha_consumo_inicio }} de {{ evento_unidad.hora_inicio + ' a ' + evento_unidad.hora_fin }}</small>) <v-btn small class="primary">
                  <a target="_blank" :href="evento_unidad.HtmlLink" class="white--text" >Unirse</a></v-btn>
                </p>
              </div>

              <h5 class="blue--text">Materiales de la sesión</h5>
              <small v-if="( unidad.materiales_sesion && unidad.materiales_sesion.length == 0 )">No se encontraron materiales para esta sesión</small>
              <p v-if="(unidad.materiales_habilitados <= 0)">No hay materiales disponibles para esta sesión</p>
              <v-list v-if="(unidad.materiales_habilitados > 0)">
                <v-list-item v-for="material_sesion in unidad.materiales_sesion">
                  <div style="display: contents;" v-if="(material_sesion.material_evento_habilitado==1)">
                    {{ material_sesion.nombre_material }}
                    <v-btn small class="ml-3 primary"><a class="white--text" target="_blank" :href="material_sesion.ruta_documento">Abrir material</a></v-btn>
                  </div>
                </v-list-item>
              </v-list>

              <div class="mt-5">
                <h5 class="blue--text">Actividades</h5>
                <div v-for="actividad_unidad in unidad.actividades_unidad">
                  <v-list v-if="actividad_unidad.actividad_habilitada">
                    <v-list-group
                        :value="true"
                        no-action
                        sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>{{ actividad_unidad.detalles_actividad }}</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                          v-for="(item_actividad, i) in actividad_unidad.items_actividad"
                          :key="i"
                          link
                      >
                        <v-list-item>{{ item_actividad.descripcion_actividad }}</v-list-item>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </div>
              </div>

              <div class="mt-5">
                <h5 class="blue--text">Objetivos de la unidad</h5>
                <div v-for="objetivo_unidad in unidad.unidad_objetivos">
                  <v-list>
                    <v-list-group
                        :value="true"
                        no-action
                        sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>{{ objetivo_unidad.nombre_objetivo }}</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item v-if="objetivo_unidad.materiales_objetivos_habilitados <= 0">No hay materiales disponibles para este objetivo</v-list-item>
                      <v-list-item
                          v-if="objetivo_unidad.materiales_objetivos_habilitados > 0"
                          v-for="(item_material_objetivo, i) in objetivo_unidad.materiales_objetivo"
                          :key="i"
                          link
                      >
                        {{ item_material_objetivo.nombre_material }}
                        <v-btn small class="primary ml-3" small><a class="white--text" :href="item_material_objetivo.ruta_documento" target="_blank">Abrir</a></v-btn>

                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </div>

              </div>

            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  name : 'contenidodocente',
  data:() => ({
    nombre_sesion : '',
    contenido : [],
    current_role_user : 0,
    admins: [
      ['Management', 'mdi-account-multiple-outline'],
      ['Settings', 'mdi-cog-outline'],
    ],
  }),
  mounted() {
    this.getRoleUser()
    this.getContenidoSesion()
    this.nombre_sesion = this.$route.query.nombre_sesion;
  },
  methods : {
    visibilidadMaterialObjetivo(material) {
      this.$http.post("/api/aprende/visibilidad-material", {
        material_id : material.id,
        estatus_visibilidad : material.material_objetivo_habilitado,
        tipo_material : 2,
        evento_id : this.$route.query.evento_id
      })
          .then(response => {
            Swal.fire({
              //position: 'top-end',
              icon: 'success',
              title: 'Estatus actualizado correctamente',
              showConfirmButton: false,
              timer: 2500
            })
          })
          .catch(error => {

          })
    },
    visibilidadMaterialSesion(material) {
      this.$http.post("/api/aprende/visibilidad-material", {
        material_id : material.id,
        estatus_visibilidad : material.material_evento_habilitado,
        tipo_material : 1,
        evento_id : this.$route.query.evento_id
      })
          .then(response => {
            Swal.fire({
              //position: 'top-end',
              icon: 'success',
              title: 'Estatus actualizado correctamente',
              showConfirmButton: false,
              timer: 2500
            })
          })
          .catch(error => {

          })
    },
    getRoleUser() {
      this.$http.get("/api/aprende/users/get-current-role")
          .then(response => {
            this.current_role_user = response.data;
          }).catch(error => {

      })
    },
    getContenidoSesion() {
      this.$http.post('/api/aprende/contenido-curricula/get-sesion-contenido', {
        curricula_id : this.$route.query.curricula_id,
        evento_id : this.$route.query.evento_id,
        sesion_id : this.$route.query.sesion_id,
      })
      .then(response => {
        this.contenido = response.data;
      })
      .catch(error => {

      })
    }
  }
}
</script>
<template>
    <v-container>
        <template>
            <v-row justify="center">
                <v-dialog v-if="dialogIndicador" :value="true" @input="dialogIndicador = false" persistent max-width="790">
                    <v-card elevation="2" class="mx-auto">
                        <v-card-title>
                            <v-icon large class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon><h3>Unidades para Requisitos</h3>
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                                </v-row>
                                <v-data-table
                                    :headers="headersunidades"
                                    :items="unidades"
                                    class="elevation-1"
                                    :search="search"
                                >
                                    <template v-slot:item.actions="{ item }">
                                        <v-icon v-if=" !((concentrado_unidades).indexOf(parseInt(item.id))>=0)"
                                                class="mr-2"
                                                color="green"
                                                title="Agregar unidad"
                                                @click="anexarUnidad(item)"
                                        >
                                            mdi-cloud-download
                                        </v-icon>
                                    </template>
                                </v-data-table>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="closeImportar()"
                            >
                                Cerrar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <v-card elevation="2" class="mx-auto">
            <v-card-title>
                <!--                <v-breadcrumbs :items="items"></v-breadcrumbs>-->

                <v-icon large class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon><h3>Requisitos Unidades</h3>
                <v-breadcrumbs :items="items" large ></v-breadcrumbs>
                <v-spacer></v-spacer>

            </v-card-title>
            <v-card elevation="2">
                <v-card-title>
                    <v-row
                        align="center"
                        justify="space-around"

                    >
                        <v-btn
                            color="green"
                            small
                            @click="showUnidad()"
                            title="Agregar Unidad"
                        >
                            <v-icon  small color="white" >mdi-checkbox-marked-circle-outline</v-icon>
                        </v-btn>
                    </v-row>
                </v-card-title>
            </v-card>
            <v-card elevation="2">
                <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
                <v-data-table :headers="headers" :items="requisitosunidades" class="elevation-1">
                    <template v-slot:item.actions="{ item }">
                        <div class="text-center">
                            <!--                            <v-btn
                                                            fab elevation="2"
                                                            color="green"
                                                            small
                                                            x-small
                                                            @click="editIndicador(item)"
                                                            style="margin-right: 10px"
                                                            title="Editar Indicador"
                                                        >
                                                            <v-icon small color="white">mdi-pencil</v-icon>
                                                        </v-btn>-->

                            <v-btn
                                fab elevation="2"
                                color="red"
                                small
                                x-small
                                style="margin-right: 10px"
                                @click="deleteUnidad(item)"
                                title="Eliminar Unidad"
                            >
                                <v-icon small color="white">mdi-delete</v-icon>
                            </v-btn>

                        </div>

                    </template>
                </v-data-table>

            </v-card>
        </v-card>
    </v-container>
</template>

<script>
export default {

    data:() => ({
        search:'',
        nombre:'',
        escuela_id:1,
        refrescar_tabla:0,
        abreviatura:'',
        item:[],
        items:[{
            text: 'Unidades',
            disabled: false,
            href: '/dashboardUnidades',
        },
            {
                text: 'Cargando...',
                disabled: true,
            }
        ],
        unidades:[],
        requisitosunidades:[],
        snack: false,
        snackColor: '',
        snackText: '',
        respuestasTotal:0,
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        headers: [
            { text: '#',                value: 'id',                  sortable: true, align: 'start'},
            { text: 'Unidad',           value: 'unidades.nombre_unidad'},
            { text: 'Clave',            value: 'unidades.clave_interna' },
            { text: 'Descripcion',      value: 'unidades.descripcion' },
            { text: 'Acciones',         value: 'actions',             sortable: true, align: 'center'},
        ],
        headersunidades: [
            { text: '#',                value: 'id',                  sortable: true, align: 'start'},
            { text: 'Nombre',           value: 'nombre_unidad',       sortable: true, align: 'justify'},
            { text: 'Clave',            value: 'clave_interna',       sortable: true, align: 'center' },
            { text: 'Descripcion',      value: 'descripcion',         sortable: true, align: 'center' },
            { text: 'Acciones',         value: 'actions',             sortable: true, align: 'center'},
        ],
        overlay: false,
        absolute:true,
        isModalVisible: false,
        dialog: false,
        dialogIndicador: false,
        dialogAyuda:false,
        tipoaccion:'',
        buscar_contador:0,
        clave_indicador:'',
        txtboton:'',
        estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
        idIndicador:0,
        idUnidad:0,
        nombre_unidad:'',
        nombre_indicador:'',
        modolectura:false,
        concentrado_unidades: [],
    }),
    created () {
        //this.init();

    },
    methods: {
        init () {
            //obtiene los reactivos filtrados por el usuario actual.
        },
        anexarUnidad(item) {
            this.guardando = true;
            this.$http.post('/api/aprende/requisitosunidades', {
                'unidad_id_parent': this.idUnidad,
                'unidad_id_requisito': item.id,
                'tipo_requisito': 1
            })
                .then((response) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Unidad agregada como requisito exitosamente.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.getRequisitosUnidades();
                    //this.guardando = false;
                    //this.consultarExamen()
                })
                .catch(function (error) {
                })
        },
        closeModal() {
            this.dialog = false
        },
        closeAyuda(){
            this.dialogAyuda =  false
        },
        closeImportar(){
            this.nombre_indicador   =   '';
            this.clave_indicador    =   '';
            this.descripcion        =   '';
            this.txtboton           =   '';
            this.dialogIndicador    =   false
            this.modolectura        =   false;
        },
        consultar(id){
            this.idTipoReactivo = id;
            if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
                this.showModal();
            }
            else if (this.idTipoReactivo==6)
                this.showModal();
        },
        consultarNivelEducativo(){
            let url = '';
            url = `/api/grados?idnivel=${this.idNivel.id}`
            this.$http.get(url)
                .then((response) => {
                    this.grados = response.data;
                    if(this.tipoaccion==2 ) {
                        this.idGrado = this.grados.find(item => item.id == this.grado_id)
                    }
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        consultarArea(){
            let url = '';
            url = `/api/asignaturas?idArea=${this.idArea.id}`
            this.$http.get(url)
                .then((response) => {
                    this.asignaturas = response.data;
                    if(this.tipoaccion==2 ){
                        this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
                    }
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        deleteUnidad (item){
            let index = this.concentrado_unidades.indexOf(item);
            let index2 = this.requisitosunidades.indexOf(item);
            Swal.fire({
                title: '¿Desea eliminar la Unidad requisito: '+ item.unidades.nombre_unidad + '?',
                showDenyButton: true,
                confirmButtonText: `Eliminar`,
                denyButtonText: `Cancelar`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    let url = `/api/aprende/requisitosunidades/${item.id}`
                    this.$http
                        .delete(url)
                        .then((response) => {
                            //this.concentrado_unidades.splice(index, 1); //array ocupado para los unidades
                            //this.requisitosunidades.splice(index2, 1);
                            this.getRequisitosUnidades()
                            Swal.fire('Unidad requisito eliminada satisfactoriamente.', '', 'success')
                        })
                        .catch(function (error){
                            console.log(error)
                            //this.overlay = false;
                        })

                } else if (result.isDenied) {
                    //Swal.fire('Changes are not saved', '', 'info')
                }
            })
            //this.overlay = true;



        },
        editReactivo(item){
            this.item                 =   item;
            this.dialogIndicador       =   true;
            this.idTipoReactivo       =   item.tiporeactivo.tiporeactivo_concentrado.id;
            this.tipoaccion           =   2;
            this.estatusAccionTabla   =   2;
        },
        editIndicador(item) {
            this.idIndicador            =   item.id;
            this.nombre_indicador       =   item.nombre_indicador;
            this.clave_indicador        =   item.clave_indicador;
            this.dialogIndicador        =   true;
            this.txtboton               =   "Actualizar"
            this.estatusAccionTabla     =   2;
            this.modolectura            =   true;
        },
        getUnidades(){
            let url = `/api/aprende/unidades/`+this.idUnidad;
            //console.log(this.concentrado_unidades)
            this.$http.get(url)
                .then((response) => {
                    this.unidades = response.data;
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        getRequisitosUnidades(){
            let url = `/api/aprende/requisitosunidades/`+this.idUnidad
            //console.log(url);
            this. concentrado_unidades = []
            this.$http.get(url)
                .then((response) => {
                    this.requisitosunidades = response.data;
                    //console.log("requisitosunidades")
                    //console.log(this.requisitosunidades)
                    for (let item = 0; item < this.requisitosunidades.length; item++) {
                        this.concentrado_unidades.push(this.requisitosunidades[item].unidades.id)
                    }
                    console.log("concentrado unidades:")
                    console.log(this.concentrado_unidades)
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        showImportar(){
            this.dialogIndicador = true;
        },
        showModal() {
            this.dialog = true;
        },
        showUnidad() {
            this.dialogIndicador     = true;
            this.txtboton           = "Guardar";
            this.estatusAccionTabla =   1;
            this.getUnidades();
        },
    },
    mounted(){
        this.idUnidad         =    atob(this.$route.query.idUnidad);
        this.nombre_unidad    =    atob(this.$route.query.nombre_unidad);
        this.items[1].text    =    'Unidad: '+ atob(this.$route.query.nombre_unidad);
        //this.items[2].text  =    atob(this.$route.query.descripcion);
        this.getRequisitosUnidades()
    }
}
</script>
<template>
  <v-container>
    <template>
      <v-dialog v-if="dialogObjetivo" :value="true" @input="dialogObjetivo = false" persistent max-width="790">
        <v-card elevation="2" class="mx-auto">
          <v-card-title>
            <v-icon large class="mr-2" color="green">mdi-message-text-outline</v-icon><h3>Nueva Categoría Indicador</h3>
            <v-spacer></v-spacer>

          </v-card-title>
          <v-card-text>
            <v-row >
              <v-col cols="12" sm="12">
                <v-text-field
                    label="Nombre Categoria"
                    v-model="categoria"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-btn @click="guardar" small>
                  {{txtboton}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="closeModal()"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </template>
    <v-card elevation="2" class="mx-auto">
      <v-card-title>
        <v-icon large class="mr-2" color="green">mdi-message-text-outline</v-icon><h3>Categorías Indicadores</h3>
        <v-spacer></v-spacer>

      </v-card-title>
      <v-card elevation="2">
        <v-card-title>
          <v-row
              align="center"
              justify="space-around"

          >
            <v-btn
                color="green"
                small
                @click="showModal()"
                title="Crear Categoria"
            >
              <v-icon  small color="white" >mdi-message-text-outline</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card elevation="2">
        <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table :headers="headers" :items="concentrado" class="elevation-1">

          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <v-btn
                  fab elevation="2"
                  color="green"
                  small
                  x-small
                  @click="editar(item)"
                  title="Editar Categoría"
                  style="margin-right: 10px"
              >
                <v-icon small color="white">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  fab elevation="2"
                  color="red"
                  small
                  x-small
                  title="Eliminar Categoría"
              >
                <v-icon  small @click="eliminar(item)" title="Eliminar Categoría" color="white">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data:() => ({
    search:'',
    nombre:'',
    escuela_id:1,
    item:[],
    concentrado:[],
    headers: [
      {text: 'Id',align: 'start',sortable: true,value: 'id'},
      { text: 'Nombre', value: 'categoria'},
      { text: 'Acciones', value: 'actions',align:'center'},
    ],
    overlay: false,
    absolute:true,
    dialog: false,
    dialogObjetivo: false,
    categoria:'',
    clave_interna:'',
    descripcion:'',
    txtboton:'',
    estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
    id:0,
    modolectura:false,
  }),
  created () {
    this.init();
  },
  methods: {
    init () {
      this.getConcentrado()       //obtiene los reactivos filtrados por el usuario actual.
    },
    closeModal(){
      this.categoria    =  '';
      this.txtboton           =  '';
      this.dialogObjetivo     =  false
      this.modolectura        =  false;
    },
    editar(item) {
      this.id         =   item.id;
      this.categoria    =   item.categoria;
      this.dialogObjetivo     =   true;
      this.txtboton           =   "Actualizar"
      this.modolectura        =   true;
      this.estatusAccionTabla     =   2;
    },
    eliminar (item){
      Swal.fire({
        title: '¿Desea eliminar la Categoría: '+ item.categoria + '?',
        showDenyButton: true,
        confirmButtonText: `Eliminar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          let url = `/api/aprende/categoria-indicador/${item.id}`
          this.$http
              .delete(url)
              .then((response) => {
                this.getConcentrado()
                Swal.fire('Categoría eliminada satisfactoriamente.', '', 'success')
              })
              .catch(function (error){
                console.log(error)
              })

        } else if (result.isDenied) {
          //Swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    getConcentrado(){
      let url = `/api/aprende/categoria-indicador`
      this.$http.get(url)
          .then((response) => {
            this.concentrado = response.data;
          })
          .catch(function (error){
            console.log(error)
          })
    },
    guardar(){
      if(this.categoria=='')
        confirm('Debe capturar el nombre de la Categoria')
      else{
        if(this.estatusAccionTabla==1){
          this.$http.post('/api/aprende/categoria-indicador', {
            'categoria': this.categoria,
          })
              .then((response) => {
                if(response.data){
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Categoria guardada exitosamente.',
                    showConfirmButton: false,
                    timer: 2500
                  })
                  this.getConcentrado()
                  //this.concentrado.unshift(response.data) //inserta el elemento al principio del Arreglo
                  this.categoria = '';
                }
                else{
                  Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Unidad existente, verificar Clave interna.',
                    showConfirmButton: false,
                    timer: 2500
                  })
                }
              })
              .catch(function (error) {
                console.log(error)
              })
        }
        else if(this.estatusAccionTabla==2){
          this.$http.post('/api/aprende/categoria-indicador/'+ this.id, {
            _method           : 'PUT',
            'categoria'       : this.categoria,
          })
              .then((response) => {
                if(response.data){
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Categoría actualizada exitosamente.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.getConcentrado()
                }
                else{
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Categoría no actualizada. Verificar.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
              })
              .catch(function (error) {
                console.log(error)
              })
        }
      }
    },
    showModal() {
      this.dialogObjetivo     = true;
      this.txtboton           = "Guardar";
      this.estatusAccionTabla =   1;
    },
  },
  mounted(){
  }
}
</script>
import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=d5a656ae&"
import script from "./Main.vue?vue&type=script&lang=js&"
export * from "./Main.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/assets/styles/dc.css?vue&type=custom&index=0&blockType=link&issuerPath=C%3A%5Claragon%5Cwww%5Cavanza-frontend%5Csrc%5Cviews%5CEstadistica%5CMain.vue&scoped=true"
if (typeof block0 === 'function') block0(component)

export default component.exports
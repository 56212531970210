var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('h1',[_vm._v("Gestión de usuarios")]),_c('v-divider',{staticClass:"mt-5 mb-5"}),_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5 mb-5",attrs:{"color":"blue","dark":""},on:{"click":_vm.getSheets}},'v-btn',attrs,false),on),[_vm._v(" Importar usuarios ")])]}}]),model:{value:(_vm.dialogImportar),callback:function ($$v) {_vm.dialogImportar=$$v},expression:"dialogImportar"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Importación de usuarios")])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"label":"Crear nuevo documento"},model:{value:(_vm.nuevo_sheet.detalles_importacion),callback:function ($$v) {_vm.$set(_vm.nuevo_sheet, "detalles_importacion", $$v)},expression:"nuevo_sheet.detalles_importacion"}}),_c('v-select',{attrs:{"items":_vm.escuelas,"item-text":"nombre","item-value":"id","label":"Seleccionar escuela para importar"},model:{value:(_vm.nuevo_sheet.escuela_id),callback:function ($$v) {_vm.$set(_vm.nuevo_sheet, "escuela_id", $$v)},expression:"nuevo_sheet.escuela_id"}}),_c('v-select',{attrs:{"items":_vm.grupos_aprende,"label":"Elegir grupo de Nivelando","item-value":"id","item-text":"nombre_grupo"},model:{value:(_vm.nuevo_sheet.grupo_aprende_id),callback:function ($$v) {_vm.$set(_vm.nuevo_sheet, "grupo_aprende_id", $$v)},expression:"nuevo_sheet.grupo_aprende_id"}}),(_vm.habilitar_boton)?_c('v-btn',{staticClass:"primary",on:{"click":_vm.createSheet}},[_vm._v("Crear documento")]):_vm._e(),_c('v-divider',{staticClass:"mt-5 mb-5"}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersImportar,"items":_vm.desertsImportar,"loading":_vm.loadingImportar},scopedSlots:_vm._u([{key:"item.escuela_importacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_escuela)+" ")]}},{key:"item.ruta_archivo",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.ruta_archivo,"target":"_blank"}},[_c('v-btn',{staticClass:"primary  white--text"},[_vm._v("Abrir")])],1)]}},{key:"item.importar_documento",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"green white--text",on:{"click":function($event){return _vm.importarSheet(item)}}},[_vm._v("Importar")])]}},{key:"item.eliminar_documento",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"red  white--text",on:{"click":function($event){return _vm.deleteSheet(item)}}},[_vm._v("Eliminar")])]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogImportar = false}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headersUsuarios,"items":_vm.dessertsUsuarios},scopedSlots:_vm._u([{key:"item.editar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"blue"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)]}},{key:"item.eliminar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"red"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
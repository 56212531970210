var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey--text text-darken-1 mb-2"},[_vm._v(" Reporte Trimestral "),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","sm":"4"}},[[_c('v-select',{attrs:{"label":"Curso:","items":_vm.cursos,"item-value":"id","item-text":"nombre","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description !=null ? item.nombre + '('+item.description+')': item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description !=null ? item.nombre + '('+item.description+')': item.nombre)+" ")]}}]),model:{value:(_vm.idCurso),callback:function ($$v) {_vm.idCurso=$$v},expression:"idCurso"}})]],2),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","sm":"4"}},[[_c('v-select',{attrs:{"label":"Trimestre:","items":_vm.trimestres,"item-value":"id","item-text":"fecha_inicio","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_inicio))+" - "+_vm._s(_vm.formatDate(item.fecha_fin))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_inicio))+" - "+_vm._s(_vm.formatDate(item.fecha_fin))+" ")]}}]),model:{value:(_vm.idTrimestre),callback:function ($$v) {_vm.idTrimestre=$$v},expression:"idTrimestre"}})]],2),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","sm":"4"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.consultarPeriodos()}}},[_vm._v("Consultar")])],1)],1)],1),_vm._v(" Concentrado(s) generado(s) "),_c('v-data-table',{attrs:{"headers":_vm.headers_rpt,"items":_vm.reportes},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"target":"_blank","href":value}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.eliminarHoja(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_vm._v(" Periodo(s) comprendido(s) de Trimestre: "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.periodos,"single-select":_vm.singleSelect,"show-select":""},scopedSlots:_vm._u([{key:"item.periodo_concentrado.fecha_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fecha")(item.periodo_concentrado.fecha_inicio))+" ")]}},{key:"item.periodo_concentrado.fecha_fin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fecha")(item.periodo_concentrado.fecha_fin))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"grey--text text-darken-1 mb-2"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","sm":"4"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.generarReporte()}}},[_vm._v("Generar Concentrado")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <div>
      <v-alert
          text
          dismissible
          color="info"
      >
        <div>Por favor ingrese los siguientes datos para crear un nuevo curso</div>
      </v-alert>
      <v-card class="pa-5">
        <v-card-title>
          <h3>Crear un curso</h3>
        </v-card-title>
        <v-text-field
            v-model="nuevo_curso.nombre_curso"
            label="Nombre del curso"
        ></v-text-field>
        <v-text-field
            v-model="nuevo_curso.clave_interna"
            label="Clave interna del curso"
        ></v-text-field>
        <v-select
            v-model="nuevo_curso.unidades"
            multiple
            :items="itemsUnidades"
            item-text="nombre_unidad"
            item-value="id"
            label="Seleccionar unidades"
        >
          <template slot="selection" slot-scope="data">
            <!-- HTML that describe how select should render selected items -->
            {{ data.item.nombre_unidad }} - <strong>{{ data.item.clave_interna }}</strong>
          </template>
          <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ data.item.nombre_unidad }} - <strong>{{ data.item.clave_interna }}</strong>
          </template>
        </v-select>
        <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="guadarCurso()"
        >Guardar curso</v-btn>
      </v-card>

      <v-card>
        <v-card-title>
          <v-icon large class="mr-2" color="green">mdi-format-list-bulleted</v-icon><h3>Cursos disponibles</h3>
        </v-card-title>
        <v-data-table
            loading-text="Cargando, espere"
            :loading="innerLoading"
            :headers="headersCursos"
            :items="dessertsCursos"
            item-key="name">
          <template v-slot:item.editar="{ item }">
            <a style="text-decoration: none;" :href="'/cursos-show/?cursoId=' + item.id">
              <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="green"
              >
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </a>
          </template>
          <template v-slot:item.eliminar="{ item }">
            <v-btn
                @click="deleteItem(item)"
                class="mx-2"
                fab
                dark
                small
                color="red"
            >
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
<!--          <template v-slot:item.requisitos="{ item }">
            <v-btn
                class="mx-2"
                fab
                dark
                small
                color="green"
            >
              <v-icon dark>
                mdi-check
              </v-icon>
            </v-btn>
          </template>-->
<!--          <template v-slot:item.curriculas="{ item }">
            <v-btn
                class="mx-2"
                fab
                dark
                small
                color="orange"
            >
              <v-icon dark>
                mdi-clipboard-list
              </v-icon>
            </v-btn>
          </template>-->
        </v-data-table>
      </v-card>
    </div>
  </v-container>
</template>



<script>
export default {
  data:() => ({
    dessertsCursos : [],
    innerLoading : false,
    cursos : [],
    itemsUnidades : [],
    nuevo_curso : {
      nombre_curso : '',
      clave_interna : '',
      unidades : [],
    }
  }),
  mounted() {
    this.showCursos();
    this.getUnidades();
  },
  methods : {
    getUnidades() {
      this.$http.get('/api/aprende/unidades')
          .then(response => {
            this.itemsUnidades = response.data;
          })
          .catch(error => {

          })
    },
    showCursos() {
      this.innerLoading = true;
      this.$http.get('/api/aprende/cursos')
          .then(response => {
            this.dessertsCursos = response.data;
            this.innerLoading = false;
          });
    },
    deleteItem(item) {
      let index = this.dessertsCursos.indexOf(item);
      Swal.fire({
        title: '¿Está seguro que desea eliminar este registro?',
        //showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$http.post('/api/aprende/cursos/' + item.id, {
            _method : 'DELETE'
          })
              .then(response => {
                this.dessertsCursos.splice(index, 1);
              })
              .catch(error => {

              })
          Swal.fire('ELiminado correctamente!', '', 'success')
        } else if (result.isDenied) {
          //Swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    guadarCurso() {
      this.$http.post('/api/aprende/cursos', {
        nombre_curso : this.nuevo_curso.nombre_curso,
        clave_interna : this.nuevo_curso.clave_interna,
        unidades : this.nuevo_curso.unidades
      })
      .then(response => {
        if ( response.data ) {
          this.dessertsCursos.unshift(response.data);
          this.nuevo_curso.nombre_curso = '';
          this.nuevo_curso.clave_interna = '';
        }
        else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'El curso ingresado ya existe, por favor intente con otro',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
      .catch(error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Todos los campos deben ser obligatorios',
          showConfirmButton: false,
          timer: 1500
        })
      })
    },
  },
  computed : {
    headersCursos() {
      return [
        { text: '#', sortable: false, value: 'id', },
        { text: 'Nombre', sortable: false, value: 'nombre_curso', },
        { text: 'Clave', sortable: false, value: 'clave_interna', },
        { text: 'Fecha', sortable: false, value: 'format_created_at', },
        { text: 'Detalles', sortable: false, value: 'editar', },
        { text: 'Eliminar', sortable: false, value: 'eliminar', },
        /*{ text: 'Requisitos', sortable: false, value: 'requisitos', },*/
        /*{ text: 'Contenidos', sortable: false, value: 'curriculas', },*/
      ]
    },
  }
}
</script>
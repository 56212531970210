var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('h3',[_vm._v("Configuración de Grados")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('v-text-field',{attrs:{"label":"Nombre","value":_vm.nombre,"dense":""},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('v-text-field',{attrs:{"label":"Núm. de Orden","value":_vm.orden,"dense":""},model:{value:(_vm.orden),callback:function ($$v) {_vm.orden=$$v},expression:"orden"}})],1),_c('v-col',{attrs:{"cols":"3","sm":"3"}},[[_c('v-select',{attrs:{"label":"Nivel Educativo:","items":_vm.niveles,"item-value":"id","item-text":"nombre","dense":""},on:{"change":_vm.getIdNivel},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" ")]}}]),model:{value:(_vm.idNivel),callback:function ($$v) {_vm.idNivel=$$v},expression:"idNivel"}})]],2),_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","title":"Agregar Grado"},on:{"click":function($event){return _vm.guardarGrado()}}},[_vm._v("Agregar")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.grados},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.nombre},on:{"change":function($event){return _vm.actualizarNombre(item)}},model:{value:(item.nombre),callback:function ($$v) {_vm.$set(item, "nombre", $$v)},expression:"item.nombre"}})]}},{key:"item.abreviatura",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.abreviatura},on:{"change":function($event){return _vm.actualizarAbreviatura(item)}},model:{value:(item.abreviatura),callback:function ($$v) {_vm.$set(item, "abreviatura", $$v)},expression:"item.abreviatura"}})]}},{key:"item.activo",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"value":item.activo},on:{"change":function($event){return _vm.actualizarActivo(item)}},model:{value:(item.activo),callback:function ($$v) {_vm.$set(item, "activo", $$v)},expression:"item.activo"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container fluid>
        <v-row>
            <v-col :cols="1"></v-col>
            <v-col :cols="10">               
                <v-card elevation="4">
                    <v-card-title>
                        <v-icon large class="mr-2" color="purple">
                            mdi mdi-check-all
                        </v-icon>
                        <h3>{{nombreEncuesta}}</h3>
                    </v-card-title>
                    <v-card-text>


                        <v-row>
                            <v-col :cols="12">
                                <div class="form-group" v-for="(input,k) in inputs" :key="k">
                                    
                                        <label >
                                            {{input.name}}
                                        </label>

                                        <v-radio-group
                                            
                                            :value="( input.respuesta ) ? input.respuesta : 0" 
                                             row>
                                            <div class="form-group" v-for="(answer,j) in input.answers" :key="j">
                                                
                                                <v-radio
                                                    :label="answer.name"
                                                    :value="answer.id"
                                                    @change="add(answer.id, k, input.id)"
                                                >
                                                </v-radio>

                                            </div><!--form-group respuestas -->
                                    </v-radio-group>
                                </div><!--form-group preguntas -->

                            </v-col>
                        </v-row>  
                     </v-card-text>
                </v-card>
                 <v-row>
                    <v-col :cols="12" id="col-save">
                        <v-btn
                            id=""
                            color="primary"
                            dark
                            class="my-2 ml-auto"
                            @click="saveData()"
                        >Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
             <v-col :cols="1"></v-col>
        </v-row>
       
   </v-container>
</template>

<script>
export default {
    beforeCreated  () {
                var id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
                if( id === "" || !id || id == 0 || id === 0) 
                { 
                      Swal.fire(
                        '¡Uuppss!',
                        'Ha ocurrido un error al consultar la encuesta',
                        'error'
                        )       
                  console.log("error");
                  window.location.href = '/';

                }
    },
    data(){
        return{
                state: [],
                radio1:'',
                id          :'',
                evento_id   :'',
                nombreEncuesta: String,
                inputs: [
                            { pregId:'', name: '' , choosed: '',answers: [] }
                        ]
        }
    },
    mounted(){

             this.getData();
    },
    methods:{
        add(item, index2, pregId){
            this.inputs[index2].choosed = item;
            this.inputs[index2].pregId  = pregId;
        },
        getData(){

               const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
               
               const url = `/api/show-encuesta/${id}`;
                    this.$http.get(url)
                  .then((response) => {         
                    this.nombreEncuesta = response.data.nombreEncuesta;            
                    this.inputs = response.data.preguntas;
                  })
                },//getData
             saveData(){
          
            let preguntas       = (this.inputs)           ? this.inputs         : 0;
            const id            = (this.$route.params.id) ? (this.$route.params.id) : 0;
            const evid          = (this.evid)           ? this.evid         : 0;

            if( !this.$route.params.id) { return }

            for (let index = 0; index <  this.inputs.length; index++) {
                if( this.inputs[index].choosed === "" || !this.inputs[index].choosed ) 
                { 
                    
                     Swal.fire(
                        '¡Uuppss!',
                        'Hacen falta de contestar preguntas',
                        'error'
                        )
                   return                             
                }
            }

            this.$http.post("/api/save-encuesta", {
            preguntas         : preguntas,
            evid              :evid,
            id                : id
            }).then((response) => {
               
                    if(response.data.data === "1"){
                                        Swal.fire(
                                                '¡Excelente!',
                                                '¡Gracias por tu participación!',
                                                'success'
                                                )
                    }

                })
                .catch(function (error) {
                console.log(error);
                    Swal.fire(
                        '¡Uuppss!',
                        'Ha ocurrido un error al actualizar',
                        'error'
                        )
                return 
                });

        }
    }

}
</script>

<style scoped>
    #col-save{
        text-align: right !important;

    }
</style>
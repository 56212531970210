<template>
    <v-container>
    <v-card elevation="2">
        <v-card-title>Datos del Colegio</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col class="d-flex" cols="12" sm="6" md="6">
                        <template>
                            <v-select
                                label='Curso:'
                                v-model='idCurso'
                                :items='cursos'
                                item-value='id'
                                item-text='nombre'
                                outlined
                                return-object
                                @change="getCursoSeleccionado"
                            >
                                <template slot='selection' slot-scope='{ item }'>
                                    {{ item.nombre }}
                                </template>
                                <template slot='item' slot-scope='{ item }'>
                                    {{ item.nombre }}
                                </template>
                            </v-select>
                        </template>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex" cols="12" sm="6" md="6">
                        <v-data-table
                            :headers="headers"
                            :items="alumnos"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex" cols="12" sm="6" md="6">
                        <v-data-table
                            :headers="headers2"
                            :items="tareas"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" sm="6">
                        <v-btn
                            color="primary"
                            dark
                            id="btnReactivo"
                            class="mb-2"
                            @click="asignarCalificacion()"
                        >Asignar calificación
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
    </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
  export default {
    data:() => ({
        idCurso: null,
        pagination: {},
        cursos:[],
        alumnos:[],
        tareas:[],
        course_id:'',
        headers: [
          {text: 'Nombre del Alumno',align: 'start',sortable: false,value: 'nombre',},
          { text: 'Email', value: 'email' },
          { text: 'idalumno', value: 'id' },
        ],
        headers2: [
            {text: 'Id',align: 'start',sortable: false,value: 'id',},
            {text: 'Nombre de la Tarea',align: 'start',sortable: false,value: 'titulo',},
            {text: 'Descripcion',align: 'start',sortable: false,value: 'descripcion',},
        ],

    }),
    created () {

    },    
    methods: {
        init () {
        },
        getCursos () {
            let url = '/api/cursos'
            this.$http.get(url)
                .then((response) => {
                    this.cursos = response.data;
                })
                .catch(function (error){
                        console.log(error)
                })
        },
        getCursoSeleccionado(){
          this.course_id = this.idCurso.course_id;
          this.getAlumnos();
          this.getTareas();
        },
        getAlumnos(){
            let url = '/api/escuelas/getAlumnos?course_id='+this.course_id;
            this.$http.get(url)
                .then((response) => {
                    this.alumnos = response.data;
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        getTareas(){
           // console.log(this.course_id)
            let url = '/api/escuelas/getTareas?course_id='+this.course_id;
            this.$http.get(url)
                .then((response) => {
                    this.tareas = response.data;
                })
                .catch(function (error){
                    console.log(error)
                })
        },
        asignarCalificacion(){
            let url = '/api/escuelas/asignarCalificacion?course_id='+this.course_id;
            this.$http.post(url)
                .then((response) => {
                    console.log(response.data)
                })
                .catch(function (error){
                    console.log(error)
                })
        }
    },
    mounted(){
        this.getCursos()
    } 
  }
</script>
<template>
  <v-container>
    <v-card
        elevation="2"
    >
      <v-card-title>
        <v-icon large class="mr-2" color="green">mdi-equal-box</v-icon>
        <h3>{{ texto }}</h3>
      </v-card-title>
      <v-container class="grey lighten-5">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <template>
              <v-select
                  label='Nivel Educativo:'
                  v-model='idNivel'
                  :items='niveles'
                  item-value='id'
                  item-text='nombre'
                  outlined
                  id="selectidnivel"
                  return-object
                  @change="consultarNivelEducativo"
              >
                <template slot='selection' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
                <template slot='item' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
              </v-select>
            </template>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <template>
              <v-select
                  label='Grado:'
                  v-model='idGrado'
                  :items='grados'
                  item-value='id'
                  item-text='nombre'
                  outlined
                  id="selectidGrado"
                  return-object
              >
                <template slot='selection' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
                <template slot='item' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
              </v-select>
            </template>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <template>
              <v-select
                  label='Area de Conocimiento:'
                  v-model='idArea'
                  :items='areas'
                  item-value='id'
                  item-text='nombre'
                  outlined
                  id="selectidArea"
                  return-object
                  @change="consultarArea"
              >
                <template slot='selection' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
                <template slot='item' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
              </v-select>
            </template>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <template>
              <v-select
                  label='Asignatura:'
                  v-model='idAsignatura'
                  :items='asignaturas'
                  item-value='id'
                  item-text='nombre'
                  outlined
                  id="selectidAsignatura"
                  return-object
              >
                <template slot='selection' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
                <template slot='item' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
              </v-select>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
                label="Descripción:"
                v-model="descripcion"
                v-bind:value="descripcion"
                :rows="3"
                id="txtdescripcion"
                outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" sm="8">
            <div v-if="imagen_seleccionada==1">
              <a :href="url_imagen_seleccionada" target="_blank">
                <v-img
                    :src="url_imagen_seleccionada"
                    width="150px"
                    height="auto"
                ></v-img>
              </a>
            </div>
          </v-col>
          <v-col cols="4" sm="4">
            <drive :idarchivo="idarchivo" @idarchivo="idarchivo=$event"></drive>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="12">
            <v-switch v-model="agregar_otro_youtube" label="Agregar video de otra cuenta de youtube"></v-switch>

            <v-text-field v-if="agregar_otro_youtube" v-model="enlace_externo_youtube" label="Pegar enlace de youtube"></v-text-field>

            <v-autocomplete
                v-else
                v-model="youtubeModel"
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                hide-no-data
                hide-selected
                item-text="title"
                label="Buscar videos en mi canal de Youtube"
                placeholder="Escriba para filtrar la búsqueda"
                prepend-icon="mdi-video"
                return-object
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <img :src="data.item.thumbnails.default.url">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                        v-html="data.item.title + ' (Empieza el '+(data.item.scheduledStartTime)+') '"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.description.substr(0,100) + '...' "></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>

            </v-autocomplete>

            <v-expand-transition>

              <v-container v-if="youtubeModel">
                <v-alert
                    dense
                    type="info"
                    class="pa-5"
                >
                  A continuación se desglosa la <strong>información del video</strong> elegido desde el filtro
                </v-alert>
                <h2 class="mt-5 mb-2">{{ fields.title }}</h2>
                <p class="ma-0">Link del video: <strong><a target="_blank"
                                                           v-text="'https://www.youtube.com/watch?v=' + fields.videoId.videoId"
                                                           :href="'https://www.youtube.com/watch?v=' + fields.videoId.videoId"></a></strong>
                </p>
                <v-divider class="mt-2 mb-2"></v-divider>
                <p>{{ fields.description }}</p>
                <!--              <iframe width="560" height="315" :src="'https://www.youtube.com/embed/'+fields.videoId.videoId" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                <v-img :src="fields.thumbnails.high.url"></v-img>
              </v-container>

            </v-expand-transition>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
                label="Instrucciones (opcional):"
                v-model="instrucciones"
                v-bind:value="instrucciones"
                :rows="3"
                id="txtinstrucciones"
                outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <template>
              <v-select
                  label='Tiempo en responder:'
                  v-model='idtiemporeactivo'
                  :items='arraytiemporeactivo'
                  item-value='id'
                  item-text='nombre'
                  outlined
                  id="selecttiemporeactivos"
                  return-object
              >
                <template slot='selection' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
                <template slot='item' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
              </v-select>
            </template>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <template>
              <v-select
                  label='Nivel de Pregunta:'
                  v-model='idnivelreactivo'
                  :items='arraynivelreactivo'
                  item-value='id'
                  item-text='nombre'
                  outlined
                  id="selectnivelreactivo"
                  return-object
              >
                <template slot='selection' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
                <template slot='item' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
              </v-select>
            </template>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <template>
              <v-select
                  label='Tipo de visibilidad:'
                  v-model='idtipovisibilidad'
                  :items='arraytipovisibilidad'
                  item-value='id'
                  item-text='nombre'
                  outlined
                  id="selecttipovisibilidad"
                  return-object
              >
                <template slot='selection' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
                <template slot='item' slot-scope='{ item }'>
                  {{ item.nombre }}
                </template>
              </v-select>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="4" md="4">
            <template>
              <v-select
                  label='Criterio para calificar pregunta:'
                  v-model='idCriterio'
                  :items='arraycriterios'
                  item-value='id'
                  item-text='nombre_criterio'
                  outlined
                  :disabled="desactivarcriterio"
                  id="selectcriterio"
                  return-object
              >
                <template slot='selection' slot-scope='{ item }'>
                  {{ item.nombre_criterio }}
                </template>
                <template slot='item' slot-scope='{ item }'>
                  {{ item.nombre_criterio }}
                </template>
              </v-select>
            </template>
          </v-col>
          <v-col cols="6" sm="4" md="4">
            <template>
              <v-switch
                  label="Permitir justificante"
                  v-model="permitir_justificante_val"
              ></v-switch>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-btn
                color="primary"
                dark
                id="btnReactivo"
                class="mb-2"
                @click="guardarReactivo()"
            >GUARDAR REACTIVO
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-overlay :value="overlay" :absolute="absolute">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-data-table
          v-model="selected"
          :headers="headers"
          :items="banco"
          :loading="guardando"
          loading-text="Actualizando..."
          dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Respuestas</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="640px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!activobtn"
                >Agregar respuesta
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Capturar opción de respuesta</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="">
                        <v-text-field v-model="respuesta" id="respuesta" label="Respuesta"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" sm="8">
                        <div v-if="imagen_seleccionada_respuesta==1">
                          <a :href="url_imagen_seleccionada_respuesta" target="_blank">
                            <v-img
                                :src="url_imagen_seleccionada_respuesta"
                                width="150px"
                                height="auto"
                            ></v-img>
                          </a>
                        </div>
                      </v-col>
                      <v-col cols="4" sm="4">
                        <drive_respuestas :idarchivo_respuesta="idarchivo_respuesta"
                                          @idarchivo_respuesta="idarchivo_respuesta=$event"></drive_respuestas>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small color="warning" dark @click="close">Cerrar</v-btn>
                  <v-btn small color="primary" dark @click="guardarRespuesta()">Agregar opción</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.correcta="{ item }">
          <v-checkbox
              v-model="item.correcta"
              v-bind:value="item.correcta"
              @change="actualizarCorrecta(item)"
          ></v-checkbox>
        </template>
        <template v-slot:item.respuesta="{ item }">
          <v-text-field
              v-model="item.respuesta"
              v-bind:value="item.respuesta"
              regular
              :readonly="isReadOnly"
              dense
              @change="actualizarRespuesta(item)"
          ></v-text-field>
        </template>

        <template v-slot:item.imagen_respuesta="{ item }">
          <a :href="item.imagen_respuesta" target="_blank">
            <v-img
                :src="item.imagen_respuesta"
                width="150px"
                height="auto"
            ></v-img>
          </a>
        </template>


        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              @click="deleteRespuesta(item)"
              title="Eliminar Respuesta"
              color="red"
              :disabled="!activobtn"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import drive from "@/components/drive";
import drive_respuestas from "@/components/drive_respuestas";

export default {
  name: 'modal',
  props: {
    enlace_externo_youtube_edit: {
      type: String,
      required: false,
    },
    show_youtube_info: {
      type: Number,
      required: false,
    },
    youtube_video_id: {
      type: String,
      required: false,
    },
    tiporeactivo: {
      type: Number,
      required: true,
    },
    tipoaccion: {
      type: Number,
      required: true,
    },
    escuela_id_prop: {
      type: Number,
      required: false,
    },
    nivel_id: {
      type: Number,
      required: false,
    },
    grado_id: {
      type: Number,
      required: false,
    },
    criterio_id: {
      type: Number,
      required: false,
    },
    area_id: {
      type: Number,
      required: false,
    },
    asignatura_id: {
      type: Number,
      required: false,
    },
    descripcion_prop: {
      type: String,
      required: false,
    },
    instrucciones_prop: {
      type: String,
      required: false,
    },
    id: {
      type: Number,
      required: false,
    },
    tiemporeactivo: {
      type: Number,
      required: false,

    },
    nivelreactivo: {
      type: Number,
      required: false,
    },
    tipovisibilidad: {
      type: Number,
      required: false,
    },
    urlimagen_pregunta: {
    type: String,
    required: false,
    },
    permitir_justificante: {
      type: Number,
      required: false,
    }
  },
  components: {drive, drive_respuestas},
  data: () => ({
    enlace_externo_youtube: "",
    agregar_otro_youtube: false,
    listStatus: {'private': 'Privado', 'public': 'Público', 'unlisted': 'No listado'},
    entries: [],
    isLoading: false,
    youtubeModel: null,
    search: null,
    idarchivo: [],
    imagen_seleccionada: 0,
    url_imagen_seleccionada: '',
    url_imagen_seleccionada_copia: '',
    obj_imagen_pregunta: [],

    idarchivo_respuesta: [],
    imagen_seleccionada_respuesta: 0,
    url_imagen_seleccionada_respuesta: '',
    obj_imagen_pregunta_respuesta: [],

    permitir_justificante_val:0,

    dialog: false,
    formula: '',
    selected: [],
    absolute: false,
    oculto: true,
    descripcion: '',
    instrucciones: '',
    editedIndex: -1,
    desactivarcriterio: false,
    nombre: '',
    idNivel: '',
    idGrado: '',
    idArea: '',
    idCriterio: '',
    idAsignatura: '',
    idEscuela: '',
    idReactivo: '',
    activobtn: '',
    idtiporeactivo: '',
    guardando: false,
    niveles: [],
    arraycriterios: [],
    banco: [],
    areas: [],
    grados: [],
    texto: '',
    respuesta: '',
    textbtn: 'Agregar eactivo',
    asignaturas: [],
    headers: [
      {text: 'Es correcta', align: 'start', sortable: false, value: 'correcta'},
      {text: 'Respuesta', align: 'start', sortable: false, value: 'respuesta'},
      {text: 'Imagen', align: 'start', sortable: false, value: 'imagen_respuesta'},
      {text: 'Acciones', value: 'actions', sortable: true},
    ],
    overlay: false,
    textobtn: '',
    arraytiemporeactivo: [],
    arraynivelreactivo: [],
    arraytipovisibilidad: [],
    idtiemporeactivo: '',
    idnivelreactivo: '',
    idtipovisibilidad: '',
    guardado: 0,
    respuesta_seleccionada: 0,
    totalRespuestas:0,
    totalRespuestasSeleccionadas:0
    //iniciocargado: 1,
  }),
  computed: {
    fields() {
      if (!this.youtubeModel) return []

      return this.youtubeModel

      return Object.keys(this.youtubeModel).map(key => {
        return {
          key,
          value: this.youtubeModel[key] || 'n/a',
        }
      })
    },
    items() {
      return this.entries.map(entry => {
        const Description = entry.description.length > this.descriptionLimit
            ? entry.description.slice(0, this.descriptionLimit) + '...'
            : entry.description

        return Object.assign({}, entry, {Description})
      })
    },
    isReadOnly() {
      if (this.tiporeactivo == 3)
        return true;
      else
        return false;
    },
    istexto() {
      return "ACTUALIZAR REACTIVO";
    }
  },
  methods: {
    init() {

    },
    setIt(event) {
      this.formula = event || '';
      console.log(this.formula);
    },
    actualizarCorrecta(item) {
      this.guardando = true;
      if (item.correcta == null) {
        item.correcta = 0;
        this.respuesta_seleccionada = this.respuesta_seleccionada - 1;
        this.$emit("gettotalRespuestasSeleccionadas",this.respuesta_seleccionada);

      } else {
        item.correcta = 1;
        this.respuesta_seleccionada = this.respuesta_seleccionada + 1;
         this.$emit("gettotalRespuestasSeleccionadas",this.respuesta_seleccionada);
      }
      this.$http.post('/api/respuestas', {
        'id': item.id,
        'correcta': item.correcta,
        'reactivo_id': this.idReactivo,
        'tiporeactivo_id': this.tiporeactivo
      })
          .then((response) => {
            this.getRespuestas();
            this.guardando = false;
            //this.overlay = false;
          })
          .catch(function (error) {
            //this.overlay = false;
          })

    },
    actualizarRespuesta(item) {
      this.guardando = true;
      this.$http.post('/api/respuestas', {
        'id': item.id,
        'respuesta': item.respuesta
      })
          .then((response) => {
            this.getRespuestas();
            this.guardando = false;
          })
          .catch(function (error) {
          })

    },

    consultarNivelEducativo() {
      let url = '';
      url = `/api/grados?idnivel=${this.idNivel.id}`
      this.$http.get(url)
          .then((response) => {
            this.grados = response.data;
            if (this.tipoaccion == 2) {
              this.idGrado = this.grados.find(item => item.id == this.grado_id)
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    consultarGrado() {
      //this.idGrado = id;
    },
    consultarArea() {
      let url = '';
      url = `/api/asignaturas?idArea=${this.idArea.id}`
      this.$http.get(url)
          .then((response) => {
            this.asignaturas = response.data;
            if (this.tipoaccion == 2) {
              this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    consultartiemporeactivos(id) {
      this.idtiemporeactivo = id;
    },
    consultarnivelreactivo(id) {
      this.idnivelreactivo = id;
    },
    consultartipovisibilidad(id) {
      this.idtipovisibilidad = id;
    },
    deleteRespuesta(item) {
      var refs = ''
      refs = confirm('¿Deseas eliminar la respuesta: ' + item.respuesta + '?')
      if (refs) {
        let url = '';
        url = `/api/respuestas/${item.id}`
        this.$http
            .delete(url)
            .then((response) => {
              this.getRespuestas()
            })
            .catch(function (error) {
              console.log(error)
            })
      } else {
        // cancel
      }
    },
    guardarReactivo() {
      let valocriterio = 0;
      let megas = 0;
      let actualizar_imagen = 0
      megas = ((this.obj_imagen_pregunta.sizeBytes) / 1024) / 1024

      if(this.permitir_justificante_val==false)
        this.permitir_justificante_val=0
      else
        this.permitir_justificante_val=1
      if (this.tiporeactivo != 3)
        valocriterio = this.idCriterio.id
      else
        valocriterio = 2;
      if (this.idReactivo == '') {
        if (this.idNivel == '') {
          Swal.fire(
              'Atención',
              'Debe seleccionar un Nivel Educativo.',
              'warning'
          )
          selectidnivel.focus();
        } else if (this.idGrado == '') {
          Swal.fire(
              'Atención',
              'Debe seleccionar un Grado.',
              'warning'
          )
          selectidGrado.focus();
        } else if (this.idArea == '') {
          Swal.fire(
              'Atención',
              'Debe seleccionar un Área de Conocimiento.',
              'warning'
          )
          selectidArea.focus();
        } else if (this.idAsignatura == '') {
          Swal.fire(
              'Atención',
              'Debe seleccionar una Asignatura.',
              'warning'
          )
          selectidAsignatura.focus();
        } else if (megas > 3) {
          Swal.fire(
              'Atención',
              'El tamaño máximo del archivo no debe exceder los 3 megabytes.',
              'warning'
          )
          txtdescripcion.focus();
        } else if (this.descripcion == '') {
          Swal.fire(
              'Atención',
              'Debe ingresar la pregunta.',
              'warning'
          )
          txtdescripcion.focus();
        } else if (this.tiemporeactivo == '') {
          Swal.fire(
              'Atención',
              'Debe ingresar el tiempo aproximado en responder la pregunta.',
              'warning'
          )
          selecttiemporeactivo.focus();
        } else if (this.idnivelreactivo == '') {
          Swal.fire(
              'Atención',
              'Debe ingresar el nivel de la pregunta.',
              'warning'
          )
          selectnivelreactivo.focus();
        } else if (this.tipovisibilidad == '') {
          Swal.fire(
              'Atención',
              'Debe ingresar el tipo de visilbilidad de la pregunta.',
              'warning'
          )
          selecttipovisibilidad.focus();
        } else { //INSERT YA TODOS LOS CAMPOS ESTAN LLENOS

          let params = { //insert
            'escuela_id':             this.idEscuela,
            'nivel_id':               this.idNivel.id,
            'grado_id':               this.idGrado.id,
            'area_id':                this.idArea.id,
            'asignatura_id':          this.idAsignatura.id,
            'tiporeactivo_id':        this.tiporeactivo,
            'descripcion':            this.descripcion,
            'instrucciones':          this.instrucciones,
            'tiemporeactivo':         this.idtiemporeactivo.id,
            'nivelreactivo':          this.idnivelreactivo.id,
            'tipovisibilidad':        this.idtipovisibilidad.id,
            'criterio_id':            valocriterio,
            'obj_imagen_pregunta':    this.obj_imagen_pregunta,
            'permitir_justificante':  this.permitir_justificante_val,
            youtubeVideo: this.youtubeModel,
            enlace_externo_youtube: this.enlace_externo_youtube,
            agregar_otro_youtube: (this.agregar_otro_youtube)?1:0,

          };
          //console.log(params);
          //return false;
          this.$http.post('/api/reactivos', params)
              //this.$http.post('/api/reactivos/cloud', params)
              .then((response) => {
                this.totalRespuestasSeleccionadas = 0
                this.totalRespuestas = 0
                this.$emit("gettotalRespuestasSeleccionadas",this.totalRespuestasSeleccionadas);
                this.$emit("getTotalRespuestas",this.totalRespuestas);

                this.idReactivo = response.data.id;
                this.activobtn = 1;
                this.editedIndex = 1;
                this.oculto = false;
                this.guardado = 1;
                this.$emit("getrefrescar_tabla", 1);
                Swal.fire(
                    '¡Guardado!',
                    'La pregunta ha sido guardada exitosamente.',
                    'success'
                )
                if (this.tiporeactivo == 3) {
                  this.activobtn = '';
                  this.getRespuestas();
                  this.texto = 'Editar pregunta: Verdadero o Falso';
                }
                Swal.fire(
                    '¡Guardado!',
                    'La pregunta ha sido guardada exitosamente.',
                    'success'
                )
              })
              .catch(function (error) {
              })
        }
      } else { //update evaluando campos vacios
        let valocriterio2 = 0;
        if(this.permitir_justificante_val==false)
          this.permitir_justificante_val=0
        else
          this.permitir_justificante_val=1
        if (this.tipoaccion != 3)
          valocriterio2 = this.idCriterio.id
        else
          valocriterio2 = 2;

        if(this.url_imagen_seleccionada!=this.url_imagen_seleccionada_copia){
          actualizar_imagen = 1
        }
        else{
          this.obj_imagen_pregunta = 0;
        }

        if(actualizar_imagen){
          megas = ((this.obj_imagen_pregunta.sizeBytes) / 1024) / 1024
        }

        if (this.idNivel == '') {
          Swal.fire(
              'Atención',
              'Debe seleccionar un Nivel Educativo.',
              'warning'
          )
          selectidnivel.focus();
        } else if (this.idGrado == '') {
          Swal.fire(
              'Atención',
              'Debe seleccionar un Grado.',
              'warning'
          )
          selectidGrado.focus();
        } else if (this.idArea == '') {
          Swal.fire(
              'Atención',
              'Debe seleccionar un Área de Conocimiento.',
              'warning'
          )
          selectidArea.focus();
        } else if (this.idAsignatura == '') {
          Swal.fire(
              'Atención',
              'Debe seleccionar una Asignatura.',
              'warning'
          )
          selectidAsignatura.focus();
        } else if (this.descripcion == '') {
          Swal.fire(
              'Atención',
              'Debe ingresar la pregunta.',
              'warning'
          )
          txtdescripcion.focus();
        }
        else if (megas > 3) {
          Swal.fire(
              'Atención',
              'El tamaño máximo del archivo no debe exceder los 3 megabytes.',
              'warning'
          )
          txtdescripcion.focus();
        }
        else if (this.tiemporeactivo == '') {
          Swal.fire(
              'Atención',
              'Debe ingresar el tiempo aproximado en responder la pregunta.',
              'warning'
          )
          selecttiemporeactivo.focus();
        } else if (this.idnivelreactivo == '') {
          Swal.fire(
              'Atención',
              'Debe ingresar el nivel de la pregunta.',
              'warning'
          )
          selectnivelreactivo.focus();
        } else if (this.tipovisibilidad == '') {
          Swal.fire(
              'Atención',
              'Debe ingresar el tipo de visilbilidad de la pregunta.',
              'warning'
          )
          selecttipovisibilidad.focus();
        } else {
          let params = {  //UPDATE REACTIVOS
            'idReactivo'            : this.idReactivo,
            'escuela_id'            : this.idEscuela,
            'nivel_id'              : this.idNivel.id,
            'grado_id'              : this.idGrado.id,
            'area_id'               : this.idArea.id,
            'asignatura_id'         : this.idAsignatura.id,
            'tiporeactivo_id'       : this.tiporeactivo,
            'descripcion'           : this.descripcion,
            'instrucciones'         : this.instrucciones,
            'tiemporeactivo'        : this.idtiemporeactivo.id,
            'nivelreactivo'         : this.idnivelreactivo.id,
            'tipovisibilidad'       : this.idtipovisibilidad.id,
            'criterio_id'           : valocriterio2,
            'obj_imagen_pregunta'   : this.obj_imagen_pregunta,
            'permitir_justificante' : this.permitir_justificante_val,
            youtubeVideo: this.youtubeModel,
            
            enlace_externo_youtube: this.enlace_externo_youtube,
            agregar_otro_youtube: (this.agregar_otro_youtube)?1:0,

          }
          //console.log(params);
          //return false;
          this.$http.post('/api/reactivos', params)
              .then((response) => {
                this.idReactivo = response.data.id;
                this.editedIndex = 1;
                this.guardado = 1;
                this.$emit("getrefrescar_tabla", 1);
                Swal.fire(
                    '¡Actualizado!',
                    'La pregunta ha sido actualizada exitosamente.',
                    'success'
                )
              })
              .catch(function (error) {
                console.log(error)
              })
        }
      }

    },
    guardarRespuesta() {
      let megas = 0;
      megas = ((this.obj_imagen_pregunta_respuesta.sizeBytes) / 1024) / 1024
      if (this.respuesta == '') {
        Swal.fire(
            'Atención',
            'Debe ingresar la descripción de la respuesta.',
            'warning'
        )
        respuesta.focus();
      }
      else if (megas > 3) {
        Swal.fire(
            'Atención',
            'El tamaño máximo del archivo no debe exceder los 3 megabytes.',
            'warning'
        )
        respuesta.focus();
      }
      else{
        //console.log("imagen respuesta ")
        //console.log(this.obj_imagen_pregunta_respuesta)
        this.guardando = true;
        this.$http.post('/api/respuestas', {
          'respuesta': this.respuesta,
          'reactivo_id': this.idReactivo,
          'imagen_respuesta': this.obj_imagen_pregunta_respuesta,
          'save': 1
        })
            .then((response) => {
              this.totalRespuestas++;
              this.$emit("getTotalRespuestas",this.totalRespuestas);
              this.respuesta = '';
              this.obj_imagen_pregunta_respuesta = [];
              this.imagen_seleccionada_respuesta = 0;
              this.url_imagen_seleccionada_respuesta = '';
              this.getRespuestas();
              this.guardando = false;
              setTimeout(() => {
                this.getRespuestas();
              }, 3000) //3 segundos de espera para volver a ejecutar el cargado de respuestas
            })
            .catch(function (error) {
            })
      }
    },
    getAreas() {
      let url = '';
      url = '/api/areas'
      this.idArea = '';
      this.$http.get(url)
          .then((response) => {
            this.areas = response.data;
            if (this.tipoaccion == 2) {
              this.idArea = this.areas.find(item => item.id == this.area_id)
              this.consultarArea(this.idArea)
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getCriterios() {
      let url = '';
      url = '/api/criterios'
      this.$http.get(url)
          .then((response) => {
            this.arraycriterios = response.data;
            if (this.tipoaccion == 2) {
              this.idCriterio = this.arraycriterios.find(item => item.id == this.criterio_id)
              if (this.tiporeactivo == 3) {
                this.activobtn = '';
              } else {
                this.activobtn = 1;
              }
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getRespuestas() {
      let url = '';
      this.totalRespuestas              = 0;
      this.totalRespuestasSeleccionadas = 0;
      url = `/api/respuestas?reactivo_id=${this.idReactivo}`
      this.$http.get(url)
          .then((response) => {
            this.banco = response.data;
            this.totalRespuestas = this.banco.length
            //console.log(this.banco)
            for (let item = 0; item < this.totalRespuestas; item++) {
              //console.log(this.banco[item])
              if(this.banco[item].correcta==1)
                this.totalRespuestasSeleccionadas++;
            }
            console.log("Respuestas seleccionadas" + this.totalRespuestasSeleccionadas)
            this.$emit("gettotalRespuestasSeleccionadas",this.totalRespuestasSeleccionadas);
            this.$emit("getTotalRespuestas",this.totalRespuestas);
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getNivelesEducativos() {
      let url = '';
      url = '/api/niveleseducativos'
      this.idNivel = '';
      this.$http.get(url)
          .then((response) => {
            this.niveles = response.data;
            if (this.tipoaccion == 2) {
              this.idNivel = this.niveles.find(item => item.id == this.nivel_id)
              this.consultarNivelEducativo()
              if (this.tiporeactivo == 3) {
                this.activobtn = '';
              } else {
                this.activobtn = 1;
              }
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    gettiemporeactivo() {
      let url = '';
      url = '/api/tiemporeactivo'
      this.$http.get(url)
          .then((response) => {
            this.arraytiemporeactivo = response.data;
            if (this.tipoaccion == 2) {
              //modo edicion de reactivo
              this.idtiemporeactivo = this.arraytiemporeactivo.find(item => item.id == this.tiemporeactivo)
              //this.idtiemporeactivo = this.idtiemporeactivo.id
              if (this.tiporeactivo == 3) {
                this.activobtn = '';
              } else {
                this.activobtn = 1;
              }
            }
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    getnivelreactivo() {
      let url = '';
      url = '/api/nivelreactivo'
      this.$http.get(url)
          .then((response) => {
            this.arraynivelreactivo = response.data;
            if (this.tipoaccion == 2) {
              this.idnivelreactivo = this.arraynivelreactivo.find(item => item.id == this.nivelreactivo)
              if (this.tiporeactivo == 3) {
                this.activobtn = '';
              } else {
                this.activobtn = 1;
              }
            } else
              this.idnivelreactivo = '';
          })
          .catch(function (error) {
            console.log(error)
          })
    },
    gettipovisibilidad() {
      let url = '';
      url = '/api/tipovisibilidad'
      this.$http.get(url)
          .then((response) => {
            this.arraytipovisibilidad = response.data;
            //console.log(this.arraytipovisibilidad)
            if (this.tipoaccion == 2) {
              this.idtipovisibilidad = this.arraytipovisibilidad.find(item => item.id == this.tipovisibilidad)
              if (this.tiporeactivo == 3) {
                this.activobtn = '';
              } else {
                this.activobtn = 1;
              }
            }
          })
          /*                .then(()=>{
                              this.getAreas()
                          })*/
          .catch(function (error) {
            console.log(error)
          })
    },
    close() {
        this.dialog = false
    },
    getSearch(callback = null) {
      this.$http.get("/api/eventos-stream/search-youtube")
          .then(res => {
            this.count = res.data.count
            this.entries = res.data.entries
            if (callback) {
              callback()
            }
          })
          .finally(() => (this.isLoading = false))
    },
    getStatus(statusVideo) {
      return this.listStatus[statusVideo]
    },
/*    guardarPermitirJusticante(item){
      console.log("permitir justificante:")
      //console.log(item)
      if(item==true)
        console.log("verdadero")
      else
        console.log("falso")

    }*/
  },
  watch: {
    search (val) {
      // Items have already been loaded
      if (this.items.length > 0) return
      // Items have already been requested
      if (this.isLoading) return
      this.isLoading = true
      this.getSearch()
    },
    idarchivo: function (val) {
      this.imagen_seleccionada = 1;
      this.url_imagen_seleccionada = "https://drive.google.com/uc?id=" + val.id
      this.obj_imagen_pregunta = val

    },
    idarchivo_respuesta: function (respuesta) {
      this.imagen_seleccionada_respuesta = 1;
      this.url_imagen_seleccionada_respuesta = "https://drive.google.com/uc?id=" + respuesta.id
      this.obj_imagen_pregunta_respuesta = respuesta
    }
  },
  mounted() {
      if ( this.enlace_externo_youtube_edit ) {
        this.agregar_otro_youtube = true
        this.enlace_externo_youtube = "https://www.youtube.com/watch?v=" + this.enlace_externo_youtube_edit
      }
      if (this.show_youtube_info == 1) {
        this.$http.get("/api/eventos-stream/get-video-youtube/" + this.youtube_video_id).then(res => {
          this.getSearch(() => {
            try {
              this.youtubeModel = res.data.entries[0]
            } catch (e) {
            }
          })
        }).catch(err => {
        })
        //console.log(this.youtube_video_id)
      }
      //cargar catalogos
      this.getNivelesEducativos()
      this.getCriterios()
      this.getAreas()
      this.gettiemporeactivo()
      this.getnivelreactivo()
      this.gettipovisibilidad()
      if (this.tipoaccion == 1) {
        if (this.tiporeactivo == 1)
          this.texto = 'Nueva pregunta: opción múltiple, única respuesta';
        else if (this.tiporeactivo == 2)
          this.texto = 'Nueva pregunta: opción múltiple, varias respuestas';
        else if (this.tiporeactivo == 3) {
          this.texto = 'Nueva pregunta: Verdadero o Falso';
          this.desactivarcriterio = true;
        }
        this.idEscuela = 1; //this.escuela_id;
        this.textobtn = 'GUARDAR PREGUNTA';
      }
      else if (this.tipoaccion == 2) {
        if(this.urlimagen_pregunta!=null){
          if (this.urlimagen_pregunta.length>0){
          this.imagen_seleccionada = 1
          this.url_imagen_seleccionada        = this.urlimagen_pregunta
          this.url_imagen_seleccionada_copia  = this.urlimagen_pregunta
          }
        }
        if(this.permitir_justificante==0 || this.permitir_justificante==null)
          this.permitir_justificante_val = 0;
        else
          this.permitir_justificante_val = 1;

        //Asignación de valores iniciales para edición
        this.textobtn = 'ACTUALIZAR PREGUNTA';
        this.tipoaccion = 2;
        if (this.tiporeactivo == 1)
          this.texto = 'Editar pregunta: opción múltiple, única respuesta';
        else if (this.tiporeactivo == 2)
          this.texto = 'Editar pregunta: opción múltiple, varias respuestas';
        else if (this.tiporeactivo == 3) {
          this.texto = 'Editar pregunta: Verdadero o Falso';
          this.desactivarcriterio = true;
        }
        this.idReactivo = this.id;
        this.idEscuela = this.escuela_id_prop;
        this.descripcion = this.descripcion_prop;
        this.instrucciones = this.instrucciones_prop;
        this.getRespuestas()
      }
    }

}
</script>
<style scoped>

</style>

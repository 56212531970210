<template>
  <v-container>
    <div>
      <h1>Gestión de usuarios</h1>

      <v-divider class="mt-5 mb-5"></v-divider>

      <!--AGREGAR USUARIO-->
<!--      <v-dialog
          v-model="dialogUsuario"
          persistent
          max-width="900px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="mr-5"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Agregar usuario
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Agregar usuario</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col lg="4" md="12" sm="12">
                  <div align="center">
                    <v-img
                        width="200"
                        height="200"
                        lazy-src="https://i.imgur.com/TQR1sff.png"
                        src="https://i.imgur.com/RHDXuAA.jpg"
                    ></v-img>
                    <v-file-input
                        @change="getImage()"
                        v-model="usuario.foto_perfil"
                        label="Seleccionar una imagen para cambiarla"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col lg="8" md="12" sm="12">
                  <v-text-field
                      v-model="usuario.nombre_usuario"
                      label="Nombre del usuario"
                  ></v-text-field>
                  <v-text-field
                      v-model="usuario.correo_usuario"
                      label="Correo del usuario"
                  ></v-text-field>
                  <v-select
                      :items="roles_disponibles"
                      label="Seleccionar un rol"
                      item-text="nombre_rol"
                      item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogUsuario = false"
            >
              Cerrar
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="guardarUsuario"
            >
              Guardar cambios
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>-->

      <!--IMPORTAR USUARIOS-->
      <v-dialog

          v-model="dialogImportar"
          persistent
          max-width="900px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="getSheets"
              class="mr-5 mb-5"
              color="blue"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Importar usuarios
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Importación de usuarios</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field v-model="nuevo_sheet.detalles_importacion" label="Crear nuevo documento"></v-text-field>
              <v-select
              v-model="nuevo_sheet.escuela_id"
              :items="escuelas"
              item-text="nombre"
              item-value="id"
              label="Seleccionar escuela para importar"></v-select>

              <v-select :items="grupos_aprende" label="Elegir grupo de Nivelando" item-value="id" item-text="nombre_grupo" v-model="nuevo_sheet.grupo_aprende_id"></v-select>

              <v-btn @click="createSheet" v-if="habilitar_boton" class="primary">Crear documento</v-btn>
              <v-divider class="mt-5 mb-5"></v-divider>

              <v-data-table
              :headers="headersImportar"
              :items="desertsImportar"
              :loading="loadingImportar"
              class="elevation-1">
                <template v-slot:item.escuela_importacion="{item}">
                  {{ item.nombre_escuela }}
                </template>
                <template v-slot:item.ruta_archivo="{item}">
                  <a :href="item.ruta_archivo" target="_blank">
                    <v-btn class="primary  white--text">Abrir</v-btn>
                  </a>
                </template>
                <template v-slot:item.importar_documento="{item}">
                  <v-btn @click="importarSheet(item)" class="green white--text">Importar</v-btn>
                </template>
                <template v-slot:item.eliminar_documento="{item}">
                  <v-btn @click="deleteSheet(item)" class="red  white--text">Eliminar</v-btn>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogImportar = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--EXPORTAR USUARIOS-->
<!--      <v-dialog
          v-model="dialogExportar"
          persistent
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="green"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Exportar usuarios
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              ok
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogExportar = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogExportar = false"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>-->

      <v-data-table
       class="elevation-1"
      :loading="loading"
      :headers="headersUsuarios"
      :items="dessertsUsuarios"
      >
        <template v-slot:item.editar="{item}">
          <v-btn
              class="mx-2"
              fab
              dark
              small
              color="blue"
          >
            <v-icon  dark>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.eliminar="{item}">
          <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
          >
            <v-icon dark>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
export default {
  data:() => ({
    grupos_aprende : [],
    nuevo_sheet : {
      detalles_importacion : '',
      escuela_id : 0,
      grupo_aprende_id : 0
    },
    habilitar_boton : true,
    detalles_importacion : '',
    desertsImportar : [],
    loadingImportar : true,
    usuario : {
      foto_perfil : '',
      nombre_usuario : '',
      correo_usuario : '',
      rol_usuario : 0,
    },
    roles_disponibles : [],
    dialogUsuario : false,
    dialogImportar : false,
    dialogExportar : false,
    loading : true,
    escuelas : [],
    headersUsuarios : [
      {text : 'Nombre', sortable :false, value : 'name'},
      {text : 'Correo', sortable :false, value : 'email'},
      {text : 'Fecha alta', sortable :false, value : 'created_at'},
      {text : 'Detalles', sortable :false, value : 'editar'},
      {text : 'Eliminar', sortable :false, value : 'eliminar'}
    ],
    headersImportar : [
      {text : 'Nombre documento', sortable: false, value: 'detalles_importacion'},
      {text : 'Escuela', sortable: false, value: 'escuela_importacion'},
      {text : 'Estatus importación', sortable: false, value: 'string_estatus_importacion'},
      {text : 'Documento', sortable : false, value : 'ruta_archivo'},
      {text : 'Importar', sortable : false, value : 'importar_documento'},
      {text : 'Eliminar', sortable : false, value : 'eliminar_documento'}
    ],
    dessertsUsuarios : []
  }),
  mounted() {
    this.getUsuarios();
    this.getRoles();
    this.getGruposAprende();
  },
  methods : {
    getGruposAprende() {
      this.$http.get("/api/aprende/gruposaprende")
      .then(response => {
        this.grupos_aprende = response.data;
      })
      .catch(error => {

      })
    },
    importarSheet(item) {
      let index = this.desertsImportar.indexOf(item);
      Swal.fire({
        title: '¿Está seguro que desea importar este archivo?',
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingImportar = true;
          this.$http.post('/api/users/import-sheet', {
            documento_id : item.id,
            escuela_id : item.escuela_id,
            sheet_id : item.sheet_id,
          })
          .then(response => {
            this.desertsImportar[index].string_estatus_importacion = 'Importado';
          })
          .catch(error => {

          })
          this.loadingImportar = false;
          Swal.fire('Importado correctamente!', '', 'success')
        } else if (result.isDenied) {
          //Swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    getEscuelas() {
      let url = '/api/escuelas/getEscuelas'
      this.$http.get(url)
      .then((response) => {
        this.escuelas = response.data;
      })
      .catch(function (error){
        console.log(error)
      })
    },
    deleteSheet(item) {
      let index = this.desertsImportar.indexOf(item);
      Swal.fire({
        title: '¿Está seguro que desea eliminar este registro?',
        //showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$http.post('/api/users/delete-sheet/' + item.id, {
            _method : 'DELETE'
          })
          .then(response => {
            this.desertsImportar.splice(index, 1);
          })
          .catch(error => {

          })
          Swal.fire('Eliminado correctamente!', '', 'success')
        } else if (result.isDenied) {
          //Swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    getSheets() {
      this.getEscuelas();
      this.loadingImportar = true;
      this.$http.get('/api/users/get-sheets-importacion')
      .then(response => {
        this.desertsImportar = response.data;

      })
      .catch(error => {

      })
      this.loadingImportar = false;
    },
    createSheet() {
      this.loadingImportar = true;
      this.habilitar_boton = false;
      this.$http.post('/api/users/create-sheet-importacion', {
        detalles_importacion : this.nuevo_sheet.detalles_importacion,
        escuela_id : this.nuevo_sheet.escuela_id,
        grupo_aprende_id : this.nuevo_sheet.grupo_aprende_id
      })
      .then(response => {
        this.desertsImportar.push(response.data);
        this.loadingImportar = false;
        this.habilitar_boton = true;
        this.nuevo_sheet.detalles_importacion = '';
      })
      .catch(error => {

      })

    },
    guardarUsuario() {
      this.dialogUsuario = false
      this.$http.post("/api/users")
      .then(response => {

      })
      .catch(error => {

      })
    },
    getRoles() {
      this.$http.get('/api/roles')
      .then(response => {
        this.roles_disponibles = response.data;
      })
      .catch(error => {

      })
    },
    getImage() {
      console.log(this.usuario.foto_perfil)
    },
    getUsuarios() {
      this.loading = true;
      this.$http.get('/api/users')
      .then(response => {
        this.dessertsUsuarios = response.data;
        this.loading = false;
      })
      .catch(error => {

      })
    }
  }
}
</script>
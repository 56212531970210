<template>
<v-container>
    <template>
        <v-row justify="center">
            <v-dialog v-if="dialogObjetivo" :value="true" @input="dialogObjetivo = false" persistent max-width="790">
                <v-card elevation="2" class="mx-auto">
                    <v-card-title>
                        <v-icon large class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon><h3>Objetivo</h3>
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-row >
                                <v-col cols="12" sm="12">
                                    <v-text-field
                                        label="Nombre del objetivo"
                                        v-model="nombre_objetivo"
                                        v-bind:value="nombre_objetivo"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        label="Código"
                                        v-model="clave_interna"
                                        :readonly="modolectura"
                                        v-bind:value="clave_interna"
                                        outlined
                                    ></v-text-field>
                                    <v-textarea
                                        label="Descripción:"
                                        v-model="descripcion"
                                        v-bind:value="descripcion"
                                        :rows="3"
                                        outlined
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-btn @click="guardarObjetivo" small>
                                    {{txtboton}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closeImportar()"
                        >
                            Cerrar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </template>
    <v-card elevation="2" class="mx-auto">
        <v-card-title>
                    <v-icon large class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon><h3>Objetivos1</h3>
               <v-spacer></v-spacer>

        </v-card-title>
      <v-card-text align="center">
        <v-btn
            small
            color="green"
            @click="showObjetivo()"
            title="Crear Objetivo"
        >
          <v-icon color="white" >mdi-checkbox-marked-circle-outline</v-icon>
        </v-btn>
      </v-card-text>
        <v-card elevation="2">
          <v-card-title><v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" ></v-text-field></v-card-title>
            <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>


                <v-data-table :headers="headers" :items="objetivos" :search="search" class="elevation-1">
                    <template v-slot:item.detalles="{ item }">
                        <div class="text-center">
                            <v-btn
                                fab elevation="2"
                                color="green"
                                small
                                x-small
                                @click="editObjetivo(item)"
                                title="Editar Objetivo"
                            >
                            <v-icon color="white"  >mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                    </template>
                  <template v-slot:item.eliminar="{ item }">
                    <div class="text-center">
                      <v-btn
                          fab elevation="2"
                          color="red"
                          small
                          x-small
                          title="Eliminar Objetivo"
                      >
                        <v-icon @click="deleteObjetivo(item)" title="Eliminar Objetivo" color="white">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </template>
                    <template v-slot:item.indicadores="{ item }">
                        <div class="text-center">
                            <v-btn
                                fab elevation="2"
                                color="blue"
                                small
                                x-small
                                title="Indicadores"
                                @click="showIndicadores(item)"
                            >
                                <v-icon color="white">mdi-share-variant</v-icon>
                            </v-btn>
                        </div>
                    </template>

                  <template v-slot:item.actividades="{item}">
                    <v-btn @click="objetivoActividad(item)" class="primary white--text" fab small>
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                  </template>

                    <template v-slot:item.materiales="{ item }">
                        <div class="text-center">
                            <v-btn
                                fab elevation="2"
                                color="purple"
                                small
                                x-small
                                title="Materiales"
                                @click="showMateriales(item)"
                            >
                                <v-icon color="white">mdi-more</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
        </v-card>
    </v-card>


  <v-dialog
      v-model="dialogActividad"
      persistent
      max-width="900"
  >
    <v-card>
      <v-card-title class="headline">
        {{ current_nombre_objetivo }}
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="actividad_objetivo.nombre_actividad_objetivo" label="Ingresa el nombre de la actividad"></v-text-field>
        <v-btn class="primary" v-if="!boton_editar_actividad" @click="guardarActividadObjetivo">Guardar</v-btn>
        <v-btn class="primary mr-5" v-if="boton_editar_actividad" @click="guardarActualizarActividad">Actualizar cambios</v-btn>
        <v-btn class="danger" v-if="boton_editar_actividad" @click="cancelarEdicionActividad">Cancelar</v-btn>

        <v-dialog
            v-model="dialogItemsActividad"
            persistent
            max-width="900"
        >
          <v-card>
            <v-card-title class="headline">
              Agregando items a la actividad
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="item_actividad.nombre_item" label="Ingresa el nombre del item"></v-text-field>
              <v-btn class="primary" v-if="!boton_editar_item" @click="guardarItemActividad">Guardar</v-btn>
              <v-btn class="primary mr-5" v-if="boton_editar_item" @click="guardarActualizarItem">Actualizar cambios</v-btn>
              <v-btn class="danger" v-if="boton_editar_item" @click="cancelarEdicionItem">Cancelar</v-btn>
              <v-data-table :loading="loadingItems" :items="dessertsItems" :headers="headersItems">
                <template v-slot:item.editar_item="{item}">
                  <v-btn @click="editarItemActividad(item)" class="blue white--text" small>Editar</v-btn>
                </template>
                <template v-slot:item.eliminar_item="{item}">
                  <v-btn @click="eliminarItemActividad(item)" class="red white--text" small>Eliminar</v-btn>
                </template>
              </v-data-table>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="dialogItemsActividad = false"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-divider class="mt-5"></v-divider>
        <v-data-table :loading="loading" :headers="headersActividades" :items="dessertsActividades" class="elevation-1">
          <template v-slot:item.editar_actividad="{item}">
            <v-btn @click="editarActividad(item)" small>Editar actividad</v-btn>
          </template>
          <template v-slot:item.items_actividad="{item}">
            <v-btn @click="agregarItemsActividad(item)" class="" small>Administrar items</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="dialogActividad = false"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>
<script>
  export default {
    data:() => ({
      boton_editar_item : false,
      loadingItems : false,
      item_actividad : {
        current_actividad : [],
        current_item_actividad : [],
        nombre_item : '',
        index_actual : -1
      },
      dessertsItems : [],
      dialogItemsActividad : false,
      loading : true,
      boton_editar_actividad : false,
      actividad_objetivo : {
        nombre_actividad_objetivo : '',
        actividad_objetivo_id : 0,
        index_actividad : 0,
      },
      current_nombre_objetivo : '',
        current_objetivo : null,
        dialogActividad : false,
        search:'',
        nombre:'',
        idTipoReactivo:'',
        escuela_id:1,
        refrescar_tabla:0,
        abreviatura:'',
        tiporeactivo:[],
        banco:[],
        item:[],
        objetivos:[],
        respuestasTotal:0,
        pagination: {},
        headers: [
          {text:  '#',                value: 'id',              align: 'start',sortable: true},
          { text: 'Nombre',           value: 'nombre_objetivo'                },
          { text: 'Clave',            value: 'clave_interna'                  },
          { text: 'Detalles',         value: 'detalles',        align:'center'},
          { text: 'Eliminar',         value: 'eliminar',        align:'center'},
          { text: 'Indicadores',      value: 'indicadores',     align:'center'},
          { text: 'Materiales',       value: 'materiales',      align:'center'},
        ],
        headersActividades : [
          {text : 'Nombre actividad', value : 'detalles_actividad',   sortable : false},
          {text : 'Número de items',  value : 'numero_items',         sortable : false},
          {text : 'Editar',           value : 'editar_actividad',     sortable : false},
          {text : 'Agregar items',    value : 'items_actividad',      sortable : false},
        ],
        headersItems : [
          {text : 'Nombre item',      value : 'descripcion_actividad',  sortable : false},
          {text : 'Editar item',      value : 'editar_item',            sortable : false},
          {text : 'Eliminar item',    value : 'eliminar_item',          sortable : false},
        ],
        dessertsActividades : [],
        overlay: false,
        absolute:true,
        isModalVisible: false,
        dialog: false,
        dialogObjetivo: false,
        dialogAyuda:false,
        tipoaccion:'',
        buscar_contador:0,
        checkstatus : null,
        nombre_objetivo:'',
        clave_interna:'',
        descripcion:'',
        txtboton:'',
        estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
        idObjetivo:0,
        modolectura:false,

    }),
    created () {
      this.init();
    },    
    methods: {
    init () {
      this.getObjetivos()       //obtiene los reactivos filtrados por el usuario actual.
    },
      getItemsActividad(item) {

      },
      cancelarEdicionActividad() {
        this.boton_editar_actividad = false;
        this.actividad_objetivo.nombre_actividad_objetivo = '';
      },
      cancelarEdicionItem() {
        this.boton_editar_item = false;
        this.item_actividad.nombre_item = '';
      },
      guardarActualizarItem() {
        this.loadingItems = true;
        this.$http.post('/api/aprende/actividad-items-objetivo/' + this.item_actividad.current_item_actividad.id, {
          _method : "PUT",
          descripcion_actividad : this.item_actividad.nombre_item
        })
        .then(response => {
          this.dessertsItems.splice(this.item_actividad.index_actual, 1, response.data)
          this.loadingItems = false;
          this.cancelarEdicionItem();
        })
        .catch(error => {

        })
      },
      editarItemActividad(item) {
        this.boton_editar_item = true;
        this.item_actividad.current_item_actividad = item;
        this.item_actividad.nombre_item = item.descripcion_actividad;
        this.item_actividad.index_actual = this.dessertsItems.indexOf(item);
      },
      eliminarItemActividad(item) {
        let index = this.dessertsItems.indexOf(item);
        Swal.fire({
          title: '¿Está seguro que desea eliminar este registro?',
          //showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$http.post('/api/aprende/actividad-items-objetivo/' + item.id, {
              _method : 'DELETE'
            })
            .then(response => {
              this.dessertsItems.splice(index, 1);
              this.objetivoActividad(this.current_objetivo);
            })
            .catch(error => {

            })
            Swal.fire('ELiminado correctamente!', '', 'success')
          } else if (result.isDenied) {
            //Swal.fire('Changes are not saved', '', 'info')
          }
        })
      },
      guardarItemActividad() {
        this.$http.post("/api/aprende/actividad-items-objetivo", {
          activdad_objetivo_id : this.item_actividad.current_actividad.id,
          descripcion_actividad : this.item_actividad.nombre_item
        })
        .then(response => {
          this.dessertsItems.unshift(response.data);
          this.item_actividad.nombre_item = '';
          this.objetivoActividad(this.current_objetivo);
        })
      },
      agregarItemsActividad(item) {
        this.item_actividad.current_actividad = item;
        this.dialogItemsActividad = true;
        this.$http.get("/api/aprende/actividad-items-objetivo/get-all-items/" + this.item_actividad.current_actividad.id)
        .then(response => {
          this.dessertsItems = response.data;
        })
        .catch(error => {

        })
      },
      guardarActualizarActividad() {
        this.loading = true;
        this.$http.post("/api/aprende/actividad-objetivo/" + this.actividad_objetivo.actividad_objetivo_id, {
          _method : "PUT",
          detalles_actividad : this.actividad_objetivo.nombre_actividad_objetivo
        })
        .then(response => {
          this.dessertsActividades.splice(this.actividad_objetivo.index_actividad, 1, response.data[0])
          this.cancelarEdicionActividad();
          this.loading = false;
        })
        .catch(error => {

        })
      },
      miprueba() {
        alert("prueba")
      },
      editarActividad(item) {
        console.log(item)
        this.boton_editar_actividad = true;
        this.actividad_objetivo.nombre_actividad_objetivo = item.detalles_actividad;
        this.actividad_objetivo.actividad_objetivo_id = item.id;
        this.actividad_objetivo.index_actividad = this.dessertsActividades.indexOf(item);
      },
      guardarActividadObjetivo() {
        this.$http.post('/api/aprende/actividad-objetivo', {
          //actividad_objetivo_id : this.actividad_objetivo.actividad_objetivo_id,
          objetivo_id : this.current_objetivo.id,
          nombre_actividad_objetivo : this.actividad_objetivo.nombre_actividad_objetivo
        })
        .then(response => {
          this.objetivoActividad(this.current_objetivo);
        })
        .catch(error => {

        })
      },
    objetivoActividad(item) {
      this.actividad_objetivo.nombre_actividad_objetivo = '';
      this.boton_editar_actividad = false;
      this.current_objetivo = item;
      this.current_nombre_objetivo = this.current_objetivo.nombre_objetivo;
      this.dialogActividad = true;
      this.$http.get("/api/aprende/actividad-unidad/all-actividades/" + this.current_objetivo.id)
      .then(response => {
        this.dessertsActividades = response.data;
        this.loading = false;
      })
      .catch(error => {
      })
    },
    actualizarActivo(item){
            if(item.alumnocontactado==null){
                item.activo = 0;
            }
            else
                item.activo = 1;
            //console.log('alumnoContactado actualizado: ' + alumnocontactado + 'id:' + id)
            this.overlay = true;
            this.$http.post('/api/sistemaseducativos', {

                'id':               item.id,
                'atributo':         2,
                'alumnocontactado':  item.alumnocontactado,

            })
                .then((response) => {
                    // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                    // console.log(error)
                    this.overlay = false;
                })
    },
    closeModal() {
        this.dialog = false
    },
    closeAyuda(){
            this.dialogAyuda =  false
    },
    closeImportar(){
        this.nombre_objetivo    =  '';
        this.clave_interna      =  '';
        this.descripcion        =  '';
        this.txtboton           =  '';
        this.dialogObjetivo     =  false
        this.modolectura        =  false;
    },
    consultar(id){
      this.idTipoReactivo = id;
      if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
          this.showModal();
      }
      else if (this.idTipoReactivo==6)
          this.showModal();
    },
    consultarNivelEducativo(){
            let url = '';
            url = `/api/grados?idnivel=${this.idNivel.id}`
            this.$http.get(url)
                .then((response) => {
                    this.grados = response.data;
                    if(this.tipoaccion==2 ) {
                        this.idGrado = this.grados.find(item => item.id == this.grado_id)
                    }
                })
                .catch(function (error){
                    console.log(error)
                })
   },
   consultarArea(){
            let url = '';
            url = `/api/asignaturas?idArea=${this.idArea.id}`
            this.$http.get(url)
                .then((response) => {
                    this.asignaturas = response.data;
                    if(this.tipoaccion==2 ){
                        this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
                    }
                })
                .catch(function (error){
                    console.log(error)
                })
   },

        deleteObjetivo (item){
            Swal.fire({
                title: '¿Desea eliminar el Objetivo: '+ item.nombre_objetivo + '?',
                showDenyButton: true,
                confirmButtonText: `Eliminar`,
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = `/api/aprende/objetivos/${item.id}`
                    this.$http
                        .delete(url)
                        .then((response) => {
                            this.getObjetivos()
                            Swal.fire('Objetivo eliminado satisfactoriamente.', '', 'success')
                        })
                        .catch(function (error){
                            console.log(error)
                        })

                } else if (result.isDenied) {
                    //Swal.fire('Changes are not saved', '', 'info')
                }
            })
            //this.overlay = true;



        },
    editReactivo(item){
            this.item                 =   item;
            this.dialogObjetivo       =   true;
            this.idTipoReactivo       =   item.tiporeactivo.tiporeactivo_concentrado.id;
            this.tipoaccion           =   2;
            this.estatusAccionTabla   =   2;
    },
    editObjetivo(item) {
            this.idObjetivo         =   item.id;
            this.nombre_objetivo    =   item.nombre_objetivo;
            this.clave_interna      =   item.clave_interna;
            this.descripcion        =   item.descripcion;
            this.dialogObjetivo     =   true;
            this.txtboton           =   "Actualizar"
            this.modolectura        =   true;
            this.estatusAccionTabla     =   2;
    },
    getObjetivos(){
            let url = `/api/aprende/objetivos`
            this.$http.get(url)
            .then((response) => {
               this.objetivos = response.data;
            })
            .catch(function (error){
                    console.log(error)
            })
    },
    getCatalogos(){
            let url = `/api/niveleseducativos/get-NivelEducativoAreaConocimiento`
            this.$http.get(url)
                .then((response) => {
                    //console.log(response)
                    this.niveles = response.data.nivel_educativo;
                    this.areas   = response.data.area_conocimiento;
                })
                .catch(function (error){
                    console.log(error)
                })
    },
    getTipoReactivo () {
            let url = '/api/tiporeactivo'
            this.$http.get(url)
                .then((response) => {
                    this.tiporeactivo = response.data;
                    //console.log(this.periodos)
                })
                .catch(function (error){
                        console.log(error)
                    }
                )
    },
    guardarObjetivo(){
            if(this.nombre_objetivo=='')
                confirm('Debe capturar el nombre del objetivo')
            else if (this.clave_interna==''){
                confirm('Debe capturar una Clave interna')
            }
            else{
                    if(this.estatusAccionTabla==1){
                        this.$http.post('/api/aprende/objetivos', {
                            'nombre_objetivo': this.nombre_objetivo,
                            'clave_interna': this.clave_interna,
                            'descripcion': this.descripcion
                        })
                        .then((response) => {
                            if(response.data){
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Objetivo guardado exitosamente.',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                                //this.getObjetivos()  llenar el arreglo
                                this.objetivos.unshift(response.data) //inserta el elemento al principio del Arreglo
                                this.nombre_objetivo = '';
                                this.clave_interna = '';
                                this.descripcion = '';
                            }
                            else{
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: 'Objetivo existente, verificar Clave interna.',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                            }
                            })
                        .catch(function (error) {
                                console.log(error)
                        })
                    }
                    else if(this.estatusAccionTabla==2){
                        this.$http.post('/api/aprende/objetivos/'+ this.idObjetivo, {
                            _method:    'PUT',
                            'nombre_objetivo'       : this.nombre_objetivo,
                            'descripcion'           : this.descripcion
                        })
                        .then((response) => {
                            if(response.data){
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Objetivo actualizado exitosamente.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.getObjetivos()
                                //this.nombre_objetivo = '';
                                //this.clave_interna = '';
                                //this.descripcion = '';
                            }
                            else{
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Objetivo no actualizado. Verificar.',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            }
        },

    showImportar(){
        this.dialogObjetivo = true;
    },
    showIndicadores(item){
        this.$router.push({ path: '/dashboardindicadores', query: { idObjetivo: btoa(item.id),nombre_objetivo:btoa(item.nombre_objetivo),descripcion:btoa(item.descripcion)} })
    },
    showMateriales(item){
        this.$router.push({ path: '/dashboardMateriales', query: { idObjetivo: btoa(item.id),nombre_objetivo:btoa(item.nombre_objetivo),descripcion:btoa(item.descripcion)} })
    },
    showModal() {
        this.dialog = true;
    },
    showObjetivo() {
        this.dialogObjetivo     = true;
        this.txtboton           = "Guardar";
        this.estatusAccionTabla =   1;
    },
        
    totalrespuestas(total){
      this.respuestasTotal =  total;
    },
    },

    mounted(){

    } 
  }
</script>
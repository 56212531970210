<template>
  <v-container>
    <div>
      <v-alert
          text
          dismissible
          color="info"
      >
        <div>Por favor ingrese los siguientes datos para crear una nueva currícula</div>
      </v-alert>
      <v-card class="pa-5">
        <h1>Crear contenido</h1>
        <v-select
            v-model="nuevo_contenido.curso_id"
            item-text="nombre_curso"
            item-value="id"
            :items="itemsCursos"
            label="Seleccionar un curso"
        ></v-select>
        <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="guardarContenido()"
        >Guardar contenido</v-btn>
      </v-card>

      <v-card class="pa-5 mt-5">
        <div class="text-h5" v-for="curso_curricula in cursos_curriculas">
          <strong>{{ curso_curricula.curso_curricula.nombre_curso }}</strong> <v-btn @click="deleteCurriculaCurso(curso_curricula)" class="red white--text" small fab><v-icon>mdi-delete</v-icon></v-btn>
          <div class="text-h5 pl-5" v-for="unidad in curso_curricula.curso_curricula.curso_unidad">
            <v-icon>mdi mdi-chevron-right</v-icon>{{ unidad.unidad.nombre_unidad }}
          </div>
        </div>
        <p v-if="cursos_curriculas.length == 0" class="pa-0 ma-0">No se encontraron cursos enlazados a esta currícula</p>
<!--        <v-text><div v-html="drawList"></div></v-text>-->
      </v-card>
    </div>
  </v-container>
</template>



<script>
export default {
  data:() => ({
    curriculaId : 0,
    dessertsCurriculas : [],
    innerLoading : false,
    itemsCursos : [],
    itemsUnidades : [],
    cursos_curriculas : [],
    nuevo_contenido : {
      curso_id : 0,
      unidades : [],
    }
  }),
  mounted() {
    this.curriculaId = this.$route.query.curriculaId;
    this.showCursos();
    this.getUnidades();
    this.getCurriculaCurso();
  },

  methods : {

    showCursos() {
      this.$http.get('/api/aprende/cursos')
          .then(response => {
            this.itemsCursos = response.data;
          });
    },
    getUnidades() {
      this.$http.get('/api/aprende/unidades')
      .then(response => {
        this.itemsUnidades = response.data;
      })
      .catch(error => {

      })
    },
    getCurriculaCurso() {
      this.$http.get('/api/aprende/contenido-curricula/' + this.curriculaId)
          .then(response => {
            this.cursos_curriculas = response.data.contenido;
          }).catch(error => {

          })
    },
    deleteCurriculaCurso(curricula_curso) {
      let r = confirm("Confirma eliminar este registro ahora?");
      if ( r == true ) {
        this.$http.post("/api/aprende/curricula-curso/" + curricula_curso.id, {
          _method : "DELETE"
        })
        .then(response => {
          this.getCurriculaCurso()
        })
        .catch(error => {

        })
      }
    },
    guardarContenido() {
      this.$http.post('/api/aprende/curricula-curso', {
        curso_id : this.nuevo_contenido.curso_id,
        unidades : this.nuevo_contenido.unidades,
        curricula_id : this.curriculaId
      }).then(response => {
        this.getCurriculaCurso()
      }).catch(error => {
      })
    },
  },
  computed : {
    headersCurriculas() {
      return [
        { text: '#', sortable: false, value: 'id', },
        { text: 'Nombre', sortable: false, value: 'nombre_curricula', },
        { text: 'Fecha', sortable: false, value: 'created_at', },
        { text: 'Editar', sortable: false, value: 'editar', },
        { text: 'Eliminar', sortable: false, value: 'eliminar', },
        { text: 'Administrar contenidos', sortable: false, value: 'cursos', },
        /*{ text: 'Contenidos', sortable: false, value: 'curriculas', },*/
      ]
    },
  }
}
</script>
<style>
  @media only screen and (max-width: 768px) {
    .responsive-iframe {
      min-height: 250px;
    }
  }
  .responsive-iframe {

  }
</style>
<template>
  <div>
    <v-alert
        class="ma-0"
        color="blue-grey"
        dark
        dense
        icon="mdi-school"
        prominent
    >
      Usted podrá visualizar el contenido del streaming en vivo e interactuar en tiempo real con los participantes de este evento
    </v-alert>
    <v-row>
      <v-col cols="12" lg="8" md="12" sm="12">
        <v-card class="elevation-2 pa-0 ma-0">
          <v-responsive class="pa-0 ma-0" :aspect-ratio="16/9">
            <v-card-text class="pa-0 ma-0">
              <iframe class="responsive-iframe" :src="'https://www.youtube.com/embed/' + streaming_data.youtube_video_id" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <h1 class="ma-5">{{ streaming_data.titulo_video }}</h1>
              <div class="ma-5">{{ streaming_data.descripcion_video }}</div>
            </v-card-text>
          </v-responsive>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="12" sm="12">

        <v-tabs
            v-model="tab"
            background-color="deep-purple accent-4"
            center-active
            dark
        >
          <v-tab href="#tab-1">Chat</v-tab>
          <v-tab href="#tab-2">Encuestas</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1" >
            <v-card class="elevation-2">
              <chat :es_coach="true" :id_evento="evento_id"></chat>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
          <v-card class="elevation-2 pa-5">
            <encuestas
             v-if="(id_encuestas!=0)"
             :id_encuestas="id_encuestas" 
             :id_evento="evento_id"/>
          
              <h2 v-if="(id_encuestas==0)">!No hay encuesta agendada!</h2>

            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import chat from '@/components/chat';
import encuestas from '@/components/Encuestas';
export default {
  components : {chat, encuestas},
  data:()=>({
    tab: null,
    streaming_data : [],
    evento_id : 0,
    id_encuestas:0
  }),
  mounted() {
    this.evento_id = parseInt(this.$route.query.evento);
    this.getStream();
    this.getEncuesta(this.$route.query.evento);
  },
  methods : {
    getEncuesta(){
      this.$http.post('/api/id-encuesta', {
         'evento_id': this.evento_id
      })
      .then((response) => {
          this.id_encuestas = response.data['encuesta_id'];
      })
      .catch(function (error) {
      })
    },
    getStream() {
      this.$http.get('/api/aprende/eventos-stream/' + this.evento_id)
      .then(res => {
        this.streaming_data = res.data
      })
      .catch(err => {

      })
    },
  }
}
</script>
<template>
  <v-container>
    <script type="application/javascript" src="https://apis.google.com/js/api.js"></script>
    <div id="result" style="float: left" >
      <v-btn @click="showPickerDialog" small>
        Seleccionar archivo
      </v-btn>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "drive.vue",
  props:[
    'idarchivo' //,variable2
  ],

  /*{
    idarchivo: {
      type: String,
      required: false
    }
  },*/
  data:() => ({
//name: "drive2"
/*
si funciona
    developerKey     : 'AIzaSyA0wWylTXI5GAVCoAVPwevfsuVtpxDnMZE',
    clientId         : "869858860532-csp3gggo7la580v396ssl4dvd4vd08th.apps.googleusercontent.com",
    appId            : "869858860532",
*/

    developerKey     : 'AIzaSyAG6tR1HV3Z2M-Q2nBFjD-5vX-_6R6eoQU',
    clientId         : "95089445529-nalgm9qt29jbfnsoph7hk04ol1bv5uo2.apps.googleusercontent.com",
    appId            : "95089445529",


    scope            : ['https://www.googleapis.com/auth/drive.file'],
    pickerApiLoaded  : false,
    oauthToken: ''
  }),
  methods:{
    copyFile(fileId){
      //console.clear()
      //this.idarchivo = fileId
      this.$emit('idarchivo',fileId)
      //console.log(fileId)
/*      this.$http.post('/api/aprende/copy-file', {
        'fileId'       : fileId,

      })
          .then((response) => {
            if(response.data){
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Material guardado exitosamente.',
                showConfirmButton: false,
                timer: 2500
              })
            }
            else{
              Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Archivo no almacenado.',
                showConfirmButton: false,
                timer: 2500
              })
            }
          })
          .catch(function (error) {
            console.log(error)
          })*/
    },
    showPickerDialog(){
      this.onApiLoad()
    },
    onApiLoad() {
      gapi.load('auth', { 'callback': this.onAuthApiLoad });
      gapi.load('picker');
    },
    onAuthApiLoad() {
      window.gapi.auth.authorize({
        'client_id': '95089445529-nalgm9qt29jbfnsoph7hk04ol1bv5uo2.apps.googleusercontent.com',
        'scope'    : ['https://www.googleapis.com/auth/drive']
      }, this.handleAuthResult);
    },
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      }
    },
    createPicker() {
      let view = new google.picker.View(google.picker.ViewId.DOCS);
      view.setMimeTypes("image/png,image/jpeg,image/jpg");
      let picker = new google.picker.PickerBuilder()
          //.enableFeature(google.picker.Feature.NAV_HIDDEN)
          //.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setAppId(this.appId)
          .setOAuthToken(this.oauthToken)
          .addView(view)
          .addView(new google.picker.DocsUploadView())
          .setDeveloperKey(this.developerKey)

          //.setDeveloperKey('AIzaSyDPs9U-dgOC9h1jRFNwOwhRtARCph8_3HM')
          .setCallback(this.pickerCallback)
          .build();
      picker.setVisible(true);
    },

    pickerCallback(data) {
      let url = 'nothing';
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        let fileId = data.docs[0].id;
        //alert('The user selected: ' + fileId);
/*        console.log("el usuario selecciono:")
        console.log(fileId)
        console.log("info archivo")
        console.log(data.docs[0])*/
       // console.log("data docs")
        //console.log(data)
        this.$emit('idarchivo',data.docs[0])
        //this.copyFile(fileId)
        //let doc = data[google.picker.Response.DOCUMENTS][0];
        //url = doc[google.picker.Document.URL];
      }
      //let message = 'You picked: ' + url;
      //document.getElementById('result').innerHTML = message;
    }
  }
}
</script>

<style scoped>

</style>

<template>
    <v-container>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-icon large class="mr-2" color="green">mdi-checkbox-marked-outline</v-icon> <h3>Banco de Evaluaciones</h3>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" fab dark small color="purple" title="Crear examen" @click="abrircrearexamen()">
                        <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                </v-row>
            </v-card-title>
            <v-card elevation="2">
                <v-card-title>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table v-model="selected" :headers="headers" :items="examenes" :search="search">
                    <template v-slot:item.actions="{ item }">
                        <!--
                        <v-icon small @click="visualizarExamen(item)" title="Vista previa de Examen" color="green">
                            mdi-text-box-check-outline
                        </v-icon>
                        -->
                      <v-btn
                          fab elevation="2"
                          color="green"
                          small
                          x-small
                          style="margin-right: 5px"
                      >
                        <v-icon small @click="editarExamen(item)" title="Editar Examen" color="white">
                            mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                          fab elevation="2"
                          color="red"
                          small
                          x-small
                      >
                        <v-icon  small @click="deleteExamen(item)" title="Eliminar Reactivo" color="white">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  <template v-slot:item.invitar_usuarios="{item}">
                    <v-btn @click="invitarExamen(item)" class="primary" ><v-icon>mdi-email</v-icon></v-btn>
                  </template>
                </v-data-table>
            </v-card>
        </v-card>


      <v-dialog
          v-model="dialogInvitacion"
          persistent
          max-width="800"
      >
        <v-card>
          <v-card-title class="text-h5">
            Enviar invitación de examen
          </v-card-title>
          <v-card-text>
            <v-select v-model="invitaciones.grupo_elegido" item-value="id" item-text="name" :items="invitaciones.grupos_usuarios" @change="getUsers" label="Seleccione el grupo" return-object></v-select>

            <v-select readonly v-model="invitaciones.examen_elegido" item-value="id" item-text="titulo" :items="invitaciones.examenes" label="Seleccione el examen" return-object></v-select>

            <v-alert
                dense
                type="info"
            >
              A continuación, seleccione a las personas que necesite invitar
            </v-alert>
            <v-data-table :loadingAlumnos="loadingAlumnos" :items="invitaciones.alumnos" :headers="headersUsers">
              <template v-slot:item.seleccionar="{item}">
                <v-checkbox label="" v-model="item.usuario_invitado"></v-checkbox>
              </template>
            </v-data-table>
            <v-divider></v-divider>

            <v-btn v-if="!loading_autenticando" class="primary mt-5" @click="enviarInvitaciones">Enviar invitaciones</v-btn>

            <div class="mt-2" v-if="loading_autenticando" v-model="loading_autenticando">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              Enviando invitaciones, espere por favor...
            </div>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="dialogInvitacion = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        loading_autenticando: false,
        resultado_autenticacion: false,
        loadingAlumnos: true,
        dialogInvitacion: false,
        search: '',
        idExamen:'',
        examenes:[],
        selected: [],
        headersUsers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Seleccionar', value: 'seleccionar' },
        ],
        headers: [
            //{text: 'Id',align: 'start',sortable: true,value: 'id'},
            {text: 'Título del Examen',align: 'start',sortable: true,value: 'titulo'},
            {text: 'Fecha inicio', value: 'fecha_inicio'},
            {text: 'Fecha fin', value: 'fecha_fin'},
            {text: 'Hora inicio', value: 'hora_inicio'},
            {text: 'Hora fin', value: 'hora_fin'},
            { text: 'Invitar usuarios', value: 'invitar_usuarios' },
            { text: 'Acciones', value: 'actions',align: 'center'},
        ],
        email: '',
        invitaciones: {
          examenes: [],
          alumnos: [],
          examen_elegido: [],
          grupo_elegido: [],
          grupos_usuarios: []
        }
    }),
    created () {
        this.consultarExamen()
    },
    mounted() {


    },
    methods: {
        enviarInvitaciones (){
          this.resultado_autenticacion = false
          this.loading_autenticando = true
          this.$http.post("/api/usuarios/send-exam-invitation", {
            invitaciones : this.invitaciones
          }).then(res => {
            Swal.fire({
              icon: 'success',
              title: 'Excelente',
              text: 'Se enviaron las invitaciones con éxito'
            })
            this.resultado_autenticacion = true
            this.loading_autenticando = false
          }).catch(err => {

          })
        },
        getGroupsUsers (){
          this.$http.get("/api/usuarios/grupos").then(res => {
            this.invitaciones.grupos_usuarios = res.data
          }).catch(err => {

          })
        },
        getUsers (){
          console.log(this.invitaciones)
          this.$http.get("/api/usuarios?examen_id=" + this.invitaciones.examen_elegido.id + "&grupo=" + this.invitaciones.grupo_elegido.name).then(res => {
            this.invitaciones.alumnos = res.data;
          }).catch(err => {

          })
        },
        getExamenes (){
          this.$http.get("/api/examenes").then(res => {
            this.invitaciones.examenes = res.data;
          }).catch(err => {

          })
        },
        invitarExamen (item){
          this.invitaciones.examenes = []
          this.invitaciones.alumnos = []
          this.invitaciones.examen_elegido = []
          this.invitaciones.grupo_elegido = []
          this.invitaciones.grupos_usuarios = []
          this.dialogInvitacion = true
          this.invitaciones.examen_elegido = item
          this.getExamenes()
          this.getGroupsUsers()

        },
        init () {
            //this.loginGoogle()
        },
        abrircrearexamen(){
            this.$router.push({ path: '/examen' })
        },
        consultarExamen(){
            this.$http.get("/api/examenes")
                .then((response) => {
                    this.examenes = response.data;
                })
                .catch(function (error){
                        console.log(error)
                    }
                )
        },
        visualizarExamen(item){
            this.$router.push({ path: '/vistaexamen', query: { idExamen: item.id} })
        },
        deleteExamen (item){
          //alert(item.titulo)
          Swal.fire({
            title: 'Atención',
            html: '¿Desea eliminar el examen: '+item.titulo+' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              let url = `/api/examenes/${item.id}`
              this.$http
                  .delete(url)
                  .then((response) => {

                  })
                  .catch(function (error){
                    console.log(error)
                  }).finally(() => {
                      this.consultarExamen()
                      Swal.fire(
                          'Excelente',
                          'El examen se eliminó correctamente',
                          'success'
                      )
                  })
            }
          })
        },
        editarExamen(item){
            this.$router.push({ path: '/examen', query: { idExamen: item.id, tipoaccion:2} })
        }

    }
}
</script>

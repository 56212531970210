<template>
<v-container>

    <v-card
      elevation="2"
     >
     <v-card-title>
            <h3>Sistemas Educativos</h3>
          </v-card-title>
      
      <v-row >

          <v-col cols="4" sm="4">
                    <v-text-field  
                    label="Nombre de Sistema Educativo"         
                    v-model="nombre"
                    v-bind:value="nombre"
                    dense
                    @change="guardarNombre()"
                    ></v-text-field>      
        </v-col>
        <v-col cols="4" sm="4">
                    <v-text-field  
                    label="Abreviatura"         
                    v-model="abreviatura"
                    v-bind:value="abreviatura"
                    dense
                    @change="guardarNombre()"
                    ></v-text-field>      
        </v-col>
        <v-col cols="4" sm="4">
                    <v-btn
              color="primary"
              dark
              class="mb-2"
              title="Agregar nuevo Sistema Educativo"
            >Agregar</v-btn>
        </v-col>
      </v-row>


  <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>

        <v-data-table
          :headers="headers"
          :items="sistemas"
          class="elevation-1"
        >
            <template v-slot:item.nombre="{ item }">               
                    <v-text-field                
                    v-model="item.nombre"
                    v-bind:value="item.nombre"
                    @change="actualizarNombre(item)"
                    ></v-text-field>    
            </template>
            <template v-slot:item.abreviatura="{ item }">               
                    <v-text-field                
                    v-model="item.abreviatura"
                    v-bind:value="item.abreviatura"
                    @change="actualizarAbreviatura(item)"
                    ></v-text-field>    
            </template>
            <template v-slot:item.activo="{ item }">               
                <v-switch 
                v-model="item.activo" 
                v-bind:value="item.activo"
                @change="actualizarActivo(item)"
                ></v-switch>
            </template>            
        </v-data-table>
    </v-card>
 </v-container>
</template>

<script>
   import moment from 'moment';
   moment.locale('es');
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  export default {
    data:() => ({
        absolute: false,
        opacity: 0.46,
        zIndex: 5,
        nombre:'',
        abreviatura:'',
        sistemas:[],
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        headers: [
          {text: 'Id',align: 'start',sortable: false,value: 'id'},
          {text: 'Nombre',align: 'start',sortable: false,value: 'nombre'},
          { text: 'Abreviatura', value: 'abreviatura'},   
          { text: 'Activo', value: 'activo' },
        ],
        overlay: false,
        absolute:true,
     
    }),
    created () {
      this.init();
    },    
    methods: {
    init () {
      this.getSistemasEducativos()
    }, 
    formatDate(value) {
      return moment(value,"DDMMYYYY").format('ll');
    }, 
    actualizarActivo(item){
      if(item.alumnocontactado==null){
         item.activo = 0;
      }
      else
        item.activo = 1;
      //console.log('alumnoContactado actualizado: ' + alumnocontactado + 'id:' + id)
                this.overlay = true;
                this.$http.post('/api/sistemaseducativos', {
              
                'id':               item.id,
                'atributo':         2,
                'alumnocontactado':  item.alumnocontactado,
               
                })
                 .then((response) => {
                   // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                   // console.log(error)
                    this.overlay = false;
                })
                
    },
    guardarMediodeContacto(mediodecontacto,id){
        console.log('medio contactado:' + mediodecontacto + 'del id:' + id);
                this.$http.post('/api/concentrados', {
                
                'id':               id,
                'atributo':         4,
                'mediodecontacto':  mediodecontacto,
               
                })
                 .then((response) => {
                   // console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                   // console.log(error)
                    this.overlay = false;
                })
    },
    getSistemasEducativos () {
            let url = '/api/sistemaseducativos'
            this.$http.get(url)
            .then((response) => {
               this.sistemas = response.data;
               //console.log(this.periodos)
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
    }
    },
    mounted(){

    } 
  }
</script>
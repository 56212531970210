<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-if="(! hide_menu)"
        v-model="drawer"
        app
        clipped
        permanent
        expand-on-hover
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row
              v-if="item.heading"
              :key="item.heading"
              align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
                cols="6"
                class="text-center"
            >
              <a
                  href="#!"
                  class="body-2 black--text"
              >EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
              v-else-if="item.children"
              :key="item.text"
              v-model="item.model"
              :prepend-icon="item.model ? item.icon : item.icon"
              append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                :to="child.link"
                link
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- menu padre -->
          <v-list-item
              v-else
              :key="item.text"
              :to="item.link"
              link
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>


    </v-navigation-drawer>

    <v-app-bar

        app
        color="primary"
        clipped-left
        dark
    >
      <v-app-bar-nav-icon v-if="(! hide_menu)" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title><img src="../public/logoEstudioAppBlanco.svg" height="47"></v-toolbar-title><v-spacer></v-spacer>
      <v-menu offset-y >
        <template v-slot:activator="{ on }">

          <v-btn
              icon
              large
              v-on="on"
          >
            <v-avatar size="32px">
              <img
                  :src="profile_image"
                  :alt="name_user"
              >
            </v-avatar>

          </v-btn>



<!--          <notificaciones></notificaciones>-->


        </template>
        <v-card>
          <v-list dense>

            <v-list-item>
              <v-list-item-title ><strong><span style="color:#952f81;">{{ name_user }}</span></strong></v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title ><span style="">{{ email }}</span></v-list-item-title>
            </v-list-item>

            <v-list-item @click="logout()">
              <v-list-item-title class="red--text text-center"><v-icon class="red--text">mdi mdi-power</v-icon> Salir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>


    </v-app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <v-footer app
              color="primary"
              dark
    >
      <span>Nivel A &copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
//import notificaciones from '@/views/notificaciones.vue';


import router from "@/router";
import api from "@/services/api";

export default {
/*  components : {notificaciones},
  props: {
    source: String,
  },*/
  data: () => ({
    hide_menu : false,
    show: false,
    //email : '',
    profile_image : '',
    name_user : '',
    drawer: null,
    items: [],
    email: ''
  }),
  mounted() {

    let currentRoute = router.currentRoute.path;

    if (currentRoute == "/examenes/loginuser") {
      this.$http.get("/sanctum/csrf-cookie").then(() => {
        window.location.href =
            process.env.VUE_APP_API_URL + "/api" + router.currentRoute.fullPath;
      });
      return false
    }
    this.$http.get('api/menu/get-menu')
        .then(response => {
          if ( response.data.redirect == "/error" ) {
            this.hide_menu = true;
          }
          this.items = response.data.menus;
            //console.log("menus")
            //console.log(this.items)
            this.profile_image = response.data.auth.avatar;
            this.name_user = response.data.auth.name;
            this.email = response.data.auth.email;
            let hide_roles_menu = [7];

            if ( parseInt(response.data.auth.role_id) == 7 ) {
              this.hide_menu = true;
            }
        })
  },
  methods: {
    init () {

    },
    logout(){
      let url = 'logout'
      this.$http.post(url)
          .then(response => {
            //this.$router.push({ name: 'Home'})
            if(response.data.success) {
              window.location = response.data.site;
            }
          }).catch(error => {
        location.reload();
      });
    }
  },

}
</script>

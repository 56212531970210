<style>
iframe {
  width: 100%;
  min-height: 500px;
}
</style>
<template>
  <div>
    <v-row>
      <v-col
          cols="12"
          sm="12"
          md="12"
          lg="8"
      >

        <div class="text-h4">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span
              v-bind="attrs"
              v-on="on"
              >{{ curricula.nombre_curricula }}</span>
            </template>
            <span>Nombre de la especialidad o currícula</span>
          </v-tooltip>
        </div>
        <v-divider class="mt-5"></v-divider>

        <v-row>
          <v-col cols="12"  lg="5" md="12" sm="12">
            <v-select :disabled="true" @change="loadUnidades" v-model="cursos_navegacion" label="Elegir un curso" item-text="curso_curricula.nombre_curso" item-value="curso_curricula.curso_id" :items="curricula.lista_cursos" return-object></v-select>
          </v-col>
          <v-col cols="12"  lg="5" md="12" sm="12">
            <v-select :disabled="true" v-model="unidades_navegacion" label="Elegir unidad" item-text="unidad.nombre_unidad" item-value="unidad.id" :items="curricula.lista_unidades" return-object></v-select>
          </v-col>
          <v-col cols="12"  lg="2" md="12" sm="12">
            <v-btn :disabled="true" class="blue white--text pa-5" @click="actualizarContenido">
              Actualizar <v-icon right> mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>



        <v-card class="">

          <v-responsive :aspect-ratio="16/9">
            <v-card-title class="grey white--text">{{ nombre_curso_actual }}</v-card-title>

            <v-card-subtitle class="grey white--text">{{ nombre_unidad_actual }}</v-card-subtitle>
<!--            <v-row>
              <v-col class="text-left" lg="6" md="6" sm="6">
                <v-btn text>
                  <v-icon left> mdi-arrow-left</v-icon>{{ curricula.unidad_siguiente.nombre_unidad }}
                </v-btn>
              </v-col>
              <v-col class="text-right" lg="6" md="6" sm="6">
                <v-btn text>
                  {{ curricula.unidad_siguiente.nombre_unidad }} <v-icon right> mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>-->
            <v-card-text class="pa-5">

              <p>Sesión Online.</p>

<!--              <v-btn class="primary">Abrir al Google Meet</v-btn>-->
              <!--              <iframe src="https://www.youtube.com/embed/pEzonGXrkJc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--
              <p v-for="(item, i) in 5">Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.</p>
-->

            </v-card-text>
          </v-responsive>
        </v-card>
        <p class="mt-5">Actividades de la unidad:</p>
        <v-card v-for="(actividad, i) in actividades_unidad" class="pa-5 mb-5 elevation-5" v-if="actividades_unidad.length > 0">
          <p>{{ actividad.detalles_actividad  }}</p>

          <v-list-item v-for="(itemActividad, i) in actividad.items_actividad"  two-line>
            <v-list-item-content>
              <v-list-item-title><v-icon class="green--text">mdi-checkbox-marked-circle-outline</v-icon> {{itemActividad.descripcion_actividad}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>

      </v-col>
      <v-col
          cols="12"
          lg="4"
          md="12"
          sm="12"
      >
        <template v-for="(sesion, i) in curricula.sesiones">
          <v-expansion-panels class="pa-1" >
            <v-expansion-panel >
              <v-expansion-panel-header>
                <p class="pa-0 ma-0"><strong>Sesion. {{ i + 1 }}.- </strong> {{ sesion.nombre_sesion  }} <!--<small class="blue--text" v-if="!sesion.sesion_disponible">Inicia el <strong>{{ sesion.fecha_consumo_inicio_f }}</strong></small>--></p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <p>{{ sesion.descripcion_sesion }}</p>

                <v-container v-if="(sesion.materiales_sesion)">
                  <small>Materiales de la sesión:</small>
                  <v-row v-for="(material_sesion, i) in sesion.materiales_sesion">
                    <v-col>
                      {{ (i+1) + '.- '+ material_sesion.nombre_material }} <a target="_blank" :href="material_sesion.ruta_documento"><v-icon>mdi-link-variant</v-icon></a>
                    </v-col>
                  </v-row>
                </v-container>

                <a target="_blank" :href="sesion.HtmlLink">
                  <v-btn class="mt-5 green white--text">Unirse al Google Meet</v-btn>
                </a>
<!--                <v-btn :disabled="(i==0) ? false : true" class="primary">Iniciar</v-btn>-->
                <v-divider class="mt-5"></v-divider>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-card :rounded="false" class="elevation-0 pa-0 ma-0">
                      <v-list>
                        <v-list-group
                            v-for="(mObjetivo, i) in sesion.objetivos_sesion"
                            :value="true"
                            prepend-icon="mdi-folder"
                            no-action>
                          <template v-slot:activator >
                            <v-list-item-title >
                              <v-checkbox
                                @change="habilitarSesion(mObjetivo)"
                                v-model="i"
                                value="primary"
                              >
                                <template v-slot:label>
                                  <div>
                                    {{ mObjetivo.objetivo.nombre_objetivo }} <v-icon @click.prevent="abrirActividades(mObjetivo)">mdi-information-outline</v-icon>
                                  </div>
                                </template>
                              </v-checkbox></v-list-item-title>
                          </template>
                          <v-list-item
                              v-for="(mMaterial, i) in mObjetivo.objetivo.materiales_objetivo"
                              :key="i"
                              link
                          >
                            <v-list-item-title @click="abrirLink(mMaterial.ruta_documento)" v-text="mMaterial.nombre_material"></v-list-item-title>

                            <v-list-item-icon>
                              <v-icon v-text="drawIconMaterial(mMaterial.tipo_material_id)"></v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-group>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>




        </template>
      </v-col>
    </v-row>


    <v-dialog
        v-model="dialogActividades"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Lista de actividades</span>
        </v-card-title>
        <v-card-text>
          <v-container>

            <v-expansion-panels class="m-0 p-0 elevation-0" accordion>
              <v-expansion-panel
                  v-for="(itemActividad, i) in itemsActividades"
                  :key="i"

              >
                <v-expansion-panel-header>{{ itemActividad.detalles_actividad }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item v-for="(item, i) in itemActividad.items_actividad">
                    <v-list-item-icon>
                      <v-icon class="green--text">mdi-checkbox-marked-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.descripcion_actividad }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>


          </v-container>
          <small>* Esta es la lista de items por hacer en esta actividad</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialogActividades = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
export default  {
  data:() => ({
    nombre_curso_actual : '',
    nombre_unidad_actual : '',
    itemsActividades : [],
    dialogActividades : false,
    cursos_navegacion : '',
    unidades_navegacion : '',
    curricula : {
      lista_cursos : [],
      lista_unidades : [],
      nombre_curricula : '',
      sesiones : [],
      nombre_unidad : '',
/*      unidad_anterior : {
        unidad_id : 1,
        nombre_unidad : 'Unidad 0',
      },
      unidad_siguiente : {
        unidad_id : 2,
        nombre_unidad : 'Unidad 2',
      },
      curso_anterior : {
        curso_id : 1,
        nombre_curso : '-',
      },
      curso_siguiente : {
        curso_id : 2,
        nombre_curso : 'Componentes internos',
      }*/
    },
    curriculaId : 0,
    cursoId : 0,
    unidadId : 0,
    items : [],
    actividades_unidad : [],
    objetivos : [
      {
        title : 'Mi objetivo uno',
        icon : 'mdi-folder',
        materiales : [
          {
            title : 'Lectura para complementar',
            icon : 'mdi-link-variant',
            type : 1,
          },
          {
            title : 'Link de referencia externa',
            icon : 'mdi-file-pdf',
            type : 2
          }
        ]
      },
      {
        title : 'Mi objetivo dos',
        icon : 'mdi-folder',
        materiales : [
          {
            title : 'Video de retroalimentación',
            icon : 'mdi-file-video',
            type : 1,
          },
          {
            title : 'Audio de podcast de la semana',
            icon : 'mdi-music-note',
            type : 2
          }
        ]
      }
    ],
  }),
  mounted() {
    this.items = [
      {
        title : 'Aprendiendo lo básico Aprendiendo '
      },
      {
        title : 'Interfaz gráfica'
      },
      {
        title : 'Componentes'
      }
    ];
    this.curriculaId = this.$route.query.id;
    this.unidadId = this.$route.query.unidad_id;
    this.cursoId = this.$route.query.curso_id;

    this.getCurriculaContenido();
    this.getContent();

    this.getActividadesUnidad();
  },
  methods : {
    getActividadesUnidad() {
      this.$http.get("api/aprende/actividad-unidad/get-all-actividades-items/" + this.unidadId)
      .then(response => {
        this.actividades_unidad = response.data;
      })
    },
    abrirActividades(item) {
      console.log(item)
      this.dialogActividades = true;
      this.$http.get('/api/aprende/actividad-objetivo/get-all-actividades-items/' + item.objetivo_id)
      .then(response => {
        this.itemsActividades = response.data;
      })
      .catch(error => {

      })
    },
    abrirLink(ruta_documento) {
      window.open(ruta_documento, '_blank');
    },
    habilitarSesion(item) {
      console.log(item)
    },
    drawIconMaterial(tipo_material) {
      switch ( tipo_material ) {
        case 1 :
          return "mdi-music-note";
          break;
        case 2 :
          return "mdi-file-video";
        case 3 :
          return "mdi-link-variant";
          break;
          break;
        case 4 :
          return "mdi-file-pdf";
          break;
      }
      return "mdi-link-variant";
    },
    actualizarContenido() {
      let url = '';
      url = `/contenido-curricula-curso?id=${this.curriculaId}&curso_id=${this.cursos_navegacion.curso_id}&unidad_id=${this.unidades_navegacion.unidad.id}`;
      window.location.href=url;
    },
    loadUnidades(setSelected = false, item) {
      this.$http.get('/api/aprende/get-unidades-curso/' + this.cursos_navegacion.curso_id)
      .then(response => {
        this.curricula.lista_unidades = response.data;
        //this.unidades_navegacion = this.curricula.lista_unidades[0];
        if ( setSelected ) {
          this.unidades_navegacion = item;
          this.nombre_unidad_actual = this.unidades_navegacion.unidad.nombre_unidad;
        }
      })
      .catch(error => {
      })
    },
    getCurriculaContenido() {
      this.$http.get('/api/aprende/contenido-curricula/' + this.curriculaId)
      .then(response => {
        //console.log(response.data)
        this.curricula.nombre_curricula = response.data.curricula.nombre_curricula;
        this.curricula.lista_cursos = response.data.contenido;

        /*this.curricula.lista_unidades = [{
          unidad_id : 1,
          nombre_unidad : 'Unidad 2. Datos estructurados',
        }];*/
      })
      .catch(error => {

      });
    },
    getContent() {

      this.$http.post('/api/aprende/get-contenido-curso-unidad', {
        curriculaId : this.curriculaId,
        cursoId : this.cursoId,
        unidadId : this.unidadId,
        curricula_oferta_id : this.$route.query.curricula_oferta_id
      })
      .then(response => {

/*        console.log('//////////////////////////////////////////////////')
        console.log(response.data.contenido)*/
        this.cursos_navegacion = response.data.contenido[0];
        this.nombre_curso_actual = this.cursos_navegacion.curso_curricula.nombre_curso;

      /*  console.log("//////////CURSOS NAVEGACION///////////////")

*/
        //console.log(this.cursos_navegacion.curso_curricula.nombre_curso)

        this.loadUnidades(true, response.data.unidad[0])
        this.curricula.sesiones = response.data.contenido[0].curso_curricula.sesiones_curso;

 /*       console.log("//////LAS SESIONES////////")
        console.log(this.curricula.sesiones)*/
        /*alert(response.data.contenido[0].curso_curricula.id)*/
      })
      .catch(error => {

      })

    },
  },
  computed : {

  }
}
</script>
<template>
  <v-container>
    <template>
      <v-row justify="center">
        <v-dialog v-if="dialogIndicador" :value="true" @input="dialogIndicador = false" persistent max-width="790">
          <v-card elevation="2" class="mx-auto">
            <v-card-title>
              <v-icon large class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon><h3>SubIndicador</h3>
              <v-spacer></v-spacer>

            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
                </v-row>
                <v-data-table
                    :headers="headersobjetivos"
                    :items="objetivos"
                    class="elevation-1"
                    :search="search"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon v-if=" !((concentrado_unidadesobjetivos).indexOf(parseInt(item.id))>=0)"
                            class="mr-2"
                            color="green"
                            title="Agregar objetivo"
                            @click="anexarObjetivo(item)"
                    >
                      mdi-cloud-download
                    </v-icon>
                  </template>
                </v-data-table>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="closeImportar()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-card elevation="2" class="mx-auto">
      <v-card-title>
        <!--                <v-breadcrumbs :items="items"></v-breadcrumbs>-->

        <v-icon large class="mr-2" color="green">mdi-checkbox-marked-circle-outline</v-icon><h3>SubIndicadores</h3>
        <v-breadcrumbs :items="items" large ></v-breadcrumbs>
        <v-spacer></v-spacer>

      </v-card-title>
      <v-card elevation="2">
        <v-card-title>
          <v-row
              align="center"
              justify="space-around"

          >
            <v-btn
                color="green"
                small
                @click="showObjetivo()"
                title="Agregar Objetivo"
            >
              <v-icon  small color="white" >mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card elevation="2">
        <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table :headers="headers" :items="unidadesobjetivos" class="elevation-1">

          <template v-slot:item.actions="{ item }">
            <div class="text-center">
              <!--                            <v-btn
                                              fab elevation="2"
                                              color="green"
                                              small
                                              x-small
                                              @click="editIndicador(item)"
                                              style="margin-right: 10px"
                                              title="Editar Indicador"
                                          >
                                              <v-icon small color="white">mdi-pencil</v-icon>
                                          </v-btn>-->

              <v-btn
                  fab elevation="2"
                  color="red"
                  small
                  x-small
                  style="margin-right: 10px"
                  @click="deleteObjetivo(item)"
                  title="Eliminar objetivo"
              >
                <v-icon small color="white">mdi-delete</v-icon>
              </v-btn>

            </div>

          </template>
        </v-data-table>

      </v-card>
    </v-card>
  </v-container>
</template>

<script>
export default {

  data:() => ({
    opacity: 0.46,
    search:'',
    zIndex: 5,
    nombre:'',
    escuela_id:1,
    refrescar_tabla:0,
    abreviatura:'',
    item:[],
    items:[{
      text: 'Indicadores',
      disabled: false,
      href: '/dashboardindicadores',
    },
      {
        text: 'Cargando...',
        disabled: true,
      }
    ],
    objetivos:[],
    unidadesobjetivos:[],
    snack: false,
    snackColor: '',
    snackText: '',
    respuestasTotal:0,
    max25chars: v => v.length <= 25 || 'Input too long!',
    pagination: {},
    headers: [
      {text: 'Id',align: 'start',sortable: true,value: 'id'},
      { text: 'Objetivo', value: 'objetivos.nombre_objetivo'},
      { text: 'Clave', value: 'objetivos.clave_interna' },
      { text: 'Descripcion', value: 'objetivos.descripcion' },
      { text: 'Acciones', value: 'actions', sortable: true , align: 'center'},
    ],
    headersobjetivos: [
      {text: 'Id',align: 'start',sortable: true,value: 'id'},
      { text: 'Nombre', value: 'nombre_objetivo', sortable: true , align: 'justify'},
      { text: 'Clave', value: 'clave_interna', sortable: true , align: 'center' },
      { text: 'Descripcion', value: 'descripcion', sortable: true , align: 'justify'},
      { text: 'Acciones', value: 'actions', sortable: true, align: 'center'},
    ],
    overlay: false,
    absolute:true,
    isModalVisible: false,
    dialog: false,
    dialogIndicador: false,
    dialogAyuda:false,
    tipoaccion:'',
    buscar_contador:0,
    checkstatus : null,
    clave_indicador:'',
    txtboton:'',
    estatusAccionTabla:0,    //1.- Create 2.- Update 3. Delete
    idIndicador:0,
    nombre_indicador:'',
    modolectura:false,
    concentrado_unidadesobjetivos: [],
  }),
  created () {
    //this.init();

  },
  methods: {
    init () {
      //obtiene los reactivos filtrados por el usuario actual.
    },
    anexarObjetivo(item) {
      this.guardando = true;
      this.$http.post('/api/aprende/unidadesobjetivos', {
        'objetivo_id': item.id,
        'unidad_id': this.idUnidad
      })
          .then((response) => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Objetivo agregado a unidad exitosamente.',
              showConfirmButton: false,
              timer: 1500
            })
            this.getUnidadesObjetivos();
            //this.guardando = false;
            //this.consultarExamen()
          })
          .catch(function (error) {
          })
    },
    closeModal() {
      this.dialog = false
    },
    closeAyuda(){
      this.dialogAyuda =  false
    },
    closeImportar(){
      this.nombre_indicador   =   '';
      this.clave_indicador    =   '';
      this.descripcion        =   '';
      this.txtboton           =   '';
      this.dialogIndicador    =   false
      this.modolectura        =   false;
    },
    consultar(id){
      this.idTipoReactivo = id;
      if (this.idTipoReactivo==1 || this.idTipoReactivo==2 || this.idTipoReactivo==3){
        this.showModal();
      }
      else if (this.idTipoReactivo==6)
        this.showModal();
    },
    consultarNivelEducativo(){
      let url = '';
      url = `/api/grados?idnivel=${this.idNivel.id}`
      this.$http.get(url)
          .then((response) => {
            this.grados = response.data;
            if(this.tipoaccion==2 ) {
              this.idGrado = this.grados.find(item => item.id == this.grado_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    consultarArea(){
      let url = '';
      url = `/api/asignaturas?idArea=${this.idArea.id}`
      this.$http.get(url)
          .then((response) => {
            this.asignaturas = response.data;
            if(this.tipoaccion==2 ){
              this.idAsignatura = this.asignaturas.find(item => item.id == this.asignatura_id)
            }
          })
          .catch(function (error){
            console.log(error)
          })
    },
    deleteObjetivo (item){
      let index = this.concentrado_unidadesobjetivos.indexOf(item);
      let index2 = this.unidadesobjetivos.indexOf(item);
      Swal.fire({
        title: '¿Desea eliminar el objetivo: '+ item.objetivos.nombre_objetivo + '?',
        showDenyButton: true,
        confirmButtonText: `Eliminar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let url = `/api/aprende/unidadesobjetivos/${item.id}`
          this.$http
              .delete(url)
              .then((response) => {
                this.concentrado_unidadesobjetivos.splice(index, 1); //array ocupado para los objetivos
                this.unidadesobjetivos.splice(index2, 1);
                //this.getUnidadesObjetivos()
                Swal.fire('Objetivo eliminado satisfactoriamente.', '', 'success')
              })
              .catch(function (error){
                console.log(error)
                //this.overlay = false;
              })

        } else if (result.isDenied) {
          //Swal.fire('Changes are not saved', '', 'info')
        }
      })
      //this.overlay = true;



    },
    editReactivo(item){
      this.item                 =   item;
      this.dialogIndicador       =   true;
      this.idTipoReactivo       =   item.tiporeactivo.tiporeactivo_concentrado.id;
      this.tipoaccion           =   2;
      this.estatusAccionTabla   =   2;
    },
    editIndicador(item) {
      this.idIndicador            =   item.id;
      this.nombre_indicador       =   item.nombre_indicador;
      this.clave_indicador        =   item.clave_indicador;
      this.dialogIndicador        =   true;
      this.txtboton               =   "Actualizar"
      this.estatusAccionTabla     =   2;
      this.modolectura            =   true;
    },
    getObjetivos(){
      let url = `/api/aprende/objetivos`
      console.log(this.concentrado_unidadesobjetivos)
      this.$http.get(url)
          .then((response) => {
            this.objetivos = response.data;
          })
          .catch(function (error){
            console.log(error)
          })
    },
    getUnidadesObjetivos(){
      let url = `/api/aprende/unidadesobjetivos/`+this.idUnidad
      //console.log(url);
      //this.concentrado_unidadesobjetivos  =   [];
      this.$http.get(url)
          .then((response) => {
            this.unidadesobjetivos = response.data;
            // console.log("unidades-objetivos")
            // console.log(this.unidadesobjetivos)
            for (let item = 0; item < this.unidadesobjetivos.length; item++) {
              this.concentrado_unidadesobjetivos.push(this.unidadesobjetivos[item].objetivos.id)
            }
            //console.log(this.concentrado_unidadesobjetivos)
          })
          .catch(function (error){
            console.log(error)
          })
    },
    showImportar(){
      this.dialogIndicador = true;
    },
    showModal() {
      this.dialog = true;
    },
    showObjetivo() {
      this.dialogIndicador     = true;
      this.txtboton           = "Guardar";
      this.estatusAccionTabla =   1;
      this.getObjetivos();
    },
  },
  mounted(){
    this.idIndicador         =    atob(this.$route.query.idIndicador);
    this.nombre_indicador    =    atob(this.$route.query.nombre_indicador);
    this.items[1].text       =    'Indicador: '+ atob(this.$route.query.nombre_indicador);
    this.getUnidadesObjetivos()
  }
}
</script>
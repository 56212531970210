<template>
  <div>
  <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table
          :headers="headers"
          :items="clases"
        >        
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Requisito(s) de la clase: {{nombre_clase}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >Nueva Clase</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="">
                     <v-text-field v-model="nombre" label="Nombre"></v-text-field> 
                  </v-col>
                  <v-col cols="12" sm="12" md="0">
                     <v-text-field v-model="descripcion" label="Descripión"></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="guardarClase(nombre,descripcion)">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 
      </v-toolbar>
    </template>                        
    <template v-slot:item.nombre="{ item }">  
        {{item.nombre}}                  
    </template>
    <template v-slot:item.indispensable="{ item }">
        <v-checkbox 
          v-model="item.indispensable" 
          v-bind:value="item.indispensable"
          @change="actualizarIndispensable(item.indispensable,item.id)"
        ></v-checkbox>
    </template>
  </v-data-table>
  </div>
</template>

<script>
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = 'http://localhost:8000'
  export default {
    data:() => ({
        dialog: false,
        dialogAcciones:false,
        editedIndex: -1,
        absolute: false,
        opacity: 0.46,
        zIndex: 5,
        idCurso: null,
        idProfesor:null,
        course_id:null,
        idPeriodo:'',
        nombre_curso:'',
        alumnos:[],
        datos:[],      
        clases:[],       
        medios:[],
        snack: false,
        snackColor: '',
        snackText: '',
        nombre_clase:'',
        clase_id:'',
        pagination: {},
        headers: [
          //{text: 'Id',align: 'start',sortable: true,value: 'id',},
          { text: 'Requisito', value: 'indispensable'},
          {text: 'Nombre',align: 'start',sortable: true,value: 'nombre'},
          

        ],
        overlay: false,
        absolute:true,
        nombre:'',
        descripcion:'',
        activo:1,
     
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Clase' : 'Editar Clase'
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },    
    created () {
      this.nombre_clase = this.$route.query.nombre
      this.clase_id     = this.$route.query.id
     // this.init()
        this.getRequisitos()
    },    
    methods: {
    init () {
      
    },  
    getRequisitos () {      
            this.$http.get('/api/requisitos', {
                params: {
                'clase_id':           this.clase_id,

                }
                })
            .then((response) => {
               this.clases = response.data;
               console.log(this.clases)
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
    },
    actualizarIndispensable(activo,id){
        console.log(activo)
       if(activo==null){
            //eliminar registro de requisitos
       }        
      else{
                    activo = 1; 
                    this.overlay = true;
                    this.$http.post('/api/requisitos', {
                    params: {
                        'clase_id':               this.clase_id,
                        'claserequisito_id':      id,                
                    }
                    })
                    .then((response) => {
                        console.log(response.data)
                        this.overlay = false;
                  
                    })
                    .catch(function (error){
                        console.log(error)
                        this.overlay = false;
                    })  
      }   
                   
    },    
    cerrarDialog(){
      this.dialogAcciones = false
    },
    close() {
        this.dialog = false
    },
    },
    mounted(){
    } 
  }
</script>
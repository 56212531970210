<template>
  <v-container>
    <div>
      <v-alert
          text
          dismissible
          color="info"
      >
        <div>Por favor ingrese los siguientes datos para crear una nueva currícula</div>
      </v-alert>
      <v-card class="pa-5">
        <v-card-title>
          <h3>Crear una currícula</h3>
        </v-card-title>
        <v-text-field
            v-model="nueva_curricula.nombre_curricula"
            label="Nombre de la currícula"
        ></v-text-field>
        <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="guardarCurricula()"
        >Guardar currícula</v-btn>
      </v-card>
      <v-spacer></v-spacer>
      <v-card>
        <v-card-title>
          <v-icon large class="mr-2" color="green">mdi-apps</v-icon><h3>Currículas disponibles</h3>
        </v-card-title>
        <v-data-table
            loading-text="Cargando, espere"
            :loading="innerLoading"
            :headers="headersCurriculas"
            :items="dessertsCurriculas"
            item-key="name">
          <template v-slot:item.editar="{ item }">
            <a style="text-decoration: none;" :href="'/curriculas-show/?curriculaId=' + item.id">
              <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="green"
              >
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </a>
          </template>
          <template v-slot:item.eliminar="{ item }">
            <v-btn
                @click="deleteItem(item)"
                class="mx-2"
                fab
                dark
                small
                color="red"
            >
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.cursos="{ item }">
            <a :href="'/contenido-curricula?curriculaId=' + item.id" style="text-decoration: none;">
              <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="orange"
              >
                <v-icon dark>
                  mdi-check
                </v-icon>
              </v-btn>
            </a>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-container>
</template>



<script>
export default {
  data:() => ({
    dessertsCurriculas : [],
    innerLoading : false,
    curriculas : [],
    nueva_curricula : {
      nombre_curricula : '',
    }
  }),
  mounted() {
    this.showCurriculas();
  },
  methods : {
    showCurriculas() {
      this.innerLoading = true;
      this.$http.get('/api/aprende/curriculas').then(response => {
        this.dessertsCurriculas = response.data;
        this.innerLoading = false;
      });
    },
    deleteItem(item) {
      let index = this.dessertsCurriculas.indexOf(item);
      Swal.fire({
        title: '¿Está seguro que desea eliminar este registro?',
        //showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$http.post('/api/aprende/curriculas/' + item.id, {
            _method : 'DELETE'
          })
          .then(response => {
            this.dessertsCurriculas.splice(index, 1);
          })
          .catch(error => {

          })
          Swal.fire('ELiminado correctamente!', '', 'success')
        } else if (result.isDenied) {
          //Swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    guardarCurricula() {
      this.$http.post('/api/aprende/curriculas', {
        nombre_curricula : this.nueva_curricula.nombre_curricula,
      }).then(response => {
        if ( response.data ) {
          this.dessertsCurriculas.unshift(response.data);
          this.nueva_curricula.nombre_curricula = '';
        }
        else {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'La currícula ingresada ya existe, por favor intente con otro',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }).catch(error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Todos los campos deben ser obligatorios',
          showConfirmButton: false,
          timer: 1500
        })
      })
    },
  },
  computed : {
    headersCurriculas() {
      return [
        { text: '#',                      sortable: false,    value: 'id', },
        { text: 'Nombre',                 sortable: false,    value: 'nombre_curricula', },
        { text: 'Fecha',                  sortable: false,    value: 'format_date', },
        { text: 'Editar',                 sortable: false,    value: 'editar',      align:'center'},
        { text: 'Eliminar',               sortable: false,    value: 'eliminar',    align:'center' },
        { text: 'Administrar contenidos', sortable: false,    value: 'cursos',      align:'center' },
        /*{ text: 'Contenidos', sortable: false, value: 'curriculas', },*/
      ]
    },
  }
}
</script>
import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import Tawk from "vue-tawk";
//fin 1
import api from "./services/api";
import JsonExcel from "vue-json-excel";
Vue.config.productionTip = false;
Vue.prototype.$http = api;

api.defaults.timeout = 50000;

api.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204
    ) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //do something
          break;

        case 401:
          let url = process.env.VUE_APP_API_URL + `/redirect`;

          if (currentRoute == "/authenticate-user") {
            return false;
          }

          let currentRoute = router.currentRoute.path;

          if (currentRoute == "/examenes/loginuser") {
            api.get("/sanctum/csrf-cookie").then(() => {
              window.location.href =
                process.env.VUE_APP_API_URL + "/api" + router.currentRoute.fullPath;
            });
          }

          api.get("/sanctum/csrf-cookie").then(() => {
            window.location = url;
          });

          break;
        case 403:
          api.get("/sanctum/csrf-cookie").then(() => {
            window.location = process.env.VUE_APP_API_URL + "/redirect";
          });
          break;

        case 404:
          this.$router.push({ name: "Home" });

          break;
        case 500:
          api.get("/sanctum/csrf-cookie").then(() => {
            window.location = process.env.VUE_APP_API_URL + "/redirect";
          });
          break;
        /*   case 502:
         setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1000); */
      }
      return Promise.reject(error.response);
    }
  }
);
Vue.prototype.$Tawk = Vue.use(Tawk, {
  tawkSrc: "https://embed.tawk.to/60216cb8a9a34e36b975092c/1eu191c4l",
});

Vue.config.productionTip = false;
Vue.component("downloadExcel", JsonExcel);
new Vue({
  router,
  vuetify,
  JsonExcel,
  render: (h) => h(App),
}).$mount("#app");

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[[_c('v-row',{attrs:{"justify":"center"}},[(_vm.dialogIndicador)?_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"790"},on:{"input":function($event){_vm.dialogIndicador = false}}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-share-variant")]),_c('h3',[_vm._v("Indicador")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre del indicador","value":_vm.nombre_indicador,"outlined":""},model:{value:(_vm.nombre_indicador),callback:function ($$v) {_vm.nombre_indicador=$$v},expression:"nombre_indicador"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Clave","readonly":_vm.modolectura,"value":_vm.clave_indicador,"outlined":""},model:{value:(_vm.clave_indicador),callback:function ($$v) {_vm.clave_indicador=$$v},expression:"clave_indicador"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[[_c('v-select',{attrs:{"label":"Categoría:","items":_vm.categorias,"item-value":"id","item-text":"categoria","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.categoria)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.categoria)+" ")]}}],null,false,245054716),model:{value:(_vm.idCategoria),callback:function ($$v) {_vm.idCategoria=$$v},expression:"idCategoria"}})]],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.guardarIndicador}},[_vm._v(" "+_vm._s(_vm.txtboton)+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.closeImportar()}}},[_vm._v(" Cerrar ")])],1)],1)],1):_vm._e(),(_vm.dialogSubIndicador)?_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"790"},on:{"input":function($event){_vm.dialogSubIndicador = false}}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-arrange-bring-to-front")]),_c('h4',[_vm._v("SubIndicador(es) de Indicador: "+_vm._s(_vm.nombre_indicador))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre del subindicador","outlined":""},model:{value:(_vm.nombre_subindicador),callback:function ($$v) {_vm.nombre_subindicador=$$v},expression:"nombre_subindicador"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.guardarSubIndicador}},[_vm._v(" "+_vm._s(_vm.txtboton)+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers2,"items":_vm.subindicadores},scopedSlots:_vm._u([{key:"item.nombre_subindicador",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.nombre_subindicador,"dense":""},on:{"change":function($event){return _vm.guardarNombreSubIndicador(item.nombre_subindicador,item.id)}},model:{value:(item.nombre_subindicador),callback:function ($$v) {_vm.$set(item, "nombre_subindicador", $$v)},expression:"item.nombre_subindicador"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"fab":"","elevation":"2","color":"red","small":"","x-small":"","title":"Eliminar Indicador"},on:{"click":function($event){return _vm.deleteSubIndicador(item)}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-delete")])],1)],1)]}}],null,false,3309414861)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.closeSubIndicador()}}},[_vm._v(" Cerrar ")])],1)],1)],1):_vm._e()],1)],_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-share-variant")]),_c('h3',[_vm._v("Indicadores")]),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"large":""}}),_c('v-spacer')],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"green","small":"","title":"Crear Indicador"},on:{"click":function($event){return _vm.showIndicador()}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-share-variant")])],1)],1)],1)],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.indicadores},scopedSlots:_vm._u([{key:"item.detalles",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"fab":"","elevation":"2","color":"green","small":"","x-small":"","title":"Editar Indicador"},on:{"click":function($event){return _vm.editIndicador(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.eliminar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"fab":"","elevation":"2","color":"red","small":"","x-small":"","title":"Eliminar Indicador"},on:{"click":function($event){return _vm.deleteIndicador(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-delete")])],1)]}},{key:"item.subindicadores",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"fab":"","elevation":"2","color":"blue","small":"","x-small":"","title":"Subindicadores"},on:{"click":function($event){return _vm.showSubIndicadores(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-arrange-bring-to-front")])],1)],1)]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
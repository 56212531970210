<template>
  <div>
  <v-overlay :value="overlay" :absolute="absolute"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>
        <v-data-table
          :headers="headers"
          :items="clases"
        >        
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Clases</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >Nueva Clase</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Agregar Clase</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="">
                     <v-text-field v-model="nombre" label="Nombre"></v-text-field> 
                  </v-col>
                  <v-col cols="12" sm="12" md="0">
                     <v-text-field v-model="descripcion" label="Descripión"></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="guardarClase(nombre,descripcion)">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="dialogRequisito" max-width="600px">
          <div class="grey--text text-darken-1 mb-2">
          <v-card>
            <v-card-title>
              <span class="headline">Detalles Clase: {{ nombreClase }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="12">
                    <v-select    
                    label='Clase Precedente:'    
                    v-model='idClasePrecedente'
                    :items='clasesPrecedente'
                    item-value='id'
                    item-text='nombre'
                    return-object
                    @change="setClasePrecedente()"
                    >
                    <template slot='selection' slot-scope='{ item }'>
                        {{ item.nombre }}
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{ item.nombre }}
                    </template> 
                    </v-select>
                </v-col>
                </v-row>
                <v-row align="center">
                  <v-col class="d-flex" cols="8" sm="8">
                    <v-select
                        label='Clase requisito:'
                        v-model='idClaseRequisito'
                        :items='clasesRequisito'
                        item-value='id'
                        item-text='nombre'
                        return-object
                        >
                        <template slot='selection' slot-scope='{ item }'>
                            {{item.nombre}}
                        </template>
                        <template slot='item' slot-scope='{ item }'>
                            {{item.nombre}}
                        </template> 
                      </v-select>
                  </v-col>
                  <v-col class="d-flex" cols="4" sm="4">
                    <v-btn small @click="setClaseRequisito()">Agregar</v-btn>
                  </v-col>
                  <v-col>
                      <v-data-table
                          :headers="headerRequisitos"
                          :items="requisitos"
                      >          
                        <template v-slot:item.actions="{ item }"> 
                            <v-icon
                              small
                              @click="deleteClaseRequisito(item)"
                              title="Eliminar Clase Requisito"
                              color="red"
                            >
                              mdi-delete
                            </v-icon>    
                        </template>
                      </v-data-table> 
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeRequisito">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="guardarClase(nombre,descripcion)">Guardar</v-btn>
            </v-card-actions>
          </v-card>
          </div>
        </v-dialog>     


      </v-toolbar>
    </template>                        
    <template v-slot:item.nombre="{ item }">                    
        <v-text-field
          v-model="item.nombre"
          v-bind:value="item.nombre"
          regular
          dense
          @change="actualizarClaseNombre(item.nombre,item.id)"
        ></v-text-field> 
    </template>
    <template v-slot:item.descripcion="{ item }">                    
        <v-textarea
          v-model="item.descripcion"
          v-bind:value="item.descripcion"
          :rows="1"
          dense
          @change="actualizarClaseDescripcion(item.descripcion,item.id)"
        ></v-textarea> 
    </template>
   
    <template v-slot:item.activo="{ item }">
        <v-switch 
          v-model="item.activo" 
          v-bind:value="item.activo"
          @change="actualizarClaseActiva(item.activo,item.id)"
        ></v-switch>
    </template>
    <template v-slot:item.actions="{ item }"> 
      <v-icon
        small
        @click="deleteClase(item)"
        title="Eliminar Clase"
        color="red"
      >
        mdi-delete
      </v-icon>
      <v-icon
        small
        class="mr-2"
        color="green"
        title="Requisitos"
        @click="getClasePrecedente(item)"
      >
        mdi-clipboard-text-outline
      </v-icon>      
    </template>
  </v-data-table>
  </div>
</template>

<script>
  import axios from 'axios'
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  export default {
    data:() => ({
        dialog: false,
        dialogRequisito:false,
        editedIndex: -1,
        absolute: false,
        opacity: 0.46,
        zIndex: 5,
        idCurso: null,
        course_id:null,
        idPeriodo:'',
        idClaseRequisito:'',
        idClasePrecedente:'',
        idClasePadre:'',
        nombre_curso:'',  
        clases:[],
        clasesPrecedente:[], 
        clasesRequisito:[],
        requisitos:[],       
        nombreClase:'',
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        headers: [
          {text: 'Id',align: 'start',sortable: true,value: 'id',},
          {text: 'Nombre',align: 'start',sortable: true,value: 'nombre'},
          { text: 'Descripción', value: 'descripcion'},
          /* { text: 'Precedente', value: 'clase_id'}, */
          { text: 'Activa', value: 'activo'},
          { text: 'Acciones', value: 'actions', sortable: true },

        ],
        headerRequisitos: [
          {text: 'Id',align: 'start',sortable: true,value: 'id',},
          {text: 'Clase',align: 'start',sortable: true,value: 'nombre'},
          { text: 'Descripción', value: 'descripcion'},
          { text: 'Acciones', value: 'actions', sortable: true },

        ],
        overlay: false,
        absolute:true,
        nombre:'',
        descripcion:'',
        activo:1,
     
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Detalles Clase' : 'Editar Clase'
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },    
    created () {
      this.init()
    },    
    methods: {
    init () {
      this.getClases()
     
    },  
    getClasePrecedente(item){
        console.log('el id clase a requisitar es' +item.id);
        this.idClasePadre = item.id;
        this.idClasePrecedente = item.clase_id
        this.dialogRequisito = true
        this.nombreClase = item.nombre
                    let url = `/api/clases?id=${item.id}`
            this.$http.get(url)
            .then((response) => {
               this.clasesPrecedente = response.data;
               console.log('CLASE PRECEDENTE: '+this.clasesPrecedente)
               
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
          this.getClaseRequisitos()
          this.getRequisitos()
    },
    getClaseRequisitos(){
      console.log('CLASE requisito idclasepadre: '+this.idClasePadre + 'id clase precedente'+this.idClasePrecedente)
            let url = `/api/clases?idClasePadre=${this.idClasePadre}&idClasePrecedente=${this.idClasePrecedente}`
            this.$http.get(url)
            .then((response) => {
               this.clasesRequisito = response.data;
               console.log('CLASE requisito: '+this.clasesRequisito)
               
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
    },
    setClasePrecedente(){
      console.log('clase precedente elegida: '+this.idClasePrecedente.id)
      console.log('clase padre elegida: '+this.idClasePadre)
      this.idClasePrecedente = this.idClasePrecedente.id
           this.$http.post('/api/requisitos', {
            'clase_id':               this.idClasePadre,
            'claserequisito_id':         this.idClaseRequisito.id,
          })
          .then((response) => {
            //actualizar a clases
            this.getClases()
             console.log(response.data)

          })
          .catch(function (error){
            console.log(error)
          }) 
    },
    setClaseRequisito(){
           this.$http.post('/api/requisitos', {
            'clase_id':               this.idClasePadre,
            'claserequisito_id':         this.idClaseRequisito.id,
          })
          .then((response) => {
            this.getRequisitos()
          })
          .catch(function (error){
            console.log(error)
          }) 
    },    
    consultar(id){
      this.idClaseRequisito = id
    },
    getRequisitos(){  
            let url = `/api/requisitos?id=${this.idClasePadre}`
            this.$http.get(url)
            .then((response) => {

               this.requisitos = response.data;

               console.log('clases REQUISITOS: '+ response.data)
               
            })
            .catch(function (error){
                    console.log(error)
            }
          
            ) 
    },    
    guardarClase(nombre,descripcion){
      this.overlay = true;
                this.$http.post('/api/clases', {
                params: {
                'nombre':           nombre,
                'activo':           1,
                'descripcion':      descripcion,
                'atributo':         0
                }
                })
                 .then((response) => {
                    console.log(response.data)
                    this.overlay = false;
                    this.nombre= '';
                    this.descripcion= '';
                    this.activo= '';
                    this.getClases()
                })
                .catch(function (error){
                    console.log(error)
                    this.overlay = false;
                })       
    },
    getClases () {
            let url = '/api/clases'
            this.$http.get(url)
            .then((response) => {
               this.clases = response.data;
               console.log(this.clases)
            })
            .catch(function (error){
                    console.log(error)
            }
          
            )
    },
    deleteClase (item){
      var refs = ''
      refs = confirm('¿Deseas eliminar la clase: ' + item.nombre + '?')
      if (refs) {
          console.log('registro eliminado')
          this.overlay = true;
          let url = `/api/clases/${item.id}`
          this.$http
          .delete(url)
          .then((response) => {
            console.log(response.data)
            this.overlay = false;
            this.getClases()
          })
          .catch(function (error){
            console.log(error)
            this.overlay = false;
          })
      }
      else {
        // cancel
      }       
    },
    deleteClaseRequisito (item){
      var refs = ''
      refs = confirm('¿Deseas eliminar la clase requisito: ' + item.nombre + '?')
      if (refs) {
          this.overlay = true;
          let url = `/api/requisitos/${item.id}`
          this.$http
          .delete(url)
          .then((response) => {
            console.log(response.data)
            this.overlay = false;
            this.getRequisitos()
          })
          .catch(function (error){
            console.log(error)
           this.overlay = false;
          })
      }
      else {
        // cancel
      }       
    },    
    actualizarClaseNombre(nombre,id){
      //console.log('aprovechamiento actualizado: ' + aprovechamiento + 'id:' + id)
      this.overlay = true;
                this.$http.post('/api/clases', {
                params: {
                'id':               id,
                'atributo':         1,
                'nombre':           nombre,
                }
                })
                 .then((response) => {
                    console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                    console.log(error)
                    this.overlay = false;
                }) 
                
    },
    actualizarClaseDescripcion(descripcion,id){
      //console.log('aprovechamiento actualizado: ' + aprovechamiento + 'id:' + id)
      this.overlay = true;
                this.$http.post('/api/clases', {
                params: {
                'id':               id,
                'atributo':         2,
                'descripcion':      descripcion,
                }
                })
                 .then((response) => {
                    console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                    console.log(error)
                    this.overlay = false;
                }) 
                
    },
    actualizarClaseActiva(activo,id){
      if(activo==null)
        activo = 0;
      else
        activo = 1;
      this.overlay = true;
                this.$http.post('/api/clases', {
                params: {
                'id':               id,
                'atributo':         3,
                'activo':           activo,
                }
                })
                 .then((response) => {
                    console.log(response.data)
                    this.overlay = false;
                })
                .catch(function (error){
                    console.log(error)
                    this.overlay = false;
                })          
    },    
    cerrarDialog(){
      this.dialogAcciones = false
    },
    close() {
        this.dialog = false
    },
    closeRequisito(){
        this.dialogRequisito = false
    }
    },
    mounted(){
    } 
  }
</script>
<template>
  <v-container>
    <v-col cols="12">
      <v-row>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/HNCMu9nYBUc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-row>
    </v-col>
    <v-col cols="12">
        <div>
          <chat  :id_evento="1" :es_coach="false"></chat>
<!--          <chat :email_logueado="'nunito102@g.nive.la'" :id_evento="1" :es_coach="false"></chat>-->
        </div>
    </v-col>
  </v-container>
</template>
<script>
import chat from "./../../components/chat"
export default {
  name:'App',
  components:{
    chat
  }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[[_c('v-row',{attrs:{"justify":"center"}},[(_vm.dialogObjetivo)?_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"790"},on:{"input":function($event){_vm.dialogObjetivo = false}}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-message-text-outline")]),_c('h3',[_vm._v("Oferta Académica")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre","outlined":""},model:{value:(_vm.nombre_oferta_academica),callback:function ($$v) {_vm.nombre_oferta_academica=$$v},expression:"nombre_oferta_academica"}}),_c('v-textarea',{attrs:{"label":"Descripción:","rows":3,"outlined":""},model:{value:(_vm.descripcion),callback:function ($$v) {_vm.descripcion=$$v},expression:"descripcion"}}),_c('v-text-field',{attrs:{"label":"Imagen","outlined":""},model:{value:(_vm.imagen),callback:function ($$v) {_vm.imagen=$$v},expression:"imagen"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('v-select',{attrs:{"label":"Curricula:","items":_vm.curriculas,"item-value":"id","item-text":"nombre_curricula","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_curricula)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre_curricula)+" ")]}}],null,false,1387764412),model:{value:(_vm.idCurricula),callback:function ($$v) {_vm.idCurricula=$$v},expression:"idCurricula"}})]],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha inicio","prepend-icon":"mdi-calendar"},on:{"blur":function($event){_vm.date_inicio = _vm.parseDate(_vm.fecha_inicio)}},model:{value:(_vm.fecha_inicio),callback:function ($$v) {_vm.fecha_inicio=$$v},expression:"fecha_inicio"}},'v-text-field',attrs,false),on))]}}],null,false,1814295830),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date_inicio),callback:function ($$v) {_vm.date_inicio=$$v},expression:"date_inicio"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha finalización","prepend-icon":"mdi-calendar"},model:{value:(_vm.fecha_finalizacion),callback:function ($$v) {_vm.fecha_finalizacion=$$v},expression:"fecha_finalizacion"}},'v-text-field',attrs,false),on))]}}],null,false,2379863909),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date_finalizacion),callback:function ($$v) {_vm.date_finalizacion=$$v},expression:"date_finalizacion"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-switch',{attrs:{"label":"Publicado","value":_vm.switchpublicado},model:{value:(_vm.switchpublicado),callback:function ($$v) {_vm.switchpublicado=$$v},expression:"switchpublicado"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha inicio publicación","prepend-icon":"mdi-calendar"},on:{"blur":function($event){_vm.date_inicio_publicacion = _vm.parseDate(_vm.fecha_inicio_publicacion)}},model:{value:(_vm.fecha_inicio_publicacion),callback:function ($$v) {_vm.fecha_inicio_publicacion=$$v},expression:"fecha_inicio_publicacion"}},'v-text-field',attrs,false),on))]}}],null,false,3288611861),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.date_inicio_publicacion),callback:function ($$v) {_vm.date_inicio_publicacion=$$v},expression:"date_inicio_publicacion"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha fin de publicación","prepend-icon":"mdi-calendar"},model:{value:(_vm.fecha_fin_publicacion),callback:function ($$v) {_vm.fecha_fin_publicacion=$$v},expression:"fecha_fin_publicacion"}},'v-text-field',attrs,false),on))]}}],null,false,3930761723),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.date_fin_publicacion),callback:function ($$v) {_vm.date_fin_publicacion=$$v},expression:"date_fin_publicacion"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.guardarUnidad}},[_vm._v(" "+_vm._s(_vm.txtboton)+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.closeImportar()}}},[_vm._v(" Cerrar ")])],1)],1)],1):_vm._e()],1)],_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"green"}},[_vm._v("mdi-message-text-outline")]),_c('h3',[_vm._v("Ofertas Académicas")]),_c('v-spacer')],1),_c('v-card-text',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"color":"green","small":"","title":"Crear Unidad"},on:{"click":function($event){return _vm.showUnidad()}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-message-text-outline")])],1)],1),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-overlay',{attrs:{"value":_vm.overlay,"absolute":_vm.absolute}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.unidades},scopedSlots:_vm._u([{key:"item.publicado",fn:function(ref){
var item = ref.item;
return [((parseInt(item.publicado)>=1))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","title":"Oferta publicada"}},[_vm._v(" mdi-arrow-up-bold-circle-outline ")]):_vm._e(),((parseInt(item.publicado)==0))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange","title":"Oferta No publicada"}},[_vm._v(" mdi-flag-outline ")]):_vm._e()]}},{key:"item.fecha_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_inicio))+" ")]}},{key:"item.fecha_finalizacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_finalizacion))+" ")]}},{key:"item.fecha_inicio_publicacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_inicio_publicacion))+" ")]}},{key:"item.fecha_fin_publicacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_fin_publicacion))+" ")]}},{key:"item.editar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"fab":"","elevation":"2","color":"green","small":"","x-small":"","title":"Editar Oferta Académica"},on:{"click":function($event){return _vm.editOferta(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.eliminar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","elevation":"2","color":"red","small":"","x-small":"","title":"Eliminar Oferta Académica"}},[_c('v-icon',{attrs:{"title":"Eliminar Oferta Académica","color":"white"},on:{"click":function($event){return _vm.deleteOferta(item)}}},[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }